<template>
  <q-card class="dialog">
    <q-linear-progress v-if="this.fetching" indeterminate/>
    <q-card-section class="dialog_content">
      <div class="dialog_header">
        <div class="dialog_headerLeft">
          <p class="dialog_headerTitle">{{ this.type === 'step' ? 'Шаг' : 'Действие' }}:</p>
          <h4 class="dialog_title">{{ this.title || '' }}</h4>
        </div>
        <q-btn @click="() => this.resetData && this.resetData()"
               icon="close" flat round dense
               v-close-popup/>
      </div>
      <q-card-actions align="right" class="text-primary dialog_act">
        <CustomInput :value="this.searchTerm" :isSearch="true" label="Поиск" v-debounce:300="this.onSearchChange"/>
        <q-btn label="Сохранить" @click="this.onAddSubmit" color="primary" size="sm" padding="xs"
               :loading="this.loading"/>
      </q-card-actions>
      <custom-table
          :customBody="true" :hide-footer-title="true" :sticky-header="false"
          :on-all-check-click="this.onAllCheck" :checkbox="true"
          :columns="this.addActionColumns"
          :checkbox-value="allSelected" :rowsData="this.computedRowsData">
        <template #customBody="props">
          <q-tr>
            <custom-table-cell align="center">
              <CustomCheckbox
                  :value="this.clientRequestIds.includes(props.row.client_request_id)"
                  @change="(value) => onSelectChange(props.row.client_request_id, value)"
              />
            </custom-table-cell>
            <custom-table-cell align="center">
              <Clickable router-link :href="`/requests/${props.row.client_request_id}`">
                {{ props.row.client_request_id || '' }} / {{ props.row.remont_id || '' }}
              </Clickable>
            </custom-table-cell>
            <custom-table-cell align="left">
              {{ props.row.address }}
            </custom-table-cell>
            <custom-table-cell align="left">
              {{ props.row.current_step }}
            </custom-table-cell>
          </q-tr>
        </template>
      </custom-table>
    </q-card-section>
  </q-card>
</template>

<script>
import CustomInput from "@/components/form/CustomInput";
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import Clickable from "@/components/common/Clickable";
import {
  addGradeStepForRemontFunc,
  gradeAddActionsTableColumns,
  notIncludedRequestFunc
} from "@/components/modal/modals/grade-timeline-step/services";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";

export default {
  name: "add-grade-step-to-remont",
  components: {Clickable, CustomCheckbox, CustomTableCell, CustomTable, CustomInput},
  props: ['resetData', 'grade_step_id', 'title', 'id', 'onSubmit', 'type'],
  data() {
    return {
      addActionColumns: gradeAddActionsTableColumns,
      fetching: false,
      loading: false,
      allSelected: false,
      searchTerm: '',
      stepActivityName: '',
      remonts: [],
      clientRequestIds: [],
    }
  },
  watch: {
    clientRequestIds(newValue) {
      const checkedLength = newValue?.length
      const isAllChecked = checkedLength === this.remonts.length
      if (isAllChecked && !!checkedLength)
        return this.allSelected = true
      if (checkedLength > 0)
        return this.allSelected = ""
      this.allSelected = false
    }
  },
  methods: {
    onSearchChange(value) {
      this.searchTerm = value
    },
    onAllCheck(value) {
      if (!this.remonts?.length) return
      this.allSelected = value
      if(value)
        return this.clientRequestIds = this.remonts?.map(item => item.client_request_id)
      this.clientRequestIds = []
    },
    onSelectChange(client_request_id, value) {
      if (value) {
        return this.clientRequestIds = [...this.clientRequestIds, client_request_id]
      }
      this.clientRequestIds = this.clientRequestIds.filter(id => client_request_id !== id)
    },
    async getData() {
      const args = [this.grade_step_id, this.id]
      this.fetching = true
      const res = await notIncludedRequestFunc[this.type || 'step'](...args)
      this.fetching = false
      if (!res) return
      this.remonts = res || []
    },
    async onAddSubmit() {
      const args = [this.grade_step_id, this.clientRequestIds, this.id]
      this.loading = true
      const res = await addGradeStepForRemontFunc[this.type || 'step'](...args)
      this.loading = false
      if (!res) return
      this.showSucceedSnackbar()
      this.clientRequestIds = []
      this.onSubmit && this.onSubmit(res)
    }
  },
  computed: {
    computedRowsData() {
      if (!this.searchTerm) return this.remonts
      return this.remonts?.filter(item => String(item.client_request_id)?.toLowerCase().includes(this.searchTerm)
          || String(item.remont_id)?.toLowerCase().includes(this.searchTerm)
          || String(item.address)?.toLowerCase().includes(this.searchTerm.toLowerCase())
          || String(item.current_step).toLowerCase().includes(this.searchTerm))
    },
  },
  mounted() {
    this.getData()
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar();
    return {showSucceedSnackbar};
  },
}
</script>

<style scoped lang="scss">
.dialog {
  position: relative;
  padding-bottom: 30px;
  min-width: 350px;
  @media (max-width: 370px) {
    min-width: 100%;
  }

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    margin-bottom: 10px;

    &Left {
      display: flex;
      flex-direction: column;
      gap: 0;
    }

    &Title {
      font-size: 12px;
      color: #4e565d;
      padding: 0;
      margin: 0;
    }
  }

  &_title {
    font-size: 16px;
    margin: 0;
    line-height: 16px;
  }

  &_footer {
    width: 100%;
  }

  &_act {
    display: flex;
    justify-content: space-between;
    gap: 5px;
  }
}
</style>