<template>
  <FetchingWrapper :loading="this.isFetching" :data="this.data">
    <div class="checkList__wrapper">
      <q-tabs
          v-model="tab"
          align="left"
          class="bg-white text-dark"
          dense
      >
        <q-tab v-for="item in this.data" :name="item.component" :label="item.grant_name"/>
      </q-tabs>
      <q-tab-panels v-model="tab" animated>
        <q-tab-panel class="tabPanel" v-for="item in this.data" :name="item.component">
          <component :is="item.component" :data="item.data" :client_request_id="this.client_request_id"/>
        </q-tab-panel>
      </q-tab-panels>
    </div>
  </FetchingWrapper>
</template>

<script>
import CheckListBlock from "./checkList"
import CheckListDraftBlock from "./checkListDraftBlock"
import {getCheckListData} from "@/views/ClientRequests/checkList/services";
import FetchingWrapper from "@/components/common/FetchingWrapper";

export default {
  name: "CheckList",
  components: {FetchingWrapper, CheckListBlock, CheckListDraftBlock},
  props: ["client_request_id"],
  data() {
    return {
      isFetching: false,
      data: [],
      tab: 'CheckListBlock'
    }
  },
  methods: {
    async getData() {
      this.isFetching = true
      const res = await getCheckListData(this?.client_request_id)
      this.isFetching = false
      if (!res) return
      this.data = res
    },
  },
  mounted() {
    this.getData()
  },
}
</script>

<style scoped lang="scss">

.checkList {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
}
.tabPanel {
  @media (max-width: 576px) {
    padding: 5px;
  }
}
</style>