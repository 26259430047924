<template>
  <Block title="Интеграция с 1С">
    <template #actions>
    </template>
    <div class="requisites__content">
      <div>
        <p class="requisites__content_title">Интеграция с 1С SR</p>
        <div class="requisites__content_values">
          <BlockItem title="ГУИД">{{ this.data?.guid }}</BlockItem>
          <BlockItem title="Статус">{{ this.data?.is_send_to_1c_text }}</BlockItem>
        </div>
      </div>
      <hr class="requisites__content_gap">
      <div>
        <p class="requisites__content_title">Интеграция с 1С BIG</p>
        <BlockItem title="Данные с 1С">{{ this.data?.is_send_to_1c_text }}</BlockItem>
      </div>
      <q-btn class="integration__data_btn" size="sm" color="primary" label="Данные с 1С">
        <q-popup-proxy>
          <q-banner>
            <p class="integration__data_title" v-for="item in this.jsonData"><span>{{`${item.label}: `}}</span>{{item.value}}</p>
          </q-banner>
        </q-popup-proxy>
      </q-btn>
    </div>
  </Block>
</template>

<script>
import Block from "@/components/common/Block";
import BlockItem from "@/components/common/BlockItem";

export default {
  name: "integrationBlock",
  props: ["data"],
  components: {Block, BlockItem},
  computed: {
    jsonData() {
      if(!this.data?.json_pretty_1c) return []
      const parsedJson = JSON.parse(this.data.json_pretty_1c)
      return Object.keys(parsedJson).map(item => {
        return {label: item.replaceAll("_", " "), value: parsedJson[item]}
      })
    }
  }
}
</script>

<style scoped lang="scss">
.requisites {
  &__content {

    &_title {
      color: #313131;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin: 0 0 10px;
    }

    &_values {
      display: flex;
      flex-direction: column;
      gap: 10px;

    }

    &_gap {
      margin: 10px 0;
      border-color: #e9e9e9;
    }
  }
}
.integration {
  &__data {
    &_btn {
      margin-top: 10px;
    }
    &_title {
      margin: 0;

      span {
        font-weight: 500;
      }
    }
  }
}
</style>