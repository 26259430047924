<template>
  <div class="measures">
    <h2 class="measures__title">
      {{ this.header?.planirovka_name }}
    </h2>
    <div class="measures__confirm">
      <CustomCheckbox
          color="orange"
          label="Замеры подтверждены"
          :value="!!this.header?.is_measure_confirm"
          @change="e => handleConfirmCheckboxChange(e)"
      />
      <CustomLoader small v-if="this.confirmLoading"/>
    </div>
    <div class="measures__content">
      <ul class="measures__list">
        <li v-for="item in this.rooms">
          <CustomCheckbox
              :value="!!item.planirovka_room_id"
              @change="e => handleCheckboxChange(e, item.room_id)"
          />
          <Clickable
              @click="() => !!item.planirovka_room_id && this.handleSelectRoom(item.planirovka_room_id)"
              alt-mode
          >
            <span
                :class="`measures__list_name ${item.planirovka_room_id === this.activeRoomId ? 'active' : ''}`"
            >
              {{ item.room_name }}
              <CustomLoader v-if="this.loadingRooms[item.room_id]" small/>
            </span>
          </Clickable>
        </li>
      </ul>
      <div>
        <div v-if="!!this.activeRoomId">
          <CustomLoader v-if="isFetching"/>
          <div v-else>
            <div class="measures__content_header">
              <BackBtn @click="resetActiveRoom"/>
              <span
                  v-if="this.activeRoomData?.header"
              >{{ `${this.activeRoomData?.header?.room_name}, ${this.activeRoomData?.header?.planirovka_name}` }}</span
              >
            </div>
            <div class="measures__content_inputs">
              <div v-for="item in this.activeRoomData?.measures">
                <label
                    class="measures__content_inputsLabel"
                    :for="item.param_code"
                >{{ item.param_name }}
                  <span
                      class="measures__content_inputsCode"
                  >{{ item.param_code }}</span
                  ></label>
                <CustomInput
                    type="number"
                    label=""
                    :id="item.param_code"
                    :value="item.param_value"
                    @change_input="(value) => onInputChange(item.param_code, value)"
                />
              </div>
            </div>
            <q-btn
                v-if="this.activeRoomData?.measures?.length"
                @click="handleSaveMeasure"
                :loading="this.measureLoading"
                :disable="this.measureLoading"
                color="primary"
                class="measures__content_submit"
            >Сохранить
            </q-btn>
          </div>
        </div>
        <h3 v-else class="measures__content_title">
          Кликните по названию комнаты, чтобы внести замеры
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import {remontStagesColumns} from "@/views/ClientRequests/remontStages/services";
import CustomIcon from "@/components/common/CustomIcon";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import Clickable from "@/components/common/Clickable";
import {
  getRemontMeasureRoomData, addMeasureRoom,
  saveMeasureRoomData,
  confirmMeasure
} from "@/views/ClientRequests/remontMeasure/services";
import CustomInput from "@/components/form/CustomInput";
import CustomLoader from "@/components/common/CustomLoader";
import BackBtn from "@/components/common/BackBtn";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";

export default {
  name: "measureBlock",
  components: {BackBtn, CustomLoader, CustomInput, Clickable, CustomCheckbox, CustomIcon, CustomTable, CustomTableCell},
  props: ["data", "client_request_id"],
  data() {
    return {
      remontStagesColumns,
      rooms: this.data?.rooms || [],
      header: this.data?.header || {},
      activeRoomId: null,
      activeRoomData: null,
      isFetching: false,
      measureLoading: false,
      confirmLoading: false,
      loadingRooms: {},
    }
  },
  methods: {
    async handleConfirmCheckboxChange() {
      this.confirmLoading = true
      const res = await confirmMeasure(this.client_request_id, {planirovka_id: this?.header.planirovka_id})
      this.confirmLoading = false
      if (!res) return
      const {rooms, header} = res
      this.header = header
      this.rooms = rooms
    },
    async handleCheckboxChange(checked, room_id) {
      this.loadingRooms[room_id] = true
      const res = await addMeasureRoom(this.client_request_id, {
        room_id,
        planirovka_id: this?.header.planirovka_id
      })
      this.loadingRooms[room_id] = false
      if (!res) return
      this.resetActiveRoom()
      const {rooms, header} = res
      this.header = header
      this.rooms = rooms
    },
    resetActiveRoom() {
      this.activeRoomId = null
      this.activeRoomData = null
    },
    handleSelectRoom(roomId) {
      this.activeRoomId = roomId
    },
    onInputChange(param_code, param_value) {
      if (!this.activeRoomData) return
      this.activeRoomData = {
        ...this.activeRoomData, measures: this.activeRoomData.measures?.map(item => {
          if (item.param_code === param_code)
            return {...item, param_value}
          return item
        })
      }
    },
    async getRoomData() {
      if (!this.activeRoomId) return
      this.isFetching = true
      this.activeRoomData = await getRemontMeasureRoomData(this.client_request_id, this.activeRoomId)
      this.isFetching = false
    },
    async handleSaveMeasure() {
      const body = {data: this.activeRoomData?.measures}
      this.measureLoading = true
      const res = await saveMeasureRoomData(this.client_request_id, this.activeRoomId, body)
      this.measureLoading = false
      if (!res) return
      const {header, measures, rooms, planirovka_header} = res
      this.header = planirovka_header
      this.rooms = rooms
      this.activeRoomData.measures = measures
      this.activeRoomData.header = header
      this.showSucceedSnackbar()
    }
  },
  watch: {
    activeRoomId() {
      this.getRoomData()
    }
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar()
    return {showSucceedSnackbar}
  }
}
</script>

<style scoped lang="scss">
.measures {
  &__content {
    display: grid;
    gap: 40px;
    grid-template-columns: 3fr 6fr 3fr;

    @media (max-width: 768px) {
      grid-template-columns: 6fr 6fr;
      gap: 15px;
    }

    &_header {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 20px;
      display: flex;
      gap: 15px;
      flex-direction: column;
    }

    &_title {
      font-size: 16px;
      color: #8f8f8f;
      margin: 0;
      text-align: center;
      line-height: 25px;
    }

    &_inputs {
      display: flex;
      flex-direction: column;
      gap: 20px;

      &Label {
        margin-bottom: -14px;
        display: block;
      }

      &Code {
        margin-left: 2px;
        color: #1f5ac2;
      }
    }

    &_submit {
      margin-top: 15px;
    }
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;

    & > li {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      white-space: nowrap;
    }

    &_name {
      display: flex;
      align-items: center;
      gap: 5px;

      &.active {
        color: #00c90a;
        text-decoration: underline;
      }
    }
  }

  &__title {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    color: #1f5ac2;
    margin: 0;
  }

  &__confirm {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 345px;

    & > div {
      white-space: nowrap;
    }
  }
}
</style>
