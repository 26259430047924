import {handbookWorksetsAPI} from "@/views/handbook/WorkSets/api";

export const getHandbookWorksets = async () => {
  try {
    const res = await handbookWorksetsAPI.getList()
    return res?.data
  } catch (e) {}
}
export const getWorksetCheckGroups = async () => {
  try {
    const res = await handbookWorksetsAPI.getWorksetCheckGroups()
    return res?.data
  } catch (e) {}
}
export const getSpecialities = async () => {
  try {
    const res = await handbookWorksetsAPI.getSpecialities()
    return res?.data
  } catch (e) {}
}
export const updateHandbookWorkset = async (work_set_id, body) => {
  try {
    const res = await handbookWorksetsAPI.updateHandbookWorkset(work_set_id, body)
    return res?.data
  } catch (e) {}
}

export const handbookWorksetsColumns = [
  {
    name: "work_set_id",
    field: "work_set_id",
    label: "ID",
    align: "center",
  },
  {
    name: "work_set_name",
    field: "work_set_name",
    label: "Конструктив",
    align: "left",
  },
  {
    name: "work_set_check_group_name",
    field: "work_set_check_group_name",
    label: "Группа работ",
    align: "left",
  },
  {
    name: "speciality_name",
    field: "speciality_name",
    label: "Специализация",
    align: "left",
  },
  {
    name: "is_call",
    field: "is_call",
    label: "Вызов мастера",
    align: "center",
  },
  {
    name: "is_service",
    field: "is_service",
    label: "Поставщик SR",
    align: "left",
  },
  {
    name: "is_pay_online",
    field: "is_pay_online",
    label: "Оплата онлайн",
    align: "left",
  },
  {
    name: "service_call_work_set_group_name",
    field: "service_call_work_set_group_name",
    label: "Зависимая группа работ",
    align: "left",
  },
  {
    name: "actions",
    field: "actions",
    label: "Действие",
    align: "center",
  },
]