import {checkListAPI} from "@/views/ClientRequests/checkList/api";

const checkListBlockNames = {
  OA__RemontFormCheckListStageBlock: "CheckListBlock",
  OA__RemontFormCheckListDraftBlock: "CheckListDraftBlock",
}
export const getCheckListData = async (client_request_id) => {
  try {
    const res = await checkListAPI.getList(client_request_id)
    const data = res?.data
    if (!data?.length) return
    return data?.map(item => ({...item, component: checkListBlockNames[item.grant_code]}))
  } catch (e) {
  }
}
export const checkListColumns = [
  {
    name: "check_name",
    label: "Название",
    field: "check_name",
    align: "left",
  },
  {
    name: "norm",
    label: "Норма",
    field: "norm",
    align: "right",
  },
  {
    name: "cnt_standart",
    label: "Стандарты",
    field: "cnt_standart",
    align: "right",
  },
  {
    name: "photo_cnt",
    label: "Фото",
    field: "photo_cnt",
    align: "right",
  },
  {
    name: "comments",
    label: "Комментарии",
    field: "comments",
    align: "left",
  },
  {
    name: "fio",
    label: "Работник",
    field: "fio",
    align: "left",
  },
  {
    name: "is_required",
    label: "Обязательно",
    field: "is_required",
    align: "center",
  },
  {
    name: "is_okk_manager",
    label: "Должность",
    field: "is_okk_manager",
    align: "center",
  },
  {
    name: "date_create",
    label: "Дата",
    field: "date_create",
    align: "center",
  },
  {
    name: "is_accepted",
    label: "Статус",
    field: "is_accepted",
    align: "center",
  },
]
export const roughFinishColumns = [
  {
    name: "name",
    label: "Название",
    align: "left",
  },
  {
    name: "photo",
    label: "Фото",
    align: "center",
  },
  {
    name: "comments",
    label: "Комментарии",
    align: "left",
  },
  {
    name: "fio",
    label: "Работник",
    align: "left",
  },
  {
    name: "date_create",
    label: "Дата создания",
    align: "center",
  },
]