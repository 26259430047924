import instance from "@/services/api";

export const techFeaturesAPI = {
  async getList(client_request_id) {
    return await instance()
      .get(`/client_request/${client_request_id}/tech_feature/read/`)
      .then((res) => res?.data);
  },
  async update(client_request_id, body) {
    return await instance()
      .put(`/client_request/${client_request_id}/tech_feature/update/`, body)
      .then((res) => res?.data);
  },
};
