<template>
  <Block title="Информация о ремонте">
    <template #actions>
    </template>
    <div class="requisites__content">
      <div>
        <BlockItem title="ЖК">{{ this.data?.resident_name }}</BlockItem>
        <BlockItem title="Класс">{{ this.data?.resident_class_name }}</BlockItem>
        <BlockItem title="Планировка">{{ this.data?.room_cnt ? `${this.data?.room_cnt} комн., ${this.data?.area} кв.м.` : '' }}</BlockItem>
        <BlockItem title="Город">{{ this.data?.city_name }}</BlockItem>
        <BlockItem title="Статус ЧО">{{ this.data?.draft_accept_name }}</BlockItem>
        <BlockItem title="Пресет">{{ this.data?.preset_name }}</BlockItem>
        <BlockItem title="Площадь">{{ this.data?.area ? `${this.data?.area} кв.м.` : '' }}</BlockItem>
        <BlockItem title="Адрес ЖК ">{{ this.data?.prop_district_address }}</BlockItem>
        <BlockItem title="Номер квартиры">{{ this.data?.flat_num }}</BlockItem>
      </div>
      <div>
        <BlockItem title="Срок работы">{{ this.data?.prop_term }}</BlockItem>
        <BlockItem title="Договор №">{{ this.data?.prop_number }}</BlockItem>
        <BlockItem title="Дата договора">{{ this.data?.prop_date }}</BlockItem>
        <BlockItem title="Дата начала ремонта по договору">{{ this.data?.remont_begin_date }}</BlockItem>
        <BlockItem title="Дата окончания ремонта по договору">{{ this.data?.remont_end_date }}</BlockItem>
        <BlockItem title="Вид ремонта">{{ this.data?.remont_type_1c_name }}</BlockItem>
        <BlockItem title="Начало ремонта">{{ this.data?.choose_remont_begin_name }}</BlockItem>
        <BlockItem title="Точка продажи">{{ this.data?.point_name }}</BlockItem>
      </div>
    </div>
  </Block>
</template>

<script>
import Block from "@/components/common/Block";
import BlockItem from "@/components/common/BlockItem";

export default {
  name: "remontInfoBlock",
  props: ["data"],
  components: {Block, BlockItem},
}
</script>

<style scoped lang="scss">
.requisites {
  &__content {
    display: grid;
    gap: 25px;
    grid-template-columns: 6fr 6fr;

    & > div {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    @media (max-width: 400px) {
      grid-template-columns: 12fr;
    }
  }
}
</style>