import {noticeTemplatesAPI} from "@/views/handbook/EmailTemplates/api";

export const getNoticeTemplates = async () => {
  try {
    const res = await noticeTemplatesAPI.getList()
    return res?.data
  } catch (e) {
  }
}
export const addNoticeTemplate = async (body = {}) => {
  try {
    const res = await noticeTemplatesAPI.addTemplate(body)
    return res?.data
  } catch (e) {
  }
}
export const updateNoticeTemplate = async (notice_template_id, body = {}) => {
  try {
    const res = await noticeTemplatesAPI.updateTemplate(notice_template_id, body)
    return res?.data
  } catch (e) {
  }
}
export const deleteNoticeTemplate = async (notice_template_id) => {
  try {
    const res = await noticeTemplatesAPI.deleteTemplate(notice_template_id)
    return res?.data
  } catch (e) {
  }
}

export const noticeTemplatesColumns = [
  {
    name: "notice_template_name",
    field: "notice_template_name",
    label: "Название",
    align: "left",
  },
  {
    name: "notice_template_code",
    field: "notice_template_code",
    label: "Код",
    align: "left",
  },
  {
    name: "notice_template_content",
    field: "notice_template_content",
    label: "Текст",
    align: "left",
  },
]