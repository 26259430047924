import {
  financePaymentsFiltersAPI
} from "./api";
import {financePaymentsAPI} from "@/views/FinancePayments/api";
import {getCommonContractors, getProvidersData} from "@/views/Costs/СostsFilters/services";

export const getFiltersMetaData = async () => {
  return await Promise.all([
    getProvidersData(),
    getCommonContractors(),
    financePaymentsFiltersAPI.getFinancePaymentStatuses(),
    financePaymentsFiltersAPI.getFinancePaymentTypes(),
    financePaymentsAPI.getTechprojectDevelopers(),
  ]).catch(() => {
    return null;
  });
};
