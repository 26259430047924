<template>
  <div class="header">
    <q-btn :disable="!Object.keys(this.checked).some(key => !!this.checked[key])" color="red" icon-right="delete"
           label="Удалить" size="sm" padding="sm" class="removeBtn">

      <PopupConfirm
          :loading="this.deleteLoading"
          :approve="this.onDeleteSteps"
      />
    </q-btn>
  </div>
  <custom-table
      title="Предшествующие связанные шаги"
      :customBody="true" :hide-footer-title="true" :loading="this.isFetching"
      :columns="this.columns" :rowsData="this.rowsData" :checkboxValue="true"
      :show-header-add="true" :checkbox="true" :on-all-check-click="this.handleAllCheck"
      :checkbox-value="this.allSelected" :on-add-click="this.handleAdd">
    <template #customBody="props">
      <q-tr>
        <custom-table-cell align="center">
          <CustomCheckbox
              label=""
              :value="!!this.checked[props.row.grade_step_link_id]"
              @change="(value) => handleCheckChange(props.row.grade_step_link_id, value)"
          />
        </custom-table-cell>
        <custom-table-cell align="center">
          {{ props.row.step_id }}
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.step_name }}
        </custom-table-cell>
      </q-tr>
    </template>
  </custom-table>
  <q-dialog v-model="this.showDialog" @update:model-value="this.handleDialog">
    <q-card>
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6">Добавить шаги</div>
        <q-space/>
        <q-btn icon="close" flat round dense v-close-popup/>
      </q-card-section>
      <q-card-section style="max-width: 300px" class="row items-center q-pb-none">
        <CustomSelect
            :options="this.steps"
            label="Шаг" :full-width="true"
            optionValueKey="step_id"
            optionLabelKey="step_name"
            :multiple="true"
            :value="this.gradeStepIds"
            @change="this.onChange"
        />
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat label="Отмена" color="red" v-close-popup/>
        <q-btn flat label="Добавить" @click="this.onAddSteps" :loading="this.loading" color="primary"/>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import CustomIcon from "@/components/common/CustomIcon";
import CustomLoader from "@/components/common/CustomLoader";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import {
  addNestedGradeSteps,
  deleteNestedGradeSteps,
  getNestedGradeSteps,
  gradeNestedStepsColumns
} from "@/views/handbook/gradeTimelineSteps/services";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import CustomSelect from "@/components/form/CustomSelect";
import {getTimelineStepsIndividual} from "@/views/handbook/timelineSteps/services";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";
import PopupConfirm from "@/components/common/PopupConfirm";

export default {
  name: "nested-grade-timeline-steps",
  components: {PopupConfirm, CustomSelect, CustomCheckbox, CustomTableCell, CustomLoader, CustomIcon, CustomTable},
  props: ['data'],
  data() {
    return {
      columns: gradeNestedStepsColumns,
      loading: false,
      deleteLoading: false,
      steps: [],
      checked: {},
      rowsData: [],
      gradeStepIds: [],
      isFetching: false,
      showDialog: false,
    }
  },
  computed: {
    allSelected() {
      if (!this.rowsData?.length) return false
      return this.rowsData.every(item => !!this.checked[item.grade_step_link_id])
    }
  },
  methods: {
    async getData() {
      this.isFetching = true
      const res = await getNestedGradeSteps(this.data?.gradeId, this.data?.step_id)
      this.isFetching = false
      this.rowsData = res || []
    },
    async onAddSteps() {
      this.loading = true
      const res = await addNestedGradeSteps(this.data?.gradeId, this.data?.step_id, this.gradeStepIds)
      this.loading = false
      if (!res) return
      this.rowsData = res || []
      this.showDialog = false
      this.showSucceedSnackbar()
      this.gradeStepIds = []
      this.checked = {}
    },
    async onDeleteSteps() {
      this.deleteLoading = true
      const res = await deleteNestedGradeSteps(this.data?.gradeId, this.data?.step_id, this.checked)
      this.deleteLoading = false
      if (!res) return
      this.checked = {}
      this.rowsData = res || []
      this.showSucceedSnackbar()
    },
    onChange(value) {
      this.gradeStepIds = value || []
    },
    handleDialog(value) {
      if (!value) {
        this.gradeStepIds = []
      }
    },
    handleAllCheck(value) {
      if (value) {
        const object = {};
        this.rowsData.forEach(item => {
          object[item.grade_step_link_id] = true;
        });
        return this.checked = object
      }
      this.checked = {}
    },
    handleCheckChange(id, value) {
      this.checked[id] = value
    },
    handleAdd() {
      this.showDialog = true
      this.getSteps()
    },
    async getSteps() {
      if (!!this.steps?.length) return
      const res = await getTimelineStepsIndividual(this.data?.gradeId, this.data?.step_id)
      this.steps = res || []
    },
  },
  mounted() {
    this.getData()
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar();
    return {showSucceedSnackbar};
  },
}
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: flex-end;
}

.removeBtn {
  margin: 10px 0
}
</style>