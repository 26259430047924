import instance from "@/services/api";

export const remontPaymentsAPI = {
  async getList(client_request_id) {
    return await instance().get(`/client_request/${client_request_id}/payments/read/`).then(res => res?.data)
  },
  async getCostsTypes() {
    return await instance().get('/common/remont_costs_types/read/').then(res => res?.data)
  },
  async getPaymentDetail(client_request_id, body) {
    return await instance().post(`/client_request/${client_request_id}/payments/cost/item/read/`, body).then(res => res?.data)
  },
  async addTranche(client_request_id, body) {
    return await instance().post(`/client_request/${client_request_id}/payments/tranche/add/`, body).then(res => res?.data)
  },
  async editTranche(client_request_id, client_request_tranche_id, body) {
    return await instance().put(`/client_request/${client_request_id}/payments/tranche/${client_request_tranche_id}/update/`, body).then(res => res?.data)
  },
  async deleteTranche(client_request_id, client_request_tranche_id) {
    return await instance().delete(`/client_request/${client_request_id}/payments/tranche/${client_request_tranche_id}/delete/`).then(res => res?.data)
  },
  async addIncome(client_request_id, body) {
    return await instance().post(`/client_request/${client_request_id}/payments/client_payment/add/`, body).then(res => res?.data)
  },
  async editIncome(client_request_id, client_request_payment_id, body) {
    return await instance().put(`/client_request/${client_request_id}/payments/client_payment/${client_request_payment_id}/update/`, body).then(res => res?.data)
  },
  async deleteIncome(client_request_id, client_request_payment_id) {
    return await instance().delete(`/client_request/${client_request_id}/payments/client_payment/${client_request_payment_id}/delete/`).then(res => res?.data)
  },
  async changeClientType(client_request_id, client_request_payment_id) {
    return await instance().post(`/client_request/${client_request_id}/payments/client_payment/${client_request_payment_id}/client_type/change/`).then(res => res?.data)
  },
  async addCost(client_request_id, body) {
    return await instance().post(`/client_request/${client_request_id}/payments/cost/add/`, body).then(res => res?.data)
  },
  async editCost(client_request_id, remont_costs_id, body) {
    return await instance().put(`/client_request/${client_request_id}/payments/cost/${remont_costs_id}/update/`, body).then(res => res?.data)
  },
  async deleteCost(client_request_id, remont_costs_id) {
    return await instance().delete(`/client_request/${client_request_id}/payments/cost/${remont_costs_id}/delete/`).then(res => res?.data)
  },
}