import instance from "@/services/api";

export const noticeTemplatesAPI = {
  async getList() {
    return await instance().get('/handbook/notice_templates/read/').then(res => res?.data)
  },
  async addTemplate(body) {
    return await instance().post('/handbook/notice_templates/add/', body).then(res => res?.data)
  },
  async updateTemplate(notice_template_id, body) {
    return await instance().put(`/handbook/notice_templates/${notice_template_id}/update/`, body).then(res => res?.data)
  },
  async deleteTemplate(notice_template_id) {
    return await instance().delete(`/handbook/notice_templates/${notice_template_id}/update/`).then(res => res?.data)
  }
}

