<template>
  <q-form @submit="this.onSubmit" class="form">
    <div class="form__item">
      <CustomSelect
          :options="this.rooms"
          label="Выберите комнату"
          :required="true"
          optionValueKey="room_id"
          optionLabelKey="room_name"
          :value="this.formData.room_id || ''"
          @change="(value, row) => onChange('room_id', value, row)"
      />
    </div>
    <div class="form__item">
      <CustomInput
          label="Площадь" type="number"
          :value="this.formData.room_area" :required="true"
          @change_input="(value) => onChange('room_area', value)"/>
    </div>
    <CustomModalFooter :btn-loading="this.loading"/>
  </q-form>
</template>
<script>

import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomSelect from "@/components/form/CustomSelect";
import CustomInput from "@/components/form/CustomInput";
import {getDSRooms} from "@/views/ClientRequests/remontDS/services";

export default {
  name: "add-room",
  components: {CustomInput, CustomSelect, CustomModalFooter},
  props: ['data'],
  data() {
    return {
      loading: false,
      isFetching: false,
      rooms: [],
      formData: {
        room_id: this.data?.body?.room_id || '',
        room_area: this.data?.body?.room_area || '',
      },
    }
  },
  mounted() {
    this.getRooms()
  },
  methods: {
    onChange(key, value, room) {
      this.formData[key] = value
      if (room)
        this.formData.room = value ? room : null
    },
    async onSubmit() {
      this.loading = true
      await this.data?.onSubmit({...this.formData.room, room_area: this.formData.room_area})
      this.loading = false
    },
    async getRooms() {
      this.isFetching = true
      const res = await getDSRooms(this.data.client_request_id, this.data.ds_id, {room_ids: this.data.room_ids})
      this.isFetching = false
      if (!res) return
      this.rooms = res
    },
  },
}
</script>

<style scoped lang="scss">
.form {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  gap: 20px;

  @media (max-width: 428px) {
    min-width: 100%;
  }
}
</style>
