<template>
  <CollapseBlock class-name="filters">
    <FiltersWrapper>
      <CustomDate
          placeholder="Дата от"
          :value="this.values.created_date_begin || ''"
          @change_date="(value) => onChange('created_date_begin', value)"
      />
    </FiltersWrapper>
    <FiltersWrapper>
      <CustomDate
          placeholder="Дата до"
          :value="this.values.created_date_end || ''"
          @change_date="(value) => onChange('created_date_end', value)"
      />
    </FiltersWrapper>

    <div class="filters__item">
      <CustomSelect
          :options="this.paymentTypes"
          label="Тип платежа"
          optionValueKey="finance_payment_type_id"
          optionLabelKey="finance_payment_type_name"
          :value="this.values.finance_payment_type_id || ''"
          @change="(value) => onChange('finance_payment_type_id', value)"
      />
    </div>
    <div class="filters__item">
      <CustomSelect
          :options="this.paymentStatuses"
          label="Статус платежа"
          optionValueKey="finance_payment_status_id"
          optionLabelKey="finance_payment_status_name"
          :value="this.values.finance_payment_status_id || ''"
          @change="(value) => onChange('finance_payment_status_id', value)"
      />
    </div>
    <div class="filters__item">
      <CustomSelect
          :options="this.contractors"
          label="Подрядчик" :multiple="true"
          optionValueKey="contractor_id"
          optionLabelKey="contractor_name"
          :value="this.values.contractor_ids"
          @change="(value) => onChange('contractor_ids', value)"
      />
    </div>
    <div class="filters__item">
      <CustomSelect
          :options="this.providers"
          label="Поставщик" :multiple="true"
          optionValueKey="provider_id"
          optionLabelKey="provider_name"
          :value="this.values.provider_ids"
          @change="(value) => onChange('provider_ids', value)"
      />
    </div>
    <div class="filters__item">
      <CustomSelect
          :options="this.techprojectDevelopers"
          label="Разработчик тех. проекта" :multiple="true"
          optionValueKey="techproject_developer_id"
          optionLabelKey="techproject_developer_name"
          :value="this.values.techproject_developer_ids"
          @change="(value) => onChange('techproject_developer_ids', value)"
      />
    </div>
    <div class="filters__item">
      <CustomInput :value="this.values.client_request_id" type="number" label="client_request_id"
                   @clear_input="onChange('client_request_id', '')"
                   v-debounce:600="(value) => onChange('client_request_id', value)"/>
    </div>
    <div class="filters__item">
      <CustomInput :value="this.values.finance_payment_id_filter" type="number" label="ID платежного поручения"
                   @clear_input="onChange('finance_payment_id_filter', '')"
                   v-debounce:600="(value) => onChange('finance_payment_id_filter', value)"/>
    </div>
    <CustomCheckbox :value="this.values.is_return" label="Возврат" :with-null="true"
                    @change="(value) => onChange('is_return', value)"/>
  </CollapseBlock>
</template>

<script>
import CustomSelect from "@/components/form/CustomSelect";
import CustomDate from "@/components/form/CustomDate";
import CustomInput from "@/components/form/CustomInput";
import {getFiltersMetaData} from "./services";
import CollapseBlock from "@/components/common/CollapseBlock";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import FiltersWrapper from "@/components/common/FiltersWrapper";

export default {
  name: "FinancePaymentsFilters",
  props: ["values"],
  components: {FiltersWrapper, CustomCheckbox, CollapseBlock, CustomSelect, CustomDate, CustomInput},
  data() {
    return {
      providers: [],
      contractors: [],
      paymentStatuses: [],
      paymentTypes: [],
      techprojectDevelopers: [],
    };
  },
  methods: {
    onChange(type, value) {
      this.$emit('filter_change', type, value);
    },
  },
  async mounted() {
    const res = await getFiltersMetaData();
    if (!res) return;
    const [
      providersResponse,
      contractorsResponse,
      paymentStatusesResponse,
      paymentTypesResponse,
      techprojectDevelopersResponse,
    ] = res;
    this.providers = providersResponse;
    this.contractors = contractorsResponse;
    this.paymentStatuses = paymentStatusesResponse.finance_payment_statuses;
    this.paymentTypes = paymentTypesResponse.finance_payment_types;
    this.techprojectDevelopers = techprojectDevelopersResponse.techproject_developers;
  },
};
</script>
<style scoped lang="scss">
.filters {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 15px;
  padding-bottom: 35px !important;

  &__item {
    width: 230px;
    max-width: 230px;
  }
}
</style>
