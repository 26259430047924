<template>
  <FetchingWrapper :loading="this.isFetching" :data="this.data">
    <div class="remontServices__wrapper">
      <div v-for="item in this.data">
        <component :is="item.component" :isFetching="this.isFetching" :client_request_id="this.client_request_id"
                   :data="item?.data"/>
      </div>
    </div>
  </FetchingWrapper>
</template>

<script>
import {getRemontServicesData} from "./services";
import RemontsServicesBlock from "./services.vue";
import FetchingWrapper from "@/components/common/FetchingWrapper";

export default {
  name: "RemontServices",
  components: {FetchingWrapper, RemontsServicesBlock},
  props: ["client_request_id"],
  data() {
    return {
      data: [],
      isFetching: false,
    }
  },
  methods: {
    async getData() {
      this.isFetching = true
      const res = await getRemontServicesData(this?.client_request_id)
      this.isFetching = false
      if (!res) return
      this.data = res
    }
  },
  mounted() {
    this.getData()
  },
}
</script>

<style scoped lang="scss">
.remontServices {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
}

</style>