<template>
  <q-checkbox :label="this.label || ''" class="checkbox" v-model="model" />
</template>

<script>
export default {
  name: "CustomCheckbox",
  props: ["value", "label", "withNull", "binary"],
  methods: {
    checkBinary(value, isBinary = false) {
      if (isBinary) {
        if (value === true)
          return 1
        else if (value === false)
          return 0
      }
      return value
    }
  },
  computed: {
    model: {
      get() {
        return this.binary ? this.value === null ? this.value : !!this.value : this.value;
      },
      set(newValue) {
        if (this.withNull) {
          let checkValue = ""
          if (this.value === null || this.value === '') {
            checkValue = true;
          } else if (this.value === true || this.value === 1) {
            checkValue = false;
          } else if (this.value === false || this.value === 0) {
            checkValue = null;
          }
          return this.$emit("change", this.checkBinary(checkValue, this.binary));
        }
        this.$emit("change", this.checkBinary(newValue, this.binary));
      },
    },
  },
};
</script>

<style scoped lang="scss">
.checkbox {
}
</style>
