<template>
  <CustomTable
    title="Список траншей"
    :rowsData="this.rowsData"
    :columns="remontPaymentsTrancheColumns"
    :custom-body="true"
  >
    <template v-slot:top-right="props">
      <q-btn @click="handleAddClick" color="primary" size="sm" padding="sm">Добавить транш</q-btn>
    </template>
    <template #customBody="props">
      <q-tr>
        <custom-table-cell align="center">
          <Clickable
              @click="() => this.handleEditClick(props.row.client_request_tranche_id, props.row)"
          >
            {{ props.row?.client_request_tranche_id }}
          </Clickable>
        </custom-table-cell>
        <custom-table-cell align="right">
          <Amount :value = "props.row.payment_amount"/>
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.is_agreement_change_text }}
        </custom-table-cell>
        <custom-table-cell align="center">
          {{ props.row.payment_date }}
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.days_overdue }}
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.comments }}
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.status }}
        </custom-table-cell>
        <custom-table-cell align="center">
          <CustomIcon
            icon="trash"
            :approve="() => this.onDeleteSubmit(props.row.client_request_tranche_id)"
            :approve-mode="true"
            :loading="this.deleting"
          />
        </custom-table-cell>
      </q-tr>
    </template>
  </CustomTable>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import {
  addTranche,
  deleteTranche,
  editTranche,
  remontPaymentsTrancheColumns
} from "@/views/ClientRequests/remontPayments/services";
import {appActions} from "@/services/store/modules/app_module/app_actions";
import {MODAL_NAMES} from "@/constants";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";
import CustomIcon from "@/components/common/CustomIcon";
import PopupConfirm from "@/components/common/PopupConfirm";
import Clickable from "@/components/common/Clickable";
import Amount from "@/components/common/Amount";

export default {
  name: "remontPaymentsTranches",
  components: {Amount, Clickable, PopupConfirm, CustomIcon, CustomTable, CustomTableCell},
  props: ["data", 'client_request_id'],
  data() {
    return {
      remontPaymentsTrancheColumns,
      deleting: false,
      rowsData: this.data,
    }
  },
  methods: {
    handleAddClick() {
      this.$store.commit(appActions.showModal(MODAL_NAMES.AddTranche, {
        title: 'Добавить транш',
        onSubmit: this.onSubmit,
      }));
    },
    handleEditClick(id, body) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.AddTranche, {
        title: 'Редактировать транш',
        onSubmit: body => this.onEditSubmit(id, body),
        body
      }));
    },
    async onSubmit(body) {
      const res = await addTranche(this.client_request_id, body)
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    async onEditSubmit(id, body) {
      const res = await editTranche(this.client_request_id, id, body)
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    async onDeleteSubmit(id) {
      this.deleting = true
      const res = await deleteTranche(this.client_request_id, id)
      this.deleting = false
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
    }
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar();
    return {showSucceedSnackbar};
  },
}
</script>

