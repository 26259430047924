import {getFingerKey} from "@/services";
import {resetPassword} from "@/views/ForgetPassword/api";

export const onForgetPasswordSubmit = async (body) => {
  const finger = getFingerKey();
  try {
    const res = await resetPassword({...body, finger});
    return !!res?.status
  } catch (e) {
  }
}