<template>
  <div class="materials">
    <div v-for="item in this.rowsData" class="materials__room">
      <div class="materials__room_title">
        {{ item.room_name }}
      </div>
      <CustomTable
          title=""
          :rowsData="item.materials"
          :columns="designerMeasureColumns"
          :loading="isFetching"
          :custom-body="true"
      >
        <template #customBody="props">
          <q-tr>
            <custom-table-cell align="center">
              {{ props.row.material_id }}
            </custom-table-cell>
            <custom-table-cell align="center">
              <CustomImg
                  class-name="materials__img"
                  :src="props.row.photo_url"
              />
            </custom-table-cell>
            <custom-table-cell align="left">
              {{ props.row.material_name }}
            </custom-table-cell>
            <custom-table-cell align="left">
              {{ props.row.unit_name }}
            </custom-table-cell>
            <custom-table-cell align="left">
              {{ props.row.formula }}
            </custom-table-cell>
            <custom-table-cell align="left">
              <CustomInput
                  :disable="(props.row.material_type_code === 'BATH' && props.row.cnt !== null) || props.row.is_atom_measure === 1"
                  class="materials__input"
                  type="number"
                  :showClearIcon="false"
                  :value="props.row.cnt"
                  @change_input="(value) => this.onInputCountChange(item.room_id, props.row.material_id, props.row.client_material_id, value)"
              />
            </custom-table-cell>
            <custom-table-cell align="left">
              <CustomCheckbox
                  :binary="true"
                  :value="!!props.row.is_remove"
                  @change="value => this.onRemoveCheckChange(item.room_id, props.row.material_id, props.row.client_material_id, value)"
              />
            </custom-table-cell>
            <custom-table-cell align="left">
              <div v-if="props.row.is_atom_measure === 1">
                <q-input
                    v-if="props.row.is_input_active"
                    v-model.number="props.row.atom_cnt"
                    type="number"
                    filled
                    dense
                    autofocus
                    @blur="() => this.activeParamInput(item.room_id, props.row.client_material_id, false, props.row.material_id)"
                    style="max-width: 95px"
                />
                <span
                    class="materials__cnt"
                    v-else
                    @click="() => this.activeParamInput(item.room_id, props.row.client_material_id, true, props.row.material_id)"
                >{{ `${props.row.atom_cnt} ${props.row.atom_unit_name}` }}</span
                >
              </div>
            </custom-table-cell>
            <custom-table-cell align="right">
              {{ props.row.material_cnt }}
            </custom-table-cell>
          </q-tr>
        </template>
      </CustomTable>
    </div>
    <q-btn
        v-if="this.rowsData?.length"
        class="materials__submit"
        size="md"
        padding="xs"
        color="primary"
        :loading="this.loading"
        :disable="this.loading"
        @click="handleSubmit"
    >Сохранить
    </q-btn
    >
  </div>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import {changeDataRow, designerMeasureColumns, getMaterialCount, saveDesignMeasureData} from "./services";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import CustomInput from "@/components/form/CustomInput";
import CustomImg from "@/components/common/CustomImg";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";

export default {
  name: "designerMeasureBlock",
  components: {
    CustomImg, CustomInput, CustomCheckbox, CustomTable, CustomTableCell
  },
  props: ["data", "client_request_id"],
  data() {
    return {
      designerMeasureColumns,
      isFetching: false,
      rowsData: this.data,
      loading: false
    }
  },
  methods: {
    changeDataRow,
    async handleSubmit() {
      this.loading = true
      const res = await saveDesignMeasureData(this.client_request_id, this.rowsData)
      this.loading = false
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
    },
    changeRowsData(...args) {
      this.rowsData = this.changeDataRow(this.rowsData, ...args)
    },
    onInputCountChange(room_id, material_id, client_material_id, value) {
      this.changeRowsData(room_id, material_id, client_material_id, 'cnt', value)
    },
    onRemoveCheckChange(room_id, material_id, client_material_id, value) {
      this.changeRowsData(room_id, material_id, client_material_id, 'is_remove', value)
    },
    activeParamInput(room_id, client_material_id, is_input_active, material_id) {
      this.changeRowsData(room_id, material_id, client_material_id, 'is_input_active', is_input_active)
      if (!is_input_active) {
        this.getMaterialCountData(room_id, client_material_id, material_id)
      }
    },
    async getMaterialCountData(room_id, client_material_id, material_id) {
      const res = await getMaterialCount(this.client_request_id, {material_id, room_id}, this.rowsData)
      if (res === undefined) return
      this.changeRowsData(room_id, material_id, client_material_id, 'cnt', res)
    }
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar();
    return {showSucceedSnackbar};
  },
}
</script>

<style scoped lang="scss">
.materials {
  &__img {
    width: 50px;
  }

  &__room {
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }

    &_title {
      margin-bottom: 10px;
      font-size: 16px;
      color: #094c87;
    }
  }

  &__input {
    max-width: 80px;
  }

  &__submit {
    margin-top: 20px;
  }

  &__cnt {
    font-size: 16px;
    text-decoration: underline;
  }
}
</style>
