import instance from "@/services/api";

export const login = async (body) => {
  return await instance(false, null, {throwError: true})
    .post("/auth/login/", body)
    .then((res) => res?.data);
};

export const loginWithUrl = async (token) => {
  return await instance(false, null, {throwError: true})
    .get(`/auth/login_url/token/${token}/`)
    .then((res) => res?.data);
};

export const sendCode = async (body) => {
  return await instance(false)
    .post("/auth/send_code/", body)
    .then((res) => res?.data);
};
