<template>
  <div class="templates">
    <CustomTable
        title="Файлы организации"
        :rowsData="this.rowsData"
        :columns="this.orgFilesColumns"
        :loading="isFetching"
        :small="true"
        :show-search="true"
        :custom-body="true"
        :sticky-header="true"
        :add-btn="true"
        :on-add-click="this.handleAddClick">
      <template #customBody="props">
        <q-tr>
          <custom-table-cell align="left">
            {{ props.row.org_file_name }}
          </custom-table-cell>
          <custom-table-cell align="left">
            {{ props.row.org_file_extension }}
          </custom-table-cell>
          <custom-table-cell align="left">
            <Clickable link :href="getFullUrl(props.row.org_file_url)">
              Скачать
              <q-icon name="download" />
            </Clickable>
          </custom-table-cell>
          <custom-table-cell align="center">
            <CustomIcon icon="trash" :approve-mode="true" :loading="this.loading"
                        :approve="() => this.onDeleteSubmit(props.row.org_file_id)"/>
          </custom-table-cell>
        </q-tr>
      </template>
    </CustomTable>
  </div>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";
import {appActions} from "@/services/store/modules/app_module/app_actions";
import CustomIcon from "@/components/common/CustomIcon";
import {MODAL_NAMES} from "@/constants";
import {getFullUrl} from "@/services";
import Clickable from "@/components/common/Clickable";
import {addOrgFile, deleteOrgFile, getOrgFiles, orgFilesColumns} from "@/views/handbook/documents/services";

export default {
  name: "documents",
  components: {Clickable, CustomIcon, CustomTableCell, CustomTable},
  data() {
    return {
      orgFilesColumns,
      rowsData: [],
      isFetching: false,
      loading: false
    }
  },
  methods: {
    getFullUrl,
    async getData() {
      this.isFetching = true
      const res = await getOrgFiles()
      this.isFetching = false
      if (!res) return
      this.rowsData = res
    },
    async handleAddClick() {
      this.$store.commit(appActions.showModal(MODAL_NAMES.Add_HANDBOOK_DOCUMENT, {
        onSubmit: this.onAddSubmit,
        title: 'Добавить файл'
      }));
    },
    async onAddSubmit(body) {
      const res = await addOrgFile(body)
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    async onDeleteSubmit(org_file_id) {
      this.loading = true
      const res = await deleteOrgFile(org_file_id)
      this.loading = false
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
  },
  mounted() {
    this.getData()
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar();
    return {showSucceedSnackbar};
  },
}
</script>

<style scoped>
.templates {
}
</style>