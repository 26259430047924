<template>
  <div class="remontChat">
    <RemontChatGroups :data="this.chatGroups" :activeChatGroup="this.activeChatGroup" :onChange="this.onActiveChatGroupChange"/>
    <Chat :goBack="this.onActiveChatGroupChange" :data="this.activeChatGroup" :client_request_id="this.client_request_id" />
  </div>
</template>

<script>
import RemontChatGroups from "@/views/ClientRequests/remontChat/chatGroups";
import Chat from "@/views/ClientRequests/remontChat/chat";

export default {
  name: "RemontChatBlock",
  components: {Chat, RemontChatGroups},
  props: ["client_request_id", "data", "isFetching"],
  data() {
    return {
      chatGroups: this.data,
      activeChatGroup: null,
    }
  },
  methods: {
    onActiveChatGroupChange(chatGroup) {
      this.activeChatGroup = chatGroup
    }
  },
}
</script>

<style scoped lang="scss">
.remontChat {
  display: grid;
  grid-template-columns: 5fr 7fr;
  gap: 30px;
  min-height: 620px;

  @media (max-width: 991px) {
    gap: 15px;
    grid-template-columns: 12fr;
  }
  @media (max-width: 768px) {
    min-height: 500px;
  }
}
</style>