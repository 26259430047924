import {participantsReplacementAPI} from "@/components/modal/modals/participants-replacement-history/api";

export const getParticipantsHistoryData = async (client_request_id) => {
  try {
    const res = await participantsReplacementAPI.getList(client_request_id)
    return res?.data?.map((item, i) => ({...item, number: i + 1}))
  } catch (e) {
  }
}
export const participantsHistoryColumns = [
  {
    name: "number",
    label: "№",
    field: "number",
    align: "center",
  },
  {
    name: "position_name",
    label: "Должность",
    field: "position_name",
    align: "left",
  },
  {
    name: "old_employee",
    label: "Старый сотрудник",
    field: "old_employee",
    align: "left",
  },
  {
    name: "new_employee",
    label: "Новый сотрудник",
    field: "new_employee",
    align: "left",
  },
  {
    name: "change_date",
    label: "Дата изменения",
    field: "change_date",
    align: "center",
  },
  {
    name: "fio",
    label: "Кто заменил",
    field: "fio",
    align: "left",
  },
]