import {fastSearchAPI} from "@/views/FastSearch/api";
import {toCorrectPhone} from "@/services";

export const getCommonResidents = async () => {
  try {
    const res = await fastSearchAPI.getResidents()
    return res?.residents
  } catch (e) {}
}
export const getSearchData = async (body = {}) => {
  try {
    const reqBody = {...body}
    if(reqBody?.client_phone) {
      reqBody.client_phone = toCorrectPhone(reqBody.client_phone)
    }
    const res = await fastSearchAPI.search(reqBody)
    return res?.data
  } catch (e) {}
}
export const quickSearchHistory = async () => {
  try {
    const res = await fastSearchAPI.quickSearchHistory()
    return res?.data
  } catch (e) {}
}
export const fastSearchColumns = [
  {
    name: "client_request_id",
    label: "ID заявки",
    align: "center",
  },
  {
    name: "remont_id",
    label: "ID ремонта",
    align: "center",
  },
  {
    name: "client_fio",
    label: "ФИО",
    align: "left",
  },
  {
    name: "client_phone",
    label: "Телефон",
    align: "center",
  },
  {
    name: "address",
    label: "Адрес",
    align: "left",
  },
  {
    name: "remont_type",
    label: "Вид ремонта",
    align: "left",
  },
  {
    name: "request_status_name",
    label: "Статус заявки",
    align: "left",
  },
  {
    name: "remont_status_name",
    label: "Статус ремонта",
    align: "left",
  },
  {
    name: "preset",
    label: "Пакет",
    align: "left",
  },
  {
    name: "warranty_date_begin",
    label: "Дата начала гарантии",
    align: "center",
  },
  {
    name: "waranty_date_end",
    label: "Дата окончания гарантии",
    align: "center",
  },
  {
    name: "official",
    label: "Ответственные",
    align: "left",
  },
  ]
export const quickSearchHistoryColumns = [
  {
    name: "client_request_id",
    label: "ID заявки",
    align: "center",
  },
  {
    name: "remont_id",
    label: "ID ремонта",
    align: "center",
  },
  {
    name: "client_name",
    label: "ФИО клиента",
    align: "left",
  },
  {
    name: "address",
    label: "Адрес",
    align: "left",
  },
  {
    name: "rowversion",
    label: "Дата просмотра",
    align: "center",
  },
  ]
