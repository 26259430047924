import instance from "@/services/api";

export const receiveKeysAPI = {
  async getList(client_request_id) {
    return await instance().get(`/client_request/${client_request_id}/key_receive/read/`).then(res => res?.data)
  },
  async getKeysCount(client_request_id) {
    return await instance().get(`/client_request/${client_request_id}/key_receive/key_trinket/count/get/`).then(res => res?.data)
  },
  async updateKeysCount(client_request_id, body) {
    return await instance().put(`/client_request/${client_request_id}/key_receive/key_trinket/update/`, body).then(res => res?.data)
  },
  async addKeys(client_request_id, body) {
    return await instance().post(`/client_request/${client_request_id}/key_receive/add/`, body).then(res => res?.data)
  },
  async updateKeys(client_request_id, key_receive_id, body) {
    return await instance().put(`/client_request/${client_request_id}/key_receive/${key_receive_id}/update/`, body).then(res => res?.data)
  },
  async deleteKey(client_request_id, key_receive_id) {
    return await instance().delete(`/client_request/${client_request_id}/key_receive/${key_receive_id}/delete/`).then(res => res?.data)
  },
}