import { refreshToken } from "@/services/api/helpers";
import cookiesService from "@/services/cookiesService";
import { getTokenExpires } from "@/services/tokenService";
import {AppAPI} from "@/services/api/app_api";

const cookiesServiceInstance = cookiesService.getService();
export const logout = () => {
  cookiesServiceInstance.clearToken()
}
export const checkAuth = async () => {
  try {
    const token = cookiesServiceInstance.getAccessToken();
    const expires = getTokenExpires(token);
    if (token && expires) {
      const dateNow = Math.trunc(new Date().getTime() / 1000);
      if (expires < dateNow) return await refreshToken();
    } else {
      return await refreshToken();
    }
    return true;
  } catch (e) {
    return await refreshToken();
  }
};

export const getUserData = async () => {
  try {
    const res = await AppAPI.getUserData()
    return res?.user_info
  } catch (e) {}
}
export const getCitiesData = async () => {
  try {
    const res = await AppAPI.getCities()
    return res?.data
  } catch (e) {}
}
export const getCompaniesData = async () => {
  try {
    const res = await AppAPI.getCompanies()
    return res?.data
  } catch (e) {}
}
export const chooseCity = async (city_id) => {
  try {
    const res = await AppAPI.chooseCity({city_id})
    return res?.data
  } catch (e) {}
}
export const chooseCompany = async (company_id) => {
  try {
    const res = await AppAPI.chooseCompany({company_id})
    return res?.data
  } catch (e) {}
}
export const getResidentsByCity = async () => {
  try {
    const res = await AppAPI.getResidentsByCity()
    return res?.data
  } catch (e) {}
}
