<template>
  <div>
    <q-linear-progress v-if="this.loading" indeterminate/>
  </div>
</template>

<script>
import {doLoginWithUrl} from "@/views/Login/services";
import {PAGES} from "@/constants";
import cookiesService from "@/services/cookiesService";
import {appActions} from "@/services/store/modules/app_module/app_actions";
import {removeDangerSymbols} from "@/services";
import {appActionTypes} from "@/services/store/modules/app_module/app_mutation_types";

export default {
  name: "LoginUrl",
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    async login() {
      const cookiesServiceInstance = cookiesService.getService();
      cookiesServiceInstance.clearToken()
      const token = removeDangerSymbols(this.$route?.params?.token)
      if(!token) return
      const res = await doLoginWithUrl(token)
      if(!res) {
        this.$router.push(PAGES.login)
        this.$store.dispatch(appActionTypes.logout)
        this.$store.commit(appActions.setInit());
        return
      }
      this.$router.push(PAGES.main)
      this.$store.commit(appActions.setAuth());
      this.$store.commit(appActions.setInit());
    }
  },
  mounted() {
    this.login()
  }
}
</script>

<style scoped>

</style>