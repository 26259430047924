<template>
  <div id="timeline__wrapper" class="timeline" ref="graph" :style="this.timelineStyles"></div>
</template>

<script>
import * as d3 from "d3";
import {MODAL_NAMES, timelineStepsCodes} from "@/constants";
import {appActions} from "@/services/store/modules/app_module/app_actions";

export default {
  name: "timeline",
  props: ["savePositionData", "allowDrag", "type", "graphData", "updateData", "height"],
  data() {
    return {
      loading: false,
    };
  },
  watch: {
    graphData() {
      this.drawGraph();
    },
  },
  computed: {
    timelineStyles() {
      if(this.height) {
        return `height: ${this.height}px;`
      }
      return 'height: calc(100vh - 120px);'
    }
  },
  methods: {
    async savePosition(body) {
      if (!this.allowDrag) return;
      const {nodes, lines} = await this.savePositionData(body);
      if (!nodes) return;
      this.reDrawGraphContent({nodes, lines});
    },
    drawGraph() {
      const timeline = d3.select("#timeline");
      if (timeline) timeline.remove();
      const container = this.$refs.graph;
      d3.select(container)
          .append("svg")
          .attr("id", "timeline")
          .attr("class", "timeline__wrapper");
      this.drawGraphContent(this.graphData);
    },
    onNodeClick(data = {}) {
      const modalName = this.type === 'grade' ? MODAL_NAMES.GRADE_TIMELINE_STEP : MODAL_NAMES.REMONT_TIMELINE_STEP
      this.$store.commit(
          appActions.showModal(modalName, {
            ...data,
            title: data.step_name,
            updateData: this.updateData
          })
      );
    },
    drawGraphContent(data) {
      const svg = d3.select("#timeline");
      const g = svg.append("g");
      const nodes = g
          .append("g")
          .attr("id", "node")
          .selectAll("rect")
          .data(data.nodes || [])
          .enter()
          .append("foreignObject")
          .attr("id", (d) => `node__${d.step_id}`)
          .attr("x", (d) => d.x)
          .attr("y", (d) => d.y)
          .attr("width", (d) => d.width)
          .attr("height", (d) => d.height)
          .attr("class", 'timeline__node_foreign')
          .html((d) => {
            let stepName = `<p class="timeline__node_name">${d.step_id}. ${d.step_name}${d?.is_marked ? '<i title="Имеются условия вхождения" class="material-icons timeline__node_marked">star</i>' : ''}</p>`;
            let date = "";
            let cnt = "";
            if (d.start_date) {
              date = `<p class="timeline__node_date">${d.start_date}${
                  d.complete_date ? ` - ${d.complete_date}` : ""
              }</p>`;
            }
            if (d.counts) {
              cnt = `<p class="timeline__node_cnt">${d?.counts?.conditions} | ${d?.counts?.actions}</p>`;
            }
            if (d.cnt_condition) {
              cnt = `<p class="timeline__node_cnt timeline__node_cntWide" ><span>${d.cnt_condition}</span><span>${d.cnt_activity}</span></p>`;
            }
            return `<div style="background: ${
                d.color || "#0e74bc"
            }" class="timeline__node timeline__node_${
                timelineStepsCodes[d.status_code]
            }">${stepName}${date}${cnt}</div>`;
          })
          .on("click", (d, b) => this.onNodeClick(b));

      const drag = d3
          .drag()
          .on("drag", (d, b) => {
            if (!this.allowDrag) return;
            const {x, y} = d;
            d.subject.x = x;
            d.subject.y = y;
            d3.select(`#node__${b.step_id}`).attr("x", x).attr("y", y);
          })
          .on("end", (d) => {
            if (!this.allowDrag) return;
            this.savePosition({
              x: d.x,
              y: d.y,
              grade_step_id: d?.subject?.grade_step_id,
            });
          });
      nodes.call(drag);

      data.lines &&
      g
          .append("g")
          .attr("id", "lines")
          .selectAll("path")
          .data(data.lines || [])
          .enter()
          .append("path")
          .attr("class", "timeline__line")
          .attr("marker-end", "url(#arrowhead)")
          .attr("d", (d) => {
            const {target, source} = d;
            return `M${source.x1},${source.y1} L${target.x2},${target.y2}`;
          });

      data.lines &&
      g
          .append("defs")
          .attr("id", "arrowheads")
          .append("marker")
          .attr("id", "arrowhead")
          .attr("refX", 12)
          .attr("refY", 12)
          .attr("viewBox", "0 0 24 24")
          .attr("markerWidth", 12)
          .attr("markerHeight", 12)
          .attr("orient", "auto")
          .attr("stroke", "#565050")
          .attr("stroke-width", 2)
          .attr("fill", "none")
          .append("path")
          .attr("d", "M6,18 L12,12 L6,6");

      const zoom = d3
          .zoom()
          .scaleExtent([0.1, 5])
          .on("zoom", (event) => {
            g.attr("transform", event.transform);
          });
      svg.call(zoom);

      try {
        const wrapper = document.getElementById("timeline__wrapper");
        const svgWidth = wrapper.clientWidth;
        const svgHeight = wrapper.clientHeight;
        const bbox = g.node().getBBox(); // получаем границы объекта в SVG
        const scaleX = svgWidth / (bbox.width + 100);
        const scaleY = svgHeight / (bbox.height + 100);
        const scale = Math.min(scaleX, scaleY);

        const x = (svgWidth - bbox.width * scale) / 2 - bbox.x * scale;
        const y = (svgHeight - bbox.height * scale) / 2 - bbox.y * scale;

        svg
            .select("g")
            .attr(
                "transform",
                "translate(" + x + "," + y + ") scale(" + scale + ")"
            );

        const initialTransform = d3.zoomIdentity.translate(x, y).scale(scale);
        svg.call(zoom.transform, initialTransform);
      } catch (e) {
      }
    },
    reDrawGraphContent(data) {
      const node = d3.select("#node");
      const lines = d3.select("#lines");

      node
          .selectAll("foreignObject")
          .data(data.nodes || [])
          .attr("x", (d) => d.x)
          .attr("y", (d) => d.y)
          .attr("width", (d) => d.width)
          .attr("height", (d) => d.height);

      lines
          .selectAll("path")
          .data(data.lines || [])
          .attr("d", (d) => {
            const {target, source} = d;
            return `M${source.x1},${source.y1} L${target.x2},${target.y2}`;
          });
    },
  },
};
</script>

<style lang="scss">
.timeline {
  overflow: hidden;
  height: calc(100vh - 120px);
  width: 100%;
  min-height: 400px;

  &__wrapper {
    cursor: grab;
    background: rgb(249, 249, 249, 0.45);
    height: 100%;
    width: 100%;
    min-height: inherit;

    &:active {
      cursor: grabbing;
    }
  }

  &__pointer {
    cursor: pointer;
  }
  &__toggle {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    color: #9d1010;
    font-size: 30px !important;
    cursor: pointer;

    &_completed {
      color: #0c880c;
    }
  }

  @media not all and (min-resolution: .001dpcm) {
    @supports (-webkit-appearance:none) {
      &__node {
        display: contents !important;

        &_foreign {
          padding: 10px !important;
          background: #0e74bc !important;
          border-radius: 10px !important;
        }
      }
    }
  }

  &__node {
    padding: 10px;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0 6px #978e8e;

    &:hover {
      cursor: pointer;
    }

    &_wrapper {
      border-radius: 10px;
      fill: none;
    }

    &_foreign {
      overflow: visible;
    }

    &_marked {
      color: #e3d621;
      margin-left: 7px;
      font-size: 20px !important;
    }

    &_name {
      color: #fff;
      font-weight: bold;
      font-size: 14px;
      width: 100%;
      text-align: center;
      margin: 0 0 9px;
      line-height: 16px;
    }

    &_date {
      position: absolute;
      color: #ededed;
      font-size: 12px;
      bottom: 3px;
      left: 6px;
      margin: 0;
    }

    &_cnt {
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content;
      color: #fff;
      font-size: 17px;
      margin: 0;
      font-weight: 300;
      transition: all 0.4s;
      gap: 5px;

      &Icon {
        margin-left: 5px;
        font-size: 22px !important;
      }

      &Item {
        border-radius: 4px;
        padding: 3px 8px;
      }

      &Wide {
        gap: 15px;
      }
    }

    &_NOT_STARTED {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 1000;
    }
  }

  &__line {
    fill: none;
    stroke: #2d317a;
    stroke-opacity: 0.5;
    stroke-width: 3px;
  }

  &__check {
    font-size: 20px !important;

    &_completed {
      color: #0c880c !important;
    }

    &_close {
      color: #9d1010 !important;
    }
  }
}
</style>
