import {remontPaymentsAPI} from "@/views/ClientRequests/remontPayments/api";
import {numberWithCommas} from "@/services";

const remontPaymentsBlockNames = {
  OA__RemontFormTrancheBlock: "remontPaymentsTranchesBlock",
  OA__RemontFormCostBlock: "remontPaymentsCostsBlock",
  OA__RemontFormClientPaymentBlock: "remontPaymentsIncomesBlock",
};
export const getRemontPaymentsData = async (client_request_id) => {
  try {
    const res = await remontPaymentsAPI.getList(client_request_id);
    const data = res?.data;
    if (!data?.length) return;
    return data?.map((item) => ({
      ...item,
      component: remontPaymentsBlockNames[item.grant_code],
    }));
  } catch (e) {}
};
export const getCostsTypes = async () => {
  try {
    const res = await remontPaymentsAPI.getCostsTypes();
    return res?.data;
  } catch (e) {}
};
export const getRemontPaymentDetail = async (
  client_request_id,
  body,
  oldData,
  id
) => {
  try {
    const res = await remontPaymentsAPI.getPaymentDetail(
      client_request_id,
      body
    );
    return oldData?.map((item) => {
      if (item.id === id) {
        return { ...item, detailData: res?.data };
      }
      return item;
    });
  } catch (e) {}
};
export const addTranche = async (client_request_id, body) => {
  try {
    const res = await remontPaymentsAPI.addTranche(client_request_id, body);
    return res?.data;
  } catch (e) {}
};
export const editTranche = async (client_request_id, trancheId, body) => {
  try {
    const res = await remontPaymentsAPI.editTranche(
      client_request_id,
      trancheId,
      body
    );
    return res?.data;
  } catch (e) {}
};
export const deleteTranche = async (client_request_id, trancheId) => {
  try {
    const res = await remontPaymentsAPI.deleteTranche(
      client_request_id,
      trancheId
    );
    return res?.data;
  } catch (e) {}
};
export const addIncome = async (client_request_id, body) => {
  try {
    const res = await remontPaymentsAPI.addIncome(client_request_id, body);
    return res?.data;
  } catch (e) {}
};
export const editIncome = async (client_request_id, clientPaymentId, body) => {
  try {
    const res = await remontPaymentsAPI.editIncome(
      client_request_id,
      clientPaymentId,
      body
    );
    return res?.data;
  } catch (e) {}
};
export const deleteIncome = async (client_request_id, clientPaymentId) => {
  try {
    const res = await remontPaymentsAPI.deleteIncome(
      client_request_id,
      clientPaymentId
    );
    return res?.data;
  } catch (e) {}
};
export const changeClientType = async (client_request_id, clientPaymentId) => {
  try {
    const res = await remontPaymentsAPI.changeClientType(
      client_request_id,
      clientPaymentId
    );
    return res?.data;
  } catch (e) {}
};
export const addCost = async (client_request_id, body) => {
  try {
    const res = await remontPaymentsAPI.addCost(client_request_id, body);
    return res?.data;
  } catch (e) {}
};
export const editCost = async (client_request_id, remont_costs_id, body) => {
  try {
    const res = await remontPaymentsAPI.editCost(
      client_request_id,
      remont_costs_id,
      body
    );
    return res?.data;
  } catch (e) {}
};
export const deleteCost = async (client_request_id, remont_costs_id) => {
  try {
    const res = await remontPaymentsAPI.deleteCost(
      client_request_id,
      remont_costs_id
    );
    return res?.data;
  } catch (e) {}
};
export const remontPaymentsTrancheColumns = [
  {
    name: "client_request_tranche_id",
    label: "ID",
    field: "client_request_tranche_id",
    align: "center",
  },
  {
    name: "payment_amount",
    label: "Сумма",
    field: "payment_amount",
    align: "right",
    format: (val) => numberWithCommas(val),
    style: "white-space: nowrap;",
  },
  {
    name: "is_agreement_change_text",
    label: "По условию договора",
    field: "is_agreement_change_text",
    align: "left",
  },
  {
    name: "payment_date",
    label: "Дата ожидаемой оплаты",
    field: "payment_date",
    align: "center",
  },
  {
    name: "days_overdue",
    label: "Кол-во просроченных дней",
    field: "days_overdue",
    align: "left",
  },
  {
    name: "comments",
    label: "Комментарий",
    field: "comments",
    align: "left",
  },
  {
    name: "status",
    label: "Статус",
    field: "status",
    align: "left",
  },
  {
    name: "trash",
    label: "",
    align: "center",
  },
];
export const remontPaymentsCostsColumns = [
  {
    name: "index",
    label: "№",
    field: "index",
    align: "center",
  },
  {
    name: "contragent",
    label: "Контрагент",
    field: "contragent",
    align: "left",
  },
  {
    name: "remont_costs_type_name",
    label: "Тип платежа",
    field: "remont_costs_type_name",
    align: "left",
  },
  {
    name: "item_cnt",
    label: "Кол-во",
    field: "item_cnt",
    align: "right",
  },
  {
    name: "payment_sum",
    label: "Сумма",
    field: "payment_sum",
    align: "right",
    format: (val) => numberWithCommas(val),
    style: "white-space: nowrap;",
  },
];
export const remontPaymentsCostsDetailColumns = [
  {
    name: "ID",
    label: "ID",
    field: "ID",
    align: "center",
  },
  {
    name: "payment_amount",
    label: "Сумма",
    field: "payment_amount",
    align: "right",
    style: "white-space: nowrap;",
  },
  {
    name: "remont_costs_type_name",
    label: "Дата платежа",
    field: "remont_costs_type_name",
    align: "left",
  },
  {
    name: "contragent",
    label: "Контрагент",
    field: "contragent",
    align: "left",
  },
  {
    name: "comments",
    label: "Комментарий",
    field: "comments",
    align: "left",
  },
  {
    name: "payment_type",
    label: "Тип платежа",
    field: "payment_type",
    align: "left",
  },
  {
    name: "payment_status",
    label: "Статус",
    field: "payment_status",
    align: "left",
  },
  {
    name: "actions_remove",
    label: "",
    field: "actions_remove",
    align: "center",
  },
];
export const remontPaymentsIncomesColumns = [
  {
    name: "client_request_payment_id",
    label: "ID",
    field: "client_request_payment_id",
    align: "center",
  },
  {
    name: "payment_amount",
    label: "Сумма",
    field: "payment_amount",
    align: "right",
    style: "white-space: nowrap;",
  },
  {
    name: "payment_date",
    label: "Дата ожидаемой оплаты",
    field: "payment_date",
    align: "center",
  },
  {
    name: "payment_num",
    label: "Номер счета",
    field: "payment_num",
    align: "left",
  },
  {
    name: "comments",
    label: "Комментарий",
    field: "comments",
    align: "left",
  },
  {
    name: "is_payed",
    label: "Статус",
    field: "is_payed",
    align: "center",
  },
  {
    name: "type",
    label: "Тип",
    align: "center",
  },
  {
    name: "transaction_num",
    label: "Номер транзакции",
    field: "transaction_num",
    align: "left",
  },
  {
    name: "actions_remove",
    label: "",
    field: "actions_remove",
    align: "center",
  },
];
