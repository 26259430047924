<template>
  <FetchingWrapper :loading="this.isFetching" :data="this.data">
    <div class="remontStages__wrapper">
      <div v-for="item in this.data">
        <component :is="item.component" :isFetching="this.isFetching" :client_request_id="this.client_request_id"
                   :data="item?.data"/>
      </div>
    </div>
  </FetchingWrapper>
</template>

<script>
import RemontsStagesBlock from "./stages";
import CallWorkGroupsBlock from "./callWorkGroups";
import ClientAcceptWaitBlock from "./clientAcceptWait";
import DraftCheckBlock from "./draftCheckBlock";
import {getRemontStagesData} from "@/views/ClientRequests/remontStages/services";
import FetchingWrapper from "@/components/common/FetchingWrapper";

export default {
  name: "RemontStages",
  components: {FetchingWrapper, RemontsStagesBlock, DraftCheckBlock, ClientAcceptWaitBlock, CallWorkGroupsBlock},
  props: ["client_request_id"],
  data() {
    return {
      data: [],
      isFetching: false,
    }
  },
  methods: {
    async getData() {
      this.isFetching = true
      const res = await getRemontStagesData(this?.client_request_id)
      this.isFetching = false
      if (!res) return
      this.data = res
    }
  },
  mounted() {
    this.getData()
  },
}
</script>

<style scoped lang="scss">
.remontStages {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
}

</style>