<template>
  <div class="gpr__header">
    <q-btn size="sm" padding="xs" color="primary">
      <PopupConfirm
        :loading="this.loading.recount"
        :approve="this.recountDates"
      />
      Пересчитать даты этапов ГПР
    </q-btn>
    <q-btn
      size="sm"
      padding="xs"
      color="primary"
      @click="handleUploadExcel"
      :loading="this.loading.upload"
    >
      Выгрузка в Excel
    </q-btn>
  </div>
  <CustomTable
    title="ГПР"
    :rowsData="this.gprData"
    :columns="gprStageColumns"
    :loading="isFetching"
    :custom-body="true"
    :hide-footer-title="true"
  >
    <template #customBody="props">
      <q-tr>
        <custom-table-cell align="left">
          {{ props.row.stage_name }}
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.work_name }}
        </custom-table-cell>
        <custom-table-cell align="center">
          <div class="gpr__dates">
            <CustomDate
              :min-width-mode="true"
              :disabled="true"
              placeholder="Дата от"
              :value="props.row.date_begin || ''"
              @change_date="(value) => onDateChange('date_begin', value)"
            />
            <CustomDate
              :min-width-mode="true"
              :disabled="true"
              placeholder="Дата от"
              :value="props.row.date_end || ''"
              @change_date="(value) => onDateChange('date_end', value)"
            />
          </div>
        </custom-table-cell>
        <custom-table-cell align="center">
          <CheckOrClose :value="!!props.row.is_edit_gpr" />
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.comments }}
        </custom-table-cell>
      </q-tr>
    </template>
  </CustomTable>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import {gprStageColumns, recountGprDates, uploadExcel} from "@/views/ClientRequests/gprStage/services";
import CheckOrClose from "@/components/common/CheckOrClose";
import CustomDate from "@/components/form/CustomDate";
import PopupConfirm from "@/components/common/PopupConfirm";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";

export default {
  name: "GprStageBlock",
  components: {
    PopupConfirm,
    CheckOrClose, CustomTable, CustomTableCell, CustomDate
  },
  props: ["client_request_id", "data", "isFetching"],
  data() {
    return {
      selectedItems: {},
      gprStageColumns,
      gprData: this.data,
      loading: {
        recount: false,
        upload: false
      },
    }
  },
  methods: {
    onDateChange() {
    },
    async recountDates() {
      this.loading.recount = true
      const res = await recountGprDates(this.client_request_id)
      this.loading.recount = false
      if (!res) return
      this.gprData = res
      this.showSucceedSnackbar()
    },
    async handleUploadExcel() {
      this.loading.upload = true
      await uploadExcel(this.client_request_id)
      this.loading.upload = false
    }
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar();
    return {showSucceedSnackbar};
  },
}
</script>

<style scoped lang="scss">
.gpr {
  &__dates {
    display: flex;
    align-items: center;
    gap: 7px;
  }

  &__header {
    display: flex;
    gap: 15px;
    margin-bottom: 20px;
  }
}
</style>
