<template>
  <div>
    <ProvidersAgreementFilters :is-fetching="this.isFetching" :get-data="this.getData" :on-change="this.onFiltersChange"
                               :filters="this.filters"/>
    <CustomTable
        title="Договор поставщиков"
        :rowsData="this.rowsData"
        :columns="this.providersAgreementHandbookColumns"
        :loading="isFetching"
        :small="true"
        :show-search="true"
        :custom-body="true"
        :sticky-header="true"
        :add-btn="true"
        :on-add-click="this.handleAddClick"
        :update-data="this.getData">
      <template #customBody="props">
        <q-tr>
          <custom-table-cell align="center">
            <Clickable @click="() => this.handleUpdateClick(props.row)">
              {{ props.row.contractor_agreement_list_id }}
            </Clickable>
          </custom-table-cell>
          <custom-table-cell align="left">
            {{ props.row.provider_name }}
          </custom-table-cell>
          <custom-table-cell align="center">
            {{ props.row.document_date }}
          </custom-table-cell>
          <custom-table-cell align="left">
            {{ props.row.agreement_num }}
          </custom-table-cell>
          <custom-table-cell align="left">
            {{ props.row.guid }}
          </custom-table-cell>
          <custom-table-cell align="left">
            {{
              props.row.is_sent_to_1c === 0
                  ? 'Не отправлен'
                  : props.row.is_sent_to_1c === 1
                      ? 'Отправлен'
                      : ''
            }}
          </custom-table-cell>
          <custom-table-cell align="left">
            {{ props.row.fio }}
          </custom-table-cell>
          <custom-table-cell align="center">
            {{ props.row.date_create }}
          </custom-table-cell>
          <custom-table-cell align="center" vertical-align="center">
            <CustomIcon icon="trash" :approve-mode="true"
                        :approve="() => this.onDeleteSubmit(props.row.contractor_agreement_list_id)"
                        :loading="this.deleteLoading[props.row.contractor_agreement_list_id]"/>
          </custom-table-cell>
        </q-tr>
      </template>
    </CustomTable>
  </div>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";
import {appActions} from "@/services/store/modules/app_module/app_actions";
import CustomIcon from "@/components/common/CustomIcon";
import {MODAL_NAMES} from "@/constants";
import Clickable from "@/components/common/Clickable";
import CheckOrClose from "@/components/common/CheckOrClose";
import {
  addHandbookProviderAgreement,
  deleteHandbookProviderAgreement,
  getHandbookProvidersAgreement,
  providersAgreementHandbookColumns,
  updateHandbookProviderAgreement
} from "@/views/handbook/ProvidersAgreement/services";
import ProvidersAgreementFilters from "@/views/handbook/ProvidersAgreement/filters";

export default {
  name: "HandbookProvidersAgreement",
  components: {ProvidersAgreementFilters, CheckOrClose, Clickable, CustomIcon, CustomTableCell, CustomTable},
  data() {
    return {
      providersAgreementHandbookColumns,
      rowsData: [],
      isFetching: false,
      deleteLoading: {},
      loading: false,
      filters: {
        provider_id: ''
      }
    }
  },
  methods: {
    onFiltersChange(key, value) {
      this.filters[key] = value;
      this.getData()
    },
    async getData() {
      this.isFetching = true
      const res = await getHandbookProvidersAgreement(this.filters)
      this.isFetching = false
      if (!res) return
      this.rowsData = res
    },
    async handleAddClick() {
      this.$store.commit(appActions.showModal(MODAL_NAMES.ADD_PROVIDERS_AGREEMENT, {
        onSubmit: this.onAddSubmit,
        title: 'Добавить договор',
      }));
    },
    async handleUpdateClick(row) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.ADD_PROVIDERS_AGREEMENT, {
        onSubmit: (body) => this.onUpdateSubmit(row.contractor_agreement_list_id, body),
        title: 'Изменить договор',
        body: row
      }));
    },
    async onAddSubmit(body) {
      const res = await addHandbookProviderAgreement(body, this.filters)
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    async onUpdateSubmit(contractor_agreement_list_id, body) {
      const res = await updateHandbookProviderAgreement(contractor_agreement_list_id, body, this.filters)
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    async onDeleteSubmit(contractor_agreement_list_id) {
      this.deleteLoading[contractor_agreement_list_id] = true
      const res = await deleteHandbookProviderAgreement(contractor_agreement_list_id, this.filters)
      this.deleteLoading[contractor_agreement_list_id] = false
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
    },
  },
  mounted() {
    this.getData()
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar();
    return {showSucceedSnackbar};
  },
}
</script>