<template>
  <AgreementJournalFilters :get-data="this.getData" :filters="this.filters" :onChange="this.onFiltersChange" :isFetching="this.isFetching" />
  <AgreementsTable
      title="Журнал договоров"
      :rowsData="this.rowsData" :params="this.filters"
      :is-fetching="isFetching"
      :on-cancel-click="this.cancelAgreementData"
      :on-delete-click="this.deleteAgreementData"
      :modal-request="this.agreementDocAction"
      :sign-spec="this.signSpec"
  />
</template>

<script>
import {
  agreementDocActionReq,
  cancelAgreement,
  deleteAgreement,
  getAgreementJournal,
  signAgreement
} from "@/views/AgreementsJournal/services";
import AgreementsTable from "@/views/AgreementsJournal/AgreementsTable";
import AgreementJournalFilters from "@/views/AgreementsJournal/filters";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";
import {appActions} from "@/services/store/modules/app_module/app_actions";

export default {
  name: "AgreementsJournal",
  components: {AgreementJournalFilters, AgreementsTable},
  data() {
    return {
      rowsData: [],
      isFetching: false,
      filters: {
        remont_id: '',
        contractor_agreement_list_type_id: '',
        is_active: 2,
        is_signed: 2,
        is_error: '',
        warranty_date_begin: '',
        warranty_date_end: '',
      }
    }
  },
  methods: {
    onFiltersChange(key, value) {
      this.filters[key] = value;
    },
    async getData() {
      this.isFetching = true
      const res = await getAgreementJournal(this.filters)
      this.isFetching = false
      if(!res) return
      this.rowsData = res
    },
    async cancelAgreementData(contractor_agreement_list_id) {
      const res = await cancelAgreement(contractor_agreement_list_id, this.filters)
      if(!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
    },
    async deleteAgreementData(contractor_agreement_list_id) {
      const res = await deleteAgreement(contractor_agreement_list_id, this.filters)
      if(!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
    },
    async agreementDocAction(body, actionName) {
      const res = await agreementDocActionReq(body, actionName, this.filters)
      if(!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    async signSpec(contractor_agreement_list_id) {
      const redirect_url = await signAgreement(contractor_agreement_list_id)
      if(!redirect_url) return
      window.open(redirect_url, "_blank")
    },
  },
  mounted() {
    this.getData()
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar();
    return {showSucceedSnackbar};
  },
}
</script>