import {
  checkAuth,
  chooseCity,
  chooseCompany,
  getCitiesData,
  getCompaniesData,
  getUserData,
  logout,
} from "@/services/auth";
import {appActions} from "./app_actions.js";
import {appActionTypes, appMutationTypes} from "./app_mutation_types";
import {getChangeMaterialReqStatuses} from "@/views/ChangeMaterialRequests/services";
import {getFinancePaymentStatusesData} from "@/views/FinancePayments/services";
import {getCostsTypes} from "@/views/ClientRequests/remontPayments/services";
import {getCommonContractors, getProvidersData} from "@/views/Costs/СostsFilters/services.js";
import {getStageStatusesList} from "@/views/ClientRequests/remontStages/services";
import {getDocTypes} from "@/views/ClientRequests/remontDocs/services";
import {getAgreementTypes} from "@/views/AgreementsJournal/services";
import {getSpecialities, getWorksetCheckGroups} from "@/views/handbook/WorkSets/services";
import {getDSTypes} from "@/views/ClientRequests/remontDS/services";

const modalInitialValue = {
  show: false,
  type: null,
  data: null,
};
export const appModule = {
  state: () => ({
    modal: modalInitialValue,
    auth: false,
    init: false,
    menuDrawerOpen: true,
    userData: null,
    cities: null,
    companies: null,
    providers: [],
    contractors: [],
    specialities: [],
    stageStatuses: [],
    changeMaterialsStatuses: [],
    financePaymentStatuses: [],
    agreementTypes: [],
    worksetGroups: [],
    costsTypes: [],
    docTypes: [],
    dsTypes: {data: [], isFetching: false},
  }),
  mutations: {
    [appMutationTypes.APP_INIT](state) {
      state.init = true;
    },
    [appMutationTypes.SET_AUTH](state) {
      state.auth = true;
    },
    [appMutationTypes.SET_USER_DATA](state, {payload}) {
      state.userData = payload;
    },
    [appMutationTypes.CHANGE_CITY_ID](state, {payload}) {
      if (!state.userData) state.userData = payload;
      else state.userData = {...state.userData, ...payload};
    },
    [appMutationTypes.CHANGE_COMPANY_ID](state, {payload}) {
      if (!state.userData) state.userData = payload;
      else state.userData = {...state.userData, ...payload};
    },
    [appMutationTypes.SET_CITIES](state, {payload}) {
      state.cities = payload;
    },
    [appMutationTypes.SET_COMPANIES](state, {payload}) {
      state.companies = payload;
    },
    [appMutationTypes.LOGOUT](state) {
      state.auth = false;
      state.userData = null;
      state.cities = null;
      state.companies = null;
    },
    [appMutationTypes.SHOW_MODAL](state, {payload}) {
      state.modal = {
        type: payload.type,
        show: true,
        data: payload.data || {},
      };
    },
    [appMutationTypes.CLOSE_MODAL](state) {
      state.modal = modalInitialValue;
    },
    [appMutationTypes.TOGGLE_MENU_DRAWER](state) {
      state.menuDrawerOpen = !state.menuDrawerOpen;
    },
    [appMutationTypes.CHANGE_MATERIALS_REQ_STATUSES](state, {payload}) {
      state.changeMaterialsStatuses = payload;
    },
    [appMutationTypes.SET_COSTS_TYPES](state, {payload}) {
      state.costsTypes = payload;
    },
    [appMutationTypes.FINANCE_PAYMENT_STATUSES](state, {payload}) {
      state.financePaymentStatuses = payload;
    },
  },
  actions: {
    async [appActionTypes.checkAuth]({commit}) {
      const isAuth = await checkAuth();
      if (isAuth) commit(appActions.setAuth());
      commit(appActions.setInit());
      return isAuth;
    },
    [appActionTypes.logout]({commit}) {
      logout();
      commit(appActions.logout());
    },
    async [appActionTypes.getChangeMaterialsReqStatuses]({commit, state}) {
      if (!!state.changeMaterialsStatuses?.length) return;
      const res = await getChangeMaterialReqStatuses();
      commit(appActions.setChangeMaterialsReqStatuses(res));
    },
    async [appActionTypes.getFinancePaymentStatuses]({commit, state}) {
      if (!!state.financePaymentStatuses?.length) return;
      const res = await getFinancePaymentStatusesData();
      commit(appActions.setFinancePaymentStatuses(res));
    },
    async [appActionTypes.getCostsTypes]({commit}) {
      const res = await getCostsTypes();
      if (!res) return;
      commit(appActions.setCostsTypes(res));
    },
    async [appActionTypes.getUserData]({commit, state}) {
      if (!!state.userData) return;
      const res = await getUserData();
      if (res) commit(appActions.setUserData(res));
    },
    async [appActionTypes.getCities]({commit, state}) {
      if (!!state.cities) return;
      const res = await getCitiesData();
      if (res) commit(appActions.setCities(res));
    },
    async [appActionTypes.getCompanies]({commit, state}) {
      if (!!state.companies) return;
      const res = await getCompaniesData();
      if (res) commit(appActions.setCompanies(res));
    },
    async [appActionTypes.getAgreementTypes]({_, state}) {
      if (!!state.agreementTypes?.length) return;
      const res = await getAgreementTypes();
      if (res) state.agreementTypes = res;
    },
    async [appActionTypes.getCommonProviders]({_, state}) {
      if (!!state.providers?.length) return;
      const res = await getProvidersData();
      if (res) state.providers = res;
    },
    async [appActionTypes.getCommonContractors]({_, state}) {
      const res = await getCommonContractors();
      if (res) state.contractors = res;
    },
    async [appActionTypes.getStageStatuses]({_, state}) {
      const res = await getStageStatusesList();
      if (res) state.stageStatuses = res;
    },
    async [appActionTypes.getDocTypes]({_, state}) {
      const res = await getDocTypes();
      if (res) state.docTypes = res;
    },
    async [appActionTypes.getWorksetCheckGroups]({_, state}) {
      const res = await getWorksetCheckGroups();
      if (res) state.worksetGroups = res;
    },
    async [appActionTypes.getSpecialities]({_, state}) {
      if (!!state.specialities?.length) return;
      const res = await getSpecialities();
      if (res) state.specialities = res;
    },
    async [appActionTypes.getDSTypes]({_, state}) {
      if (!!state.dsTypes.data?.length) return;
      state.dsTypes = {...state.dsTypes, isFetching: true}
      const res = await getDSTypes();
      state.dsTypes = {...state.dsTypes, isFetching: false, data: res || []}
    },
    async [appActionTypes.chooseCity]({commit, state}, city_id) {
      const res = await chooseCity(city_id);
      if (res) {
        commit(appActions.chooseCity(res));
        window.location.reload();
      }
    },
    async [appActionTypes.chooseCompany]({commit, state}, company_id) {
      const res = await chooseCompany(company_id);
      if (res) {
        commit(appActions.chooseCompany(res));
        window.location.reload();
      }
    },
  },
};
