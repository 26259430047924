<template>
  <CustomTable
      title="Этапы"
      :sticky-header="true"
      :rowsData="this.rowsData"
      :columns="remontStagesColumns"
      :custom-body="true"
  >
    <template v-slot:top-right="props">
      <q-btn @click="onAddStageClick" color="primary" size="sm" padding="sm">Добавить этап</q-btn>
    </template>
    <template #customBody="props">
      <q-tr>
        <custom-table-cell align="center">
          {{ props.row.remont_stage_id }}
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.stage_name }}
        </custom-table-cell>
        <custom-table-cell align="left" :style="`backgroundColor: ${props.row.stage_status_colour || 'initial'}`">
          {{ props.row.status_name }}
        </custom-table-cell>
        <custom-table-cell align="center" >
          {{ props.row.date_create }}
        </custom-table-cell>
        <custom-table-cell align="left" >
          {{ props.row.fio }}
        </custom-table-cell>
        <custom-table-cell align="left" >
          {{ props.row.remark_name }}
        </custom-table-cell>
        <custom-table-cell align="left" >
          {{ props.row.comments }}
        </custom-table-cell>
      </q-tr>
    </template>
  </CustomTable>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import {addStage, remontStagesColumns} from "@/views/ClientRequests/remontStages/services";
import CustomIcon from "@/components/common/CustomIcon";
import PopupConfirm from "@/components/common/PopupConfirm";
import {appActions} from "@/services/store/modules/app_module/app_actions";
import {MODAL_NAMES} from "@/constants";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";

export default {
  name: "remontsStagesBlock",
  components: {PopupConfirm, CustomIcon, CustomTable, CustomTableCell},
  props: ["data", "client_request_id"],
  data() {
    return {
      remontStagesColumns,
      rowsData: this.data
    }
  },
  methods: {
    onAddStageClick() {
      this.$store.commit(appActions.showModal(MODAL_NAMES.AddStage, {
        title: 'Добавить этап',
        onSubmit: this.onSubmit,
        client_request_id: this.client_request_id
      }))
    },
    async onSubmit(body) {
      const res = await addStage(this.client_request_id, body)
      if(!res) return
      this.showSucceedSnackbar()
      this.rowsData = res
      this.$store.commit(appActions.closeModal());
    },
  },
  setup() {
    const { showSucceedSnackbar } = useCustomSnackbar();
    return { showSucceedSnackbar };
  },
}
</script>