<template>
  <ChangeMaterialRequestsContent :header-height="this.headerHeight" :req-type="changeMaterialsReqTypes.AVAILABILITY" :columns="columns" title="Заявки на изменение наличия материалов"
                                 :data="this.data" :loading="this.loading" :change-data="changeData" />
</template>

<script>
import ChangeMaterialRequestsContent from "./ChangeMaterialRequestsContent"
import {changeMaterialsReqTypes, reqAvailTableColumns} from "./services"

export default {
  name: "ChangeMaterialRequestsAvail",
  components: {ChangeMaterialRequestsContent},
  props: ["data", "changeData", "loading", "headerHeight"],
  data() {
    return {
      columns: reqAvailTableColumns,
      changeMaterialsReqTypes
    }
  },
}
</script>

<style scoped>

</style>