<template>
  <q-tab-panel class="tabPanel" :name="this.name">
    <slot></slot>
  </q-tab-panel>
</template>

<script>
export default {
  name: "CustomTabPanel",
  props: {
    name: String,
  }
}
</script>

<style scoped lang="scss">
.tabPanel {
  @media (max-width: 400px) {
    padding: 15px 0;
  }
}
</style>