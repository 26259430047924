<template>
  <div class="wrapper">
    <q-form @submit="this.onSubmit" class="form">
      <CustomInput :value="this.formData.comment"
                   @change_input="(value) => this.onChange('comment', value)"
                   label="Комментарии" type="textarea" :required="true"/>
      <CustomModalFooter :hide-cancel-btn="true"/>
    </q-form>

    <CustomTable
        title=""
        :rowsData="this.rowsData || this.data.modalData"
        :columns="this.executiveReportCommentColumns"
        :custom-body="true"
        :hide-footer-title="true" :sticky-header="false"
    >
      <template #customBody="props">
        <q-tr>
          <custom-table-cell align="left">
            {{ props.row.comments }}
          </custom-table-cell>
          <custom-table-cell align="left">
            {{ props.row.stage_num }}
          </custom-table-cell>
          <custom-table-cell align="left">
            {{ props.row.fio }}
          </custom-table-cell>
          <custom-table-cell align="center">
            {{ props.row.rowversion }}
          </custom-table-cell>
          <custom-table-cell align="center">
            <CustomIcon icon="trash" v-if="props.row.is_edit" :loading="this.deleting" :approve-mode="true"
                        :approve="() => this.deleteComment(props.row.mng_report_comment_id)"/>
          </custom-table-cell>
        </q-tr>
      </template>
    </CustomTable>

    <CustomModalFooter :hide-submit-btn="true" cancel-btn-text="Закрыть"/>
  </div>
</template>
<script>

import CustomInput from "@/components/form/CustomInput";
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import {addExecutiveReportComment, deleteReportComment} from "@/views/ExecutiveReport/services";
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import {executiveReportCommentColumns} from "@/components/modal/modals/executive-report-comments/services";
import CustomIcon from "@/components/common/CustomIcon";

export default {
  name: "executive-report-comment",
  components: {CustomIcon, CustomTableCell, CustomTable, CustomInput, CustomModalFooter},
  props: ['data', 'closeModal'],
  data() {
    return {
      executiveReportCommentColumns,
      loading: false,
      deleting: false,
      rowsData: null,
      formData: {
        comment: '',
      }
    }
  },
  methods: {
    onChange(key, value) {
      this.formData[key] = value
    },
    async onSubmit() {
      const body = {
        ...this.data.body, comment: this.formData.comment
      }
      this.loading = true
      const res = await addExecutiveReportComment(body)
      this.loading = false
      if (!res) return
      this.rowsData = res
    },
    async deleteComment(mng_report_comment_id) {
      const params = {
        client_request_id: this.data.body?.client_request_id
      }
      this.deleting = true
      const res = await deleteReportComment(mng_report_comment_id, params)
      this.deleting = false
      if (!res) return
      this.rowsData = res
    },
  },
}
</script>

<style scoped lang="scss">
.wrapper {
  max-width: 500px;
}

.form {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  gap: 20px;

  @media (max-width: 428px) {
    min-width: 100%;
  }
}
@media (max-width: 576px) {
  .wrapper {
    max-width: 100%;
  }
}
</style>
