import {handbookProvidersAPI} from "@/views/handbook/Providers/api";

export const getHandbookProviders = async () => {
  try {
    const res = await handbookProvidersAPI.getList()
    return res?.data
  } catch (e) {
  }
}
export const updateHandbookProvider = async (provider_id, body = {}) => {
  try {
    const res = await handbookProvidersAPI.updateProvider(provider_id, body)
    return res?.data
  } catch (e) {
  }
}

export const providersHandbookColumns = [
  {
    name: "provider_id",
    field: "provider_id",
    label: "ID",
    align: "center",
  },
  {
    name: "provider_name",
    field: "provider_name",
    label: "Название",
    align: "left",
  },
  {
    name: "is_not_create_auto_payment",
    field: "is_not_create_auto_payment",
    label: "Запрет на создание автоматических платежей",
    align: "center",
  },
]