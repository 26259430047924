<template>
  <grade-timeline-filters
    :loading="this.loading"
    :options="this.options"
    :on-submit="this.getData"
    :values="this.filters"
    :onChange="this.onFiltersChange"
  />
  <timeline
    :graphData="this.graphData"
    :allow-drag="this.options.dragMode"
    :save-position-data="this.savePosition"
    type="grade"
  />
</template>

<script>
import GradeTimelineFilters from "@/views/timeline/timeline-grade/filters";
import {
  getGradeTimelineData,
  saveGradeTimelinePositionData,
} from "@/views/timeline/services";
import Timeline from "@/views/timeline";

export default {
  name: "timeline-grade",
  components: { GradeTimelineFilters, Timeline },
  data() {
    return {
      loading: false,
      filters: {
        grade_id: "",
      },
      options: {
        dragMode: false,
      },
      graphData: {},
    };
  },
  methods: {
    async savePosition(body) {
      return await saveGradeTimelinePositionData(this.filters, body);
    },
    async getData() {
      this.loading = true;
      const res = await getGradeTimelineData(this.filters);
      this.loading = false;
      if (!res) return;
      this.graphData = res;
    },
    onFiltersChange(key, value) {
      if(this.filters[key] === value) return
      this.filters[key] = value;
    },
  },
};
</script>

<style scoped></style>
