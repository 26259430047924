import {reportGeneratorAPI} from "@/views/ReportGenerator/services/api";
import {isDotFormatDate, isValidDate, toValidComponentDate} from "@/_helpers";
import {downloadExcel} from "@/services";

export const getGeneratorReports = async () => {
  try {
    const res = await reportGeneratorAPI.getReports()
    return {reports: res?.data, ids: res?.data?.map(item => item.report_id)}
  } catch (e) {
  }
}
export const getReportFilters = async (report_id) => {
  try {
    const res = await reportGeneratorAPI.getReportFilters(report_id)
    const data = res?.data
    const defaultValue = {}
    if(data?.length) {
      data?.forEach(item => defaultValue[item.report_param_code] = null)
    }
    return {data, isRequiredExist: data?.some(item => item.is_required), defaultValue}
  } catch (e) {
  }
}
export const uploadReportExcel = async (report_id, params, cancelToken, report_name) => {
  try {
    const res = await reportGeneratorAPI.uploadExcel(report_id, params, cancelToken)
    downloadExcel(res, report_name)
  } catch (e) {
  }
}

const getTableColumnsFromObj = (data = []) => {
  try {
    let columns = []
    if (data?.length) {
      let firstEl = data[0]
      const firstTenEl = data?.slice(0, 10)
      columns = Object.keys(firstEl).map(key => {
        const obj = {
          name: "",
          label: "",
          field: "",
          align: "left",
          sortable: true
        }
        obj.label = key
        obj.field = key
        obj.name = key

        let elValue = firstEl[key]

        if (!elValue && elValue !== 0) {
          const existEl = firstTenEl.find(item => !!item[key])
          if (existEl) elValue = existEl[key]
        }

        if (!isNaN(elValue) && elValue !== null)
          obj.align = 'right'

        const isDate = typeof elValue === 'string' && ((isNaN(elValue) && isValidDate(elValue)) || isDotFormatDate(elValue))
        if (isDate) {
          obj.align = 'center'
          obj.sort = (a, b) => new Date(a) - new Date(b)
          if (isDotFormatDate(elValue))
            obj.sort = (a, b) => new Date(toValidComponentDate(a, true)) - new Date(toValidComponentDate(b, true))
        }
        return obj
      })
    }
    return columns
  } catch (e) {
    return []
  }
}
export const getSelectedReport = async (report_id, params, cancelToken) => {
  try {
    const res = await reportGeneratorAPI.getSelectedReport(report_id, params, cancelToken)
    const columns = getTableColumnsFromObj(res?.data)

    const additional = []
    const tables = []

    const isOtherExist = Object.keys(res).some(key => key.includes('other') && res[key]?.length)
    if (isOtherExist) {
      Object.keys(res).forEach(key => {
        if (key.includes('other')) {
          if (res[key].length === 1) {
            const itemObj = res[key][0]
            Object.keys(itemObj).forEach((childKey) => {
              const obj = {}
              obj.label = childKey
              obj.value = itemObj[childKey]
              obj.isNum = (!isNaN(itemObj[childKey]) && itemObj[childKey] !== null)
              additional.push(obj)
            })
          } else if (res[key].length) {
            const name = String(tables?.length + 2)
            const table = {name, label: name, data: res[key], columns: getTableColumnsFromObj(res[key])}
            tables.push(table)
          }
        }
      })
    }
    const tabs = [{name: 'main', label: '1'}]
    if (tables?.length) {
      tables.forEach((item, index) => tabs.push({name: String(index + 2), label: String(index + 2)}))
    }
    return {name: res?.report_name, data: res?.data, columns, additional, tables, tabs}
  } catch (e) {
  }
}

