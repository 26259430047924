<template>
  <div class="wrapper">
    <div class="flex justify-between header" v-if="!hideBack">
      <BackBtn @click="() => !this.cancelApproveMode && this.onCancel()" :approve="this.onCancel" :approve-title="this.cancelApproveTitle" :approve-mode="this.cancelApproveMode" />
      <span>{{ this.label }}</span>
    </div>
    <div class="content">
      <slot></slot>
    </div>
    <CustomModalFooter :cancel-btn-text="this.cancelBtnText" :hide-cancel-btn="!!this.hideBack" :submit-btn-text="this.submitBtnText"
                       :disable-submit="this.disabled" :on-submit="this.onSubmit" :btn-loading="this.loading"
                       :cancel-approve-mode="this.cancelApproveMode"
                       :cancel-approve-title="this.cancelApproveTitle"
                       :on-cancel="this.onCancel"/>
  </div>
</template>

<script>
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import BackBtn from "@/components/common/BackBtn";

export default {
  name: "CreateDSTemplate",
  components: {BackBtn, CustomModalFooter},
  props: ['onSubmit', 'onCancel', 'loading', 'label', 'hideBack', 'submitBtnText', 'cancelBtnText', 'disabled', 'cancelApproveMode'],
  data() {
    return {
      cancelApproveTitle: 'Есть несохраненные изменения, вы действительно хотите закрыть форму?'
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  margin: 15px 0
}

.header {
  gap: 15px;
  span {
    font-size: 17px;

    @media (max-width: 576px) {
      font-size: 16px;
      line-height: 18px;
    }
  }
}

.content {
  margin-top: 15px;
}
</style>