<template>
  <div class="hover-element" @mouseover="showPopup" @mouseout="hidePopup">
    <slot name="head"></slot>
    <q-popup-proxy
      v-if="this.show === true || this.show === undefined"
      ref="popup"
      transition-show="scale"
      transition-hide="scale"
    >
      <q-banner class="steps__help_wrapper"><slot></slot></q-banner>
    </q-popup-proxy>
  </div>
</template>

<script>
export default {
  name: "CustomPopup",
  props: ['show', 'hoverMode'],
  methods: {
    showPopup() {
      if(!this.hoverMode) return
      this.$refs.popup?.show()
    },
    hidePopup() {
      if(!this.hoverMode) return
      this.$refs.popup?.hide()
    }
  }
}
</script>

<style scoped></style>
