<template>
  <div class="wrapper">
    <q-form @submit="this.onSubmit" class="form">

      <div class="form__item">
        <CustomSelect
            :options="this.statuses?.map(item => item.grt_status_id === this.requestData?.grt_status_id ? ({...item, bold: true}) : item)"
            label="Статус"
            optionValueKey="grt_status_id"
            optionLabelKey="grt_status_name"
            :value="this.formData.grt_status_id || ''"
            @change="(value, item) => onChange('grt_status_id', value, item?.grt_status_code)"
            :required="true"
        />
      </div>
      <div class="form__item"
           v-if="this.formData.grt_status_code === '600'">
        <CustomSelect
            :options="this.warrantyDeniedReasons"
            label="Причина отказа"
            optionValueKey="value"
            optionLabelKey="label"
            :value="this.formData.grt_denied_reason || ''"
            @change="value => onChange('grt_denied_reason', value)"
            :required="true"
        />
      </div>
      <div class="form__item">
        <CustomInput :value="this.formData.comments_status"
                     @change_input="(value) => this.onChange('comments_status', value)"
                     label="Комментарии" type="textarea" />
      </div>
      <div class="form__item flex"
           v-if="this.formData.grt_status_code === '200' || this.formData.grt_status_code === '300'"
      >
        <CustomDate
            :required="true"
            v-if="this.formData.grt_status_code === '200' || this.formData.grt_status_code === '300'"
            :fullWidth="false" :min-width-mode="true"
            placeholder="Дата с"
            :value="this.formData.grt_status_date_begin"
            @change_date="(value) => onChange('grt_status_date_begin', value)"
        />
        <CustomDate
            :required="true"
            :fullWidth="false" :min-width-mode="true"
            v-if="this.formData.grt_status_code === '300'"
            placeholder="Дата до"
            :value="this.formData.grt_status_date_end"
            @change_date="(value) => onChange('grt_status_date_end', value)"
        />
      </div>
      <div class="nps" v-if="this.formData.grt_status_code === '700'">
        <div>
          <p class="label">Качество выполненных работ</p>
          <q-radio v-for="(item, idx) in Array.from({length: 10}).fill(null)" size="sm"
                   v-model="this.formData.quality_work"
                   :val="String(idx + 1)" :label="String(idx + 1)"/>
        </div>
        <div>
          <p class="label">Работы выполнены в указанный срок</p>
          <q-radio v-for="(item, idx) in Array.from({length: 10}).fill(null)" size="sm"
                   v-model="this.formData.work_completed"
                   :val="String(idx + 1)" :label="String(idx + 1)"/>
        </div>
        <div>
          <p class="label">Работа менеджера ГО</p>
          <q-radio v-for="(item, idx) in Array.from({length: 10}).fill(null)" size="sm"
                   v-model="this.formData.manager_grt_work"
                   :val="String(idx + 1)" :label="String(idx + 1)"/>
        </div>
      </div>
      <CustomModalFooter :btn-loading="this.loading" :hide-cancel-btn="true"/>
    </q-form>

    <CustomTable
        title=""
        :rowsData="this.rowsData"
        :columns="this.changeWarrantyStatusColumns"
        :custom-body="true" :loading="this.isFetching"
        :hide-footer-title="true" :sticky-header="false"
    >
      <template #customBody="props">
        <q-tr>
          <custom-table-cell align="left">
            {{ props.row.number }}
          </custom-table-cell>
          <custom-table-cell align="left">
            {{ props.row.grt_status_name }}
          </custom-table-cell>
          <custom-table-cell align="center">
            {{ props.row.grt_status_date }}
          </custom-table-cell>
          <custom-table-cell align="left">
            <span v-html="props.row.grt_status_comments"/>
          </custom-table-cell>
          <custom-table-cell align="left">
            {{ props.row.grt_manager_fio }}
          </custom-table-cell>
        </q-tr>
      </template>
    </CustomTable>

    <CustomModalFooter :hide-submit-btn="true" cancel-btn-text="Закрыть"/>
  </div>
</template>
<script>

import CustomInput from "@/components/form/CustomInput";
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import {
  changeWarrantyStatusColumns,
  getWarrantyStatuses,
  getWarrantyStatusHistory,
  warrantyDeniedReasons
} from "@/components/modal/modals/change-warranty-status/services";
import {getRemontWarrantyRequest} from "@/views/ClientRequests/warrantyRequests/services";
import CustomSelect from "@/components/form/CustomSelect";
import CustomDate from "@/components/form/CustomDate";

export default {
  name: "change-warranty-status",
  components: {CustomDate, CustomSelect, CustomTableCell, CustomTable, CustomInput, CustomModalFooter},
  props: ['data', 'closeModal'],
  data() {
    return {
      warrantyDeniedReasons,
      changeWarrantyStatusColumns,
      isFetching: false,
      historyFetching: false,
      loading: false,
      deleting: false,
      requestData: null,
      rowsData: null,
      statuses: [],
      formData: {
        grt_status_id: '',
        grt_status_code: '',
        comments_status: '',
        grt_status_date_begin: '',
        grt_status_date_end: '',
        grt_denied_reason: '',
        quality_work: '',
        work_completed: '',
        manager_grt_work: '',
      }
    }
  },
  methods: {
    onChange(key, value, code) {
      this.formData[key] = value
      if (code)
        this.formData.grt_status_code = code
    },
    async onSubmit() {
      if (!this.data?.onSubmit) return
      const body = {
        grt_status_id: this.formData.grt_status_id,
        comments_status: this.formData.comments_status
      }
      if (this.formData.grt_status_code === '200') {
        body.grt_status_date_begin = this.formData.grt_status_date_begin
      } else if (this.formData.grt_status_code === '300') {
        body.grt_status_date_begin = this.formData.grt_status_date_begin
        body.grt_status_date_end = this.formData.grt_status_date_end
      } else if (this.formData.grt_status_code === '600') {
        body.grt_denied_reason = this.formData.grt_denied_reason
      } else if (this.formData.grt_status_code === '700') {
        body.grt_denied_reason = this.formData.work_completed
        body.grt_denied_reason = this.formData.quality_work
        body.grt_denied_reason = this.formData.manager_grt_work
      }

      this.loading = true
      const res = await this.data.onSubmit(body)
      this.loading = false
      if (!res) return
      this.rowsData = res
    },
    async getData() {
      this.isFetching = true
      const res = await getRemontWarrantyRequest(this.data?.client_request_id, this.data?.grt_request_id)
      this.isFetching = false
      if (!res) return
      this.requestData = res
      this.formData = {...this.formData, ...res}
    },
    async getHistory() {
      this.historyFetching = true
      const res = await getWarrantyStatusHistory(this.data?.client_request_id, this.data?.grt_request_id)
      this.historyFetching = false
      this.rowsData = res
    },
    async getStatuses() {
      this.statuses = await getWarrantyStatuses()
    }
  },
  mounted() {
    this.getHistory()
    this.getStatuses()
    this.getData()
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  max-width: 500px;
}

.form {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  gap: 20px;

  @media (max-width: 428px) {
    min-width: 100%;
  }
}

@media (max-width: 576px) {
  .wrapper {
    max-width: 100%;
  }
}

.label {
  margin: 0;
}

.flex {
  gap: 7px;
}

.nps {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
</style>
