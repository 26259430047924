<template>
  <q-form @submit="this.onSubmit" class="form">
    <div class="form__item">
      <CustomSelect
          :options="this.agreementTypes"
          label="Тип договора"
          :required="true"
          optionValueKey="contractor_agreement_list_type_id"
          optionLabelKey="contractor_agreement_list_type_name"
          :value="this.formData.contractor_agreement_list_type_id || ''"
          @change="(value) => onChange('contractor_agreement_list_type_id', value)"
      />
    </div>
    <div class="form__item">
      <CustomInput
          label="ID ремонта" type="number"
          :value="this.formData.remont_id" :required="true"
          @change_input="(value) => onChange('remont_id', value)"/>
    </div>
    <div class="form__item">
      <CustomInput type="number"
                   :value="this.formData.agreement_sum"
                   label="Сумма договора"
                   @change_input="(value) => onChange('agreement_sum', value)"
      />
    </div>
    <div class="form__item">
      <CustomDate
          placeholder="Дата" :full-width="true"
          :value="this.formData.agreement_date || ''"
          @change_date="(value) => onChange('agreement_date', value)"
      />
    </div>
    <div class="form__item">
      <CustomCheckbox
          :value="!!this.formData.is_close"
          label="ДС для расторжения"
          @change="(value) => onChange('is_close', value)"
      />
    </div>
    <CustomModalFooter :btn-loading="this.loading"/>
  </q-form>
</template>
<script>

import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomSelect from "@/components/form/CustomSelect";
import CustomInput from "@/components/form/CustomInput";
import {getAgreementTypesForModal} from "@/views/AgreementsJournal/services";
import CustomDate from "@/components/form/CustomDate";
import CustomCheckbox from "@/components/form/CustomCheckbox";

export default {
  name: "add-agreement",
  components: {CustomCheckbox, CustomDate, CustomInput, CustomSelect, CustomModalFooter},
  props: ['data'],
  data() {
    return {
      loading: false,
      isFetching: false,
      agreementTypes: [],
      formData: {
        agreement_date: this.data?.body?.agreement_date || '',
        agreement_sum: this.data?.body?.agreement_sum || null,
        remont_id: this.data?.body?.remont_id || '',
        is_close: !!this.data?.body?.is_close,
        contractor_agreement_list_type_id: this.data?.body?.contractor_agreement_list_type_id || '',
      },
    }
  },
  mounted() {
    this.getTypes()
  },
  methods: {
    onChange(key, value) {
      this.formData[key] = value
    },
    async onSubmit() {
      this.loading = true
      await this.data?.onSubmit({
        ...this.formData,
        agreement_sum: this.formData.agreement_sum === '' ? null : this.formData.agreement_sum
      })
      this.loading = false
    },
    async getTypes() {
      this.isFetching = true
      const res = await getAgreementTypesForModal()
      this.isFetching = false
      if (!res) return
      this.agreementTypes = res
    },
  },
}
</script>

<style scoped lang="scss">
.form {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  gap: 20px;

  @media (max-width: 428px) {
    min-width: 100%;
  }
}
</style>
