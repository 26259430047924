import instance from "@/services/api";

export const gradeTimelineStepAPI = {
  async getGradeTimelineConditions(grade_step_id) {
    return await instance().get(`/timeline/grade_step/${grade_step_id}/read/condition/`).then(res => res?.data)
  },
  async getGradeTimelineActions(grade_step_id) {
    return await instance().get(`/timeline/grade_step/${grade_step_id}/read/activity/`).then(res => res?.data)
  },
  async getGradeTimelineIncludeConditions(grade_step_id) {
    return await instance().get(`/timeline/grade_step/${grade_step_id}/read/include_condition/`).then(res => res?.data)
  },
  async getGradeTimelineCount(grade_step_id) {
    return await instance().get(`/timeline/grade_step/${grade_step_id}/count/`).then(res => res?.data)
  },
  async getNotIncludedRequests(grade_step_id, body) {
    return await instance().post(`/timeline/grade_step/${grade_step_id}/read/activity/not_included/`, body).then(res => res?.data)
  },
  async addActionForRemont(grade_step_id, body) {
    return await instance().post(`/timeline/grade_step/${grade_step_id}/add/activity/for_remont/`, body).then(res => res?.data)
  },
  async getConditionNotIncludedRequests(grade_step_id, body) {
    return await instance().post(`/timeline/grade_step/${grade_step_id}/read/condition/not_included/`, body).then(res => res?.data)
  },
  async addConditionForRemont(grade_step_id, body) {
    return await instance().post(`/timeline/grade_step/${grade_step_id}/add/condition/not_included/`, body).then(res => res?.data)
  }
}