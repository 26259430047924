<template>
  <CustomTable
      title="Смета по материалам"
      :rowsData="this.rowsData"
      :columns="remontSmetaWholeColumns"
      :loading="isFetching"
      :custom-body="true" :small="true"
      :striped="false"
  >
    <template #customBody="props">
      <q-tr :props="props">
        <custom-table-cell v-for="col in props.cols" :key="col.name" auto-width :align="col.align" vertical-align="top">
          <check-or-close v-if="col.name === 'is_dirty'" :value="col.value" only-true/>
          <template v-else>{{ col.value }}</template>
        </custom-table-cell>
      </q-tr>
    </template>
  </CustomTable>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import {remontSmetaWholeColumns} from "@/views/ClientRequests/remontSmetaWhole/services";
import CheckOrClose from "@/components/common/CheckOrClose";

export default {
  name: "RemontSmetaWholeBlock",
  components: {CheckOrClose, CustomTable, CustomTableCell},
  props: ["client_request_id", "isFetching", "data"],
  data() {
    return {
      remontSmetaWholeColumns,
      rowsData: this.data
    }
  },
}
</script>