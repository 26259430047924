<template>
  <div class="materials">
    <div v-for="item in this.rowsData" class="materials__room">
      <div class="materials__room_title">
        {{item.room_name}}
      </div>
      <CustomTable
        title=""
        :rowsData="item.materials"
        :columns="managerMeasureColumns"
        :loading="isFetching"
        :custom-body="true"
      >
        <template #customBody="props">
          <q-tr>
            <custom-table-cell align="center">
              <div
                :class="{materials__inactive: !!props.row.depent_material_id}"
              >
                {{ props.row.material_id }}
              </div>
              <div v-if="!!props.row.depent_material_id">
                {{ props.row.depent_material_id }}
              </div>
            </custom-table-cell>
            <custom-table-cell align="center">
              <CustomImg
                class-name="materials__img"
                :src="props.row.photo_url"
              />
            </custom-table-cell>
            <custom-table-cell align="left">
              <div
                :class="{materials__inactive: !!props.row.depent_material_id}"
              >
                {{ props.row.material_name }}
              </div>
              <div v-if="!!props.row.depent_material_id">
                {{ props.row.depent_material_name }}
              </div>
            </custom-table-cell>
            <custom-table-cell align="left">
              {{ props.row.unit_name }}
            </custom-table-cell>
            <custom-table-cell align="left">
              {{ props.row.formula }}
            </custom-table-cell>
            <custom-table-cell align="left">
              <CustomInput
                :disable="['INTERNAL_DOOR', 'BATH', 'FLOOR_WARMING'].includes(props.row.material_type_code) && props.row.cnt !== null"
                class="materials__input"
                :value="props.row.cnt"
                type="number"
                :showClearIcon="false"
                @change_input="(value) => this.onInputCountChange(item.room_id, props.row.material_id, props.row.client_material_id, value)"
              />
            </custom-table-cell>
            <custom-table-cell align="left">
              <CustomSelect
                v-if="props.row.param_value !== null"
                :options="props.row.measures || []"
                label="Значение.."
                optionValueKey="width_polotno"
                optionLabelKey="width_polotno"
                :value="props.row.param_value || ''"
                @change="(value) => this.changeRowsData(item.room_id, props.row.material_id, props.row.client_material_id, 'param_value', value)"
                :filterFunc="() => this.fetchMaterialParams(item.room_id, props.row.material_id, props.row.client_material_id)"
              />
            </custom-table-cell>
          </q-tr>
        </template>
      </CustomTable>
    </div>
    <q-btn
      v-if="this.rowsData?.length"
      class="materials__submit"
      size="md"
      padding="xs"
      color="primary"
      :loading="this.loading"
      :disable="this.loading"
      @click="handleSubmit"
      >Сохранить</q-btn
    >
  </div>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import {managerMeasureColumns, changeDataRow, saveManagerMeasureData, getMaterialDoorDepend} from "./services";
import CustomIcon from "@/components/common/CustomIcon";
import CustomInput from "@/components/form/CustomInput";
import CustomSelect from "@/components/form/CustomSelect";
import CustomImg from "@/components/common/CustomImg";
import { useCustomSnackbar } from "@/_helpers/hooks/useCustomSnackbar";

export default {
  name: "managerMeasureBlock",
  components: {
    CustomImg, CustomInput, CustomIcon, CustomSelect, CustomTable, CustomTableCell},
  props: ["data", "client_request_id"],
  data() {
    return {
      managerMeasureColumns,
      isFetching: false,
      rowsData: this.data,
      loading: false
    }
  },
  methods: {
    changeDataRow,
    async handleSubmit() {
       this.loading = true
       const res = await saveManagerMeasureData(this.client_request_id, this.rowsData)
       this.loading = false
       if(!res) return
       this.rowsData = res
       this.showSucceedSnackbar()
    },
    async fetchMaterialParams(room_id, material_id, client_material_id) {
      let is_options_exist = false
      this.rowData?.forEach(item => {
        if(item.room_id === room_id)
          item.materials?.forEach(material => {
            if(material.material_id === material_id && material?.client_material_id === client_material_id)
              is_options_exist = !!material.measures
          })
      })
      if(is_options_exist) return
      const res = await getMaterialDoorDepend(this.client_request_id, {room_id, material_id})
      if(!res) return
      this.changeRowsData(room_id, material_id, client_material_id, 'measures', res)
    },
    changeRowsData(...args) {
      this.rowsData = this.changeDataRow(this.rowsData, ...args)
    },
    onInputCountChange(room_id, material_id, client_material_id, value) {
      this.changeRowsData(room_id, material_id, client_material_id, 'cnt', value)
    },
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar();
    return {showSucceedSnackbar};
  },
}
</script>

<style scoped lang="scss">
.materials {
  &__img {
    width: 50px;
  }
  &__inactive {
    text-decoration: line-through;
    color: red;
  }
  &__room {
    margin-top: 20px;
    &:first-child {
      margin-top: 0;
    }
    &_title {
      margin-bottom: 10px;
      font-size: 16px;
      color: #094c87;
    }
  }
  &__input {
    max-width: 80px;
  }
  &__submit {
    margin-top: 20px;
  }
}
</style>
