export const executiveReportCommentColumns = [
  {
    name: "comments",
    label: "Комментарий",
    field: "comments",
    align: "left",
  },
  {
    name: "stage_num",
    label: "Пункт",
    field: "stage_num",
    align: "left",
  },
  {
    name: "fio",
    label: "Пользователь",
    field: "fio",
    align: "left",
  },
  {
    name: "rowversion",
    label: "Дата и время",
    field: "rowversion",
    align: "center",
  },
  {
    name: "actions",
    label: "",
    field: "actions",
    align: "center",
  },
]