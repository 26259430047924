import {apartmentOwnersHistoryAPI} from "@/components/modal/modals/apartment-owners-history/api";

export const getApartmentOwnersHistory = async (client_request_id) => {
  try {
    const res = await apartmentOwnersHistoryAPI.getList(client_request_id)
    return res?.data?.map((item, i) => ({...item, number: i + 1}))
  } catch (e) {
  }
}
export const apartmentOwnersHistoryColumns = [
  {
    name: "number",
    label: "№",
    field: "number",
    align: "center",
  },
  {
    name: "prop_fio",
    label: "ФИО",
    field: "prop_fio",
    align: "left",
  },
  {
    name: "account_info",
    label: "Данные клиента",
    field: "account_info",
    align: "left",
  },
  {
    name: "email",
    label: "Почта",
    field: "email",
    align: "left",
  },
  {
    name: "bank",
    label: "Реквизиты",
    field: "bank",
    align: "left",
  },
  {
    name: "prop_new_client_date",
    label: "Дата приобретения",
    field: "prop_new_client_date",
    align: "center",
  },
]