import {remontDocsAPI} from "@/views/ClientRequests/remontDocs/api";

const docsBlockNames = {
  OA__RemontFormDocumentBlock: "remontDocsBlock",
}
export const getRemontDocsData = async (client_request_id) => {
  try {
    const res = await remontDocsAPI.getList(client_request_id)
    const data = res?.data
    if (!data?.length) return
    return data?.map(item => ({...item, component: docsBlockNames[item.grant_code]}))
  } catch (e) {
  }
}
export const addRequestDoc = async (client_request_id, body) => {
  try {
    const res = await remontDocsAPI.addDoc(client_request_id, body)
    return res?.data
  } catch (e) {
  }
}
export const updateRequestDoc = async (client_request_id, client_request_document_id, body) => {
  try {
    const res = await remontDocsAPI.updateDoc(client_request_id, client_request_document_id, body)
    return res?.data
  } catch (e) {
  }
}
export const deleteRequestDoc = async (client_request_id, client_request_document_id) => {
  try {
    const res = await remontDocsAPI.deleteDoc(client_request_id, client_request_document_id)
    return res?.data
  } catch (e) {
  }
}
export const getDocTypes = async () => {
  try {
    const res = await remontDocsAPI.getDocTypes()
    return res?.data
  } catch (e) {
  }
}

export const getApproveDocTypes = async () => {
  try {
    const res = await remontDocsAPI.getApproveDocTypes()
    return res?.data
  } catch (e) {
  }
}
export const getDSTypesByDocs = async () => {
  try {
    const res = await remontDocsAPI.getDSTypesByDocs()
    return res?.data
  } catch (e) {
  }
}
export const remontDocsColumns = [
  {
    name: "client_request_document_id",
    label: "ID",
    field: "client_request_document_id",
    align: "center",
  },
  {
    name: "document_name",
    label: "Наименование",
    field: "document_name",
    align: "left",
  },
  {
    name: "additional_agreement_amount",
    label: "Сумма ДС",
    field: "additional_agreement_amount",
    align: "right",
  },
  {
    name: "ds_origin_sum",
    label: "Сумма ДС по сверке",
    field: "ds_origin_sum",
    align: "right",
  },
  {
    name: "key_to",
    label: "Подписан клиентом",
    field: "key_to",
    align: "left",
  },
  {
    name: "comments",
    label: "Подписан подписантом",
    field: "comments",
    align: "left",
  },
  {
    name: "fio",
    label: "Добавил",
    field: "fio",
    align: "left",
  },
  {
    name: "rowversion",
    label: "Дата добавления",
    field: "rowversion",
    align: "center",
  },
  {
    name: "document_url",
    label: "",
    field: "document_url",
    align: "center",
  },
  {
    name: "actions_remove",
    label: "",
    field: "actions_remove",
    align: "center",
  },
]

export const getDocModalBody = (formData = {}, docType = '') => {
  let body = new FormData()
  try {
    body.append('document_type_id', formData.document_type_id)
    if (formData.document)
      body.append('document', formData.document[0])

    if(docType === 'ADJUSTMENT') {
      const {approve_document_type_id} = formData
      body.append('approve_document_type_id', approve_document_type_id)
    }
    if(docType === 'DRAFT_ACCEPT_ACT') {
      const {draft_akt_date} = formData
      body.append('draft_akt_date', draft_akt_date)
    }
    if(docType === 'APART_PASS_ACT_CL') {
      const {client_receive_date, implementation_date, unilateral_act} = formData
      body.append('client_receive_date', client_receive_date)
      body.append('implementation_date', implementation_date)
      body.append('unilateral_act', unilateral_act)
    }
    if(docType === 'ADDITIONAL_AGREEMENT') {
      const {ds_type_id, document_num, document_date, additional_agreement_amount} = formData
      body.append('ds_type_id', ds_type_id)
      body.append('document_num', document_num)
      body.append('document_date', document_date)
      body.append('additional_agreement_amount', additional_agreement_amount)
    }
  } catch (e) {}
  return body
}