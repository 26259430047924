import {remontMaterialsAPI} from "@/views/ClientRequests/remontMaterials/api";
import {numberWithCommas} from "@/services";

const materialsBlockNames = {
  OA__RemontFormMaterialBlock: "remontMaterialsBlock",
}
export const getRemontMaterialsData = async (client_request_id) => {
  try {
    const res = await remontMaterialsAPI.getList(client_request_id)
    const data = res?.data
    if (!data?.length) return
    return data?.map(item => ({...item, component: materialsBlockNames[item.grant_code]}))
  } catch (e) {
  }
}
export const remontMaterialsColumns = [
  {
    name: "request_material_smeta_id",
    label: "ID",
    field: "request_material_smeta_id",
    align: "center",
  },
  {
    name: "room_name",
    label: "Комната",
    field: "room_name",
    align: "left",
  },
  {
    name: "work_set_name",
    label: "Конструктив",
    field: "work_set_name",
    align: "left",
  },
  {
    name: "material_name",
    label: "Материал",
    field: "material_name",
    align: "left",
  },
  {
    name: "work_name",
    label: "Работа",
    field: "work_name",
    align: "left",
  },
  {
    name: "consumption",
    label: "Расход норма",
    field: "consumption",
    align: "right",
    format: (val) => numberWithCommas(val),
  },
  {
    name: "cnt",
    label: "Расход",
    field: "cnt",
    align: "right",
    format: (val) => numberWithCommas(val),
  },
  {
    name: "formula",
    label: "Формула",
    field: "formula",
    align: "left",
  },
]
