<template>
  <q-linear-progress v-if="this.isFetching" indeterminate />
  <div class="fileList__file_list" v-if="this.files?.length">
    <div
        v-for="file in this.files"
        class="fileList__file_wrapper"
    >
      <div
                     class="fileList__file_hover">
        <div class="fileList__file_hoverRemove">
          <CheckOrClose :value="false"/>
          <PopupConfirm
              :loading="this.loadingDeleteFile"
              :approve="() => this.handleDeleteFile(file)" />
        </div>
        <span
            @click="this.openGallery(file)"
            class="fileList__file_hoverLink"
            :title="file[this.fileNameProp]">
                <span class="fileList__file_hoverText">{{ file[this.fileNameProp] }}</span>
              </span>
      </div>
      <CustomImg
          v-if="this.isFileImg(file[this.fileExtProp] || this.getFileExt(file[this.fileNameProp]))"
          :src="file[this.fileMinUrlProp || this.fileUrlProp]"
          className="fileList__content_image"
          errorClass="fileList__content_imageError"
          :isStaticImg="isImgStatic(file)"
          :show-default-img="false"
          class="fileList__file"
          :alt="file[this.fileNameProp]"
      />
      <video
          v-else-if="file?.is_video || this.isFileVideo(file[this.fileExtProp] || this.getFileExt(file[this.fileNameProp]))"
          class="fileList__content_video"
          controls
      >
        <source
            :src="isImgStatic(file) ? file?.file : getFullUrl(file[this.fileUrlProp])"
            id="video_here"
        />
        Your browser does not support HTML5 video
      </video>
      <div v-else class="fileList__content_fileDoc">
        <a
            class="fileList__content_fileLink"
            target="_blank"
            :href="isImgStatic(file) ? file?.file : this.getFullUrl(file[this.fileUrlProp])"
            download
        >
          <q-icon name="description" class="fileList__content_fileIcon"/>
          <span>{{ file[this.fileNameProp] }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import CustomImg from "@/components/common/CustomImg";
import {appActions} from "@/services/store/modules/app_module/app_actions";
import {MODAL_NAMES} from "@/constants";
import CheckOrClose from "@/components/common/CheckOrClose";
import PopupConfirm from "@/components/common/PopupConfirm";
import {getFullUrl} from "@/services";
import {isFileImg, isFileVideo, getFileExt} from "@/_helpers";

export default {
  name: "FileList",
  components: {PopupConfirm, CheckOrClose, CustomImg},
  props: ['files', 'onDeleteFileClick', 'onOpenGallery', 'isFetching', 'fileNameProp', 'fileUrlProp', 'fileMinUrlProp', 'fileExtProp'],
  data() {
    return {
      loadingDeleteFile: false
    }
  },
  methods: {
    getFullUrl,
    getFileExt,
    isFileImg,
    isFileVideo,
    isImgStatic(file) {
      return file[this.fileUrlProp]?.includes('blob')
    },
    downloadVideo(videoUrl, filename) {
      const link = document.createElement('a');
      link.href = videoUrl;
      link.download = filename;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    openGallery(file) {
      if(!this.isFileImg(file[this.fileExtProp] || this.getFileExt(file[this.fileNameProp]))) {
        return this.downloadVideo(this.getFullUrl(file[this.fileUrlProp]), file[this.fileNameProp], {}, 'mp4', 'get')
      }
      let props = {}
      if(this.onOpenGallery) {
        const imagesData = this.onOpenGallery(file)
        if(!imagesData) return
        const {imgIndex, files} = imagesData
        props = {imgIndex, files: files?.map(item => ({...item, file_name: item[this.fileNameProp], file_url: getFullUrl(item[this.fileUrlProp])}))}
      }
      this.$store.commit(appActions.showModal(MODAL_NAMES.ChatGallery, {
        ...props,
        borderless: true
      }));
    },
    async handleDeleteFile(file) {
      this.loadingDeleteFile = true
      this.onDeleteFileClick && await this.onDeleteFileClick(file)
      this.loadingDeleteFile = false
    },
  }
}
</script>

<style scoped lang="scss">
$message_max_width: 85%;

.fileList {
  &__content {
    &_video {
      width: 100%;
      max-height: 180px;
    }

    &_image {
      padding-top: 5px;
      min-height: 75px;

      &Error {
        min-height: 45px;
      }

      &:first-of-type {
        padding-top: 0;
      }
    }

    &_file {
      position: relative;
      padding: 0;

      &Link {
        span {
          text-decoration: underline;
          transition: all .3s;
        }

        &:hover {
          span {
            color: #083577;
            text-decoration: none;
          }
        }
      }

      &Icon {
        color: #0e74bc;
        font-size: 60px;
        text-decoration: none;
      }

      &Doc {
        padding: 5px 8px;

        a {
          display: flex;
          align-items: center;
          gap: 5px;
        }

        span:active {
          text-decoration: underline;
        }
      }
    }
  }
  &__file {
    background: none;
    max-width: $message_max_width !important;
    max-height: 180px !important;
    border-radius: 6px;
    object-fit: cover;
    min-width: 150px !important;
    position: relative;

    &_list {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin: 7px 0;
    }

    &_wrapper {
      position: relative;
      cursor: pointer;
      min-height: 40px;

      &:hover {
        .fileList__file_hover {
          height: 100%;
        }
      }
    }

    &_hover {
      background: rgba(0, 0, 0, .6);
      width: 100%;
      position: absolute;
      z-index: 100;
      height: 0;
      transition: all .3s;
      overflow: hidden;

      &Text {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 10px;
        text-align: center;
        line-height: 11px;
      }

      &Remove {
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, .65);
      }

      &Link {
        overflow: hidden;
        text-overflow: ellipsis;
        height: calc(100% - 30px);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &_reply {
      height: 55px;
      width: 55px;
      border-radius: 8px;
    }
  }
}

@media (max-width: 400px) {
  .fileList {
    &__file {
      max-height: 180px;
      max-width: 100%;
      height: auto;
    }
  }
}
</style>