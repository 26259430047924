<template>
  <div :class="[{purpose: this.data?.body?.purpose}]">
    <q-form @submit="this.onSubmit" class="form">
      <div class="form__item">
        <CustomInputNumber
          label="Сумма договора"
          :value="this.formData.agreement_sum"
          @change_input="(value) => onChange('agreement_sum', value)"
        />
      </div>
      <CustomModalFooter :btn-loading="this.loading" />
    </q-form>
  </div>
</template>
<script>

import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomInputNumber from "@/components/form/CustomInputNumber";

export default {
  name: "create-additional-agreement",
  components: {CustomInputNumber, CustomModalFooter, },
  props: ['data'],
  data() {
    return {
      loading: false,
      formData: {
        agreement_sum: this.data?.body?.agreement_sum || '',
      }
    }
  },
  methods: {
    onChange(key, value) {
      this.formData[key] = value
    },
    async onSubmit() {
      this.loading = true
      await this.data?.onSubmit({...this.formData, agreement_sum: Math.round(this.formData.agreement_sum)})
      this.loading = false
    },
  },
}
</script>

<style scoped lang="scss">
.form {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  gap: 15px;

  @media (max-width: 428px) {
    min-width: 100%;
  }
}
</style>
