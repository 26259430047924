export const getSelectedIds = (obj, isNumber = true) => {
  const selected = Object.keys(obj).filter((item) => !!obj[item]);
  if (isNumber) return selected.map((item) => +item);
  return selected;
};

export const toValidComponentDate = (date, withTime = false) => {
  if (!date) return "";
  if (typeof date === "object") return date;
  try {
    let dateParts = date.split(".");
    if (dateParts?.length !== 3) {
      return date;
    }
    let day = dateParts[0];
    let month = dateParts[1];
    let year = dateParts[2];
    let time = ''

    const yearSplited = year.split(' ')
    if (yearSplited?.length > 1) {
      year = yearSplited[0].trim()
      time = yearSplited[1].trim()
    }
    return `${year}-${month}-${day}${(withTime && !!time) ? ` ${time}` : ''}`;
  } catch (e) {
    return "";
  }
};

export const checkIdValid = (id) => {
  if (!id) return;
  if (isNaN(id)) throw "Введите корректный ID";
};

export const cropWord = (word = "", maxLength = 25, defaultStr = "") => {
  if (!word) return defaultStr;
  try {
    const cropped = word.slice(0, maxLength);
    if (word?.length > maxLength) return `${cropped}...`;
    return cropped;
  } catch (e) {
    return defaultStr;
  }
};
export const isFileImg = (ext) => {
  return ext === 'jpg' || ext === "png" || ext === "jpeg"
}
export const isFileVideo = (ext) => {
  if(!ext) return false
  const videoExtensions = ['.mp4', '.avi', '.mkv', '.mov', '.wmv', '.flv', '.webm', '.mpeg'];
  return videoExtensions.includes(`.${ext.toLowerCase()}`);
}

export const getFileExt = (fileName) => {
  if(!fileName) return ''
  if(!fileName?.includes('.')) return ''
  const splited = fileName?.split('.')?.filter(item => !!item)
  return splited[splited.length - 1]
}
const isLeapYear = (year) => {
  // Leap years are divisible by 4
  // However, years divisible by 100 are not leap years, unless they are also divisible by 400
  return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
}
const months = [
  {
    "label": "Январь",
    "value": 1,
    "days": 31
  }, {
    "label": "Февраль",
    "short": "Feb",
    "value": 2,
    "days": 28
  }, {
    "label": "Март",
    "short": "Mar",
    "value": 3,
    "days": 31
  }, {
    "label": "Апрель",
    "short": "Apr",
    "value": 4,
    "days": 30
  }, {
    "label": "Май",
    "short": "May",
    "value": 5,
    "days": 31
  }, {
    "label": "Июнь",
    "short": "Jun",
    "value": 6,
    "days": 30
  }, {
    "label": "Июль",
    "short": "Jul",
    "value": 7,
    "days": 31
  }, {
    "label": "Август",
    "short": "Aug",
    "value": 8,
    "days": 31
  }, {
    "label": "Сентябрь",
    "short": "Sep",
    "value": 9,
    "days": 30
  }, {
    "label": "Октябрь",
    "short": "Oct",
    "value": 10,
    "days": 31
  }, {
    "label": "Ноябрь",
    "short": "Nov",
    "value": 11,
    "days": 30
  }, {
    "label": "Декабрь",
    "value": 12,
    "short": "Dec",
    "days": 31
  }]

const years = [2018, 2019, 2020, 2021, 2022, 2023, 2024]


const getCorrectDateNumber = (num) => {
  return String(num)?.length === 1 ? `0${num}` : num
}
const getMonthData = (year) => months
  .map(item => {
    let daysCount = item.days
    if (item.value === 2) {
      const isLeap = isLeapYear(year)
      daysCount = isLeap ? 29 : 28
    }
    return {
      ...item, "value": `${item.value}.${year}`, children: Array.from({length: daysCount})
        .map((day, index) => ({"label": String(index + 1), "value": `${getCorrectDateNumber(String(index + 1))}.${getCorrectDateNumber(item.value)}.${year}`}))
    }
  })

export const dateData = years.map(year => ({label: `${year}`, value: `${year}`, children: getMonthData(year)}))
export const isValidDate = (dateString) => {
  const dateObject = new Date(dateString);
  return !isNaN(dateObject.getTime());
}

export const isDotFormatDate = (date) => {
  if (!date) return false
  return ((date?.length === 10 || date?.length === 16 || date?.length === 19) && (date.match(/\./g) || []).length === 2)
}

