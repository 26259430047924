<template>
  <div class="message">
    <div class="message__wrapper">
      <div :class="['message__content', {message_selected: isSelected}]">
        <div class="message__header">
          <p class="message__user_name">
            {{ message?.chat_fio }}
          </p>
          <div
              class="message__header_photoReport"
              v-if="message?.is_photo_report"
          >
            Фото-отчет
          </div>
        </div>
        <FileList :files="this.message?.files" :on-delete-file-click="this.handleDeleteMessageFile"
                  :on-open-gallery="this.openGallery"
        file-ext-prop="file_ext" file-min-url-prop="file_mini_url" file-name-prop="file_name" file-url-prop="file_url"/>
        <p
            v-if="message?.message"
            :class="['message__text']"
            v-html="formatMessage(message?.message)"
        ></p>
        <div v-if="isSelected" class="message_selected__block"/>
        <div :class="['message__date', {message__date_file: isFile}]">
          <p>
            {{ this.message.date_chat }}
          </p>
        </div>
      </div>
    </div>
    <div class="message__actions">
      <CustomIcon
          icon="edit"
          small
          hover-mode
          @click="() => this.onEditMessageClick(this.message?.stage_chat_id, this.message)"
      />
      <CustomIcon
          icon="trash"
          small
          hover-mode
          :approve="() => this.handleDeleteMessage(this.message?.stage_chat_id, this.message)"
          :loading="this.loadingDelete"
      />
    </div>
  </div>
</template>

<script>
import {getFullUrl} from "@/services";
import CustomImg from "@/components/common/CustomImg";
import CustomIcon from "@/components/common/CustomIcon";
import CheckOrClose from "@/components/common/CheckOrClose";
import PopupConfirm from "@/components/common/PopupConfirm";
import FileList from "@/components/common/FileList";
import {isFileImg} from "@/_helpers";

export default {
  name: "chatMessage",
  props: ["message", "onEditMessageClick", "onDeleteMessageClick", "onDeleteMessageFileClick"],
  components: {FileList, PopupConfirm, CheckOrClose, CustomIcon, CustomImg},
  data() {
    return {
      loadingDelete: false,
    }
  },
  computed: {
    isFile() {
      return !!this.message?.files?.length
    },
    isSelected() {
      return !!this.message?.isSelected
    }
  },
  methods: {
    getFullUrl,
    isFileImg,
    formatMessage(value) {
      return value.replace(/\r\n/g, "<br>");
    },
    openGallery(file) {
      const images = this.message?.files?.filter(item => this.isFileImg(item.file_ext))
      const imgIndex = images.findIndex(item => item.stage_chat_file_id === file?.stage_chat_file_id)
      return {imgIndex, files: images}
    },
    async handleDeleteMessage(stage_chat_id, message) {
      this.loadingDelete = true
      this.onDeleteMessageClick && await this.onDeleteMessageClick(stage_chat_id, message)
      this.loadingDelete = false
    },
    async handleDeleteMessageFile(file) {
      this.onDeleteMessageFileClick && await this.onDeleteMessageFileClick(file?.stage_chat_file_id)
    },
  }
}
</script>

<style scoped lang="scss">
$message_max_width: 85%;
.message {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  position: relative;
  flex-direction: row;
  max-width: 100%;
  min-width: 70%;
  width: 100%;

  &__wrapper {
    display: flex;
    max-width: $message_max_width;
    width: 100%;
  }

  &__date {
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 10px;
      color: #5d5d5d;
      margin: 4px 0 0 0 !important;
      text-align: right;
    }

    &_file {
      padding-bottom: 5px;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__reply {
    border-left: 2px solid #FF6647;
    margin-bottom: 5px;
    cursor: pointer;
    padding-left: 5px;
    display: flex;
    flex-direction: column;

    &__file {
      width: 32px;
      height: 32px;
    }
  }

  &__user {
    &_avatar {
      margin-right: 10px;
      width: 40px;
      height: 40px;
      flex-shrink: 0;

      img {
        border-radius: 50%;
      }
    }

    &_name {
      font-style: normal;
      font-size: 13px;
      line-height: 19px;
      color: #7492FF;
      margin: 0;

      &Padding {
        padding: 5px 0 0 15px;
      }

      &Icon {
        font-size: 20px;
        color: #1f5ac2;
        line-height: 16px;
      }
    }
  }

  &_selected {
    background: rgba(255, 102, 71, 0.4) !important;
    color: #763921 !important;

    &__block {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 100;
      width: 100%;
      height: 100%;
      border-radius: 14px;
      background: rgba(255, 102, 71, 0.4) !important;
    }
  }

  &__burger {
    z-index: 100;
    overflow: visible;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
    margin-bottom: 3px;

    &_photoReport {
      border-radius: 3px;
      padding: 0 3px;
      background-color: #c971df;
      color: #fff;
      font-size: 10px;
    }
  }

  &__content {
    padding: 5px 15px;
    border-radius: 15px;
    background-color: #EFEFEF;
    font-style: normal;
    font-size: 12px;
    line-height: 19px;
    color: #000000;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  &__text {
    line-height: 20px;
    font-size: 14px;
    margin: 0;
    font-weight: 400;

    &_file {
      padding-left: 8px;
    }
  }

  &__burgers {
    margin-right: auto;
    float: left;
    margin-bottom: 20px;
    z-index: 100;
  }

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
</style>
