import {responsibleOfficialAPI} from "@/views/ClientRequests/responsibleOfficial/api";

const responsibleOfficialBlockNames = {
  OA__RemontFormResponsibleOfficialBlock: "responsibleOfficialBlock",
}
export const getResponsibleOfficialData = async (client_request_id) => {
  try {
    const res = await responsibleOfficialAPI.getList(client_request_id)
    const data = res?.data
    if(!data?.length) return
    return data?.map(item => ({...item, component: responsibleOfficialBlockNames[item.grant_code]}))
  } catch (e) {
  }
}