<template>
  <q-btn color="primary" class="wrapper">
    <div class="header">
      {{this.title}} <q-icon size="2em" color="primary" name="expand_more" />
    </div>
    <q-popup-proxy v-if="this.options?.length">
      <q-banner class="options">
        <q-linear-progress v-if="this.loading" indeterminate/>
        <div
          :class="['options__item', {options__item_disabled: this.loading}]"
          v-for="item in (this.options || [])"
          v-on:click="this.handleClick(item)"
        >
          {{item.label}}
        </div>
      </q-banner>
    </q-popup-proxy>
  </q-btn>
</template>

<script>
export default {
  name: "CustomDropdown",
  props: ["title", "options", "onClick", "loading"],
  methods: {
    handleClick(name) {
      if(!this.onClick || this.loading) return
      this.onClick(name)
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  border-radius: 3px;
  border: 1px solid #183FA6 !important;
  background: #FFF !important;
  color: #183FA6 !important;
  padding: 2px 7px !important;
  min-height: auto !important;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  & > span {
    background: #FFF !important;
  }

  &:before {
    box-shadow: none;
  }
  &:hover {
    background: #FFF !important;

    span {
      background: #FFF !important;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.options {
  padding: 5px 0;
  min-height: auto;
  &__item {
    transition: all .3s;
    padding: 3px 5px;
    cursor: pointer;
    font-size: 12px;
    &:hover {
      background: #f5f5f5;
    }

    &_disabled {
      cursor: default;
      background: #d7d7d7;
      color: #a1a1a1;
      &:hover {
        background: #d7d7d7;
      }
    }
  }
}
</style>
