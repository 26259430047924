<template>
  <div class="wrapper">
    <div ref="header" class="header">
      <p  class="header__title">{{title}}</p>
      <slot name="actions"></slot>
    </div>
    <div class="content" :style="contentHeight">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Block",
  data() {
    return {
      headerHeight: 40
    }
  },
  props: ["title"],
  computed: {
    contentHeight() {
      return `height: calc(100% - ${this.headerHeight ? this.headerHeight + 2 : 40}px)`
    }
  },
  mounted() {
    this.headerHeight = this.$refs.header?.clientHeight || 40
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  border-radius: 15px;
  background: #2e6295;
  padding: 6px;
  height: 100%;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 6px 8px;

  &__title {
    color: #ffffff;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
  }
  @media (max-width: 768px) {
    padding: 0 5px 5px 5px;
    &__title {
      font-size: 16px;
    }
  }
}

.content {
  border-radius: 0 0 15px 15px;
  background: #FFF;
  padding: 17px 10px;
}
</style>