import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./services/store";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
import {vue3Debounce} from "vue-debounce";
import money from 'v-money3'
import "@/assets/styles/global.css";
import "quasar/dist/quasar.sass"

createApp(App)
  .use(Quasar, quasarUserOptions)
  .use(store)
  .use(router)
  .use(money)
  .directive('debounce', vue3Debounce({ lock: true }))
  .directive('click-outside', {
    mounted(el, binding) {
      el.clickOutsideEvent = function(event) {
        if (!(el === event.target || el.contains(event.target))) {
          binding.value = false;
        }
      };
      document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unmounted(el) {
      document.body.removeEventListener('click', el.clickOutsideEvent);
    }
  })
  .mount("#app");
