import instance from "@/services/api";

export const fastSearchAPI = {
  async getResidents() {
    return await instance().get('common/residents/read').then(res => res?.data)
  },
  async search(body) {
    return await instance().post('/client_request/quick_search/', body).then(res => res?.data)
  },
  async quickSearchHistory() {
    return await instance().get('/client_request/quick_search/history/').then(res => res?.data)
  }
}