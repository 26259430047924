<template>
  <div v-for="item in this.data" class="checkList__parent">
    <span>{{item.stage_name}}</span>
    <CustomTable
        title=""
        :rowsData="item.check_list_parents"
        :columns="checkListColumns"
        :loading="isFetching"
        :custom-body="true"
        :hide-footer-title="true"
    >
      <template #customBody="props">
        <q-tr class="checkList__parent_header" style="background-color: #d8e3ff !important">
          <custom-table-cell align="left" colspan="10">
            {{ props.row.check_name }}
          </custom-table-cell>
        </q-tr>
        <q-tr v-for="check in props.row?.check_list">
          <custom-table-cell align="left" >
            {{ check.check_name }}
          </custom-table-cell>
          <custom-table-cell align="right" >
            {{ check.norm }}
          </custom-table-cell>
          <custom-table-cell align="right" >
            <a v-if="!!check.cnt_standart" target="_blank" :href="getFullUrl(check?.standart_url)">
              <q-btn size="xs" padding="xs" color="green" no-wrap>{{`Фото (${check.cnt_standart})`}}</q-btn>
            </a>
          </custom-table-cell>
          <custom-table-cell align="right" >
            <q-btn @click="() => handlePhotosClick(check.check_list_id)" size="xs" padding="xs" color="primary" v-if="!!check.photo_cnt" no-wrap>{{`Посмотреть (${check.photo_cnt})`}}</q-btn>
          </custom-table-cell>
          <custom-table-cell align="left" >
            {{ check.comments}}
          </custom-table-cell>
          <custom-table-cell align="left" >
            {{ check.fio}}
          </custom-table-cell>
          <custom-table-cell align="center" >
            {{ !!check.is_required ? "Да" : "Нет"}}
          </custom-table-cell>
          <custom-table-cell align="center" >
            {{ !!check.is_okk_manager ? "ОКК" : "МП"}}
          </custom-table-cell>
          <custom-table-cell align="center" >
            {{ check.date_create}}
          </custom-table-cell>
          <custom-table-cell align="center" :class="['checkList__status', {checkList__accepted: check.is_accepted === 1,checkList__error: check.is_accepted === 0}]">
            {{ check.is_accepted === -1 ? '' : check.is_accepted === 1 ? 'Принято' : "Есть дефекты"}}
          </custom-table-cell>
        </q-tr>
      </template>
    </CustomTable>
  </div>
</template>

<script>
import BlockItem from "@/components/common/BlockItem";
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import CustomIcon from "@/components/common/CustomIcon";
import Clickable from "@/components/common/Clickable";
import {numberWithCommas, getFullUrl} from "@/services";
import CheckOrClose from "@/components/common/CheckOrClose";
import {checkListColumns} from "@/views/ClientRequests/checkList/services";
import {appActions} from "@/services/store/modules/app_module/app_actions";
import {MODAL_NAMES} from "@/constants";

export default {
  name: "CheckListBlock",
  components: {CheckOrClose, Clickable, CustomIcon, BlockItem, CustomTable, CustomTableCell, CustomCheckbox},
  props: ["client_request_id", "data", "isFetching"],
  data() {
    return {
      selectedItems: {},
      checkListColumns
    }
  },
  methods: {
    numberWithCommas,
    getFullUrl,
    handlePhotosClick(check_list_id) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.CHECK_LIST_PHOTOS, {
        client_request_id: this.client_request_id, check_list_id
      }));
    }
  },
}
</script>

<style scoped lang="scss">

.checkList {
  &__parent {
    margin-top: 25px;

    &:first-child {
      margin-top: 0;
    }
    span {
      font-size: 16px;
      color: #3873e7;
      margin-bottom: 10px;
      display: block;
      margin-left: 5px;
      font-weight: 500;
    }
    &_header {
      font-weight: 500;
      position: sticky;
      top: 48px;
      z-index: 100;
    }
  }
  &__status {
    color: #fff;
    font-weight: 500;
  }
  &__accepted {
    background-color: #24e524;
  }
  &__error {
    background-color: #cc1919;
  }
}
</style>