<template>
  <div class="main__logo_wrapper">
    <img src="@/assets/img/logo.png" class="main__logo" alt="logo_new"/>
  </div>

  <div class="main__slider">
    <q-carousel
        v-model="slide"
        swipeable
        animated
        autoplay
        infinite
        navigation
        padding
        arrows
        height="500px"
        control-type="flat"
        control-color="red"
        class="text-purple rounded-borders"
    >
      <q-carousel-slide v-for="(item, index) in this.slides" :key="index" :name="`values${index}`"
                        class="column no-wrap flex-center">
        <div class="q-mt-md text-center">
          <img :src="require(`@/assets/img/company/${item}`)" class="main__slider_img" alt="logo_new"/>
        </div>
      </q-carousel-slide>
    </q-carousel>
  </div>
</template>

<script>

export default {
  name: "HomeView",
  data() {
    return {
      slide: 'values0',
      slides: [
          "smart rem 4_pages-to-jpg-0001.jpg",
          "smart rem 4_pages-to-jpg-0002.jpg",
          "smart rem 4_pages-to-jpg-0003.jpg",
          "smart rem 4_pages-to-jpg-0004.jpg",
          "smart rem 4_pages-to-jpg-0005.jpg",
          "smart rem 4_pages-to-jpg-0006.jpg",
          "smart rem 4_pages-to-jpg-0007.jpg",
      ],
    }
  }
};
</script>

<style lang="scss">
.main {
  &__logo {
    width: 180px;
    &_wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  &__slider {
    margin-top: 25px;
    &_img {
      height: 500px;

      @media (max-width: 370px) {
        max-width: calc(100vw - 20px);
      }
    }
  }
}
</style>
