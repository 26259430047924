<template>
  <a target="_blank" v-if="this.link" :href="this.href" :class="['clickable', {clickable__block: this.blockMode}, {clickable__alt: this.altMode}]">
    <slot></slot>
  </a>
  <router-link target="_blank" v-else-if="this.routerLink" :to="this.href" :class="['clickable', {clickable__block: this.blockMode}, {clickable__alt: this.altMode}]">
    <slot></slot>
  </router-link>
  <span v-else :class="['clickable', {clickable__block: this.blockMode}, {clickable__alt: this.altMode}]"><slot></slot></span>
</template>

<script>
export default {
  name: "Clickable",
  props: {
    link: Boolean,
    routerLink: Boolean,
    blockMode: Boolean,
    href: String,
    altMode: Boolean
  }
}
</script>

<style scoped lang="scss">
.clickable {
  color: #3d74dc;
  text-decoration: underline;
  transition: all .3s;
  cursor: pointer;

  &:hover {
    color: #83aaef;
    text-decoration: none;
  }

  &__block {
    display: block;
  }

  &__alt {
    text-decoration: none;

    &:hover {
      color: #3d74dc;
      text-decoration: underline;
    }
  }
}
</style>