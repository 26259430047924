<template>
  <q-carousel
      v-model="slide"
      swipeable
      animated
      arrows
      padding
      height="500px"
      control-type="flat"
      control-color="red"
      class="text-purple rounded-borders gallery"
  >
    <q-carousel-slide v-for="(file, index) in this.data.files" :key="index" :name="`values${index}`"
                      class="column no-wrap flex-center img__slide">
      <div class="text-center img__wrapper">
        <CustomImg
            :src="file?.file_url"
            :isStaticImg="true"
            :alt="file?.file_name"
            class="img"
        />
      </div>
    </q-carousel-slide>
  </q-carousel>
</template>
<script>

import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomSelect from "@/components/form/CustomSelect";
import CustomInput from "@/components/form/CustomInput";
import CustomDate from "@/components/form/CustomDate";
import CustomImg from "@/components/common/CustomImg";

export default {
  name: "chat-gallery",
  components: {CustomImg, CustomDate, CustomInput, CustomSelect, CustomModalFooter},
  props: ['data'],
  data() {
    return {
      slide: `values${this.data.imgIndex}`,
    }
  },
  methods: {
    isImgStatic(file) {
      return file?.file_url?.includes('blob')
    },
  }
}
</script>

<style scoped lang="scss">
.gallery {
  background: none;
  min-width: 400px;

  @media (max-width: 500px) {
    min-width: 240px;
  }
}
.img {
  height: calc(100vh - 180px);
  width: auto;

  &__wrapper {
    width: 100%;
    overflow: hidden;
  }
  &__slide {
    padding: 0 !important;
    justify-content: flex-start !important;
  }

  @media (max-width: 450px) {
    width: 100%;
  }
}
</style>
