<template>
  <q-expansion-item v-for="item in menu" :key="item.id" :icon="this.deepLevel === 0 ? 'folder' : 'note'"
                    :header-inset-level="this.deepLevel / 5"
                    :class="{item: true, item__active: pages[item.menu_action] === path}"
                    :label="item.menu_name"
                    :to="this.getHref(item.menu_action)"
                    expand-icon-class="item__exp_icon" :hide-expand-icon="!item.sub_menus?.length">
    <menu-list v-if="item.sub_menus" :menu="item.sub_menus" :deep-level="this.deepLevel + 1"/>
  </q-expansion-item>
</template>

<script>
import {PAGES} from "@/constants";
import {useRoute} from "vue-router/dist/vue-router";

export default {
  name: "menu-list",
  props: {
    menu: {
      type: Array,
      required: true
    },
    deepLevel: {
      default: 0
    }
  },
  computed: {
    pages() {
      return PAGES;
    },
    path() {
      const router = useRoute();
      return router.path;
    },
  },
  methods: {
    getHref(menu_action) {
      if(!menu_action || !this.pages[menu_action] || this.pages[menu_action]?.includes(':')) return
      return this.pages[menu_action]
    }
  }
}
</script>

<style lang="scss">
.item {
  color: #1976d2;
  padding: 0;
  border: none;

  .q-icon {
    font-size: 12px !important;
  }

  .material-icons {
    font-size: 17px !important;
    color: #2c68b7;
  }

  &__link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
    padding: 8px 16px;
    width: 100%;

    &_icon {
      min-width: 40px;
    }

    &_dot {
      font-size: 8px !important;
    }
  }

  &__exp {
    &_icon {
      font-size: 20px !important;
    }
  }

  &__active {
    background: rgb(182, 194, 255, 0.18);
  }
}
</style>
