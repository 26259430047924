<template>
  <div class="wrapper">
    <q-form @submit="this.onSubmit">
      <CustomSelect :required="true" :options="this.listData" :option-label-key="this.labelKey"
                    :option-value-key="this.valueKey"
                    :value="this.value" :label="this.label"
                    @change="onChange"/>
      <div class="fortItem">
        <CustomInput
            v-if="this.agreementSum !== undefined"
            type="number" label="Введите сумму спецификации (для автоматического рассчета оставить пустым):"
            :showClearIcon="false"
            :value="this.agreementSum"
            @change_input="this.onSumChange"
        />
      </div>
      <CustomModalFooter :btn-loading="this.loading" :disable-submit="this.isFetching"/>
    </q-form>
  </div>
</template>

<script>
import CustomSelect from "@/components/form/CustomSelect";
import {
  getRemontResponsibleData,
  getResponsibleAgreementSum,
  updateRemontResponsibleData
} from "@/components/modal/modals/change-remont-responsible/services";
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import {appActions} from "@/services/store/modules/app_module/app_actions";
import CustomInput from "@/components/form/CustomInput";

export default {
  name: "ChangeRemontResponsible",
  components: {CustomInput, CustomModalFooter, CustomSelect},
  props: ['data'],
  data() {
    return {
      listData: [],
      value: this.data?.id || '',
      label: 'Выберите',
      isFetching: false,
      agreementSum: undefined,
      loading: false,
      labelKey: '',
      valueKey: '',
    }
  },
  methods: {
    onChange(value) {
      this.value = value
    },
    onSumChange(value) {
      if(Number(value) < 0 || !value)
        return this.agreementSum = 0
      this.agreementSum = value
    },
    async getData() {
      this.isFetching = true
      const res = await getRemontResponsibleData(this.data?.valueCode)
      if (this.isRequireToFetchAgreementSum())
        await this.getAgreementSum()
      this.isFetching = false
      if (!res) return
      const {data, label, labelKey, valueKey} = res
      this.listData = data
      this.label = label
      this.labelKey = labelKey
      this.valueKey = valueKey
    },
    async getAgreementSum() {
      const res = await getResponsibleAgreementSum(this.data?.client_request_id, this.data?.valueCode)
      if (res === undefined) return
      this.agreementSum = res
    },
    isRequireToFetchAgreementSum() {
      const valueCode = this.data?.valueCode
      return valueCode === 'contractor_id' ||
          valueCode === 'general_contractor_id' ||
          valueCode === 'techproject_developer_id' ||
          valueCode === 'support_manager_id'
    },
    async onSubmit() {
      if (!this.data?.onSubmit) return
      const body = {[this.data?.valueCode]: this.value}

      if (this.isRequireToFetchAgreementSum())
        body.agreement_sum = Number(this.agreementSum || 0)
      this.loading = true
      const res = await updateRemontResponsibleData(this.data?.client_request_id, this.data?.valueCode, body)
      this.loading = false
      if (!res) return
      this.data?.onSubmit(res)
      this.$store.commit(appActions.closeModal());
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  min-width: 570px;

  @media (max-width: 576px) {
    min-width: 100%;
  }
}

.title {
  font-size: 20px;
  margin: 0;
  padding: 0;
}

.fortItem {
  margin-top: 15px;
}
</style>