<template>
  <div :class="[{purpose: this.data?.body?.purpose}]">
    <q-form @submit="this.onSubmit" class="form">
      <div class="form__item">
        <CustomInput
          label="Цена"
          type="number"
          :value="this.formData.service_price"
          @change_input="(value) => onChange('service_price', value)"
        />
      </div>
      <CustomModalFooter :btn-loading="this.loading" />
    </q-form>
  </div>
</template>
<script>

import CustomInput from "@/components/form/CustomInput";
import CustomModalFooter from "@/components/modal/modals/modal-footer";

export default {
  name: "add-remont-service",
  components: {CustomInput, CustomModalFooter},
  props: ['data'],
  data() {
    return {
      loading: false,
      formData: {
        service_price: this.data?.body?.service_price || '',
      }
    }
  },
  methods: {
    onChange(key, value) {
      this.formData[key] = value
    },
    async onSubmit() {
      this.loading = true
      await this.data?.onSubmit(this.formData)
      this.loading = false
    },
  },
}
</script>

<style scoped lang="scss">
.form {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  gap: 15px;

  @media (max-width: 428px) {
    min-width: 100%;
  }
}
</style>
