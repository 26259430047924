<template>
  <div :class="[{purpose: this.data?.body?.purpose}]">
    <q-form @submit="this.onSubmit" class="form">
      <div class="form__item">
        <CustomSelect
            :options="this.docTypes"
            :required="true"
            label="Тип документа"
            optionValueKey="document_type_id"
            optionLabelKey="document_type_name"
            :value="this.formData.document_type_id || ''"
            @change="(value, item) => onChange('document_type_id', value, true, item?.type_code)"
        />
      </div>
      <template v-if="this.selectedDocType === 'ADJUSTMENT'">
        <div class="form__item">
          <CustomSelect
              :options="this.approveDocTypes"
              :required="true"
              label="Тип согласования"
              optionValueKey="approve_document_type_id"
              optionLabelKey="approve_document_type_name"
              :value="this.formData.approve_document_type_id || ''"
              @change="(value) => onChange('approve_document_type_id', value)"
          />
        </div>
      </template>
      <template v-if="this.selectedDocType === 'DRAFT_ACCEPT_ACT'">
        <div class="form__item">
          <CustomDate
              :required="true"
              placeholder="Дата подписания акта приема-передачи ЧО"
              :value="this.formData.draft_akt_date || ''"
              @change_date="(value) => onChange('draft_akt_date', value)"
          />
        </div>
      </template>
      <template v-if="this.selectedDocType === 'APART_PASS_ACT_CL'">
        <div class="form__item">
          <CustomDate
              :required="true"
              placeholder="Дата реализации (для бухгалтерии)"
              :value="this.formData.implementation_date || ''"
              @change_date="(value) => onChange('implementation_date', value)"
          />
        </div>
        <div class="form__item">
          <CustomCheckbox
              label="Односторонний акт"
              :value="this.formData.unilateral_act"
              @change="(value) => onChange('unilateral_act', value)"
          />
        </div>
      </template>
      <template v-if="this.selectedDocType === 'ADDITIONAL_AGREEMENT'">
        <div class="form__item">
          <CustomSelect
              :options="this.dsTypes"
              :required="true"
              label="Тип ДС"
              optionValueKey="ds_type_id"
              optionLabelKey="ds_type_name"
              :value="this.formData.ds_type_id || ''"
              @change="(value) => onChange('ds_type_id', value)"
          />
        </div>
        <div class="form__item">
          <CustomInput
              label="Номер ДС"
              :required="true"
              :value="this.formData.document_num"
              @change_input="(value) => onChange('document_num', value)"
          />
        </div>
        <div class="form__item">
          <CustomDate
              :required="true"
              placeholder="Дата ДС"
              :value="this.formData.document_date || ''"
              @change_date="(value) => onChange('document_date', value)"
          />
        </div>
        <div class="form__item">
          <CustomInput
              label="Сумма ДС"
              :required="true" type="number"
              :value="this.formData.additional_agreement_amount"
              @change_input="(value) => onChange('additional_agreement_amount', value)"
          />
        </div>
      </template>
      <div v-if="this.data?.body?.document_url" class="form__item">
        <span class="form__item_fileLabel">Загруженный файл</span>
        <div class="form__item_file">
          <Clickable link :href="getFullUrl(this.data?.body?.document_url)">{{ this.data?.body?.document_name }}
            <q-icon name="download"/>
          </Clickable>
        </div>
      </div>
      <div class="form__item">
        <CustomUploader :value="this.formData?.document"
                        :on-change="(value) => this.onChange('document', value)" label="Файл"/>
      </div>
      <CustomModalFooter :btn-loading="this.loading"/>
    </q-form>
  </div>
</template>
<script>

import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomSelect from "@/components/form/CustomSelect";
import CustomUploader from "@/components/form/CustomUploader";
import {appActionTypes} from "@/services/store/modules/app_module/app_mutation_types";
import Clickable from "@/components/common/Clickable";
import {getFullUrl} from "@/services";
import CustomInput from "@/components/form/CustomInput";
import CustomDate from "@/components/form/CustomDate";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import {getApproveDocTypes, getDocModalBody, getDSTypesByDocs} from "@/views/ClientRequests/remontDocs/services";

export default {
  name: "add-doc",
  components: {CustomCheckbox, CustomDate, CustomInput, Clickable, CustomUploader, CustomSelect, CustomModalFooter},
  props: ['data'],
  data() {
    return {
      loading: false,
      selectedDocType: this.data?.document_type_code ||'',
      dsTypes: [],
      approveDocTypes: [],
      formData: {
        document_type_id: this.data?.body?.document_type_id || '',
        document: '',
        ds_type_id: this.data?.body?.ds_type_id || '',
        document_num: this.data?.body?.document_num || '',
        document_date: this.data?.body?.document_date || '',
        additional_agreement_amount: this.data?.body?.additional_agreement_amount || '',
        client_receive_date: this.data?.body?.client_receive_date || '',
        implementation_date: this.data?.body?.implementation_date || '',
        unilateral_act: this.data?.body?.unilateral_act || false,
        draft_akt_date: this.data?.body?.draft_akt_date || '',
        approve_document_type_id: this.data?.body?.approve_document_type_id || '',
      }
    }
  },
  methods: {
    getFullUrl,
    onChange(key, value, is_doc_type, docType) {
      if (is_doc_type) {
        this.selectedDocType = docType
        if(docType === 'ADDITIONAL_AGREEMENT') {
          this.getDSTypes()
        }
        if(docType === 'ADJUSTMENT') {
          this.getApproveTypes()
        }
      }
      this.formData[key] = value
    },
    async onSubmit() {
      const formData = getDocModalBody(this.formData, this.selectedDocType)
      this.loading = true
      await this.data?.onSubmit(formData)
      this.loading = false
    },
    async getDSTypes() {
      if(this.dsTypes?.length) return
      const res = await getDSTypesByDocs()
      this.dsTypes = res || []
    },
    async getApproveTypes() {
      if(this.approveDocTypes?.length) return
      const res = await getApproveDocTypes()
      this.approveDocTypes = res || []
    }
  },
  computed: {
    docTypes() {
      return this.$store.state.app.docTypes;
    },
  },
  mounted() {
    this.$store.dispatch(appActionTypes.getDocTypes)
  }
}
</script>

<style scoped lang="scss">
.form {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  gap: 15px;

  &__item {
    &_file {
      padding: 10px 7px;
      border-radius: 4px;
      border: 1px solid #ccc;
      color: #1f5ac2;

      &Label {
        margin-bottom: 3px;
        font-size: 13px;
        display: block;
        color: #3b3b3b;
      }
    }
  }

  @media (max-width: 428px) {
    min-width: 100%;
  }
}
</style>
