import instance from "@/services/api";

export const handbookGradeTimelineAPI = {
  async getList() {
    return await instance().get('/timeline/handbook/activity/read/').then(res => res?.data)
  },
  async addAction(body) {
    return await instance().post(`/timeline/handbook/activity/add/`, body).then(res => res?.data)
  },
  async updateAction(activity_id, body) {
    return await instance().put(`/timeline/handbook/activity/${activity_id}/update/`, body).then(res => res?.data)
  },
  async deleteAction(activity_id) {
    return await instance().delete(`/timeline/handbook/activity/${activity_id}/delete/`).then(res => res?.data)
  },
  async getActivityTypes() {
    return await instance().get(`/timeline/handbook/activity_types/read/`).then(res => res?.data)
  },
}

