<template>
  <div class="textConstructor__header">
    <q-btn size="sm" padding="xs" color="primary">
      <PopupConfirm
        :loading="this.loading"
        :approve="this.handleFillMaterialCount"
      />
      Заполнить кол-во материалов</q-btn
    >
  </div>
  <CustomTable
    title="Текстовый конструктор"
    :rowsData="this.rowsData"
    :columns="textConstructorColumns"
    :loading="isFetching"
    :custom-body="true"
  >
    <template #customBody="props">
      <q-tr>
        <custom-table-cell align="center">
          {{ props.row.client_material_id }}
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.room_name }}
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.work_set_name }}
        </custom-table-cell>
        <custom-table-cell align="center">
          <CustomImg
            class="textConstructor__photo"
            :src="props.row.photo_url"
            alt="material_photo"
          />
        </custom-table-cell>
        <custom-table-cell align="left">
          <q-icon
            v-if="props.row.is_optional"
            class="textConstructor__star"
            name="star"
            title="Опциональный материал"
          />
          {{ props.row.material_name }}
        </custom-table-cell>
        <custom-table-cell align="left">
          <CustomPopup>
            <template #head>
              <span
                class="textConstructor__sets_label"
                >{{ props.row.set_name }}</span
              >
            </template>
            <template #default>
              <span class="textConstructor__sets_title"
                >ID набора: {{ props.row.material_set_id }}</span
              >
              <div
                class="textConstructor__sets" v-if="!!props.row.set_materials"
              >
                <div v-html="props.row.set_materials"></div>
              </div>
            </template>
          </CustomPopup>
        </custom-table-cell>
      </q-tr>
    </template>
  </CustomTable>
</template>

<script>
import BlockItem from "@/components/common/BlockItem";
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import CustomImg from "@/components/common/CustomImg";
import CustomPopup from "@/components/common/CustomPopup";
import {fillMaterialCount, textConstructorColumns} from "@/views/ClientRequests/textConstructor/services";
import PopupConfirm from "@/components/common/PopupConfirm";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";

export default {
  name: "TextConstructorBlock",
  components: {PopupConfirm, BlockItem, CustomImg, CustomTable, CustomTableCell, CustomPopup},
  props: ["client_request_id", "data", "isFetching"],
  data() {
    return {
      selectedItems: {},
      textConstructorColumns,
      loading: false,
      rowsData: this.data
    }
  },
  methods: {
    async handleFillMaterialCount() {
      this.loading = true
      const res = await fillMaterialCount(this.client_request_id)
      this.loading = false
      if(!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
    }
  },
  setup() {
    const { showSucceedSnackbar } = useCustomSnackbar();
    return { showSucceedSnackbar };
  },
}
</script>

<style scoped lang="scss">
.textConstructor {
  &__header {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    margin-bottom: 20px;
  }
  &__photo {
    max-width: 90px;
    max-height: 90px;
  }

  &__sets {
    margin-top: 3px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &_item {
      padding: 5px 8px;
    }

    &_title {
      font-weight: 500;
    }

    &_label {
      color: #3c69b2;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  &__star {
    font-size: 20px;
    color: #69AA46;
    margin-right: 2px;
  }
}
</style>
