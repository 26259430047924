import {timelineStepsAPI} from "@/views/handbook/timelineSteps/api";

export const getTimelineSteps = async () => {
  try {
    const res = await timelineStepsAPI.getSteps()
    return res?.data
  } catch (e) {
  }
}
export const getTimelineStepsIndividual = async (grade_id, step_id) => {
  try {
    const res = await timelineStepsAPI.getIndividualSteps({grade_id, step_id})
    return res?.data
  } catch (e) {}
}
export const addTimelineStep = async (body = {}) => {
  try {
    const res = await timelineStepsAPI.addStep(body)
    return res?.data
  } catch (e) {
  }
}
export const updateTimelineStep = async (step_id, body = {}) => {
  try {
    const res = await timelineStepsAPI.updateStep(step_id, body)
    return res?.data
  } catch (e) {
  }
}
export const deleteTimelineStep = async (step_id) => {
  try {
    const res = await timelineStepsAPI.deleteStep(step_id)
    return res?.data
  } catch (e) {
  }
}

export const timelineStepsColumns = [
  {
    name: "step_id",
    field: "step_id",
    label: "ID",
    align: "center",
  },
  {
    name: "step_name",
    field: "step_name",
    label: "Название",
    align: "left",
  },
  {
    name: "step_code",
    field: "step_code",
    label: "Код",
    align: "left",
  },
  {
    name: "comment",
    field: "comment",
    label: "Комментарии",
    align: "left",
  },
  {
    name: "actions",
    label: "",
    align: "center",
  },
]