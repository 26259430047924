<template>
  <div class="wrapper">
    <CustomTable
        :rows-data="this.history"
        :columns="this.apartmentOwnersHistoryColumns"
        :custom-body="true"
        :loading="this.isFetching"
        :hide-footer-title="true"
    >
      <template #customBody="props">
        <q-tr>
          <custom-table-cell align="center">
            {{ props.row.number }}
          </custom-table-cell>
          <custom-table-cell align="left">
            {{ props.row.prop_fio }}
          </custom-table-cell>
          <custom-table-cell align="left">
            <p class="data_row">ИИН: {{ props.row.prop_iin }}</p>
            <p class="data_row">№ Уд.личности: {{ props.row.prop_doc_num }}</p>
            <p class="data_row">Дата выдачи: {{ props.row.prop_doc_date }}</p>
            <p class="data_row">Кем выдано: {{ props.row.prop_doc_issued }}</p>
            <p class="data_row">Район: {{ props.row.prop_district }}</p>
          </custom-table-cell>
          <custom-table-cell align="left">
            {{ props.row.email }}
          </custom-table-cell>
          <custom-table-cell align="left">
            <p class="data_row">Банк: {{ props.row.account_bank_name }}</p>
            <p class="data_row">БИН: {{ props.row.account_bin }}</p>
            <p class="data_row">БИК: {{ props.row.account_bik }}</p>
            <p class="data_row">ИИК: {{ props.row.account_iik }}</p>
          </custom-table-cell>
          <custom-table-cell align="center">
            {{ props.row.prop_new_client_date }}
          </custom-table-cell>
        </q-tr>
      </template>
    </CustomTable>
  </div>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import {
  apartmentOwnersHistoryColumns,
  getApartmentOwnersHistory
} from "@/components/modal/modals/apartment-owners-history/services";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";

export default {
  name: "ApartmentOwnersHistory",
  components: {CustomTableCell, CustomTable},
  props: ['data'],
  data() {
    return {
      history: [],
      isFetching: false,
      apartmentOwnersHistoryColumns
    }
  },
  methods: {
    async getData() {
      this.isFetching = true
      const res = await getApartmentOwnersHistory(this.data?.client_request_id)
      this.isFetching = false
      this.history = res || []
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style scoped lang="scss">
.data_row {
  margin: 0;
  font-size: 12px;
}
</style>