import {agreementJournalAPI} from "@/views/AgreementsJournal/api";
import {MODAL_NAMES} from "@/constants";

export const getAgreementJournal = async (params) => {
  try {
    const res = await agreementJournalAPI.getJournal(params)
    return res?.data
  } catch (e) {
  }
}
export const getAgreementTypes = async () => {
  try {
    const res = await agreementJournalAPI.getAgreementTypes()
    return res?.data
  } catch (e) {
  }
}
export const addAgreement = async (body, params = {}) => {
  try {
    const res = await agreementJournalAPI.addAgreement(body, params)
    return res?.data
  } catch (e) {
  }
}
export const getAgreementTypesForModal = async () => {
  try {
    const res = await agreementJournalAPI.getAgreementTypesForModal()
    return res?.data
  } catch (e) {
  }
}
export const cancelAgreement = async (contractor_agreement_list_id, params = {}) => {
  try {
    const res = await agreementJournalAPI.cancelAgreement(contractor_agreement_list_id, params)
    return res?.data
  } catch (e) {
  }
}
export const deleteAgreement = async (contractor_agreement_list_id, params = {}) => {
  try {
    const res = await agreementJournalAPI.deleteAgreement(contractor_agreement_list_id, params)
    return res?.data
  } catch (e) {
  }
}
export const signAgreement = async (contractor_agreement_list_id) => {
  try {
    const res = await agreementJournalAPI.sign(contractor_agreement_list_id)
    return res?.data?.redirect_url
  } catch (e) {
  }
}

const actionRequests = {
  [MODAL_NAMES.CreateAdditionalAgreement]: agreementJournalAPI.createAdditionalAgreement
}
export const agreementDocActionReq = async (body, actionName, params = {}, client_request_id) => {
  try {
    const res = await actionRequests[actionName](body, params, client_request_id)
    return res?.data
  } catch (e) {
  }
}

export const agreementColumns = [{
  name: "contractor_agreement_list_id", label: "ID", align: "center",
},{
  name: "type", label: "Тип", align: "left",
}, {
  name: "document_name", label: "Наименование документа", align: "left",
}, {
  name: "agreement_sum", label: "Сумма", align: "right",
}, {
  name: "employee_fio", label: "Инициатор", align: "left",
}, {
  name: "contragent_id", label: "Контрагент", align: "left",
}, {
  name: "status_name", label: "Статус", align: "left",
}, {
  name: "actions", label: "Дейсвия", align: "center",
},  {
  name: "1c_status", label: "Статус в 1С", align: "left",
}];
export const remontAgreementColumns = agreementColumns.filter(item => item.name !== 'remont_id')

export const activeOptions = [{value: 2, label: 'Все'}, {value: 1, label: 'Активен'}, {value: 0, label: 'Не активен'},]
export const signOptions = [{value: 2, label: 'Все'}, {value: 1, label: 'Подписан'}, {value: 0, label: 'Не подписан'},]