import instance from "@/services/api";

export const costsAPI = {
  async getCosts(params) {
    return await instance()
      .get("/finance/costs/read/", {params})
      .then((res) => res?.data);
  },
  async sendApprovePayments(body, params) {
    return await instance()
      .post("/finance/costs/collect/", body, {params})
      .then((res) => res?.data);
  },
  async getCostsByAmount(body, params = {}) {
    return await instance()
      .post("/finance/costs/collect_by_amount/", body, {params})
      .then((res) => res?.data);
  },
  async deleteCosts(body, params = {}) {
    return await instance()
      .post("/finance/costs/delete/", body, {params})
      .then((res) => res?.data);
  },
  async cancelCosts(body, params = {}) {
    return await instance()
      .post("/finance/costs/cancel/", body, {params})
      .then((res) => res?.data);
  },
  async costsExcel(params) {
    return await instance()
      .get(`/finance/costs/excel/`, {
        params: params,
        responseType: "arraybuffer"
      })
      .then((res) => res.data);
  },
}


