<template>
  <CustomTable
    title="Платежи от клиента"
    :rowsData="this.rowsData"
    :columns="remontPaymentsIncomesColumns"
    :customBody="true"
  >
    <template v-slot:top-right="props">
      <q-btn @click="handleAddClick" color="primary" size="sm" padding="sm">Добавить платеж</q-btn>
    </template>
    <template #customBody="props">
      <q-tr :props="props">
        <CustomTableCell align="center">
          <Clickable
            @click="() => this.handleEditClick(props.row?.client_request_payment_id, props.row)"
          >
            {{ props.row?.client_request_payment_id }}
          </Clickable>
        </CustomTableCell>
        <CustomTableCell
          align="right"
          ><Amount :value="props.row?.payment_amount" /></CustomTableCell
        >
        <CustomTableCell
          align="center"
          >{{ props.row?.payment_date }}</CustomTableCell
        >
        <CustomTableCell
          align="center"
          >{{ props.row?.payment_num }}</CustomTableCell
        >
        <CustomTableCell
          align="left"
          >{{ props.row?.comments }}</CustomTableCell
        >
        <CustomTableCell
          align="left"
          >{{ props.row?.is_payed === 1 ? 'Оплачено' : 'Ожидается' }}</CustomTableCell
        >
        <CustomTableCell align="center" title="Смена типа">
          {{props.row.client_type_id === 0 ? 'От клиента' : 'От застройщика'}}
          <br>
          <q-icon name="sync_alt" class="incomes__icon incomes__icon_change">
            <PopupConfirm
                title="Сменить тип"
              :loading="this.processingChangeType"
              :approve="() => this.handleChangeClientType(props.row.client_request_payment_id)"
            />
          </q-icon>
        </CustomTableCell>
        <CustomTableCell align="left"
          >{{ props.row?.transaction_num }}
        </CustomTableCell>
        <custom-table-cell align="center">
          <CustomIcon
            icon="trash"
            :approve="() => this.onDeleteSubmit(props.row.client_request_payment_id)"
            :approve-mode="true"
            :loading="this.deleting"
          />
        </custom-table-cell>
      </q-tr>
    </template>
  </CustomTable>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import {
  addIncome,
  changeClientType,
  deleteIncome,
  editIncome,
  remontPaymentsIncomesColumns
} from "@/views/ClientRequests/remontPayments/services";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import {appActions} from "@/services/store/modules/app_module/app_actions";
import {MODAL_NAMES} from "@/constants";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";
import Clickable from "@/components/common/Clickable";
import CustomIcon from "@/components/common/CustomIcon";
import PopupConfirm from "@/components/common/PopupConfirm";
import Amount from "@/components/common/Amount";

export default {
  name: "remontPaymentsIncomes",
  components: {Amount, PopupConfirm, Clickable, CustomTableCell, CustomTable, CustomIcon},
  props: ["data", "client_request_id"],
  data() {
    return {
      remontPaymentsIncomesColumns,
      deleting: false,
      processingChangeType: false,
      rowsData: this.data
    }
  },
  methods: {
    handleAddClick() {
      this.$store.commit(appActions.showModal(MODAL_NAMES.AddIncome, {
        title: 'Добавить платеж от клиента',
        onSubmit: this.onSubmit,
      }));
    },
    handleEditClick(id, body) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.AddIncome, {
        title: 'Редактировать платеж от клиента',
        onSubmit: body => this.onEditSubmit(id, body),
        body
      }));
    },
    async onSubmit(body) {
      const res = await addIncome(this.client_request_id, body)
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    async onEditSubmit(id, body) {
      const res = await editIncome(this.client_request_id, id, body)
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    async onDeleteSubmit(id) {
      this.deleting = true
      const res = await deleteIncome(this.client_request_id, id)
      this.deleting = false
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
    },
    async handleChangeClientType(id) {
      this.processingChangeType = true
      const res = await changeClientType(this.client_request_id, id)
      this.processingChangeType = false
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
    }
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar();
    return {showSucceedSnackbar};
  },
}
</script>

<style scoped lang="scss">
.incomes {
  &__icon {
    cursor: pointer;
    transition: all .3s;

    &_change {
      color: #144f9a;
      font-size: 20px;

      &:hover {
        font-size: 22px;
      }
    }
    &_print {
      color: #1ab01a;
      font-size: 18px;
    }
  }
}
</style>
