import instance from "@/services/api";

export const remontChatAPI = {
  async getList(client_request_id) {
    return await instance().get(`/client_request/${client_request_id}/chats/read/`).then(res => res?.data)
  },
  async getChat(client_request_id, group_chat_id) {
    return await instance().get(`/client_request/${client_request_id}/chats/${group_chat_id}/read/`).then(res => res?.data)
  },
  async sendMessage(client_request_id, group_chat_id, body) {
    return await instance().post(`/client_request/${client_request_id}/chats/${group_chat_id}/message/send/`, body, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then(res => res?.data)
  },
  async editMessage(client_request_id, group_chat_id,stage_chat_id, body) {
    return await instance().put(`/client_request/${client_request_id}/chats/${group_chat_id}/message/${stage_chat_id}/update/`, body, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then(res => res?.data)
  },
  async deleteMessage(client_request_id, group_chat_id, stage_chat_id,) {
    return await instance().delete(`/client_request/${client_request_id}/chats/${group_chat_id}/message/${stage_chat_id}/delete/`).then(res => res?.data)
  },
  async deleteMessageFile(client_request_id, group_chat_id, stage_chat_file_id,) {
    return await instance().delete(`/client_request/${client_request_id}/chats/${group_chat_id}/file/${stage_chat_file_id}/delete/`).then(res => res?.data)
  },
}