<template>
  <CustomTable
      title="ЧО"
      :rowsData="this.data"
      :columns="roughFinishColumns"
      :loading="isFetching"
      :custom-body="true"
      :hide-footer-title="true"
  >
    <template #customBody="props">
      <q-tr class="checkList__parent_header" style="background-color: #d8e3ff !important">
        <custom-table-cell align="left" colspan="10">
          {{ props.row.draft_check_list_name }}
        </custom-table-cell>
      </q-tr>
      <q-tr v-for="check in props.row?.drafts">
        <custom-table-cell align="left" >
          {{ check.draft_check_list_name }}
        </custom-table-cell>
        <custom-table-cell align="center" >
          {{ check.photo_cnt }}
        </custom-table-cell>
        <custom-table-cell align="left" >
          {{ check.okk_comment}}
        </custom-table-cell>
        <custom-table-cell align="left" >
          {{ check.fio}}
        </custom-table-cell>
        <custom-table-cell align="center" >
          {{ check.date_create}}
        </custom-table-cell>
      </q-tr>
    </template>
  </CustomTable>
</template>

<script>
import BlockItem from "@/components/common/BlockItem";
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import CustomIcon from "@/components/common/CustomIcon";
import Clickable from "@/components/common/Clickable";
import {getFullUrl, numberWithCommas} from "@/services";
import CheckOrClose from "@/components/common/CheckOrClose";
import {roughFinishColumns} from "@/views/ClientRequests/checkList/services";
import {appActions} from "@/services/store/modules/app_module/app_actions";
import {MODAL_NAMES} from "@/constants";

export default {
  name: "CheckListDraftBlock",
  components: {CheckOrClose, Clickable, CustomIcon, BlockItem, CustomTable, CustomTableCell, CustomCheckbox},
  props: ["client_request_id", "data", "isFetching"],
  data() {
    return {
      selectedItems: {},
      roughFinishColumns
    }
  },
  methods: {
    numberWithCommas,
    getFullUrl,
    handlePhotosClick(check_list_id) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.CHECK_LIST_PHOTOS, {
        client_request_id: this.client_request_id, check_list_id
      }));
    }
  },
}
</script>

<style scoped lang="scss">

.checkList {
  &__parent {
    margin-top: 25px;

    &:first-child {
      margin-top: 0;
    }
    span {
      font-size: 16px;
      color: #3873e7;
      margin-bottom: 10px;
      display: block;
      margin-left: 5px;
      font-weight: 500;
    }
    &_header {
      font-weight: 500;
      position: sticky;
      top: 48px;
      z-index: 100;
    }
  }
  &__status {
    color: #fff;
    font-weight: 500;
  }
  &__accepted {
    background-color: #24e524;
  }
  &__error {
    background-color: #cc1919;
  }
}
</style>