<template>
  <BackBtn class="back" @click="this.goBack"/>
  <h3 class="title">{{ this.selected?.report_name }}</h3>
  <q-linear-progress v-if="this.isFetching" indeterminate />
  <form class="filters" @submit.prevent="this.handleSubmit" v-if="!this.isFetching">
    <div v-for="item in this.filterBlocks" class="filters__item">
      <CustomDate v-if="item.report_type_code === reportFilterCodes.DATE" :required="item.is_required" :value="this.filters[item.report_param_code]"
                  @change_date="(value) => this.handleChange(item.report_param_code, value)" :placeholder="item.report_param_name"
                  :full-width="true"/>
      <CustomSelect
          v-else-if="item.report_type_code === this.reportFilterCodes.SELECT || item.report_type_code === this.reportFilterCodes.MULTISELECT"
          :options="item.parameters" :required="item.is_required"
          :label="item.report_param_name"
          :multiple="item.report_type_code === this.reportFilterCodes.MULTISELECT"
          :optionValueKey="item.valueKey || 'id'"
          :optionLabelKey="item.labelKey || 'name'"
          :value="this.filters[item.report_param_code]"
          @change="(value) => this.handleChange(item.report_param_code, value)"
      />
      <CustomMultiSelect
          v-else-if="item.report_type_code === reportFilterCodes.MULTIDATE"
          :options="this.dateData" :required="item.is_required"
          :label="item.report_param_name || 'Дата'"
          optionValueKey="value"
          optionLabelKey="label"
          :value="this.filters[item.report_param_code] || []"
          @change="(value) => this.handleChange(item.report_param_code, value)"
      />
      <CustomInput v-else-if="item.report_type_code === reportFilterCodes.INPUT"
                   :value="this.filters[item.report_param_code]" :required="item.is_required"
                   :label="item.report_param_name" @clear_input="this.handleChange(item.report_param_code, '')"
                   @change_input="(value) => this.handleChange(item.report_param_code, value)"/>
    </div>
    <q-btn type="submit" padding="sm" color="primary" :loading="this.loading" label="Отправить" size="sm"/>
  </form>
</template>

<script>
import {reportFilterCodes} from "@/services";
import BackBtn from "@/components/common/BackBtn";
import CustomMultiSelect from "@/components/form/CustomMultiSelect";
import CustomInput from "@/components/form/CustomInput";
import CustomSelect from "@/components/form/CustomSelect";
import CustomDate from "@/components/form/CustomDate";
import {dateData} from "@/_helpers";
import axios from "axios";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";
import {getReportFilters, getSelectedReport} from "@/views/ReportGenerator/services";

export default {
  name: "SelectedManualModification",
  components: {BackBtn, CustomMultiSelect, CustomInput, CustomSelect, CustomDate},
  props: ['selected', 'goBack'],
  setup() {
    const {showSucceedSnackbar, showErrorSnackbar} = useCustomSnackbar();
    return {showSucceedSnackbar, showErrorSnackbar};
  },
  data() {
    return {
      dateData,
      reportFilterCodes,
      axiosSource: null,
      isFetching: false,
      loading: false,
      filters: {},
      filterBlocks: [],
    }
  },
  mounted() {
    this.getFilters()
  },
  unmounted() {
    this.axiosSource?.cancel();
  },
  methods: {
    async getFilters() {
      this.isFilterFetching = true
      const res = await getReportFilters(this.selected?.report_id)
      this.isFilterFetching = false
      if (!res) return
      this.filterBlocks = res?.data
      if (res?.defaultValue)
        this.filters = {...this.filters, ...res.defaultValue}
    },
    async handleSubmit() {
      let isCheckPass = true
      this.filterBlocks?.forEach(item => {
        if (item.is_required && this.filters[item.report_param_code] === undefined)
          isCheckPass = false
      })
      if (!isCheckPass)
        return this.showErrorSnackbar('Заполните обязательные поля!')

      this.axiosSource = axios.CancelToken.source();
      this.loading = true
      const res = await getSelectedReport(this.selected?.report_id, this.filters, this.axiosSource?.token)
      this.loading = false
      if (!res) return
      Object.keys(this.filters)?.map(key => {
        this.filters[key] = null
      })
      this.showSucceedSnackbar()
    },
    handleChange(name, value) {
      if(value ==='' || (typeof value === "object" && !value?.length)) {
        const params = {...this.filters}
        params[name] = null
        return this.filters = params
      }
      this.filters[name] = value
    },
  }
}
</script>

<style scoped lang="scss">
.back {
  margin-bottom: 15px;
}
.title {
  margin: 0 0 15px;
  font-size: 24px;
}
.filters {
  display: flex;
  flex-direction: column;
  width: 350px;
  gap: 25px;
  margin-bottom: 20px;
  flex-wrap: wrap;

  @media (max-width: 350px) {
    width: 100%;
  }
}
</style>