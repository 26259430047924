<template>
  <div class="header" ref="header">
    <DataWith1CFilters :values="this.filters"
                       @filter_change="(type, value) => onFiltersChange(type, value)"/>
  </div>
  <CustomTable
      title="Сверка поступлений с 1C"
      :rowsData="data"
      :columns="columns"
      :page-header-height="this.$refs.header?.clientHeight"
      :showSearch="true"
      :stickyHeader="true"
      :loading="loading"
      :custom-body="true"
  >
    <template #customBody="props">
      <q-tr :props="props" :class="{same: this.isSameValuesExist(props.row)}">
        <q-td v-for="col in props.cols" :key="col.name" :props="props" >
          {{ col.value }}
        </q-td>
      </q-tr>
    </template>
  </CustomTable>
</template>

<script>
import DataWith1CFilters from "./filters"
import CustomTable from "@/components/common/CustomTable";
import {getDataWithOneC} from "@/views/DataWith1C/services";

export default {
  name: "DataWith1C",
  components: {DataWith1CFilters, CustomTable},
  data() {
    return {
      data: [],
      loading: false,
      filters: {
        provider_bin: "",
        date_begin: "",
        date_end: "",
      },
      columns: [
        {
          name: "invoice_guid",
          label: "invoice_guid",
          field: "invoice_guid",
          sortable: true,
          align: 'center',
        },
        {
          name: "invoice_date",
          label: "invoice_date",
          field: "invoice_date",
          sortable: true,
          align: 'center',
        },
        {
          name: "org_bin",
          label: "org_bin",
          field: "org_bin",
          sortable: true,
          align: 'left',
        },
        {
          name: "contragent_bin",
          label: "contragent_bin",
          field: "contragent_bin",
          sortable: true,
          align: 'left',
        },
        {
          name: "invoice_sum",
          label: "invoice_sum",
          field: "invoice_sum",
          sortable: true,
          align: 'right',
        },
        {
          name: "guid_sr",
          label: "guid_sr",
          field: "guid_sr",
          sortable: true,
          align: 'center',
        },
        {
          name: "invoice_sum_sr",
          label: "invoice_sum_sr",
          field: "invoice_sum_sr",
          sortable: true,
          align: 'right',
        },
        {
          name: "org_bin_sr",
          label: "org_bin_sr",
          field: "org_bin_sr",
          sortable: true,
          align: 'left',
        },
        {
          name: "contragent_bin_sr",
          label: "contragent_bin_sr",
          field: "contragent_bin_sr",
          sortable: true,
          align: 'center',
        },
      ],
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.loading = true;
      const res = await getDataWithOneC(this.filters);
      this.loading = false;
      if (res) this.data = res
    },
    onFiltersChange(key, value) {
      if(this.filters[key] === value) return
      this.filters[key] = value;
      this.getData();
    },
    isSameValuesExist(row) {
      const {invoice_sum, invoice_sum_sr, org_bin, org_bin_sr, invoice_date, invoice_date_sr, contragent_bin_sr, contragent_bin} = row
      return invoice_date === invoice_date_sr || invoice_sum === invoice_sum_sr || org_bin === org_bin_sr || contragent_bin_sr === contragent_bin;
    }
  }
}
</script>

<style scoped>
  .header {
    margin-bottom: 15px;
  }
  .same {
    background-color: #ffd7d7 !important;
  }
</style>