import instance from "@/services/api";

export const financePaymentsFiltersAPI = {
  async getFinancePaymentTypes() {
    return await instance()
      .get(`/common/finance_payment_types/read/`)
      .then((res) => res?.data);
  },
  async getFinancePaymentStatuses() {
    return await instance()
      .get(`/common/finance_payment_statuses/read/`)
      .then((res) => res?.data);
  },
  async getProviders(){
    return await instance()
      .get(`/common/providers/read/`)
      .then((res) => res?.data);
  },
  async getContractors() {
    return await instance()
      .get(`/common/contractors/read/`)
      .then((res) => res?.data);
  }
}
