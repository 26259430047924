<template>
  <FiltersWrapper>
    <CollapseBlock class-name="filters">
      <div class="filters__item">
        <CustomInput
            label="ФИО клиента"
            :value="this.filters.client_name"
            @change_input="(value) => onFiltersChange('client_name', value)"
        />
      </div>
      <div class="filters__item">
        <CustomSelect
            :options="this.residents"
            label="ЖК"
            optionValueKey="resident_id"
            optionLabelKey="resident_name"
            :value="this.filters.resident_id"
            @change="(value) => onFiltersChange('resident_id', value)"
        />
      </div>
      <FiltersWrapper class="filters__item" style="width: auto">
        <CustomDate
            :min-width-mode="true"
            placeholder="Дата с"
            :value="this.filters.date_begin"
            @change_date="(value) => onFiltersChange('date_begin', value)"
        />
      </FiltersWrapper>
      <FiltersWrapper class="filters__item" style="width: auto">
        <CustomDate
            :min-width-mode="true"
            placeholder="Дата до"
            :value="this.filters.date_end"
            @change_date="(value) => onFiltersChange('date_end', value)"
        />
      </FiltersWrapper>
      <div class="filters__item">
        <CustomSelect
            :options="this.statuses"
            label="Статус"
            optionValueKey="grt_status_id"
            optionLabelKey="grt_status_name"
            :value="this.filters.grt_status_id || ''"
            @change="(value) => onFiltersChange('grt_status_id', value)"
        />
      </div>
      <div class="filters__item">
        <CustomSelect
            :options="this.managers"
            label="Менеджер гарантии"
            optionValueKey="employee_id"
            optionLabelKey="fio"
            :value="this.filters.manager_guarantee_employee_id || ''"
            @change="(value) => onFiltersChange('manager_guarantee_employee_id', value)"
        />
      </div>
      <div class="filters__item" style="width: 150px">
        <CustomSelect
            :options="this.bigRequestOptions"
            label="Заявки BIG"
            optionValueKey="value"
            optionLabelKey="label"
            :value="this.filters.is_big_request || ''"
            @change="(value) => onFiltersChange('is_big_request', value)"
        />
      </div>
      <div class="filters__item">
        <CustomSelect
            :options="this.contractors"
            label="Подрядчик"
            optionValueKey="contractor_id"
            optionLabelKey="legal_name"
            :value="this.filters.contractor_id || ''"
            @change="(value) => onFiltersChange('contractor_id', value)"
        />
      </div>
      <div class="filters__item">
        <CustomSelect
            :multiple="true"
            :options="this.remakeTypes"
            label="Типы замечаний"
            optionValueKey="grt_remake_type_id"
            optionLabelKey="grt_remake_type_name"
            :value="this.filters.grt_request_remake_arr || ''"
            @change="(value) => onFiltersChange('grt_request_remake_arr', value)"
        />
      </div>
      <q-btn type="submit" @click="this.handleFiltersSubmit" :loading="this.isFetching" size="sm" no-wrap color="primary">
        <q-icon name="search"/>
        Поиск
      </q-btn>
      <q-btn type="button" @click="this.resetFilters" size="sm" no-wrap color="orange">
        <q-icon name="refresh"/>
        Сбросить
      </q-btn>
    </CollapseBlock>
  </FiltersWrapper>
</template>

<script>
import FiltersWrapper from "@/components/common/FiltersWrapper";
import CollapseBlock from "@/components/common/CollapseBlock";
import {bigRequestOptions, getWarrantyFiltersData} from "@/views/ClientRequests/warrantyRequests/services";
import CustomSelect from "@/components/form/CustomSelect";
import CustomInput from "@/components/form/CustomInput";
import CustomDate from "@/components/form/CustomDate";

export default {
  name: "WarrantyRequestsFilters",
  components: {CustomDate, CustomInput, CustomSelect, CollapseBlock, FiltersWrapper},
  props: ['onFiltersChange', 'filters','resetFilters', 'handleFiltersSubmit', 'isFetching'],
  data() {
    return {
      bigRequestOptions,
      residents: [],
      statuses: [],
      managers: [],
      contractors: [],
      remakeTypes: [],
    }
  },
  methods: {
    async getData() {
      const res = await getWarrantyFiltersData()
      if(!res) return
      const [residents,
        statuses,
        managers,
        contractors,
        remakeTypes] = res
      this.residents = residents
      this.statuses = statuses
      this.managers = managers
      this.contractors = contractors
      this.remakeTypes = remakeTypes
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style scoped lang="scss">

.filters {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 15px;
  padding-bottom: 35px !important;

  &__item {
    width: 200px;
  }
}
</style>