<template>
  <NotFoundText/>
</template>

<script>
import NotFoundText from "@/components/common/NotFoundText";
export default {
  name: "NotFound",
  components: {NotFoundText}
}
</script>