<template>
  <div :class="['companyBlock__container', {companyBlock__header: this.header}]">
    <div class="companyBlock__wrapper">
      <div class="companyBlock">
        <span>Выберите компанию</span>
        {{ this.userData?.company_name || '-' }}
      </div>
      <q-icon name="fa-solid fa-caret-down" class="companyBlock__icon"/>
    </div>
    <q-menu fit anchor="bottom left" self="top start">
      <div class="companies">
              <span v-for="item in this.companies" class="companies_item"
                    @click="this.handleChangeCompany(item.company_id)">{{
                  item.company_name
                }}</span>
      </div>
    </q-menu>
  </div>
</template>

<script>
import {appActionTypes} from "@/services/store/modules/app_module/app_mutation_types";

export default {
  name: "CompaniesBlock",
  props: {
    header: Boolean
  },
  computed: {
    userData() {
      return this.$store.state.app?.userData
    },
    companies() {
      return this.$store.state.app?.companies
    },
  },
  methods: {
    async handleChangeCompany(company_id) {
      this.$store.dispatch(appActionTypes.chooseCompany, company_id)
    },
  },
}
</script>

<style scoped lang="scss">

.companyBlock {
  display: flex;
  max-width: 260px;
  white-space: nowrap;
  overflow: hidden;
  flex-direction: column;
  gap: 0;
  align-items: flex-start;
  color: #fff;

  &__container {
    display: none;
    @media (max-width: 576px) {
      display: flex;
    }
  }

  &__header {
    display: flex;
    @media (max-width: 576px) {
      display: none;
    }
  }

  & > span {
    font-size: 10px;
    color: #dbdbdb;
  }

  &__wrapper {
    height: 50px;
    padding: 0 5px;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    transition: all 0.5s;

    &:hover {
      background-color: #026bc7;
    }
  }

  &__icon {
    color: #fff;
  }
}
.companies {
  padding: 0;
  display: flex;
  flex-direction: column;

  &_item {
    padding: 5px 7px;

    &:hover {
      background: #f2f2f2;
      cursor: pointer;
    }
  }
}
</style>