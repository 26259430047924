import instance from "@/services/api";

export const agreementAPI = {
  async getList(client_request_id) {
    return await instance()
      .get(`/client_request/${client_request_id}/specifications/read/`)
      .then((res) => res?.data);
  },
  async cancelAgreement(client_request_id, contractor_agreement_list_id) {
    return await instance().post(`/client_request/${client_request_id}/specifications/${contractor_agreement_list_id}/cancel/`).then(res => res?.data)
  },
  async deleteAgreement(client_request_id, contractor_agreement_list_id) {
    return await instance().post(`/client_request/${client_request_id}/specifications/${contractor_agreement_list_id}/sign/delete/`).then(res => res?.data)
  },
};
