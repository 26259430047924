<template>
  <RemontsMaterialSmetaTable title="Чистовые материалы" :data="this.data" />
</template>

<script>
import RemontsMaterialSmetaTable from "@/views/ClientRequests/remontMaterialSmeta/remontMaterialSmetaTable";
import PopupConfirm from "@/components/common/PopupConfirm";

export default {
  name: "MaterialSmetaNotDirtyBlock",
  props: ['data', 'client_request_id'],
  components: {PopupConfirm, RemontsMaterialSmetaTable},
}
</script>

<style scoped lang="scss">
.stages {
}
</style>
