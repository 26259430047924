<template>
  <div class="custom-input"  >
    <CustomInput @focus="showDropdown = true" @blur="onBlur" :value="this.value || ''" :label="this.label"
                 :required="!!this.required"
                 @change_input="(val) => onChange(val)" :loading="this.loading"/>
    <q-card class="custom-input__dropdown" v-if="this.showDropdown && !!this.options?.length">
      <q-card-section v-for="item in this.options" :key="item[optionValueKey]" class="custom-input__dropdown_item"
                      @click="handleSelectItem(item)">
        {{ item[this.optionLabelKey] }}
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import CustomInput from "../CustomInput"

export default {
  name: "CustomInputDropdown",
  props: [
    "options",
    "required",
    "loading",
    "label",
    "value",
    "optionLabelKey",
    "optionValueKey",
  ],
  data() {
    return {
      showDropdown: false
    }
  },
  methods: {
    onBlur() {
      const timeout = setTimeout(() => {
        this.showDropdown = false
        clearTimeout(timeout)
      }, 300)
    },
    onChange(value) {
      this.$emit('change_input', value)
    },
    handleSelectItem(item) {
      this.$emit('select_item', item[this.optionValueKey], item)
    }
  },
  components: {CustomInput}
}
</script>

<style scoped lang="scss">
.custom-input {
  position: relative;

  &__dropdown {
    position: absolute;
    top: 45px;
    left: 0;
    z-index: 1000;
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;

    &_item {
      font-size: 13px;
      padding: 10px 20px;
      cursor: pointer;

      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
}
</style>