<template>
  <FetchingWrapper :loading="this.isFetching" :data="this.data">
    <div class="remontProjects__wrapper">
      <div v-for="item in this.data">
        <component :is="item.component" :isFetching="this.isFetching" :client_request_id="this.client_request_id"
                   :data="item?.data"/>
      </div>
    </div>
  </FetchingWrapper>
</template>

<script>
import {
  getRemontProjectsData,
  remontProjectsColumns
} from "@/views/ClientRequests/remontProjects/services";
import {FILE_URL} from "@/constants";
import RemontProjectsBlock from "@/views/ClientRequests/remontProjects/remontProjectsBlock";
import RemontProjectMeasurement from "@/views/ClientRequests/remontProjects/remontProjectMeasurement";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";
import FetchingWrapper from "@/components/common/FetchingWrapper";

export default {
  name: "remontProjects",
  components: {FetchingWrapper, RemontProjectsBlock, RemontProjectMeasurement},
  props: ["client_request_id"],
  data() {
    return {
      data: [],
      isFetching: false,
      selectedItems: {},
      remontProjectsColumns,
      FILE_URL,
      chatBtns: ['Отправить в чат', 'Отправлен в чат', 'Отозван'],
      chatBtnsColors: ['#4F99C6', '#82AF6F', '#D15B47'],
    }
  },
  methods: {
    async getData() {
      this.isFetching = true
      const res = await getRemontProjectsData(this?.client_request_id)
      this.isFetching = false
      if (!res) return
      this.data = res
    },
  },
  mounted() {
    this.getData()
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar()
    return {showSucceedSnackbar}
  }
}
</script>

<style scoped lang="scss">
.remontProjects {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
}

</style>