<template>
  <div class="wrapper">
    <CustomTable
        title=""
        :rowsData="this.data.modalData"
        :columns="this.executiveReportFlatList"
        :custom-body="true" :small="true"
        :hide-footer-title="true" :sticky-header="false"
    >
      <template #customBody="props">
        <q-tr>
          <custom-table-cell align="center">
            {{ props.row.flat_num }}
          </custom-table-cell>
          <custom-table-cell align="center">
            <Clickable v-if="props.row.client_request_id" link :href="this.getRequestLink(props.row.client_request_id)">
              {{ props.row.client_request_id }}
            </Clickable>
          </custom-table-cell>
        </q-tr>
      </template>
    </CustomTable>
    <CustomModalFooter :onCancel="this.closeModal" :hide-submit-btn="true" cancel-btn-text="Закрыть"/>
  </div>
</template>
<script>

import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import {executiveReportFlatList} from "@/components/modal/modals/executive-report-flat-list/services";
import Clickable from "@/components/common/Clickable";
import {getRequestLink} from "@/views/ClientRequests/services";

export default {
  name: "executive-report-flat-list",
  components: {Clickable, CustomTableCell, CustomTable, CustomModalFooter},
  props: ['data', 'closeModal'],
  data() {
    return {
      executiveReportFlatList,
    }
  },
  methods: {
    getRequestLink
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  width: 340px;
}

@media (max-width: 576px) {
  .wrapper {
    max-width: 100%;
  }
}
</style>
