import {numberWithCommas} from "@/services";
import {remontMaterialsSaleUnitsAPI} from "@/views/ClientRequests/remontMaterialsSaleUnits/api";

const materialsSaleUnitsBlockNames = {
  OA__RemontFormAdditionBlock: "remontMaterialsSaleUnitsBlock",
}
export const getRemontMaterialsSaleUnitsData = async (client_request_id) => {
  try {
    const res = await remontMaterialsSaleUnitsAPI.getList(client_request_id)
    const data = res?.data
    if (!data?.length) return
    return data?.map(item => ({...item, component: materialsSaleUnitsBlockNames[item.grant_code]}))
  } catch (e) {
  }
}
export const remontMaterialsSaleUnitsColumns = [
  {
    name: "request_material_smeta_whole_id",
    label: "ID",
    field: "request_material_smeta_whole_id",
    align: "center",
  },
  {
    name: "material_name",
    label: "Материал",
    field: "material_name",
    align: "left",
  },
  {
    name: "provider_name",
    label: "Поставщик",
    field: "provider_name",
    align: "left",
  },
  {
    name: "cnt_consume_unit",
    label: "Кол-во в единицах расхода",
    field: "cnt_consume_unit",
    align: "right",
  },
  {
    name: "cnt_of_sell",
    label: "Кол-во в упаковке",
    field: "cnt_of_sell",
    align: "right",
  },
  {
    name: "cnt_sell_unit",
    label: "Кол-во в ед. продажи",
    field: "cnt_sell_unit",
    align: "right",
  },
  {
    name: "retail_price",
    label: "Цена в ед. продажи",
    field: "retail_price",
    align: "right",
    format: (val) => numberWithCommas(val),
  },
  {
    name: "amount",
    label: "Сумма",
    field: "amount",
    align: "right",
    format: (val) => numberWithCommas(val),
  },
  {
    name: "residue",
    label: "Остаток",
    field: "residue",
    align: "right",
  },
]
