<template>
  <div :class="[{purpose: this.data?.body?.purpose}]">
    <q-form @submit="this.onSubmit" class="form">
      <div class="form__item">
        <CustomCheckbox
            label="Запрет на создание автоматических платежей"
            :value="this.formData.is_not_create_auto_payment"
            @change="(value) => onChange('is_not_create_auto_payment', value)"
        />
      </div>
      <CustomModalFooter :btn-loading="this.loading"/>
    </q-form>
  </div>
</template>
<script>

import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomCheckbox from "@/components/form/CustomCheckbox";

export default {
  name: "add-handbook-provider",
  components: {CustomCheckbox, CustomModalFooter},
  props: ['data'],
  data() {
    return {
      loading: false,
      formData: {
        is_not_create_auto_payment: this.data?.body?.is_not_create_auto_payment || false,
      }
    }
  },
  methods: {
    onChange(key, value) {
      this.formData[key] = value
    },
    async onSubmit() {
      this.loading = true
      await this.data?.onSubmit(this.formData)
      this.loading = false
    },
  },
}
</script>

<style scoped lang="scss">
.form {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  gap: 15px;

  @media (max-width: 428px) {
    min-width: 100%;
  }
}
</style>
