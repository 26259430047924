import instance from "@/services/api";

export const agreementJournalAPI = {
  async getJournal(params) {
    return await instance().get('/client_request/specifications/read/', {params}).then(res => res?.data)
  },
  async getAgreementTypes() {
    return await instance().get('/common/contractor_agreement_list_types/read/').then(res => res?.data)
  },
  async addAgreement(body, params) {
    return await instance().post('/client_request/specifications/add/', body, {params}).then(res => res?.data)
  },
  async getAgreementTypesForModal() {
    return await instance().get('/common/contractor_agreement_list_types/read/for_insert/').then(res => res?.data)
  },
  async cancelAgreement(contractor_agreement_list_id, params = {}) {
    return await instance().post(`/client_request/specifications/${contractor_agreement_list_id}/cancel/`, {}, {params}).then(res => res?.data)
  },
  async deleteAgreement(contractor_agreement_list_id, params = {}) {
    return await instance().post(`/client_request/specifications/${contractor_agreement_list_id}/sign/delete/`, {}, {params}).then(res => res?.data)
  },
  async createAdditionalAgreement(body, params = {}, client_request_id) {
    return await instance().post(`/client_request/${client_request_id ? `${client_request_id}/` : ''}specifications/end_ds/create/`, body, {params}).then(res => res?.data)
  },
  async sign(contractor_agreement_list_id) {
    return await instance().post(`/client_request/specifications/${contractor_agreement_list_id}/sign/`).then(res => res?.data)
  },
}
