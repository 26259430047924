<template>
  <FetchingWrapper :loading="this.isFetching" :data="this.data">
    <div class="requisites__wrapper">
      <div v-for="(item, i) in this.data" :class="['row', i % 2 === 0 ? 'row__two' : 'row__three']">
        <div v-for="block in item">
          <component :is="block.grant_code" :data="block?.data" :client_request_id="this.client_request_id"/>
        </div>
      </div>
    </div>
  </FetchingWrapper>
</template>

<script>
import ClientInfoBlock from "./clientInfoBlock";
import RemontInfoBlock from "./remontInfoBlock";
import BankRequisitesBlock from "./bankRequisitesBlock";
import IntegrationBlock from "./integrationBlock";
import SalesBlock from "./salesBlock";
import {getRequisitesData} from "@/views/ClientRequests/requisites/services";
import FetchingWrapper from "@/components/common/FetchingWrapper";

export default {
  name: "requisites",
  props: ["client_request_id"],
  components: {FetchingWrapper, ClientInfoBlock, RemontInfoBlock, BankRequisitesBlock, SalesBlock, IntegrationBlock},
  methods: {
    async getData() {
      this.isFetching = true
      const res = await getRequisitesData(this?.client_request_id)
      this.isFetching = false
      if (!res) return
      this.data = res
    }
  },
  data() {
    return {
      data: [],
      isFetching: false
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style scoped lang="scss">

.requisites {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (max-width: 768px) {
      gap: 15px;
    }
  }
}

.row {
  display: grid;
  gap: 20px;
  grid-template-columns: 6fr 6fr;

  & > div {
    height: fit-content;
  }

  &:first-child {
    & > div {
      height: initial;
    }
  }

  &__three {
    grid-template-columns: 4fr 4fr 4fr;
  }

  @media (max-width: 768px) {
    gap: 15px;
    grid-template-columns: 12fr;

    &__three {
      grid-template-columns: 6fr 6fr;
    }
  }

  @media (max-width: 576px) {
    &__three {
      grid-template-columns: 12fr;
    }
  }
}
</style>