<template>
  <div>
    <q-form @submit="this.onSubmit" class="form">
      <div class="form__item">
        <CustomSelect
            :options="this.data?.modalData?.okk_employee"
            label="ОКК"
            optionValueKey="id"
            optionLabelKey="name"
            :value="this.formData.okk_employee_id || ''"
            @change="(value) => onChange('okk_employee_id', value)"
        />
      </div>
      <CustomModalFooter :on-cancel="this.closeModal" :btn-loading="this.loading"/>
    </q-form>
  </div>
</template>
<script>

import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomSelect from "@/components/form/CustomSelect";
import {setExecutiveReportOkk} from "@/views/ExecutiveReport/services";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";

export default {
  name: "executive-report-okk",
  components: {CustomSelect, CustomModalFooter},
  props: ['data', 'closeModal'],
  data() {
    return {
      loading: false,
      formData: {
        okk_employee_id: this.data?.modalData?.resident?.okk_employee_id || '',
      }
    }
  },
  methods: {
    onChange(key, value) {
      this.formData[key] = value
    },
    async onSubmit() {
      this.loading = true
      const res = await setExecutiveReportOkk({resident_id: this.data?.modalData?.resident?.resident_id, ...this.formData})
      this.loading = false
      if(res) {
        this.closeModal()
        this.showSucceedSnackbar()
      }
    },
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar();
    return {showSucceedSnackbar};
  },
}
</script>

<style scoped lang="scss">
.form {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  gap: 20px;

  @media (max-width: 428px) {
    min-width: 100%;
  }
}
</style>
