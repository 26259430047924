<template>
  <FiltersWrapper class="filters">
    <CustomDate
        placeholder="Дата от"
        :value="this.values.date_begin || ''"
        @change_date="(value) => onChange('date_begin', value)"
    />
    <CustomDate
        placeholder="Дата до"
        :value="this.values.date_end || ''"
        @change_date="(value) => onChange('date_end', value)"
    />
    <div class="filters__item">
      <CustomInput :value="this.values.provider_bin" label="BIN"
                   @clear_input="onChange('provider_bin', '')"
                   v-debounce:1000="(value) => onChange('provider_bin', value)"/>
    </div>
  </FiltersWrapper>
</template>

<script>
import CustomDate from "@/components/form/CustomDate";
import CustomInput from "@/components/form/CustomInput";
import FiltersWrapper from "@/components/common/FiltersWrapper";

export default {
  name: "DataWith1CFilters",
  props: ["values"],
  components: {FiltersWrapper, CustomDate, CustomInput},
  methods: {
    onChange(type, value) {
      this.$emit('filter_change', type, value);
    },
  },
};
</script>
<style scoped lang="scss">
.filters {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 15px;

  &__item {
    width: 230px;
    max-width: 230px;
  }
}
</style>
