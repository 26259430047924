import instance from "@/services/api";

export const gprStageAPI = {
  async getList(client_request_id) {
    return await instance()
      .get(`/client_request/${client_request_id}/gpr_stage/read/`)
      .then((res) => res?.data);
  },
  async recountGprDates(client_request_id) {
    return await instance()
      .post(`/client_request/${client_request_id}/gpr_stage/recount/`)
      .then((res) => res?.data);
  },
  async uploadExcel(client_request_id) {
    return await instance()
      .post(
        `/client_request/${client_request_id}/gpr_stage/excel/`,
        {},
        {
          responseType: "arraybuffer",
        }
      )
      .then((res) => res?.data);
  },
};
