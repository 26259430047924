import { remontAPI } from "@/views/ClientRequests/services/api";
import {PAGES_HEADS} from "@/constants";

export const tabsCodes = {
  OA__RemontFormRequisiteTab: "requisites",
  OA__RemontFormResponsibleOfficialTab: "responsibleOfficial",
  OA__RemontFormRemontMarkStepTab: "remontMarkStep",
  OA__RemontFormKeyReceiveTab: "receiveKeys",
  OA__RemontFormClientMaterialTab: "textConstructor",
  OA__RemontFormGPRStageTab: "gprStage",
  OA__RemontFormRemontStageTab: "remontStages",
  OA__RemontFormProjectRemontTab: "remontProjects",
  OA__RemontFormPaymentTab: "remontPayments",
  OA__RemontFormDocumentTab: "remontDocs",
  OA__RemontFormDSTab: "remontDS",
  OA__RemontFormProviderRequest: "providerRequests",
  OA__RemontFormRemontOldDS: "remontDSLegacy",
  OA__RemontFormMaterialSmetaTab: "remontMaterialSmeta",
  OA__RemontFormChatTab: "remontChat",
  OA__RemontFormGrtRequestTab: "warrantyRequests",
  OA__RemontFormMeasureOverallTab: "remontMeasure",
  OA__RemontFormCheckListTab: "checkList",
  OA__RemontFormContractorAgreementTab: "contractorAgreement",
  OA__RemontFormTimelineTab: "remontTimeline",
  OA__RemontFormRemontServiceTab: "remontServices",
  OA__RemontFormMaterialTab: "remontMaterials",
  OA__RemontFormAdditionTab: "remontMaterialsSaleUnits",
  OA__RemontFormSebesSmetaTab: "remontSmeta",
  OA__RemontFormSebesSmetaWholeTab: "remontSmetaWhole",
  OA__RemontFormTechFeatureTab: "techFeatures",
};
export const getHeaderData = async (client_request_id) => {
  try {
    const res = await remontAPI.getRemontHeader(client_request_id);
    const data = res?.data;
    if (data.reports) {
      data.reports = data.reports?.map((item) => {
        return { ...item, label: item.report_name };
      });
    }
    return data;
  } catch (e) {}
};
export const getTabsData = async (queryTab) => {
  try {
    const res = await remontAPI.getTabs();
    const data = res?.tabulations?.map((item) => ({
      ...item,
      component: tabsCodes[item.grant_code],
    }));
    const isExist =
      data?.some((item) => item.component === queryTab) && !!queryTab;
    return { data, isExist };
  } catch (e) {}
};
export const changeRemontGrade = async (client_request_id, body) => {
  try {
    const res = await remontAPI.changeRemontGrade(client_request_id, body);
    return res?.data
  } catch (e) {}
};
export const checkIsPermissionExist = async (client_request_id) => {
  try {
    const res = await remontAPI.checkIsPermissionExist(client_request_id);
    return res?.is_available
  } catch (e) {}
};
export const addTimelineToRemont = async (client_request_id) => {
  try {
    const res = await remontAPI.addTimelineToRemont(client_request_id);
    return res?.data
  } catch (e) {}
};
export const getClientRequestByRemontId = async (remontId) => {
  try {
    const res = await remontAPI.getClientRequestId(remontId);
    return res?.data
  } catch (e) {}
};
export const getRequestLink = (client_request_id) => {
  if(!client_request_id) return '#'
  try {
    return `/${PAGES_HEADS.requests}/${client_request_id}`
  } catch (e) {}
}
