<template>
  <CustomTable
      title="Шаги"
      :rowsData="this.rowsData"
      :columns="this.gradeStepsColumns"
      :loading="isFetching"
      :small="true"
      :show-search="true"
      :custom-body="true" :on-add-click="this.handleAddClick"
      :showHeaderAdd="true"
      :sticky-header="true">
    <template #customBody="props">
      <q-tr>
        <custom-table-cell align="center">
          {{ props.row.step_id }}
        </custom-table-cell>
        <custom-table-cell align="left">
          <Clickable @click="() => this.handleUpdateClick(props.row)">{{ props.row.step_name }}</Clickable>
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.step_code }}
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.comment }}
        </custom-table-cell>
        <custom-table-cell align="center" vertical-align="center">
          <CustomIcon icon="trash" :approve-mode="true"
                      :loading="!!this.deleteLoading[props.row.step_id]"
                      :approve="() => this.onDeleteSubmit(props.row.step_id)"/>
        </custom-table-cell>
      </q-tr>
    </template>
  </CustomTable>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import {appActions} from "@/services/store/modules/app_module/app_actions";
import {MODAL_NAMES} from "@/constants";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";
import CustomIcon from "@/components/common/CustomIcon";
import Clickable from "@/components/common/Clickable";
import {
  addTimelineStep,
  deleteTimelineStep,
  getTimelineSteps,
  timelineStepsColumns,
  updateTimelineStep
} from "@/views/handbook/timelineSteps/services";

export default {
  name: "TimelineSteps",
  data() {
    return {
      gradeStepsColumns: timelineStepsColumns,
      rowsData: [],
      deleteLoading: {},
      isFetching: false,
      gradeId: null,
    }
  },
  components: {Clickable, CustomIcon, CustomTableCell, CustomTable},
  methods: {
    async getData() {
      this.isFetching = true
      const res = await getTimelineSteps()
      this.isFetching = false
      if (!res) return
      this.rowsData = res
    },
    async handleAddClick() {
      this.$store.commit(appActions.showModal(MODAL_NAMES.AddTimelineStep, {
        onSubmit: this.onAddGradeAction,
        title: 'Добавление шага',
      }));
    },
    async onAddGradeAction(body) {
      const res = await addTimelineStep(body)
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    async handleUpdateClick(row) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.AddTimelineStep, {
        onSubmit: body => this.onUpdateGradeAction(row.step_id, body),
        title: 'Редактировать шаг',
        body: row
      }));
    },
    async onUpdateGradeAction(step_id, body) {
      const res = await updateTimelineStep(step_id, body)
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    async onDeleteSubmit(step_id) {
      this.deleteLoading = {...this.deleteLoading, [step_id]: true}
      const res = await deleteTimelineStep(step_id)
      this.deleteLoading = {...this.deleteLoading, [step_id]: false}
      if (!res) return
      this.rowsData = res
    },
  },
  mounted() {
    this.getData()
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar();
    return {showSucceedSnackbar};
  },
}
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
</style>