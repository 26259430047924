<template>
  <div class="back">
    <q-icon name="fa-solid fa-arrow-left" class="login__form_itemIcon"/>
    Назад
    <PopupConfirm v-if="!!this.approve && this.approveMode" :title="this.approveTitle || 'Подтвердите действие'"
                  :approve="this.approve"/>
  </div>
</template>

<script>
import PopupConfirm from "@/components/common/PopupConfirm";
export default {
  name: "BackBtn",
  components: {PopupConfirm},
  props: ['approve', 'approveTitle', 'approveMode']
}
</script>

<style scoped lang="scss">
.back {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  width: fit-content;
  transition: all 0.4s;
  font-size: 14px;
  font-weight: 400;

  &:hover {
    color: #453bd7;
  }
}
</style>