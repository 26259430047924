import {costsAPI} from "@/views/Costs/api";
import {checkIdValid, getSelectedIds, toValidComponentDate} from "@/_helpers";
import {numberWithCommas} from "@/services";

export const SPLIT_PAYMENT_ID_CODE = "$_$";
export const getCostsData = async (params = {}) => {
  try {
    checkIdValid(params.client_request_id);
    checkIdValid(params.provider_request_invoice_id);
    const res = await costsAPI.getCosts(params);
    return res?.costs;
  } catch (e) {
  }
};

export const getCostId = (row) => {
  return `${row.id}${SPLIT_PAYMENT_ID_CODE}${row.cost_type}${SPLIT_PAYMENT_ID_CODE}${row.cost_contragent_type}`;
};
export const splitCostId = (costId) => {
  if (!costId) return;
  const split = costId.split(SPLIT_PAYMENT_ID_CODE);
  return {
    id: +split[0],
    cost_type: split[1],
    cost_contragent_type: split[2],
  };
};

export const approvePayments = async (selectedIds = {}, params = {}) => {
  const ids = getSelectedIds(selectedIds, false);
  const costs = ids.map((item) => splitCostId(item));
  try {
    const res = await costsAPI.sendApprovePayments({costs}, params);
    return res?.costs;
  } catch (e) {
  }
};
export const getCostsByAmount = async (body) => {
  try {
    const res = await costsAPI.getCostsByAmount(body);
    if (!res) return;
    const {remont_costs: data, amount} = res;
    const newData = {};
    data?.forEach((item) => {
      const id = getCostId(item);
      newData[id] = true;
    });
    return {data: newData, amount};
  } catch (e) {
  }
};

export const costsTableColumns = [
  {
    name: "cost_id",
    label: "ID платежа",
    field: "id",
    sortable: true,
    format(val, row) {
      return `${val} (${row.id_type})`
    },
  },
  {
    name: "specific_id",
    label: "ID",
    field: "specific_id",
    sortable: true,
    sort: (a, b) => {
      try {
        const splitA = a.split(":");
        const aId = +splitA[1]?.trim();
        const splitB = b.split(":");
        const bId = +splitB[1]?.trim();
        return aId - bId;
      } catch (e) {
        return a - b;
      }
    },
    headerStyle: "width: 80px",
    style: "width: 100px",
  },
  {
    name: "contragent",
    label: "Контрагент",
    field: "contragent",
    sortable: true,
    align: "left",
  },
  {
    name: "cost_contragent_type_name",
    label: "Тип контрагента",
    field: "cost_contragent_type_name",
    sortable: true,
    align: "left",
  },
  {
    name: "created_date",
    label: "Дата создания",
    field: "created_date",
    sortable: true,
    sort: (a, b) =>
      new Date(toValidComponentDate(a)) - new Date(toValidComponentDate(b)),
    align: "center",
  },
  {
    name: "invoice_date",
    label: "Дата накладной",
    field: "invoice_date",
    sortable: true,
    sort: (a, b) =>
      new Date(toValidComponentDate(a)) - new Date(toValidComponentDate(b)),
    align: "center",
  },
  {
    name: "is_payed",
    label: "Оплачен",
    field: "is_payed",
    sortable: true,
    align: "center",
  },
  {
    name: "payment_amount",
    label: "Итого",
    field: "payment_amount",
    sortable: true,
    align: "right",
    style: "white-space: nowrap",
    format(val) {
      return numberWithCommas(val);
    },
  },
  {
    name: "agreement_sum",
    label: "Проверка",
    field: "agreement_sum",
    align: "left",
  },
  {
    name: "comments",
    label: "Комментарии",
    field: "comments",
    sortable: true,
    style: {width: "600px"},
    align: "left",
  },
];

export const getPaymentsNewAmountValue = (oldValue, paymentAmount, checked) => {
  let newValue;
  if (checked) newValue = (oldValue || 0) + paymentAmount;
  else newValue = (oldValue || 0) - paymentAmount;
  try {
    newValue = +newValue.toFixed(2);
  } catch (e) {
  }
  return newValue;
};

export const deletePayments = async (selectedIds, params) => {
  try {
    const ids = getSelectedIds(selectedIds, false);
    const costs = ids.map((item) => splitCostId(item));
    const res = await costsAPI.deleteCosts({costs}, params)
    return res?.costs;
  } catch (e) {
  }
};
export const cancelPayments = async (selectedIds, params) => {
  try {
    const ids = getSelectedIds(selectedIds, false);
    const costs = ids.map((item) => splitCostId(item));
    const res = await costsAPI.cancelCosts({costs}, params)
    return res?.costs;
  } catch (e) {
  }
};

export const saveCostsExcel = async (params) => {
  try {
    const res = await costsAPI.costsExcel(params)
    const blob = new Blob([res], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    });
    saveAs(blob, `Платежи - ${new Date().toLocaleDateString('ru')}.xlsx`);
  } catch (e) {
  }
}