<template>
  <div :class="['chatBlock', {chatBlock__active: !!this.data}]">
    <div class="chatBlock__back" v-if="this.data">
      <BackBtn @click="this.goBack(null)"/>
    </div>
    <div v-if="!this.data" class="chatBlock__empty">Выберите чат</div>
    <div v-else class="chatBlock__chat">
      <div :class="['chatBlock__messages']">
        <CustomLoader v-if="this.isFetching"/>
        <template v-else>
          <div v-if="this.isSelectMode" class="chatBlock__messagesBox__line"/>
          <div class="chatBlock__messagesWrapper">
            <div
                :class="['chatBlock__messagesInner', 'chatBlock__infinite__scroll']"
            >
              <div v-if="this.messages?.length" class="chatBlock__messagesBox">
                <ul class="chatBlock__messagesList">
                  <li
                      v-for="messageItem in messages"
                      :key="messageItem?.stage_chat_id"
                      :id="messageItem?.stage_chat_id"
                      :class="['chatBlock__messagesItem', {chatBlock__messagesItem_self: messageItem.is_self}]"
                      v-if="messageItem?.show !== false"
                  >
                    <BoxChatMessage
                        :message="messageItem"
                        :onEditMessageClick="this.onEditMessageClick"
                        :onDeleteMessageClick="this.onDeleteMessageClick"
                        :onDeleteMessageFileClick="this.onDeleteMessageFileClick"
                    />
                    <div
                        v-if="isSelectMode"
                        class="chatBlock__selected_mode__checkbox"
                    >
                      <CustomCheckbox
                          :value="!!messageItem?.isSelected"
                          @change="(value) => handleSelectedChange(value, messageItem?.id)"
                          :name="`message_${messageItem?.id}`"
                      />
                    </div>
                  </li>
                </ul>
              </div>
              <div v-else class="chatBlock__empty notFound">
                Сообщений не найдено
              </div>
            </div>
          </div>
        </template>
      </div>
      <div>
        <div
            v-if="!!selectedFiles?.length"
            class="chatBlock__selected__wrapper"
        >
          <button
              @click="disableSelectImgMode"
              :class="['chatBlock__close_btn', 'chatBlock__selected__close']"
          >
            <q-icon name="cancel"/>
          </button>
          <p class="chatBlock__selected__title">Вложения</p>
          <div class="chatBlock__selected__wrapper__img">
            <div
                v-for="(file, i) in selectedFiles"
                :key="i"
                class="chatBlock__selected__img__wrapper"
            >
              <img
                  v-if="file?.is_image"
                  class="chatBlock__selected__img"
                  :src="file?.file_url"
                  alt=""
              />
              <video v-else-if="file?.is_video" width="65" height="65">
                <source :src="file?.file_url" id="video_here"/>
                Your browser does not support HTML5 video
              </video>
              <div v-else class="chatBlock__selected_doc">
                <q-icon
                    name="description"
                    class="chatBlock__selected_docIcon"
                />
                <span>{{ file?.file?.name || '' }}</span>
              </div>
              <button
                  @click="handleRemoveFile(i)"
                  class="chatBlock__selected__img__remove"
              >
                <q-icon name="cancel"/>
              </button>
            </div>
          </div>
        </div>
        <div
            :class="['chatBlock__actions',
            {chatBlock__actions_select: !!selectedFiles?.length}, {chatBlock__actions_report: this.isPhotoReport}]"
        >
          <AttachWrapper
              inputId="inputId"
              :multiple="true"
              class-name="chatBlock__attach"
              :onInputCapture="handleChange"
              accept="*"
          >
            <q-icon name="attach_file"/>
          </AttachWrapper>

          <textarea
              id="ta"
              autoFocus
              ref="textareaRef"
              placeholder="Введите сообщение..."
              class="chatBlock__actionsTextarea"
              @keydown="handleMessageType"
              :style="{height: textareaHeight + 'px'}"
          />
          <CustomCheckbox
              class="chatBlock__actions_checkbox"
              :value="this.is_photo_report"
              @change="onCheckChange"
              label="Фото-отчет"
              v-if="this.isPhotoReport"
          />
          <button
              :disabled="isLoading"
              @click="handleSendMessage"
              class="chatBlock__actionsSendMessage_btn"
          >
            <q-icon name="send" color="white"/>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  deleteMessage, deleteMessageFile,
  editMessage,
  getRemontChatMessagesData,
  sendNewMessage
} from "@/views/ClientRequests/remontChat/services";
import {getFileInfo} from "@/services";
import CustomLoader from "@/components/common/CustomLoader";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import BoxChatMessage from "./chatMessage";
import AttachWrapper from "@/components/common/AttachWrapper";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";
import BackBtn from "@/components/common/BackBtn";
import {appActions} from "@/services/store/modules/app_module/app_actions";
import {MODAL_NAMES} from "@/constants";

export default {
  name: "chat",
  components: {BackBtn, AttachWrapper, CustomCheckbox, CustomLoader, BoxChatMessage},
  props: ['data', 'client_request_id', 'goBack'],
  data() {
    return {
      messages: [],
      selectedFiles: [],
      textareaHeight: 56,
      is_photo_report: false,
      isLoading: false,
      isFetching: false,
      isSelectMode: false,
    }
  },
  computed: {
    isPhotoReport() {
      return [3,4,5].includes(this.data?.group_chat_id)
    }
  },
  methods: {
    async getMessages() {
      if (!this.data) return
      this.isFetching = true
      const res = await getRemontChatMessagesData(this.client_request_id, this.data?.group_chat_id)
      this.isFetching = false
      if (!res) this.messages = []
      this.messages = res
    },
    handleSelectedChange() {
    },
    disableSelectImgMode() {
    },
    onEditMessageClick(stage_chat_id, body) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.EditChatMessage, {
        title: `Изменение сообщения`,
        onSubmit: (body) => this.handleEditMessage(stage_chat_id, body),
        body
      }));
    },
    async onDeleteMessageClick(stage_chat_id) {
      const res = await deleteMessage(this.client_request_id, this.data?.group_chat_id, stage_chat_id)
      if (!res) return
      this.messages = res
    },
    async onDeleteMessageFileClick(stage_chat_file_id) {
      const res = await deleteMessageFile(this.client_request_id, this.data?.group_chat_id, stage_chat_file_id)
      if (!res) return
      this.messages = res
    },
    onCheckChange(value) {
      this.is_photo_report = value
    },
    async handleSendMessage() {
      const message = this.$refs.textareaRef?.value + ""
      if (this.messages === null || this.isLoading || (!message && !this.selectedFiles?.length))
        return

      const messageObj = {
        stage_chat_id: new Date().getTime(),
        message
      }

      if (!this.selectedFiles?.length) {
        return await this.requestNewMessage({message}, messageObj)
      }
      messageObj.files = this.selectedFiles.map(file => ({...file, file: window.URL.createObjectURL(file?.file)}))
      const formdata = new FormData()
      formdata.append('is_photo_report', String(this.is_photo_report))
      this.selectedFiles?.forEach(file => {
        formdata.append('files', file?.file)
      })
      !!message && formdata.append('message', message)
      return await this.requestNewMessage(formdata, messageObj)
    },
    async handleEditMessage(stage_chat_id, body) {
      const {message, files} = body
      const formdata = new FormData()
      !!message && formdata.append('message', message)
      files?.forEach(file => {
        formdata.append('files', file)
      })
      const res = await editMessage(this.client_request_id, this.data?.group_chat_id, stage_chat_id, formdata)
      if (!res) return
      this.showSucceedSnackbar()
      this.messages = res
      this.$store.commit(appActions.closeModal());
    },
    saveMessageLocal(message) {
      this.messages = [...this.messages, message]
    },
    deleteUnSendMessage(tempMessageId) {
      this.messages = this.messages?.filter(message => message?.stage_chat_id !== tempMessageId)
    },
    async requestNewMessage(body, messageObj) {
      messageObj && this.saveMessageLocal(messageObj)
      this.isLoading = true
      const messageRes = await sendNewMessage(this.client_request_id, this.data?.group_chat_id, body)
      this.isLoading = false
      if (!messageRes) return this.deleteUnSendMessage(messageObj?.stage_chat_id)
      this.messages = messageRes
      this.resetChatSettings()
    },
    handleMessageType(event) {
      const {key} = event;
      if (key === "Enter" && !isFetching) {
        if (!event.shiftKey) {
          event.preventDefault();
          return this.handleSendMessage()
        }
      }
    },
    resetChatSettings() {
      const chatWrap = document.getElementsByClassName("chat__infinite__scroll")
      if (!!chatWrap?.length)
        chatWrap[0].scrollTo(0, 0)
      this.$refs.textareaRef.value = ''
      this.is_photo_report = false
      this.selectedFiles = []
    },
    handleRemoveFile(index) {
      this.selectedFiles = this.selectedFiles.filter((item, i) => i !== index)
    },
    handleChange(event) {
      const files = !!event.target?.files?.length && event.target.files
      if (files) {
        const acceptedFiles = []
        for (let i = 0; i < files.length; i++) {
          const file = files[i]
          if (file) {
            const fileInfo = getFileInfo(file?.name)
            const file_url = window?.URL.createObjectURL(file)
            const fileObj = {file, ...fileInfo, file_url}
            acceptedFiles.push(fileObj)
          }
        }
        if (!acceptedFiles) return
        this.selectedFiles = [...acceptedFiles, ...this.selectedFiles].slice(0, 10)
      }
    }
  },
  watch: {
    data() {
      this.getMessages()
    }
  },
  setup() {
    const {showErrorSnackbar, showSucceedSnackbar} = useCustomSnackbar();
    return {showErrorSnackbar, showSucceedSnackbar};
  },
  mounted() {
    this.getMessages()
  }
}
</script>

<style scoped lang="scss">
.chatBlock {
  width: inherit;
  height: 100%;
  position: relative;

  &__chat {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: inherit;
    height: 100%;
    position: relative;
  }

  &__back {
    position: absolute;
    top: 0;
    z-index: 2;
    width: 100%;
    background: #fff;
    padding: 2px 0 4px 0;
    opacity: .8;
    transition: all .3s;
    border-bottom: 1px solid #e9e9e9;

    &:hover {
      opacity: 1;
    }
  }

  &__empty {
    width: 100%;
    text-align: center;
    color: #b5b5b5;
    font-size: 16px;
    margin-top: 15px;
    position: absolute;
    top: 0;
    z-index: 1;

    &.notFound {
      margin-top: 0;
      top: 50px;
    }

    @media (max-width: 768px) {
      padding-bottom: 50px;
    }
  }

  &__wrapper {
    display: contents;
    position: relative;
  }

  &__boxBtn {
    position: absolute;
    top: 15px;
    left: 0;
    transition: all 0.3s ease-in-out;

    &Back {
      padding: 15px;

      & > svg {
        transform: rotate(180deg);
        fill: #1f5ac2;
        margin-right: 5px;
      }

      &:hover {
        transform: translateX(-2px);
      }
    }
  }

  &__messagesBox__line {
    position: absolute;
    height: 100%;
    width: 1px;
    border-right: 1px solid #F5F5F5;
    right: 58px;
    bottom: 0;
  }

  &__messages {
    flex: 1;
    display: flex;
    padding: 0;
    position: relative;

    &Empty {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &Wrapper {
      position: relative;
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    &Inner {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column-reverse;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 15px 0 15px 0;
      scrollbar-width: thin;
      scrollbar-color: #1f5ac2 transparent;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #1f5ac2;
        border-radius: 4px;
      }

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: #ccc;
      }
    }

    &Box {
      display: flex;
      flex-direction: column;
      padding-right: 2px;
      margin-top: 8px;

      & + & {
        margin-bottom: 40px;
      }
    }

    &Date {
      align-self: center;
      border-radius: 120px;
      padding: 0 15px;

      &Text {
        line-height: 24px;
        margin: 0;
        color: #ccc
      }
    }

    &Dumb {
      height: 20px;
    }

    &List {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      margin-bottom: 0;
      padding: 0;
    }

    &Item {
      display: flex;
      justify-content: space-between;

      & + & {
        margin-top: 15px;
      }

      &_self {
        justify-content: flex-end;
      }
    }
  }

  &__actions {
    display: flex;
    padding: 10px 10px 20px 15px;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0 0 5px #ccc;
    border-radius: 5px;
    margin-top: 5px;
    gap: 5px;
    width: 100%;

    &_report {
      position: relative;
      padding-top: 30px;
    }

    &_checkbox {
      position: absolute;
      top: -4px;
      right: 5px;
    }

    &_select {
      border-top: none;
    }

    &File {
      font-size: 0;
    }

    &Textarea {
      flex: 1;
      background: none;
      min-height: 56px;
      max-height: 300px;
      font-size: 13px;
      line-height: 16px;
      scrollbar-width: thin;
      scrollbar-color: #ccc transparent;
      height: 100%;
      border: 1px solid #D1D1D1;
      border-radius: 16px;
      padding: 7px 10px;
      resize: vertical;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #1f5ac2;
        border-radius: 4px;
      }

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: #BCBCBC;
      }
    }

    &SendMessage {
      fill: #000;

      &:hover {
        fill: #000;
      }
    }

    &SendMessage {
      &_btn {
        height: 32px;
        width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        background: #7492FF;
        border-radius: 50%;
        box-shadow: unset;
        cursor: pointer;
        border: none;
      }
    }

    &File,
    &Smiles {
      fill: #1f5ac2;

      &:hover {
        fill: #1f5ac2;
      }
    }
  }

  &__attach {
    color: #1f5ac2;
    font-size: 25px;
    cursor: pointer;
  }

  &__selected {
    &__wrapper {
      border-top: 1px solid #d7d7d7;
      padding-left: 15px;
      padding-top: 10px;
      bottom: 57px;
      background: #fff;
      left: 0;
      width: 100%;
    }

    &__title {
      margin: 0;
    }

    &__wrapper__img {
      width: 100%;
      display: flex;
      margin: 10px 0;
      overflow-x: auto;
      padding-right: 15px;

      scrollbar-width: thin;
      scrollbar-color: #1f5ac2 transparent;

      &::-webkit-scrollbar {
        width: 2px;
        height: 4px;
        margin-top: 3px;
        padding-top: 3px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #1f5ac2;
        border-radius: 2px;
      }

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: #ccc;
      }

      &_type_ {
        &thin {
          &::-webkit-scrollbar {
            width: 2px;
            height: 4px;
          }
        }
      }
    }

    &__img {
      height: 65px;
      width: 65px;

      &__wrapper {
        position: relative;
        height: 65px;
        margin-left: 10px;
        border-radius: 15px;
      }

      &__wrapper:first-child {
        margin-left: 0;
      }

      &__remove {
        position: absolute;
        padding: 3px;
        right: -4px;
        top: -4px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 20px;
        width: 20px;
        background: #fff !important;
        border: none !important;
        cursor: pointer;
        color: #2a66b3;
      }
    }

    &_doc {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 65px;
      font-size: 10px;
      text-align: center;
      overflow: hidden;

      &Icon {
        font-size: 30px;
        color: #1f5ac2;
      }
    }

    &__close {
      position: absolute;
      top: 15px;
      right: 15px;
      width: 13px;
    }
  }

  &__headerBackBtn {
    margin-right: 10px;

    svg {
      transform: rotate(180deg);
    }
  }

  &__close_btn {
    background: none !important;
    border: none !important;

    svg {
      fill: #1f5ac2;
    }
  }

  &__reply {
    &__wrapper {
      border-top: 1px solid #f5f5f5;
      position: relative;
      width: 100%;
    }

    &__item {
      margin-left: 7px;
    }

    &__file {
      width: 32px;
      height: 32px;
    }

    &_block__wrapper {
      background: #FFF0ED;
      padding: 14px 20px;

      div {
        border-left: 2px solid #FF6647;
        display: flex;
        flex-direction: column;
      }
    }

    &__close {
      position: absolute;
      top: 15px;
      right: 15px;
      width: 11px;
    }
  }

  &__selected_mode {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-right: 10px;
    animation: fadin .3s;

    &__btns {
      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
        margin-right: 7px;
      }
    }

    &__close {
      width: 11px;
      height: 11px;
      margin-left: 7px;
    }

    &__checkbox {
      display: flex;
      align-items: center;

      label {
        height: 33px;
      }
    }

  }
}

/* MEDIA QUERIES */
@media (max-width: 991px) {
  .chatBlock {
    &__header {
      padding-right: 0;
    }

    &__actions {
      padding: 10px 10px 13px;

      &_report {
        padding-top: 30px;
      }
    }
  }
}

@media (max-width: 767px) {
  .chatBlock {
    display: none;

    &__active {
      display: block;
    }

    &__header {
      background-color: #f5f5f5;

      &BackBtn {
        display: block;
      }
    }

    &__messages {
      &Box {
        & + & {
          margin-bottom: 20px;
        }
      }
    }
  }
}

@media (max-width: 479px) {
  .chatBlock {
    &__header {
      padding-top: 9px;
      padding-bottom: 7px;
      padding-left: 10px;

      &UserText {
        font-size: 16px;
      }

      &BackBtn {
        padding-left: 10px;
        padding-right: 10px;

        svg {
          width: 16px;
          height: 9px;
        }
      }
    }

    &__popup_close {
      display: none !important;
    }

    &__messages {
      &Box {
        & + & {
          margin-bottom: 5px;
        }
      }
    }
  }
}

@keyframes fadein {
  0% {
    opacity: 10%;
  }
  100% {
    transform: scale(1);
    opacity: 100%;
  }
}
</style>
