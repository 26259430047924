<template>
  <div class="templates">
    <CustomTable
        title="Мастера по услугам"
        :rowsData="this.rowsData"
        :columns="this.serviceMastersColumns"
        :loading="isFetching"
        :small="true"
        :show-search="true"
        :custom-body="true"
        :sticky-header="true">
      <template #customBody="props">
        <q-tr>
          <custom-table-cell align="left">
            {{ `${props.row.fio} (${props.row.employee_id})` }}
          </custom-table-cell>
          <custom-table-cell align="left">
            {{ `${props.row.provider_name} (${props.row.provider_id})` }}
          </custom-table-cell>
          <custom-table-cell align="left">
            {{props.row.email}}
          </custom-table-cell>
          <custom-table-cell align="left">
            {{props.row.specialities}}
          </custom-table-cell>
        </q-tr>
      </template>
    </CustomTable>
  </div>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import {getFullUrl} from "@/services";
import {getServiceMasters, serviceMastersColumns} from "@/views/handbook/serviceMasters/services";

export default {
  name: "serviceMasters",
  components: {CustomTableCell, CustomTable},
  data() {
    return {
      serviceMastersColumns,
      rowsData: [],
      isFetching: false,
      loading: false
    }
  },
  methods: {
    getFullUrl,
    async getData() {
      this.isFetching = true
      const res = await getServiceMasters()
      this.isFetching = false
      if (!res) return
      this.rowsData = res
    },
  },
  mounted() {
    this.getData()
  },
}
</script>

<style scoped>
.templates {
}
</style>