<template>
  <q-form @submit="handleSubmit" class="remontProjectEdit__wrapper">
    <span>Проект ремонта</span>
    <CustomUploader :value="this.formData?.document" :on-change="(value) => this.handleChange('document', value)"
                    label="Проект ремонта"/>
    <span>3D визуализация</span>
    <CustomUploader :value="this.formData?.visualisation_file" :on-change="(value) => this.handleChange('visualisation_file', value)"
                    label="3D визуализация"/>
    <CustomInput :value="this.formData.project_remont_comment"
                 @change_input="(value) => this.handleChange('project_remont_comment', value)"
                 label="Комментарии" type="textarea" :required="true"/>
    <CustomCheckbox label="Финальный проект" :value="!!this.formData.is_final"
                    @change="(value) => handleChange('is_final', value ? 1 : 0)"/>
    <CustomModalFooter :btn-loading="this.loading"/>
  </q-form>
</template>

<script>
import CustomInput from "@/components/form/CustomInput";
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomUploader from "@/components/form/CustomUploader";
import CustomCheckbox from "@/components/form/CustomCheckbox";

export default {
  name: "RemontProjectEdit",
  props: ['data', 'closeModal'],
  components: {CustomCheckbox, CustomUploader, CustomModalFooter, CustomInput},
  data() {
    return {
      formData: {is_final: 0, ...this.data?.formData},
      loading: false
    }
  },
  methods: {
    handleChange(key, value) {
      this.formData[key] = value
    },
    async handleSubmit() {
      const body = new FormData
      Object.keys(this.formData).map(item => {
        if (item === 'document')
          return this.formData[item]?.length ? body.append(item, this.formData[item][0]) : null
        if (item === 'visualisation_file')
          return this.formData[item]?.length ? body.append(item, this.formData[item][0]) : null
        body.append(item, this.formData[item])
      })
      this.loading = true
      const res = this.data?.onSubmit && await this.data?.onSubmit(body)
      this.loading = false
      if (res)
        this.closeModal()
    }
  }
}
</script>

<style scoped lang="scss">
.remontProjectEdit {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
  }
}
</style>