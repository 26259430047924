<template>
  <div>
    <p :class="['title', this.titleClass]"><slot name="title"></slot>{{this.title || ''}}</p>
    <div @click="() => this.onClick && onClick()" :class="['content', {content__value: !custom,content__multiple: this.multiple, pointer: this.pointer}]">
      <slot></slot>
    </div>
  </div>

</template>

<script>
export default {
  name: "BlockItem",
  props: ["title", "custom", "onClick", "pointer", "multiple", "onClick", 'titleClass']
}
</script>

<style scoped lang="scss">
.title {
  color: #7E878B;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
.content {
  &__value {
    color: #1D1E26;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 2px 0 0 0;

    &.pointer {
      cursor: pointer;
      transition: all .3s;
      &:hover {
        color: #022a6e;
      }
    }
  }

  &__multiple {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
</style>