<template>
  <div>
    <FiltersWrapper>
      <div ref="header">
        <CostsFilters
            :values="this.filters"
            @filter_change="(type, value) => onFiltersChange(type, value)"
        />
        <div class="actions__warning">
          <p>Необходимо учесть, что с момента даты накладной должно пройти не менее X дней, указанной в справочнике
            поставщиков (обычно 14 дней).</p>
        </div>
        <div class="actions">
          <div class="actions__left">
            <q-btn
                label="Сформировать"
                type="submit" padding="xs"
                :disable="this.processing || !getSelectedLength()"
                color="primary"
                :loading="this.processing" no-caps
            >
              <PopupConfirm :approve="this.approve"/>
            </q-btn>
            <q-btn
                label="Отменить" class="actions__cancel"
                type="submit" @click="this.onCancelClick" padding="xs"
                :disable="this.processing || !getSelectedLength()"
                color="primary"
                :loading="this.processing" no-caps
            />
            <q-btn
                label="Удалить" class="actions__delete" padding="xs"
                type="submit" @click="this.onDeleteClick"
                :disable="this.processing || !getSelectedLength()"
                color="primary" no-caps
                :loading="this.processing"
            />
          </div>
          <div class="actions__right">
            <q-btn :loading="this.excelLoading" :disable="this.excelLoading" class="actions__print" @click="saveExcel">
              <i :class="`material-icons`"
              >print</i>
            </q-btn>
            <CustomInputNumber
                :value="this.amount"
                label="Сумма"
                @change_input="this.onAmountChange"
            />
            <q-btn
                label="Собрать по сумме"
                type="submit" no-caps
                :disable="
              this.processingAmount || !this.amount || this.amount === '0.00'
            "
                color="primary"
                :loading="this.processingAmount"
                @click="this.getPaymentsByAmount"
            />
          </div>
        </div>
      </div>
    </FiltersWrapper>
    <CustomTable
        :title="`Ожидает оплаты${
        !!getSelectedLength()
          ? ` (Выбрано: ${getSelectedLength()}, сумма: ${this.numberWithCommas(
              this.payments_amount
            )})`
          : ''
      }`"
        rowKey="id"
        :rowsData="data"
        :columns="columns"
        :page-header-height="this.$refs.header?.clientHeight"
        :showSearch="true"
        :stickyHeader="true"
        :loading="loading"
        :checkbox="true"
        :checkbox-value="this.allSelected"
        :custom-body="true"
        :update-data="getData"
        :on-all-check-click="onAllCheckClick"
    >
      <template #customBody="props">
        <q-tr :props="props">
          <custom-table-cell auto-width vertical-align="center">
            <div class="check__wrapper">
              <CustomCheckbox
                  :value="!!this.selectedItems[this.getCostId(props.row)]"
                  @change="(value) => onCheckboxChange(props.row, value)"
              />
            </div>
          </custom-table-cell>
          <custom-table-cell v-for="col in props.cols" :key="col.name">
            <check-or-close v-if="col.name === 'is_payed'" :value="col.value"/>
            <div v-else-if="col.name === 'agreement_sum'" class="">
              <div class="sum"><span>Сумма спецификации - </span> <span v-if="props.row.agreement_sum !== null"
                                                                        class="sum__value">{{
                  numberWithCommas(props.row.agreement_sum)
                }}</span>
              </div>
              <div class="sum"><span>Оплачено - </span> <span v-if="props.row.payed_amount !== null" class="sum__value">{{
                  numberWithCommas(props.row.payed_amount)
                }}</span>
              </div>
              <div class="sum"><span>Процент оплаченного - </span> <span v-if="props.row.percentage !== null"
                                                                         class="sum__value">{{
                  `${props.row.percentage || 0}%`
                }}</span></div>
              <div class="sum"><span>Процент с учетом платежа - </span> <span
                  v-if="props.row.percentage_with_payment !== null"
                  class="sum__value">{{ `${props.row.percentage_with_payment || 0}%` }}</span></div>
            </div>
            <span v-else :class="{ amount: col.name === 'payment_amount' }">
              {{ col.value }}
            </span>
          </custom-table-cell>
        </q-tr>
      </template>
    </CustomTable>
  </div>
</template>

<script>
import {
  approvePayments,
  cancelPayments,
  costsTableColumns,
  deletePayments,
  getCostId,
  getCostsByAmount,
  getCostsData,
  getPaymentsNewAmountValue,
  saveCostsExcel,
} from "./services";
import CustomTable from "@/components/common/CustomTable";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";
import CostsFilters from "./СostsFilters";
import PopupConfirm from "@/components/common/PopupConfirm";
import CustomInputNumber from "@/components/form/CustomInputNumber";
import CheckOrClose from "@/components/common/CheckOrClose";
import {appActions} from "@/services/store/modules/app_module/app_actions";
import {MODAL_NAMES} from "@/constants";
import {numberWithCommas} from "@/services";
import Amount from "@/components/common/Amount";
import FiltersWrapper from "@/components/common/FiltersWrapper";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";

export default {
  components: {
    CustomTableCell,
    FiltersWrapper,
    Amount,
    CustomTable,
    CostsFilters,
    CustomCheckbox,
    PopupConfirm,
    CustomInputNumber,
    CheckOrClose,
  },
  data() {
    return {
      data: [],
      loading: false,
      processing: false,
      excelLoading: false,
      processingAmount: false,
      allSelected: false,
      selectedItems: {},
      filters: {
        cost_contragent_type: "",
        finance_payment_type_id: "",
        techproject_developer_id: "",
        contractor_id: "",
        provider_id: "",
        provider_id_other: "",
        remont_id: "",
        provider_request_invoice_id: "",
        payment_status_id: "",
        is_warranty: false,
        created_date_from: new Date().toLocaleDateString("ru"),
        created_date_to: new Date().toLocaleDateString("ru"),
      },
      payments_amount: 0,
      amount: "",
      columns: costsTableColumns,
      detailTableColumns: {},
    };
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar();
    return {showSucceedSnackbar};
  },
  watch: {
    selectedItems(newValue) {
      const checkedLength = this.getSelectedLength(newValue);
      const isAllChecked = this?.data.length === checkedLength;
      if (isAllChecked && !!checkedLength) return (this.allSelected = true);
      if (checkedLength > 0) return (this.allSelected = "");
      this.allSelected = false;
    },
  },
  methods: {
    saveCostsExcel,
    numberWithCommas,
    getCostId,
    getPaymentsNewAmountValue,
    async getData() {
      this.loading = true;
      const res = await getCostsData(this.filters);
      this.loading = false;
      if (!res) return;
      this.data = res;
      this.resetSelectedItems();
    },
    onAllCheckClick(value) {
      this.allSelected = value;
      this.updateAllPaymentsAmount(value);
      const newSelectedItems = {};
      this.data.forEach((item) => {
        newSelectedItems[getCostId(item)] = value;
      });
      this.selectedItems = newSelectedItems;
    },
    onCheckboxChange(row, value) {
      const id = getCostId(row);
      this.updatePaymentsAmount(row, value);
      this.selectedItems = {...this.selectedItems, [id]: value};
    },
    onCancelClick() {
      this.$store.commit(
          appActions.showModal(MODAL_NAMES.CONFIRM, {
            title: "Выбранные платежи будут отменены",
            btnText: "Подтвердить",
            onSubmit: this.cancelSelectedPayments,
          })
      );
    },
    onDeleteClick() {
      this.$store.commit(
          appActions.showModal(MODAL_NAMES.CONFIRM, {
            title: "Выбранные платежи будут удалены из списка",
            btnText: "Подтвердить",
            onSubmit: this.deleteSelectedPayments,
          })
      );
    },
    async saveExcel() {
      this.excelLoading = true
      await this.saveCostsExcel(this.filters)
      this.excelLoading = false
    },
    async deleteSelectedPayments() {
      const res = await deletePayments(this.selectedItems, this.filters)
      if (!res) return
      this.showSucceedSnackbar();
      this.data = res;
      this.resetSelectedItems();
      this.$store.commit(appActions.closeModal());
    },
    async cancelSelectedPayments() {
      const res = await cancelPayments(this.selectedItems, this.filters)
      if (!res) return
      this.showSucceedSnackbar();
      this.data = res;
      this.resetSelectedItems();
      this.$store.commit(appActions.closeModal());
    },
    updatePaymentsAmount(row, checked) {
      const payment = this.data?.find(
          (item) => item.id === row.id && item.cost_type === row.cost_type
      );
      if (!payment) return;
      this.payments_amount = this.getPaymentsNewAmountValue(
          this.payments_amount,
          payment.payment_amount,
          checked
      );
    },
    updateAllPaymentsAmount(checked) {
      if (checked) {
        let amount = this.data.reduce(
            (previousValue, currentValue) =>
                previousValue + (currentValue.payment_amount || 0),
            0
        );
        try {
          amount = +amount.toFixed(2);
        } catch (e) {
        }
        this.payments_amount = amount;
      } else this.payments_amount = 0;
    },
    getSelectedLength(selectedItems) {
      if (selectedItems)
        return Object.keys(selectedItems).filter(
            (item) => !!selectedItems[item]
        ).length;
      return Object.keys(this.selectedItems).filter(
          (item) => !!this.selectedItems[item]
      ).length;
    },
    async approve() {
      this.processing = true;
      const res = await approvePayments(this.selectedItems, this.filters);
      this.processing = false;
      if (!res) return;
      this.showSucceedSnackbar();
      this.data = res;
      this.resetSelectedItems();
    },
    onAmountChange(value) {
      this.amount = value;
    },
    async getPaymentsByAmount() {
      this.processingAmount = true;
      const body = {
        amount: +this.amount,
        cost_contragent_type: this.filters.cost_contragent_type,
        invoice_date_from: this.filters.created_date_from,
        invoice_date_to: this.filters.created_date_to,
        provider_id: this.filters.provider_id,
        is_warranty: this.filters.is_warranty,
      };
      const res = await getCostsByAmount(body);
      this.processingAmount = false;
      if (!res) return;
      const {data, amount} = res;
      this.selectedItems = data;
      this.payments_amount = amount;
    },
    resetSelectedItems() {
      this.selectedItems = {};
      this.updateAllPaymentsAmount();
    },
    onFiltersChange(key, value) {
      if (this.filters[key] === value) return
      this.filters[key] = value;
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped lang="scss">
.btn {
  &__wrapper {
    margin-bottom: 15px;
  }
}

.amount {
  color: #fff;
  background: #55c558;
  padding: 2px;
  border-radius: 2px;
  white-space: nowrap;
}

.sum {
  margin-bottom: 3px;
  white-space: nowrap;

  span {
    font-weight: 500;
    white-space: nowrap;
  }

  &__value {
    font-weight: 400;
    white-space: nowrap;
    padding: 2px 3px;
    background-color: #e7e7e7;
    border-radius: 3px;
  }
}

.detail__title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
  padding: 10px;
}

.actions {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 15px;

  &__right {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  &__print {
    background: #21830b !important;
    color: #fff;
  }

  &__warning {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    p {
      color: #eb9c24;
      padding: 10px;
      border-radius: 5px;
      margin: 0 0 10px;
      box-shadow: 0 0 3px #a15f20;
      font-size: 12px;
    }
  }

  &__left {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 15px;
  }

  &__delete {
    background: #9d1010 !important;
  }

  &__cancel {
    background: #c74f1c !important;
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }

  @media (max-width: 400px) {
    &__right {
      flex-wrap: wrap;
    }
  }
}

.edit {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: all 0.4s;
  text-decoration: underline;

  &__icon {
    position: absolute;
    transition: all 0.4s;
    display: block;
    font-size: 14px;
    right: -15px;
    opacity: 0;
  }

  &:hover {
    color: #292938;
    text-decoration: none;
  }

  &:hover &__icon {
    opacity: 1;
  }
}
</style>
