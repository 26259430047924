import { remontMarkStepAPI } from "@/views/ClientRequests/remontMarkStep/api";

const markStepBlockNames = {
  OA__RemontFormRemontMarkStepBlock: "remontMarkStepBlock",
};
const getSelectedItems = (data) => {
  try {
    const selectedItems = {};
    data?.forEach((item) => {
      selectedItems[item.mark_type_code] = !!item.is_checked;
    });
    return selectedItems;
  } catch (error) {}
};
export const getRemontMarkStepData = async (client_request_id) => {
  try {
    const res = await remontMarkStepAPI.getList(client_request_id);
    const data = res?.data;
    if (!data?.length) return;
    return data.map((item) => {
      if (item) {
        const list = item?.data;
        const selectedItems = getSelectedItems(list);
        return {
          ...item,
          data: { data: list, selectedItems },
          component: markStepBlockNames[item.grant_code],
        };
      }
      return { ...item, component: markStepBlockNames[item.grant_code] };
    });
  } catch (e) {}
};
export const checkMarkStep = async (client_request_id, mark_type_code) => {
  try {
    const res = await remontMarkStepAPI.checkMark(client_request_id, {
      mark_type_code,
    });
    if (!res?.data) return;
    const selectedItems = getSelectedItems(res?.data);
    return { selectedItems, data: res.data };
  } catch (e) {}
};
export const remontMarkStepColumns = [
  {
    name: "help",
    label: "",
    field: "help",
    align: "center",
  },
  {
    name: "check",
    label: "",
    field: "check",
    align: "center",
  },
  {
    name: "status",
    label: "Статус",
    field: "status",
    align: "left",
  },
  {
    name: "date_mark",
    label: "Дата",
    field: "date_mark",
    align: "center",
  },
  {
    name: "fio",
    label: "ФИО",
    field: "fio",
    sortable: true,
    align: "left",
  },
];
