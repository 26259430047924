<template>
  <label :for="disabled ? '' : (inputId || 'attach_file')" :class="className || ''">
  <slot></slot>
  <input
      :id="inputId || 'attach_file'"
      :multiple={multiple}
      @input="onInputCapture"
      :accept="accept || 'image/*'"
      class="attach__attachPlanInput"
      type="file"
  />
  </label>
</template>

<script>
export default {
  name: "AttachWrapper",
  props: ['disabled', 'multiple', 'onInputCapture', 'accept', 'inputId', 'className']
}
</script>

<style scoped lang="scss">
.attach {
  &__attach {
    &Plan {
      background: #FFFFFF;
      border: 1px solid #ccc;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 13px;
      margin-top: 10px;
      cursor: pointer;

      &Wrapper {
        margin-top: 20px;

        &Margin {
          margin-top: 0;
        }
      }

      &Close {
        transition: all .3s;
        transform: rotate(45deg);
      }

      &Img {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-top: 10px;

        img {
          max-width: 70%;
          max-height: 100px;
        }
      }

      &Input {
        display: none;
      }

      &Left {
        display: flex;
        align-items: center;
        span {
          margin-left: 15px;
          color: #ccc;
          font-family: 'Roboto', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
    &Header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
</style>