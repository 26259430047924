import {techFeaturesAPI} from "@/views/ClientRequests/techFeatures/api";

const techFeaturesBlockNames = {
  OA__RemontFormTechFeatureBlock: "techFeaturesBlock",
};

const sortData = (res) => {
  const data = res?.data;
  if (!data?.length) return
  return data?.map((item) => ({...item, component: techFeaturesBlockNames[item.grant_code]}))
}

export const getTechFeaturesData = async (client_request_id) => {
  try {
    const res = await techFeaturesAPI.getList(client_request_id);
    return sortData(res)
  } catch (e) {
  }
};
export const updateTechFeatures = async (client_request_id, tech_feature) => {
  try {
    const res = await techFeaturesAPI.update(client_request_id, {tech_feature});
    return res?.data
  } catch (e) {
  }
};
