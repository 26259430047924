<template>
  <q-linear-progress v-if="this.isFetching" indeterminate/>
  <NotFoundText v-if="this.error" text="Нет данных"/>
</template>

<script>
import {useRoute} from "vue-router/dist/vue-router";
import {PAGES_HEADS} from "@/constants";
import {getClientRequestByRemontId} from "@/views/ClientRequests/services";
import NotFoundText from "@/components/common/NotFoundText";

export default {
  name: "ClientRequestByRemontId",
  components: {NotFoundText},
  data() {
    return {
      isFetching: false,
      error: false
    }
  },
  methods: {
    async getClientRequestId() {
      this.isFetching = true
      const res = await getClientRequestByRemontId(this.remont_id)
      this.isFetching = false
      if (!res) return this.error = true
      this.$router.push(`/${PAGES_HEADS.requests}/${res}`)
    }
  },
  mounted() {
    this.getClientRequestId()
  },
  setup() {
    const route = useRoute()
    return {remont_id: +route?.params?.remont_id};
  },
}
</script>
