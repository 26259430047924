<template>
  <q-header elevated class="bg-primary text-white header">
    <q-toolbar class="header__toolbar">
      <q-btn
          dense
          flat
          round
          icon="menu"
          @click.prevent="$emit('toggle-navbar')"
      />
      <q-toolbar-title class="logo">
        <router-link to="/">
          <img src="@/assets/img/logo.svg" alt="logo_picture"/>
        </router-link>
      </q-toolbar-title>
      <div class="header__right">
        <CityBlock header/>
        <CompaniesBlock header/>
        <div class="row items-center no-wrap">
          <div class="userbox__wrapper">
            <q-icon name="fa-solid fa-circle-user" class="userbox__icon"/>
            <div class="userbox">{{ this.userData?.fio || 'Пользователь' }}</div>
            <q-icon name="fa-solid fa-caret-down"/>
          </div>
          <q-menu fit anchor="bottom left" self="top start">
            <q-item clickable class="list__item">
              <router-link to="/profile">
                <div class="list__item_content">
                  Профиль
                </div>
              </router-link>
            </q-item>
            <q-item clickable class="list__item">
              <div @click="handleLogout" class="list__item_content logout">
                Выйти
              </div>
            </q-item>
          </q-menu>
        </div>
      </div>
    </q-toolbar>
  </q-header>
</template>

<script>
import {PAGES} from "@/constants";
import {appActionTypes} from "@/services/store/modules/app_module/app_mutation_types";
import CityBlock from "@/components/features/cityBlock";
import CompaniesBlock from "@/components/features/companiesBlock";

export default {
  name: "Header",
  components: {CompaniesBlock, CityBlock},
  computed: {
    pages() {
      return PAGES;
    },
    userData() {
      return this.$store.state.app?.userData
    },
  },
  methods: {
    handleLogout() {
      this.$store.dispatch(appActionTypes.logout)
    },
  }
};
</script>

<style scoped lang="scss">
.logo {
  & > a {
    display: flex;
    align-items: center;
    width: fit-content;
  }

  & img {
    height: 37px;
  }
}

.header {
  &__toolbar {
    @media (max-width: 576px) {
      padding: 0 5px;
    }
  }

  &__right {
    display: flex;
    gap: 15px;
  }
}

.userbox {
  display: flex;
  align-items: center;
  gap: 5px;
  max-width: 260px;
  white-space: nowrap;
  overflow: hidden;

  @media (max-width: 360px) {
    max-width: 65px;
    text-overflow: ellipsis;
  }

  @media (max-width: 320px) {
    display: none;
  }

  &__wrapper {
    height: 50px;
    padding: 0 5px;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    transition: all 0.5s;

    &:hover {
      background-color: #026bc7;
    }
  }

  &__icon {
    font-size: 20px;
  }
}

@media (max-width: 576px) {
  .logo {
    & > img {
      display: none;
    }
  }
}

.list {
  &__item {
    padding: 0;
    width: 100%;
    min-height: auto;

    a {
      height: 100%;
      width: 100%;
    }

    &_content {
      width: 100%;
      height: 100%;
      display: flex;
      padding: 10px;
      align-items: center;

      &.logout {
        font-weight: 500;
      }
    }
  }
}
</style>
