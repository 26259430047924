import instance from "@/services/api";

export const timelineStepConditionsAPI = {
  async getList() {
    return await instance().get('/timeline/handbook/step_conditions/read/').then(res => res?.data)
  },
  async addCondition(body) {
    return await instance().post(`/timeline/handbook/step_conditions/add/`, body).then(res => res?.data)
  },
  async updateCondition(step_condition_id, body) {
    return await instance().put(`/timeline/handbook/step_conditions/${step_condition_id}/update/`, body).then(res => res?.data)
  },
  async deleteCondition(step_condition_id) {
    return await instance().delete(`/timeline/handbook/step_conditions/${step_condition_id}/delete/`).then(res => res?.data)
  },
  async getConditionTypes() {
    return await instance().get(`/timeline/handbook/condition_types/read/`).then(res => res?.data)
  },
  async getTimelineSteps() {
    return await instance().get(`/timeline/handbook/steps/read/`).then(res => res?.data)
  },
}

