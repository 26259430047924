<template>
  <div :class="[{purpose: this.data?.body?.purpose}]">
    <q-form @submit="this.onSubmit" class="form">
      <div class="form__item">
        <CustomCheckbox :binary="true" label="Оплачено" :value="this.formData.is_payed"
                        @change="(value) => onChange('is_payed', value)"/>
        <br>
        <CustomCheckbox :binary="true" label="Завершающий" :value="this.formData.is_last"
                        @change="(value) => onChange('is_last', value)"/>
      </div>
      <div class="form__item">
        <CustomDate :fullWidth="true"
                    :required="true"
                    placeholder="Дата платежа"
                    :value="this.formData.payment_date"
                    @change_date="(value) => onChange('payment_date', value)"
        />
      </div>
      <div class="form__item">
        <CustomInputNumber label="Сумма транша" :required="true" type="number" :value="this.formData.payment_amount"
                     @change_input="(value) => onChange('payment_amount', value)"/>
      </div>
      <div class="form__item">
        <CustomInput label="Комментарии" type="textarea" :value="this.formData.comments"
                     @change_input="(value) => onChange('comments', value)"/>
      </div>
      <CustomModalFooter :btn-loading="this.loading"/>
    </q-form>
  </div>
</template>
<script>

import CustomDate from "@/components/form/CustomDate";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import CustomInput from "@/components/form/CustomInput";
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomInputNumber from "@/components/form/CustomInputNumber";

export default {
  name: "add-income",
  components: {CustomInputNumber, CustomDate, CustomInput, CustomModalFooter, CustomCheckbox},
  props: ['data'],
  data() {
    return {
      loading: false,
      formData: {
        is_payed: this.data?.body?.is_payed || 0,
        is_last: this.data?.body?.is_last || 0,
        payment_amount: this.data?.body?.payment_amount || '',
        payment_date: this.data?.body?.payment_date || '',
        comments: this.data?.body?.comments || ''
      }
    }
  },
  methods: {
    onChange(key, value) {
      this.formData[key] = value
    },
    async onSubmit() {
      this.loading = true
      await this.data?.onSubmit(this.formData)
      this.loading = false
    },
  }
}
</script>

<style scoped lang="scss">
.form {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  gap: 15px;

  @media (max-width: 428px) {
    min-width: 100%;
  }
}
</style>