import instance from "@/services/api";

export const remontResponsibleAPI = {
  async getContractors() {
    return await instance().get('/client_request/common/contractors/read/').then(res => res?.data)
  },
  async updateContractor(client_request_id, body) {
    return await instance().put(`/client_request/${client_request_id}/responsible_officials/contractor/update/`, body).then(res => res?.data)
  },
  async getOKK() {
    return await instance().get('/client_request/common/okk/read/').then(res => res?.data)
  },
  async updateOKK(client_request_id, body) {
    return await instance().put(`/client_request/${client_request_id}/responsible_officials/okk/update/`, body).then(res => res?.data)
  },
  async getGeneralContractors() {
    return await instance().get('/client_request/common/general_contractors/read/').then(res => res?.data)
  },
  async updateGeneralContractor(client_request_id, body) {
    return await instance().put(`/client_request/${client_request_id}/responsible_officials/general_contractor/update/`, body).then(res => res?.data)
  },
  async getMainContractors() {
    return await instance().get('/common/contractor_mains/read/').then(res => res?.data)
  },
  async updateMainContractor(client_request_id, body) {
    return await instance().put(`/client_request/${client_request_id}/responsible_officials/contractor_main/update/`, body).then(res => res?.data)
  },
  async getProjectManagers() {
    return await instance().get('/client_request/common/inner_masters/read/').then(res => res?.data)
  },
  async updateProjectManager(client_request_id, body) {
    return await instance().put(`/client_request/${client_request_id}/responsible_officials/inner_master/update/`, body).then(res => res?.data)
  },
  async getTechprojectDevelopers() {
    return await instance().get('/client_request/common/techproject_developers/read/').then(res => res?.data)
  },
  async updateTechprojectDeveloper(client_request_id, body) {
    return await instance().put(`/client_request/${client_request_id}/responsible_officials/techproject_developer/update/`, body).then(res => res?.data)
  },
  async getTechprojectEmployees() {
    return await instance().get('/client_request/common/techproject_employees/read/').then(res => res?.data)
  },
  async updateTechprojectEmployee(client_request_id, body) {
    return await instance().put(`/client_request/${client_request_id}/responsible_officials/techproject_employee/update/`, body).then(res => res?.data)
  },
  async getProrabs() {
    return await instance().get('/client_request/common/manager_project/read/').then(res => res?.data)
  },
  async updateProrab(client_request_id, body) {
    return await instance().put(`/client_request/${client_request_id}/responsible_officials/manager_project/update/`, body).then(res => res?.data)
  },
  async getHeadDirections() {
    return await instance().get('/client_request/common/head_directions/read/').then(res => res?.data)
  },
  async updateHeadDirection(client_request_id, body) {
    return await instance().put(`/client_request/${client_request_id}/responsible_officials/head_direction/update/`, body).then(res => res?.data)
  },
  async getSupportManagers() {
    return await instance().get('/client_request/common/techproject_developers/read/').then(res => res?.data)
  },
  async updateSupportManager(client_request_id, body) {
    return await instance().put(`/client_request/${client_request_id}/responsible_officials/support_manager/update/`, body).then(res => res?.data)
  },
  async getCurators() {
    return await instance().get('/client_request/common/supervisors/read/').then(res => res?.data)
  },
  async updateCurator(client_request_id, body) {
    return await instance().put(`/client_request/${client_request_id}/responsible_officials/supervisor/update/`, body).then(res => res?.data)
  },
}