<template>
  <div class="filters" ref="filters">
    <div class="filters__item">
      <CustomSelect
          :options="this.providers"
          label="Поставщик"
          optionValueKey="provider_id"
          optionLabelKey="provider_name"
          :value="this.values.provider_id"
          @change="(value) => onChange('provider_id', value)"
      />
    </div>
    <div class="filters__item">
      <CustomSelect
          :options="this.statuses"
          label="Статус запроса"
          optionValueKey="provider_change_req_status_id"
          optionLabelKey="provider_change_req_status_name"
          :value="this.values.status_id || ''"
          @change="(value) => onChange('status_id', value)"
      />
    </div>
  </div>
</template>

<script>
import CustomSelect from "@/components/form/CustomSelect"
import {getMaterialsFiltersMetaData} from "@/views/ChangeMaterialRequests/services";

export default {
  name: "ChangeMaterialsReqFilters",
  props: ["values"],
  components: {CustomSelect},
  data() {
    return {
      statuses: [],
      providers: []
    }
  },
  methods: {
    onChange(type, value) {
      this.$emit('filter_change', type, value);
    },
  },
  async mounted() {
    const res = await getMaterialsFiltersMetaData();
    if (!res) return;
    const [
      providersResponse,
      statusesResponse,
    ] = res;
    this.providers = providersResponse;
    this.statuses = statusesResponse;
  },
}
</script>

<style scoped lang="scss">

.filters {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 15px;

  &__item {
    width: 230px;
    max-width: 230px;
  }
}
</style>