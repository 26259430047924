<template>
  <CustomTable
      :title="this.title"
      :rowsData="this.editedData"
      :columns="this.remontMode ? this.remontAgreementColumns : this.agreementColumns"
      :loading="isFetching"
      :custom-body="true"
      :small="true"
      :sticky-header="false" pagination-position="top"
  >
    <template v-slot:top-right="props">
      <q-btn @click="this.handleAddAgreement" color="primary" size="sm" padding="xs">Добавить договор</q-btn>
    </template>
    <template #customBody="props">
      <q-tr>
        <custom-table-cell align="center">
          <span>
            {{ props.row.contractor_agreement_list_id }}
            <q-tooltip>ID документа</q-tooltip>
          </span>
          <Clickable v-if="props.row.client_request_id" link :href="this.getRequestLink(props.row.client_request_id)">
            {{ props.row.remont_id }}
            <q-tooltip>ID ремонта</q-tooltip>
          </Clickable>
          <span v-else>{{ props.row.remont_id }}</span>
        </custom-table-cell>
        <custom-table-cell align="left">
          <span v-html="props.row.contractor_agreement_list_type_name"/>
          <hr/>
          {{ props.row.type_code || '' }}
          <p class="text">Оплачено в SR: <b>{{props.row.sr_true}}</b></p>
          <p class="text">Ожидает в SR: <b>{{props.row.sr_false}}</b></p>
          <p class="text">Оплачено в 1C: <b>{{props.row.buh_true}}</b></p>
          <p v-if="!!props.row.buh_false" class="text">Ожидает в 1C: <b v-html="props.row.buh_false"></b></p>
        </custom-table-cell>
        <custom-table-cell align="left">
          <div v-html="props.row.document_name"/>
          <q-btn
              v-if="!!props.row.sign_id"
              @click="() => this.downloadSignedDoc(props.row.contractor_agreement_list_id, props.row.sign_id)"
              :loading="this.loading.downloadSigned[props.row.contractor_agreement_list_id]"
              size="xs"
              padding="xs" :disable="this.actionsDisabled"
              color="primary"
              style="margin-top: 5px;"
          >Подписанный документ
          </q-btn>
        </custom-table-cell>
        <custom-table-cell align="right">
          <Amount :value="props.row.agreement_sum" :round="2"/>
        </custom-table-cell>
        <custom-table-cell align="left">
          <div v-html="props.row.employee_fio"></div>
        </custom-table-cell>
        <custom-table-cell align="left">
          <div v-html="props.row.contragent_id"></div>
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.status_name }}
        </custom-table-cell>
        <custom-table-cell align="center">
          <CustomDropdown
              v-if="props.row.status_code !== 'AGREEMENT_DECLINED' && props.row.status_code !== 'AGREEMENT_DS'"
              :loading="this.loading.download[props.row.contractor_agreement_list_id]"
              :onClick="doc => this.handleClickDoc(doc, props.row)" :disabled="this.actionsDisabled"
              :options="getRefactoredDocs(props.row.documents)"
              title="Действия"/>
          <q-btn
              v-if="props.row.is_signed === 0 && props.row.status_code !== 'AGREEMENT_DECLINED' && props.row.status_code !== 'AGREEMENT_DS'"
              size="sm"
              padding="sm" :disable="this.actionsDisabled"
              color="primary">
            <PopupConfirm :loading="this.loading[props.row.contractor_agreement_list_id]"
                          :approve="() => this.signAgreement(props.row.contractor_agreement_list_id)"/>
            Подписать
          </q-btn>
          <q-btn
              size="xs"
              padding="xs"
              color="red"
              style="margin-top: 5px;"
              no-wrap :disable="this.actionsDisabled"
              v-if="props.row.contractor_agreement_status_id !== 400"
          >
            <PopupConfirm
                :loading="this.loading.cancel[props.row.contractor_agreement_list_id]"
                :approve="() => this.onCancel(props.row.contractor_agreement_list_id, remontMode ? props.row.remont_id : null)"
            />
            Отменить спецификацию
          </q-btn>
          <q-btn
              size="xs"
              padding="xs"
              color="red" :disable="this.actionsDisabled"
              no-wrap
              style="margin-top: 5px;"
              v-if="props.row.sign_id > 0 && props.row.contractor_agreement_list_id > 0"
          >
            <PopupConfirm
                :loading="this.loading.delete[props.row.contractor_agreement_list_id]"
                :approve="() => this.onDelete(props.row.contractor_agreement_list_id, remontMode ? props.row.remont_id : null)"/>
            Удалить подпись
          </q-btn>
        </custom-table-cell>
        <custom-table-cell align="left">
          <div v-html="props.row['1c_status']"></div>
        </custom-table-cell>
      </q-tr>
    </template>
  </CustomTable>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import CustomDropdown from "@/components/common/CustomDropdown";
import PopupConfirm from "@/components/common/PopupConfirm";
import Amount from "@/components/common/Amount";
import {addAgreement, agreementColumns, remontAgreementColumns,} from './services'
import {downloadFile, getFullUrl} from '@/services'
import {appActions} from "@/services/store/modules/app_module/app_actions";
import {MODAL_NAMES} from "@/constants";
import Clickable from "@/components/common/Clickable";
import {getRequestLink} from "@/views/ClientRequests/services";

export default {
  name: "AgreementsTable",
  components: {Clickable, Amount, PopupConfirm, CustomDropdown, CustomTableCell, CustomTable},
  props: {
    isFetching: Boolean,
    remontMode: Boolean,
    rowsData: Array,
    title: String,
    params: Object,
    onCancelClick: Function,
    onDeleteClick: Function,
    modalRequest: Function,
    signSpec: Function,
  },
  data() {
    return {
      agreementColumns,
      remontAgreementColumns,
      data: null,
      loading: {
        add: false,
        sign: {},
        download: {},
        downloadSigned: {},
        delete: {},
        cancel: {}
      },
    }
  },
  computed: {
    editedData() {
      return this.data || this.rowsData
    },
    actionsDisabled() {
      return !!Object.keys(this.loading).find(key => {
        if (typeof this.loading[key] === 'boolean')
          return this.loading[key]
        return !!Object.keys(this.loading[key])?.find(childKey => this.loading[key][childKey] === true)
      })
    }
  },
  methods: {
    getRequestLink,
    getFullUrl,
    async handleClickDoc(doc, row) {
      const {is_modal, is_action, contractor_agreement_list_document_id, label, keys, action_name, body} = doc
      const {contractor_agreement_list_id} = row
      const urlWithId = getFullUrl(`${doc.url}${contractor_agreement_list_document_id}`)
      if (is_modal) {
        if (!action_name) return
        this.$store.commit(appActions.showModal(MODAL_NAMES[action_name], {
          title: 'ДС на уменьшение',
          body,
          onSubmit: (body) => this.modalRequest && this.modalRequest({
            ...body,
            contractor_agreement_list_id
          }, action_name),
        }));
      } else if (is_action) {
        const params = {}
        if (keys?.length) {
          keys.forEach(key => {
            params[key] = row[key]
          })
        }
        this.loading.download[contractor_agreement_list_id] = true
        await downloadFile(urlWithId, label, params, 'pdf', 'post')
      } else {
        const body = {contractor_agreement_list_document_id}
        const url = `/client_request/specifications/${contractor_agreement_list_id}/document/`
        this.loading.download[contractor_agreement_list_id] = true
        await downloadFile(url, label, body, 'pdf', 'post')
      }
      this.loading.download[contractor_agreement_list_id] = false
    },
    async signAgreement(contractor_agreement_list_id) {
      this.loading.sign[contractor_agreement_list_id] = true
      this.signSpec && await this.signSpec(contractor_agreement_list_id)
      this.loading.sign[contractor_agreement_list_id] = false
    },
    async downloadSignedDoc(contractor_agreement_list_id, sign_id) {
      const url = `/client_request/specifications/signed_document/${sign_id}/`
      this.loading.downloadSigned[contractor_agreement_list_id] = true
      await downloadFile(url, 'Подписанный документ', {}, 'pdf', 'post')
      this.loading.downloadSigned[contractor_agreement_list_id] = false
    },
    async onCancel(contractor_agreement_list_id, remont_id) {
      this.loading.cancel[contractor_agreement_list_id] = true
      await this.onCancelClick(contractor_agreement_list_id, remont_id)
      this.loading.cancel[contractor_agreement_list_id] = false
    },
    async onDelete(contractor_agreement_list_id, remont_id) {
      this.loading.delete[contractor_agreement_list_id] = true
      await this.onDeleteClick(contractor_agreement_list_id, remont_id)
      this.loading.delete[contractor_agreement_list_id] = false
    },
    handleAddAgreement() {
      this.$store.commit(appActions.showModal(MODAL_NAMES.AddAgreement, {
        title: 'Добавить договор',
        onSubmit: this.addAgreementSubmit
      }));
    },
    async addAgreementSubmit(body) {
      this.loading.add = true
      const res = await addAgreement(body, this.params)
      this.loading.add = false
      if (!res) return
      this.data = res || []
    },
    getRefactoredDocs(documents) {
      return documents?.map(item => ({...item, label: item.designation}))
    }
  },
}
</script>

<style lang="scss" scoped>
.text {
  margin: 0;
}
</style>