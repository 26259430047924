import {mobileAppAPI} from "@/views/handbook/MobileApps/services/api";

export const getMobileAppVersions = async (mobile_app_code) => {
  if(!mobile_app_code) return
  try {
    const res = await mobileAppAPI.getMobileAppVersions(mobile_app_code)
    return res?.data
  } catch (e) {}
}
export const deleteAppVersion = async (mobile_app_code, mobile_app_version_id) => {
  if(!mobile_app_code) return
  try {
    const res = await mobileAppAPI.deleteAppVersion(mobile_app_code, mobile_app_version_id)
    return res?.data
  } catch (e) {}
}
export const addAppVersion = async (mobile_app_code, body) => {
  if(!mobile_app_code) return
  try {
    const res = await mobileAppAPI.addAppVersion(mobile_app_code, body)
    return res?.data
  } catch (e) {}
}
export const getMobileApps = async () => {
  try {
    const res = await mobileAppAPI.getApps()
    return res?.data
  } catch (e) {}
}

export const mobileAppVersionColumns = [
  {
    name: "index",
    field: "index",
    label: "#",
    align: "center",
  },
  {
    name: "mobile_app_version_id",
    field: "mobile_app_version_id",
    label: "Версия приложения",
    align: "center",
  },
  {
    name: "file_url",
    field: "file_url",
    label: "Файл приложения",
    align: "center",
  },
  {
    name: "date_create",
    field: "date_create",
    label: "Время изменения",
    align: "left",
  },
]