<template>
  <CollapseBlock class-name="filters">
    <div class="filters__item">
      <CustomSelect
          :options="this.contractors"
          label="Подрядчик" :multiple="true"
          optionValueKey="contractor_id"
          optionLabelKey="contractor_name"
          :value="this.filters.contractor_id_arr"
          @change="(value) => onFiltersChange('contractor_id_arr', value)"
      />
    </div>
    <div class="filters__item">
      <CustomSelect
          :options="this.mainContractors"
          label="Главный подрядчик"
          optionValueKey="contractor_main_id"
          optionLabelKey="contractor_main_name"
          :value="this.filters.contractor_main_id"
          @change="(value) => onFiltersChange('contractor_main_id', value)"
      />
    </div>
    <div class="filters__item">
      <CustomSelect
          :multiple="true"
          :options="this.residents"
          label="ЖК"
          optionValueKey="resident_id"
          optionLabelKey="resident_name"
          :value="this.filters.resident_id_arr"
          @change="(value) => onFiltersChange('resident_id_arr', value)"
      />
    </div>
    <div class="filters__item">
      <CustomSelect
          :multiple="true"
          :options="this.techProjectEmployees"
          label="Разработчик тех.проекта"
          optionValueKey="employee_id"
          optionLabelKey="fio"
          :value="this.filters.techproject_employee_id_arr"
          @change="(value) => onFiltersChange('techproject_employee_id_arr', value)"
      />
    </div>
    <div class="filters__item">
      <CustomSelect
          :multiple="true"
          :options="this.prorabs"
          label="Прораб"
          optionValueKey="employee_id"
          optionLabelKey="fio"
          :value="this.filters.manager_project_id_arr"
          @change="(value) => onFiltersChange('manager_project_id_arr', value)"
      />
    </div>
    <div class="filters__item">
      <CustomSelect
          :multiple="true"
          :options="this.projectManagers"
          label="Проектный менеджер"
          optionValueKey="employee_id"
          optionLabelKey="fio"
          :value="this.filters.inner_master_id_arr"
          @change="(value) => onFiltersChange('inner_master_id_arr', value)"
      />
    </div>
    <div class="filters__item">
      <CustomSelect
          :multiple="true"
          :options="this.salePoints"
          label="Точки продаж"
          optionValueKey="sale_point_id"
          optionLabelKey="point_name"
          :value="this.filters.sale_point_id_arr"
          @change="(value) => onFiltersChange('sale_point_id_arr', value)"
      />
    </div>
    <div class="filters__item">
      <CustomSelect
          :multiple="true"
          :options="this.techProjectDevs"
          label="Разработчик тех.проекта (Контрагент)"
          optionValueKey="techproject_developer_id"
          optionLabelKey="techproject_developer_name"
          :value="this.filters.techproject_developer_id_arr"
          @change="(value) => onFiltersChange('techproject_developer_id_arr', value)"
      />
    </div>
    <div class="filters__item">
      <CustomSelect
          :multiple="true"
          :options="this.headDirections"
          label="Начальник дирекции"
          optionValueKey="employee_id"
          optionLabelKey="fio"
          :value="this.filters.head_direction_id_arr"
          @change="(value) => onFiltersChange('head_direction_id_arr', value)"
      />
    </div>
    <q-btn type="submit" @click="this.handleFiltersSubmit" :loading="this.isFetching" size="sm" no-wrap color="primary">
      <q-icon name="search"/>
      Поиск
    </q-btn>
  </CollapseBlock>
</template>

<script>
import {getFiltersData} from "@/views/ExecutiveReport/services";
import CollapseBlock from "@/components/common/CollapseBlock";
import CustomSelect from "@/components/form/CustomSelect";

export default {
  name: "ExecutiveReportFilters",
  components: {CustomSelect, CollapseBlock},
  props: ['onFiltersChange', 'filters', 'handleFiltersSubmit', 'isFetching'],
  data() {
    return {
      contractors: [],
      mainContractors: [],
      residents: [],
      techProjectEmployees: [],
      projectManagers: [],
      prorabs: [],
      techProjectDevs: [],
      salePoints: [],
      headDirections: []
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      const {
        contractors,
        mainContractors,
        salePoints,
        residents,
        techProjectEmployees,
        prorabs,
        projectManagers,
        techProjectDevs,
        headDirections
      } = await getFiltersData();
      this.contractors = contractors
      this.mainContractors = mainContractors
      this.residents = residents
      this.techProjectEmployees = techProjectEmployees
      this.projectManagers = projectManagers
      this.prorabs = prorabs
      this.techProjectDevs = techProjectDevs
      this.salePoints = salePoints
      this.headDirections = headDirections
    }
  }
}
</script>

<style scoped lang="scss">
.filters {
  margin-bottom: 20px;
  display: flex;
  align-items: flex-end;
  gap: 15px;
  flex-wrap: wrap;

  &__item {
    width: 220px;
  }
}
</style>