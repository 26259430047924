<template>
  <div class="wrapper">
    <CustomTable
        title=""
        :rowsData="this.data.modalData"
        :columns="this.executiveReportDeadlineViewColumns"
        :custom-body="true" :small="true"
        :hide-footer-title="true" :sticky-header="false"
    >
      <template #customBody="props">
        <q-tr>
          <custom-table-cell align="center">
            {{ props.row.remont_id }}
          </custom-table-cell>
          <custom-table-cell align="left">
            {{ props.row.work_set_check_group_short_name }}
          </custom-table-cell>
          <custom-table-cell align="center">
            {{ props.row.finish_date }}
          </custom-table-cell>
          <custom-table-cell align="left">
            {{ props.row.fio }}
          </custom-table-cell>
          <custom-table-cell align="left">
            {{ props.row.comment }}
          </custom-table-cell>
          <custom-table-cell align="center">
            {{ props.row.rowversion }}
          </custom-table-cell>
        </q-tr>
      </template>
    </CustomTable>
    <CustomModalFooter :onCancel="this.closeModal" :hide-submit-btn="true" cancel-btn-text="Закрыть"/>
  </div>
</template>
<script>

import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import {executiveReportDeadlineViewColumns} from "@/components/modal/modals/executive-report-deadline-view/services";

export default {
  name: "executive-report-deadline-view",
  components: {CustomTableCell, CustomTable, CustomModalFooter},
  props: ['data', 'closeModal'],
  data() {
    return {
      executiveReportDeadlineViewColumns,
    }
  },
}
</script>

<style scoped lang="scss">
.wrapper {
  max-width: 540px;
}

.form {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  gap: 20px;

  @media (max-width: 428px) {
    min-width: 100%;
  }
}

@media (max-width: 576px) {
  .wrapper {
    max-width: 100%;
  }
}
</style>
