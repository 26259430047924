<template>
  <div :class="[{purpose: this.data?.body?.purpose}]">
    <q-form @submit="this.onSubmit" class="form">
      <div class="form__item">
        <CustomSelect
          :options="this.stages"
          label="Этап"
          optionValueKey="stage_id"
          optionLabelKey="stage_name"
          :value="this.formData.stage_id || ''"
          @change="(value) => onChange('stage_id', value)"
          :required="true"
        />
      </div>
      <div class="form__item">
        <CustomSelect
          :options="this.stageStatuses"
          label="Статус этапа"
          optionValueKey="stage_status_id"
          optionLabelKey="status_name"
          :value="this.formData.stage_status_id || ''"
          @change="(value) => onChange('stage_status_id', value)"
          :required="true"
        />
      </div>
      <div class="form__item">
        <CustomDate
          :required="true"
          :fullWidth="true"
          placeholder="Дата создания"
          :value="this.formData.date_create"
          @change_date="(value) => onChange('date_create', value)"
        />
      </div>
      <div class="form__item">
        <CustomInput
          label="Комментарий"
          type="textarea"
          :value="this.formData.comments"
          @change_input="(value) => onChange('comments', value)"
        />
      </div>
      <CustomModalFooter :btn-loading="this.loading" />
    </q-form>
  </div>
</template>
<script>

import CustomDate from "@/components/form/CustomDate";
import CustomInput from "@/components/form/CustomInput";
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomSelect from "@/components/form/CustomSelect";
import {appActionTypes} from "@/services/store/modules/app_module/app_mutation_types";
import {getRequestStagesList} from "@/views/ClientRequests/remontStages/services";

export default {
  name: "add-cost",
  components: {CustomSelect, CustomDate, CustomInput, CustomModalFooter},
  props: ['data'],
  data() {
    return {
      loading: false,
      stages: [],
      formData: {
        stage_id: this.data?.body?.stage_id || '',
        stage_status_id: this.data?.body?.stage_status_id || '',
        date_create: this.data?.body?.date_create || '',
        comments: this.data?.body?.comments || ''
      }
    }
  },
  methods: {
    async getStages() {
      const res = await getRequestStagesList(this.data?.client_request_id)
      if(!res) return
      this.stages = res
    },
    onChange(key, value) {
      this.formData[key] = value
    },
    async onSubmit() {
      this.loading = true
      await this.data?.onSubmit(this.formData)
      this.loading = false
    },
  },
  computed: {
    stages() {
      return this.$store.state.app.stages;
    },
    stageStatuses() {
      return this.$store.state.app.stageStatuses;
    },
  },
  mounted() {
    this.$store.dispatch(appActionTypes.getStageStatuses)
    this.getStages()
  }
}
</script>

<style scoped lang="scss">
.form {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  gap: 15px;

  @media (max-width: 428px) {
    min-width: 100%;
  }
}
</style>
