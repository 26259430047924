<template>
  <div :class="['chat', {chat__active: !this.activeChatGroup}]">
    <div :class="['chat__header', 'chat__grid']">
      <div class="chat__header_status">Статус</div>
      <div>Название</div>
      <div class="chat__header_date">Дата обновления</div>
    </div>
    <div
      v-for="item in this.data"
      :class="['chat__grid', 'chat__item', {chat__item_active: this.activeChatGroup?.group_chat_id === item.group_chat_id}]"
      @click="this.handleActiveGroupChange(item)"
    >
      <div class="chat__item_status">{{ item.status_name }}</div>
      <div class="chat__item_name">
        <span
          class="chat__item_nameLabel"
          >{{ item.group_chat_short_name }}</span
        >
        <span class="chat__item_nameMessage"
          >{{ item.chat_fio ? `${item.chat_fio}: ` : ''









          }}{{ item.message || '' }}</span
        >
      </div>
      <div class="chat__item_date">{{ item.date_chat }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RemontChatGroups",
  props: ['data', 'onChange', 'activeChatGroup'],
  methods: {
    handleActiveGroupChange(group) {
      this.onChange && this.onChange(group)
    }
  }
}
</script>

<style scoped lang="scss">
.chat {
  &__grid {
    display: grid;
    grid-template-columns: 2fr 8fr 2fr;
    gap: 15px;

    @media (max-width: 400px) {
      gap: 10px;
    }
  }

  &__header {
    padding: 10px 15px;
    border: 1px solid #dce1e6;
    border-radius: 5px 5px 0 0;

    &_status {
      text-align: center;
    }

    &_date {
      text-align: center;
    }

    & > div {
      font-weight: 600;
      font-size: 13px;
      display: flex;
      align-items: center;
    }
  }

  &__item {
    height: 63px;
    border: 1px solid #dce1e6;
    border-top: none;
    padding: 11px 15px;
    font-size: 13px;
    cursor: pointer;
    transition: all .2s;

    @media (max-width: 400px) {
      padding: 10px;
    }

    &:hover {
      background: #f1f4f7;
    }

    &_active {
      background: #f1f4f7;
    }

    &_status {
      text-align: center;
      color: #818C99;
      line-height: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &_name {
      display: flex;
      flex-direction: column;
      gap: 2px;

      &Label {
        font-weight: 500;
        color: #2c2f31;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        @media (max-width: 1250px) {
          max-width: 200px;
        }

        @media (max-width: 991px) {
          max-width: 50vw;
          width: auto;
        }
        @media (max-width: 360px) {
          max-width: 40vw;
        }
      }

      &Message {
        color: #999999;
        width: 310px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        display: inline-block;
        white-space: nowrap;

        @media (max-width: 1250px) {
          max-width: 200px;
        }

        @media (max-width: 991px) {
          max-width: 50vw;
          width: auto;
        }
        @media (max-width: 360px) {
          max-width: 40vw;
        }
      }
    }

    &_date {
      color: #818C99;
      text-align: right;
      font-size: 12px;
    }
  }

  @media (max-width: 991px) {
    display: none;
    grid-template-columns: 12fr;
    &__active {
      display: block;
    }
  }
}
</style>
