<template>
  <custom-table
      :customBody="true" :hide-footer-title="true" :columns="this.columns" :rowsData="this.data?.data">
    <template #customBody="props">
      <q-tr :props="props" :style="(!props.row.is_active && !props.row.is_completed) ? 'background-color: #bbb !important': '' ">
        <q-td v-for="col in props.cols" :key="col.name" :props="props">
          <div v-if="col.name === 'is_checked'">
            <i :class="`material-icons timeline__check timeline__check_${col.value ? 'completed' : 'close'}`">
              {{ col.value ? "check" : "close" }}
            </i>
          </div>
          <div v-else-if="col.name === 'is_active'" class="timeline__pointer"
               @click="() => this.updateActivity(props.row.remont_step_condition_id, !col.value)">
            <template v-if="!props.row.is_checked">
              <CustomLoader v-if="this.loading[props.row.remont_step_condition_id]" small/>
              <i v-else
                 :class="`material-icons amaterial-symbols-outlined timeline__toggle timeline__toggle_${col.value ? 'completed' : ''}`">
                {{ col.value ? 'toggle_on' : 'toggle_off' }}
              </i>
            </template>
          </div>
          <span v-else>
            {{ col.value }}
          </span>
        </q-td>
      </q-tr>
    </template>
  </custom-table>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import {
  timelineConditionsTableColumns,
  updateRemontConditionStepActivity, remontTimelineTabs
} from "@/components/modal/modals/remont-timeline-step/services";
import CustomLoader from "@/components/common/CustomLoader";

export default {
  name: "remont-timeline-conditions",
  components: {CustomLoader, CustomTable},
  props: ['data', 'remont_step_id', 'updateData'],
  data() {
    return {
      remontTimelineTabs,
      columns: timelineConditionsTableColumns,
      loading: {}
    }
  },
  methods: {
    async updateActivity(remont_step_condition_id, is_active) {
      if (this.loading[remont_step_condition_id]) return
      this.loading[remont_step_condition_id] = true
      const res = await updateRemontConditionStepActivity(this.remont_step_id, remont_step_condition_id,
          {is_active})
      this.loading[remont_step_condition_id] = false
      if (!res) return
      this.updateData(this.remontTimelineTabs.conditions, res)
    }
  }
}
</script>

<style scoped>

</style>