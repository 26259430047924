import {financePaymentsAPI} from "@/views/FinancePayments/api";
import {checkIdValid, getSelectedIds, toValidComponentDate} from "@/_helpers";
import {financePaymentsFiltersAPI} from "@/views/FinancePayments/FinancePaymentsFilters/api";
import {saveAs} from "file-saver";
import {numberWithCommas} from "@/services";

export const getFinancePaymentData = async (params) => {
  try {
    checkIdValid(params.client_request_id);
    const res = await financePaymentsAPI.getFinancePayments(params);
    return res?.finance_payments;
  } catch (e) {
    return null;
  }
};

export const getPaymentDetail = async (finance_payment_id, data, detailTableColumns) => {
  try {
    const res = await financePaymentsAPI.getFinancePaymentsDetail(finance_payment_id);
    if (res) {
      let tableColumns = {...detailTableColumns};
      let editedData = data?.map((item) => {
        if (item.finance_payment_id === finance_payment_id) {
          const [updated, columnItems] = parseDetailData(item, res?.finance_payment_items);
          tableColumns = {
            ...tableColumns, [item.finance_payment_id]: columnItems,
          };
          return updated;
        }
        return item;
      });
      return {tableColumns, editedData};
    }
  } catch (e) {
  }
};

export const parseDetailData = (item, finance_payment_items) => {
  let isNewColumnsAdded = false;
  let detailTableColumns = [];
  const detailData = finance_payment_items?.map((payment) => {
    const splitted = payment.info?.split("<br/>") || [];
    const newValues = {};
    const newColumnItems = [];
    splitted.forEach((i, index) => {
      const key = index + 1;
      const itemSplit = i.split(":");
      if (itemSplit?.length === 2) {
        const name = `name${key}`;
        const value = itemSplit[1].trim();
        const label = itemSplit[0].trim();
        if (!isNewColumnsAdded) {
          const newColumnItem = {
            name, label: itemSplit[0].trim(), field: name, sortable: true,
          };
          if (!isNaN(value)) {
            if (!label?.toLowerCase()?.includes("id")) newColumnItem["format"] = (val) => numberWithCommas(val);
            newColumnItem["align"] = "right";
          }
          newColumnItems.push(newColumnItem);
        }
        newValues[name] = value;
      }
    });
    if (!isNewColumnsAdded && splitted?.length) {
      isNewColumnsAdded = true;
      detailTableColumns = [...defaultDetailTableColumns, ...newColumnItems, paymentAmountObject,];
    }
    let document_info = ''
    if(!!payment?.contractor_agreement_list_id) {
      document_info = `${payment?.contractor_agreement_list_id} (${payment?.document_num || '-'} от ${payment?.document_date || '-'})`
    }
    return {...payment, ...newValues, document_info};
  });
  if (!detailTableColumns?.length) {
    detailTableColumns = [...defaultDetailTableColumns, paymentAmountObject];
  }
  return [{
    ...item, detailData,
  }, detailTableColumns,];
};

export const getAddPaymentModalMetaData = async () => {
  return await Promise.all([financePaymentsAPI.getInitiatorCompanies(), financePaymentsAPI.getBankBik(), financePaymentsAPI.getFinancePaymentTypes(),]).catch(() => {
    return null;
  });
};

export const getRemontsPaymentPurpose = async (body) => {
  try {
    if (body.remont_ids !== null) {
      body.remont_ids = body.remont_ids
        ?.map((item) => +item.remont_id)
        .filter((item) => !!item);
      if (!body.remont_ids?.length) return;
    }
    const res = await financePaymentsAPI.getRemontsPaymentPurpose(body);
    return res?.specification || "";
  } catch (e) {
  }
};

export const getContragentInfo = async (body) => {
  try {
    const res = await financePaymentsAPI.getContragentInfoReq(body);
    if (!res?.error) {
      return res?.contragent_info?.map((item) => ({
        ...item, contragent: `${item.name || item.contragent_name || ""} / ${item.bin}`,
      }));
    }
    return [];
  } catch (e) {
    return [];
  }
};

export const onAddSubmit = async (data, params = {}) => {
  try {
    const res = await financePaymentsAPI.addFinancePayment(data, params);
    if (res?.status) {
      return res?.finance_payments;
    }
    return null;
  } catch (e) {
    return null;
  }
};

export const getPaymentStatusesData = async () => {
  try {
    const res = await financePaymentsFiltersAPI.getFinancePaymentStatuses();
    return res?.finance_payment_statuses || [];
  } catch (e) {
    return [];
  }
};
export const getContragentAgreements = async (contragent_id) => {
  if(!contragent_id) return
  try {
    const res = await financePaymentsAPI.getContragentAgreements(contragent_id);
    return res?.agreements || [];
  } catch (e) {}
};

export const getCorrectList = (obj) => {
  if (!obj) return [];
  const arr = [];
  Object.keys(obj).forEach((item) => {
    const listItem = obj[item];
    if (!!listItem && typeof listItem === "object") {
      arr.push({...listItem});
    }
  });
  return arr;
};
const defaultDetailTableColumns = [{
  name: "cost_id", label: "cost_id", field: "cost_id", sortable: true, align: "center", format(val, row) {
    return `${val} (${row.cost_type})`
  },
}, {
  name: "finance_payment_item_id",
  label: "finance_payment_item_id",
  field: "finance_payment_item_id",
  sortable: true,
  align: "center",
}, {
  name: "remont_id", label: "ID ремонта", field: "remont_id", sortable: true, align: "center",
}, {
  name: "address", label: "Адрес", field: "address", sortable: true, align: "left",
},
  {
    name: "is_payed", label: "Оплачен", field: "is_payed", sortable: true, align: "center",
  },
  {
    name: "document_info", label: "Документ", field: "document_info", align: "left",
  },
];

const paymentAmountObject = {
  name: "payment_amount",
  label: "Итого",
  field: "payment_amount",
  style: "white-space: nowrap",
  align: "right",
  sortable: true,
  format(val) {
    return numberWithCommas(val);
  },
};

export const financePaymentsColumns = [{
  name: "remont_id",
  label: "ID платежного поручения",
  field: "finance_payment_id",
  sortable: true,
  headerStyle: "width: 80px",
  style: "width: 100px",
}, {
  name: "guid", label: "GUID ", field: "guid", sortable: true,
}, {
  name: "created_date",
  label: "Дата создания",
  field: "created_date",
  sortable: true,
  sort: (a, b) => new Date(toValidComponentDate(a)) - new Date(toValidComponentDate(b)),
  headerStyle: "width: 80px",
  style: "width: 100px",
  align: "center,",
}, {
  name: "pay_date",
  label: "Дата оплаты",
  field: "pay_date",
  headerStyle: "width: 80px",
  style: "width: 100px",
  sortable: true,
  sort: (a, b) => new Date(toValidComponentDate(a)) - new Date(toValidComponentDate(b)),
  align: "center,",
  format(val) {
    return val || "Редактировать";
  },
}, {
  name: "contragent", label: "Контрагент", field: "contragent", sortable: true, align: "left",
}, {
  name: "finance_payment_type_name",
  label: "Тип платежа",
  field: "finance_payment_type_name",
  sortable: true,
  align: "left",
  style: "width: 150px",
}, {
  name: "finance_payment_status_name",
  label: "Статус платежа",
  field: "finance_payment_status_name",
  sortable: true,
  align: "left",
}, {
  name: "payment_purpose_short",
  label: "Назначение платежа",
  field: "payment_purpose_short",
  align: "left",
  style: "width: 500px",
  format(val) {
    return val || "Редактировать";
  },
}, {
  name: "comments", label: "Комментарии", field: "comments", align: "left", style: "width: 300px",
}, {
  name: "is_manually_created", label: "Ручной", field: "is_manually_created", sortable: true, align: "center",
}, {
  name: "payment_amount",
  label: "Итого",
  field: "payment_amount",
  sortable: true,
  align: "right",
  style: "white-space: nowrap",
  format(val) {
    return numberWithCommas(val);
  },
}, {
  name: "item_cnt", label: "Кол-во", field: "item_cnt", style: {width: "20px"}, align: "center",
}, {
  name: "edit", label: "", style: {width: "20px"}, align: "center",
},];

export const getFinancePaymentStatusesData = async () => {
  try {
    const res = await financePaymentsAPI.getFinancePaymentStatuses();
    return res?.finance_payment_statuses || [];
  } catch (e) {
    return [];
  }
};

export const approvePaymentsChangeStatusData = async (body) => {
  try {
    body.ids = getSelectedIds(body.ids);
    const res = await financePaymentsAPI.approveChangePaymentStatus(body);
    return res?.finance_payments;
  } catch (e) {
  }
};

export const changeFinancePaymentDate = async (body, params = {}) => {
  try {
    body.ids = getSelectedIds(body.ids);
    const res = await financePaymentsAPI.changeDate(body, params);
    return res?.finance_payments;
  } catch (e) {
  }
}
export const changeFinancePaymentPurpose = async (financePaymentId, body, params = {}) => {
  try {
    const res = await financePaymentsAPI.changeRemontsPaymentPurpose(financePaymentId, body, params);
    return res?.finance_payments;
  } catch (e) {
  }
};
export const changeFinancePayment = async (financePaymentId, body, params = {}) => {
  try {
    const res = await financePaymentsAPI.changeFinancePayment(financePaymentId, body, params);
    return res?.finance_payments;
  } catch (e) {
  }
};

export const getColTitle = (colName) => {
  if (colName === "pay_date") return "Изменить";
  return "";
};
export const getFinancePaymentClass = (colName) => {
  let className = "";
  if (colName === "pay_date") {
    className = "payments_row__hover";
  }
  if (colName === "payment_amount") {
    className += " payments_row__amount";
  }
  return className;
};

export const saveFinancePaymentsExcel = async (params) => {
  try {
    const res = await financePaymentsAPI.financePaymentsExcel(params)
    const blob = new Blob([res], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    });
    saveAs(blob, `Платежные поручения - ${new Date().toLocaleDateString('ru')}.xlsx`);
  } catch (e) {
  }
}

export const getFinancePaymentDocs = async (finance_payment_id) => {
  try {
    const res = await financePaymentsAPI.getFinancePaymentDocs(finance_payment_id)
    return res?.finance_payment_documents || []
  } catch (e) {
    return []
  }
}
export const deleteFinancePaymentDoc = async (finance_payment_id, finance_payment_document_id) => {
  try {
    const res = await financePaymentsAPI.deleteFinancePaymentDoc(finance_payment_id, finance_payment_document_id)
    return res?.finance_payment_documents || []
  } catch (e) {
  }
}

export const uploadFinancePaymentDocs = async (finance_payment_id, files = []) => {
  if (!files?.length) return
  try {
    const body = new FormData()
    files.forEach(item => body.append("documents", item))
    const res = await financePaymentsAPI.uploadFinancePaymentDocs(finance_payment_id, body)
    return res?.finance_payment_documents
  } catch (e) {
  }
}

export const financePaymentDocsColumns = [
  {
    name: "finance_payment_document_id",
    label: "ID",
    field: "finance_payment_document_id",
    sortable: true,
    align: "center",
  },
  {
    name: "finance_payment_document_name",
    label: "Название",
    field: "finance_payment_document_name",
    sortable: true,
    align: "left",
  },
  {name: "created_date", label: "Дата создания", field: "created_date", sortable: true, align: "center",},
  {
    name: "finance_payment_document_extension",
    label: "Расширение",
    field: "finance_payment_document_extension",
    sortable: true,
    align: "left",
  },
  {name: "cost_id", label: "", field: "", sortable: true, align: "center",},
]