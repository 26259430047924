export const executiveReportDeadlineViewColumns = [
  {
    name: "remont_id",
    label: "ID ремонта",
    field: "remont_id",
    align: "center",
  },
  {
    name: "work_set_check_group_short_name",
    label: "Группа работ",
    field: "work_set_check_group_short_name",
    align: "left",
  },
  {
    name: "finish_date",
    label: "Дата завершения",
    field: "finish_date",
    align: "center",
  },
  {
    name: "fio",
    label: "Пользователь",
    field: "fio",
    align: "left",
  },
  {
    name: "comment",
    label: "Комментарий",
    field: "comment",
    align: "left",
  },
  {
    name: "rowversion",
    label: "Версия записи",
    field: "rowversion",
    align: "center",
  },
]