<template>
  <div class="filters">
    <div class="filters__item">
      <CustomInput
          type="number"
          @enter="this.getData"
          label="Грейд"
          :value="this.inputGradeId"
          @change_input="this.onChange"
      />
    </div>
    <q-btn
        :loading="this.loading"
        @click="this.getData"
        color="primary"
    >
      <q-icon name="search" />
    </q-btn>
  </div>
  <CustomTable
      :title="this.gradeId ? `Грейд ${this.gradeId}` : ''"
      :rowsData="this.rowsData"
      :columns="this.gradeStepsColumns"
      :loading="isFetching"
      :small="true"
      :show-search="true"
      :custom-body="true" :on-add-click="this.handleAddClick"
      :showHeaderAdd="true"
      :headerAddDisabled="!this.gradeId"
      :sticky-header="false">
    <template #customBody="props">
      <q-tr>
        <custom-table-cell align="center">
          {{ props.row.step_id }}
        </custom-table-cell>
        <custom-table-cell align="left">
          <Clickable @click="() => this.handleUpdateClick(props.row)">{{ props.row.step_name }}</Clickable>
        </custom-table-cell>
        <custom-table-cell align="center">
          <CustomCheckbox
              color="primary"
              label=""
              :disable="!!this.activeLoading[props.row.grade_step_id]"
              :value="!!props.row.is_active"
              @change="value => this.handleChangeActive(value, props.row.grade_step_id)"
          />
          <CustomLoader small v-if="!!this.activeLoading[props.row.grade_step_id]" />
        </custom-table-cell>
        <custom-table-cell align="center" vertical-align="center">
          <CustomIcon icon="trash" :approve-mode="true"
                      :loading="!!this.deleteLoading[props.row.grade_step_id]"
                      :approve="() => this.onDeleteSubmit(props.row.grade_step_id)"/>
        </custom-table-cell>
      </q-tr>
    </template>
  </CustomTable>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import {appActions} from "@/services/store/modules/app_module/app_actions";
import {MODAL_NAMES} from "@/constants";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";
import CustomIcon from "@/components/common/CustomIcon";
import Clickable from "@/components/common/Clickable";
import {
  addGradeStep,
  deleteGradeStep,
  getGradeSteps,
  gradeStepsColumns,
  updateGradeStep
} from "@/views/handbook/gradeTimelineSteps/services";
import CustomInput from "@/components/form/CustomInput";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import CustomLoader from "@/components/common/CustomLoader";

export default {
  name: "GradeTimelineSteps",
  data() {
    return {
      gradeStepsColumns: gradeStepsColumns,
      rowsData: [],
      deleteLoading: {},
      activeLoading: {},
      loading: false,
      isFetching: false,
      inputGradeId: '',
      gradeId: '',
    }
  },
  components: {CustomLoader, CustomCheckbox, CustomInput, Clickable, CustomIcon, CustomTableCell, CustomTable},
  methods: {
    onChange(value) {
      this.inputGradeId = value
    },
    handleChangeActive(is_active, grade_step_id) {
      this.onUpdateGradeAction(grade_step_id, {is_active})
    },
    async getData() {
      if(!this.inputGradeId) return
      const grade_id = this.inputGradeId
      this.isFetching = true
      const res = await getGradeSteps(grade_id)
      this.isFetching = false
      if (!res) return
      this.rowsData = res
      this.gradeId = grade_id
    },
    async handleAddClick() {
      this.$store.commit(appActions.showModal(MODAL_NAMES.AddGradeTimelineStep, {
        onSubmit: this.onAddGradeAction,
        title: 'Добавление шага',
      }));
    },
    async onAddGradeAction(body) {
      const res = await addGradeStep(this.gradeId, body)
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    async handleUpdateClick(row) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.NestedGradeTimelineSteps, {
        title: `Шаг: ${row.step_name}`,
        step_id: row.step_id,
        gradeId: this.gradeId
      }));
    },
    async onUpdateGradeAction(grade_step_id, body) {
      this.activeLoading[grade_step_id] = true
      const res = await updateGradeStep(this.gradeId, grade_step_id, body)
      this.activeLoading[grade_step_id] = false
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    async onDeleteSubmit(grade_step_id) {
      this.deleteLoading = {...this.deleteLoading, [grade_step_id]: true}
      const res = await deleteGradeStep(this.gradeId, grade_step_id)
      this.deleteLoading = {...this.deleteLoading, [grade_step_id]: false}
      if (!res) return
      this.rowsData = res
    },
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar();
    return {showSucceedSnackbar};
  },
}
</script>

<style scoped lang="scss">
.filters {
  display: flex;
  align-items: center;
  gap: 15px;
  row-gap: 0;
  margin-bottom: 10px;
  flex-wrap: wrap;
  &__item {
    width: 100px;
  }

  @media (max-width: 400px) {
    gap: 10px;
  }
}
</style>