<template>
  <div :class="[{purpose: this.data?.body?.purpose}]">
    <q-form @submit="this.onSubmit" class="form">
      <div class="form__item">
        <CustomCheckbox
          :binary="true"
          label="Оплачено"
          :value="this.formData.is_payed"
          @change="(value) => onChange('is_payed', value)"
        />
      </div>
      <div class="form__item">
        <CustomSelect
          :options="this.costsTypes"
          label="Тип платежа"
          :required="true"
          optionValueKey="remont_costs_type_code"
          optionLabelKey="remont_costs_type_name"
          :value="this.formData.remont_costs_type_code || ''"
          @change="(value) => onChange('remont_costs_type_code', value)"
        />
      </div>
      <div
        class="form__item"
        v-if="this.formData.remont_costs_type_code === 'IS_TO_CONTRACTOR'"
      >
        <CustomSelect
          :options="this.contractors"
          label="Контрагент"
          :required="true"
          optionValueKey="legal_name"
          optionLabelKey="legal_name"
          :value="this.formData.contragent || ''"
          @change="(value, row) => onChange('contragent', value, 'contractor_id', row?.contractor_id)"
        />
      </div>
      <div class="form__item">
        <CustomDate
          :fullWidth="true"
          :required="true"
          placeholder="Дата платежа"
          :value="this.formData.payment_date"
          @change_date="(value) => onChange('payment_date', value)"
        />
      </div>
      <div class="form__item">
        <CustomInputNumber
          label="Сумма транша"
          :required="true"
          type="number"
          :value="this.formData.payment_amount"
          @change_input="(value) => onChange('payment_amount', value)"
        />
      </div>
      <div class="form__item">
        <CustomInput
          label="Комментарии"
          type="textarea"
          :required="true"
          :value="this.formData.comments"
          @change_input="(value) => onChange('comments', value)"
        />
      </div>
      <CustomModalFooter :btn-loading="this.loading" />
    </q-form>
  </div>
</template>
<script>

import CustomDate from "@/components/form/CustomDate";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import CustomInput from "@/components/form/CustomInput";
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomInputNumber from "@/components/form/CustomInputNumber";
import CustomSelect from "@/components/form/CustomSelect";
import { appActionTypes } from "@/services/store/modules/app_module/app_mutation_types";

export default {
  name: "add-cost",
  components: {CustomSelect, CustomInputNumber, CustomDate, CustomInput, CustomModalFooter, CustomCheckbox},
  props: ['data'],
  data() {
    return {
      loading: false,
      formData: {
        is_payed: this.data?.body?.is_payed || 0,
        payment_amount: this.data?.body?.payment_amount || '',
        contragent: this.data?.body?.contragent || '',
        contractor_id: this.data?.body?.contractor_id || '',
        remont_costs_type_code: this.data?.body?.remont_costs_type_code || '',
        payment_date: this.data?.body?.payment_date || '',
        comments: this.data?.body?.comments || ''
      }
    }
  },
  methods: {
    onChange(key, value, key2, value2) {
      this.formData[key] = value
      if(!key2) return
      this.formData[key2] = value2
    },
    async onSubmit() {
      let contractor_id
      if(this.formData.remont_costs_type_code === 'IS_TO_CONTRACTOR' && this.formData.contragent && !this.formData.contractor_id)
        contractor_id = this.contractors?.find(item => item.legal_name === this.formData.contragent)?.contractor_id
      if(contractor_id)
        this.formData.contractor_id = contractor_id
      this.loading = true
      await this.data?.onSubmit(this.formData)
      this.loading = false
    },
  },
  computed: {
    costsTypes() {
      return this.$store.state.app.costsTypes;
    },
    contractors() {
      return this.$store.state.app.contractors;
    },
  },
  mounted() {
    this.$store.dispatch(appActionTypes.getCommonContractors)
  }
}
</script>

<style scoped lang="scss">
.form {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  gap: 20px;

  @media (max-width: 428px) {
    min-width: 100%;
  }
}
</style>
