<template>
  <div :class="['loader__wrapper', {loader__wrapper_small: this.small}]">
    <q-circular-progress
      indeterminate
      rounded
      color="grey-5"
      :size="`${this.small ? 16 : 25}px`"
      :class="{'q-ma-md': !this.small}"
    />
  </div>
</template>

<script>
export default {
  name: "CustomLoader",
  props: {
    small: Boolean
  }
};
</script>

<style scoped lang="scss">
.loader {
  &__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &_small {
      display: block;
    }
  }
}
</style>
