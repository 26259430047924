<template>
  <CustomModalFooter :btn-loading="this.loading" :submit-btn-text="this.data?.btnText || 'Подтвердить'" :on-submit="this.onSubmit" />
</template>

<script>
import { appActions } from "@/services/store/modules/app_module/app_actions.js";
import CustomModalFooter from "@/components/modal/modals/modal-footer";

export default {
  name: 'ConfirmModal',
  props: ['data'],
  data() {
    return {
      loading: false
    }
  },
  components: {CustomModalFooter},
  computed: {
    data() {
      return this.$store.state.app.modal.data;
    },
  },
  methods: {
    closePopup() {
      this.$store.commit(appActions.closeModal());
    },
    async onSubmit() {
      this.loading = true
      this.data?.onSubmit && await this.data.onSubmit()
      this.loading = false
    }
  },
};
</script>

<style scoped lang="scss">
.title {
  margin-bottom: 15px;
  text-align: center;
}
</style>
