<template>
  <ChangeMaterialRequestsContent :header-height="this.headerHeight" :req-type="changeMaterialsReqTypes.PRICE" :columns="columns" title="Заявки на изменение цены материалов" :data="this.data" :loading="this.loading" :change-data="changeData" />
</template>

<script>
import ChangeMaterialRequestsContent from "./ChangeMaterialRequestsContent"
import {changeMaterialsReqTypes, reqPriceTableColumns} from "./services"

export default {
  name: "ChangeMaterialRequestsPrice",
  components: {ChangeMaterialRequestsContent},
  props: ["data", "changeData", "loading", "headerHeight"],
  data() {
    return {
      columns: reqPriceTableColumns,
      changeMaterialsReqTypes
    }
  },
}
</script>

<style scoped lang="scss">
</style>