import {orgFilesAPI} from "@/views/handbook/documents/api";

export const getOrgFiles = async () => {
  try {
    const res = await orgFilesAPI.getList()
    return res?.data
  } catch (e) {
  }
}
export const addOrgFile = async (body = {}) => {
  try {
    const res = await orgFilesAPI.addFile(body)
    return res?.data
  } catch (e) {
  }
}
export const deleteOrgFile = async (org_file_id) => {
  try {
    const res = await orgFilesAPI.deleteFile(org_file_id)
    return res?.data
  } catch (e) {
  }
}

export const orgFilesColumns = [
  {
    name: "org_file_name",
    label: "Название",
    align: "left",
  },
  {
    name: "org_file_extension",
    label: "Расширение",
    align: "left",
  },
  {
    name: "download",
    label: "",
    align: "left",
  },
]