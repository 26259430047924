import {remontSmetaAPI} from "@/views/ClientRequests/remontSmeta/api";
import {numberWithCommas} from "@/services";

const blockNames = {
  OA__RemontFormSebesSmetaBlock: "remontSmetaBlock",
}
export const getRemontSmetaData = async (client_request_id) => {
  try {
    const res = await remontSmetaAPI.getList(client_request_id)
    const data = res?.data
    if (!data?.length) return
    return data?.map(item => ({...item, component: blockNames[item.grant_code]}))
  } catch (e) {
  }
}
export const remontSmetaColumns = [
  {
    name: "room_name",
    label: "Комната",
    field: "room_name",
    align: "left",
  },
  {
    name: "work_set_name",
    label: "Конструктив",
    field: "work_set_name",
    align: "left",
  },
  {
    name: "material_name",
    label: "Материал",
    field: "material_name",
    align: "left",
  },
  {
    name: "material_type_name",
    label: "Тип материала",
    field: "material_type_name",
    align: "left",
  },
  {
    name: "work_name",
    label: "Работа",
    field: "work_name",
    align: "left",
  },
  {
    name: "consumption",
    label: "Расход норма",
    field: "consumption",
    align: "right",
    format: (val) => numberWithCommas(val),
  },
  {
    name: "cnt",
    label: "Расход",
    field: "cnt",
    align: "right",
    format: (val) => numberWithCommas(val),
  },
  {
    name: "formula",
    label: "Формула",
    field: "formula",
    align: "right",
    headerStyle: "width: 80px",
    style: "width: 100px",
  },
  {
    name: "formula_result",
    label: "Результат формулы",
    field: "formula_result",
    align: "left",
  },
]
