import instance from "@/services/api";

export const reportGeneratorAPI = {
  async getReports() {
    return await instance().get('/report_generator/reports/read/').then(res => res?.data)
  },
  async getSelectedReport(report_id, parameters = {}, cancelToken) {
    return await instance(true, null, {cancelToken}).post(`/report_generator/reports/${report_id}/read/result/`, {parameters}).then(res => res?.data)
  },
  async getReportFilters(report_id) {
    return await instance().get(`/report_generator/reports/${report_id}/read/parameters/`).then(res => res?.data)
  },
  async uploadExcel(report_id, parameters = {}, cancelToken) {
    return await instance(true, null, {cancelToken}).post(`/report_generator/reports/${report_id}/excel/`,
      {parameters}, {responseType: "arraybuffer"}).then(res => res?.data)
  },
}