import {gradeTimelineStepAPI} from "@/components/modal/modals/grade-timeline-step/api";
import {addStepToRemont, getStepNotIncludedRequests} from "@/views/timeline/services";

export const gradeTimelineTabs = {
  conditions: 'conditions',
  include_conditions: 'include_conditions',
  actions: 'actions',
}

export const getGradeTimelineCount = async (grade_step_id) => {
  try {
    return await gradeTimelineStepAPI.getGradeTimelineCount(grade_step_id)
  } catch (e) {
  }
}
export const getGradeTimelineConditions = async (grade_step_id) => {
  try {
    const res = await gradeTimelineStepAPI.getGradeTimelineConditions(grade_step_id)
    const {grade_step_conditions} = res
    return {conditions: {data: grade_step_conditions}}
  } catch (e) {
  }
}
export const getGradeTimelineIncludeConditions = async (grade_step_id) => {
  try {
    const res = await gradeTimelineStepAPI.getGradeTimelineIncludeConditions(grade_step_id)
    const {grade_step_include_conditions} = res
    return {include_conditions: {data: grade_step_include_conditions}}
  } catch (e) {
  }
}
export const getGradeTimelineActions = async (grade_step_id) => {
  try {
    const res = await gradeTimelineStepAPI.getGradeTimelineActions(grade_step_id)
    const {grade_step_activities} = res
    return {actions: {data: grade_step_activities}}
  } catch (e) {
  }
}

export const getNotIncludedRequests = async (grade_step_id, step_activity_id) => {
  const body = {step_activity_id}
  try {
    const res = await gradeTimelineStepAPI.getNotIncludedRequests(grade_step_id, body)
    return res?.data?.map(item => ({
      ...item,
      label: `ID заявки: ${item.client_request_id || ''}, ID ремонта: ${item.remont_id || ''}`
    }))
  } catch (e) {
  }
}
export const getConditionNotIncludedRequests = async (grade_step_id, step_condition_id) => {
  const body = {step_condition_id}
  try {
    const res = await gradeTimelineStepAPI.getConditionNotIncludedRequests(grade_step_id, body)
    return res?.data?.map(item => ({
      ...item,
      label: `ID заявки: ${item.client_request_id || ''}, ID ремонта: ${item.remont_id || ''}`
    }))
  } catch (e) {
  }
}
export const addActionForRemont = async (grade_step_id, client_request_ids, step_activity_id) => {
  const body = {step_activity_id, client_request_ids}
  try {
    const res = await gradeTimelineStepAPI.addActionForRemont(grade_step_id, body)
    const {grade_step_activities} = res
    return {actions: grade_step_activities}
  } catch (e) {
  }
}
export const addConditionForRemont = async (grade_step_id, client_request_ids, step_condition_id) => {
  const body = {step_condition_id, client_request_ids}
  try {
    const res = await gradeTimelineStepAPI.addConditionForRemont(grade_step_id, body)
    const {grade_step_conditions} = res
    return {conditions: grade_step_conditions}
  } catch (e) {
  }
}

export const notIncludedRequestFunc = {
  step: getStepNotIncludedRequests,
  action: getNotIncludedRequests,
  condition: getConditionNotIncludedRequests
}
export const addGradeStepForRemontFunc = {
  step: addStepToRemont,
  action: addActionForRemont,
  condition: addConditionForRemont
}
export const gerGradeTimelineStepData = async (grade_step_id, activeTab) => {
  switch (activeTab) {
    case gradeTimelineTabs.conditions: {
      return await getGradeTimelineConditions(grade_step_id)
    }
    case gradeTimelineTabs.actions: {
      return await getGradeTimelineActions(grade_step_id)
    }
    case gradeTimelineTabs.include_conditions: {
      return await getGradeTimelineIncludeConditions(grade_step_id)
    }
  }
}

export const conditionsTableColumns = [
  {
    name: "type_name",
    label: "Название",
    field: "type_name",
    sortable: true,
    align: 'left',
  },
  {
    name: "param_value",
    label: "Значение",
    field: "param_value",
    sortable: true,
    align: 'center',
  },
  {
    name: "param_json",
    label: "Параметры",
    field: "param_json",
    align: 'left',
  },
  {
    name: "comment",
    label: "Комментарий",
    field: "comment",
    align: 'left',
  },
]

const copyConditionsTableColumns = [...conditionsTableColumns]
copyConditionsTableColumns.splice(1, 0, {
  name: "is_active",
  label: "Активен",
  field: "is_active",
  sortable: true,
  align: 'center',
})

export const timelineConditionsTableColumns = [
  ...conditionsTableColumns,
  {
    name: "action",
    label: "",
    field: "action",
    align: 'center',
  }
]

export const includeConditionsTableColumns = copyConditionsTableColumns
export const gradeActionsTableColumns = [
  {
    name: "activity_name",
    label: "Название",
    field: "activity_name",
    sortable: true,
    align: 'left',
  },
  {
    name: "type_name",
    label: "Тип действия",
    field: "type_name",
    align: 'left',
  },
  {
    name: "param_value_json",
    label: "Значение параметров",
    field: "param_value_json",
    sortable: true,
    align: 'left',
  },
  {
    name: "param_json",
    label: "Параметры",
    field: "param_json",
    sortable: true,
    align: 'left',
  },
  {
    name: "is_active",
    label: "Активен",
    field: "is_active",
    align: 'center',
  },
  {
    name: "grade_list",
    label: "Список грейдов",
    field: "grade_list",
    align: 'left',
  },
  {
    name: "comment",
    label: "Комментарий",
    field: "comment",
    align: 'left',
  },
  {
    name: "action",
    label: "",
    field: "action",
    align: 'center',
  },
]
export const gradeAddActionsTableColumns = [
  {
    name: "client_request_id",
    label: "ID заявки / ID ремонта",
    align: 'center',
  },
  {
    name: "address",
    label: "Адрес",
    align: 'left',
  },
  {
    name: "current_step",
    label: "Текущий шаг",
    align: 'left',
  },
]