import cookiesService from "@/services/cookiesService";
import axios from "axios";
import {API_URL} from "@/constants";
import {handleError} from "./helpers";
import {showErrorToast} from "@/services";

const cookiesServiceInstance = cookiesService.getService();
export let source;
const instance = (authorization = true, url = null, paramsProps = {}) => {

  let params = {throwError: false, showToast: true}
  if (paramsProps) params = {...params, ...paramsProps}

  const CancelToken = axios.CancelToken;
  source = CancelToken.source();

  const axiosInstance = axios.create({
    baseURL: url || API_URL,
    cancelToken: paramsProps.cancelToken || source.token,
  });

  if (authorization) {
    axiosInstance.interceptors.request.use(
      (config) => {
        const jwtToken = cookiesServiceInstance.getAccessToken();
        if (!!jwtToken) config.headers.Authorization = `Bearer ${jwtToken}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (params?.showToast && !axios.isCancel(error)) {
        const message = error?.response?.data?.error;
        const defaultMessage = error.response?.status
          ? `${error.response?.status}, ${error?.response?.statusText}`
          : "Ошибка";
        showErrorToast(message || defaultMessage)
      }
      handleError(error);
      return params?.throwError
        ? Promise.reject(error)
        : Promise.reject("Что-то пошло не так!");
    }
  );
  return axiosInstance;
};

export default instance;
