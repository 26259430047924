<template>
  <Block title="Банковские реквизиты">
    <template #actions>
    </template>
    <div class="requisites__content">
      <div>
        <BlockItem title="ИИК">{{ this.data?.account_iik }}</BlockItem>
        <BlockItem title="БИК">{{ this.data?.account_bik }}</BlockItem>
        <BlockItem title="Наименование банка">{{ this.data?.account_bank_name }}</BlockItem>
        <BlockItem title="БИН">{{ this.data?.account_bin }}</BlockItem>
      </div>
    </div>
  </Block>
</template>

<script>
import Block from "@/components/common/Block";
import BlockItem from "@/components/common/BlockItem";

export default {
  name: "bankRequisitesBlock",
  props: ["data"],
  components: {Block, BlockItem},
}
</script>

<style scoped lang="scss">
.requisites {
  &__content {
    display: grid;
    gap: 25px;
    grid-template-columns: 12fr;

    & > div {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }
}
</style>