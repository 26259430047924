<template>
  <custom-table
      :customBody="true" :hide-footer-title="true" :columns="this.columns" :rowsData="this.data?.data || []">
    <template #customBody="props">
      <q-tr
          :props="props"
      >
        <q-td v-for="col in props.cols" :key="col.name" :props="props">
          <div v-if="col.name === 'param_value'" class="conditions__paramValue">{{ col.value }}</div>
          <div v-else-if="col.name === 'is_active'"><i
              :class="`material-icons timeline__check timeline__check_${col.value ? 'completed' : 'close'}`">{{
              col.value ? 'check' : 'close'
            }}</i></div>
          <div v-else-if="col.name === 'param_json'">
            <p v-for="item in Object.keys(this.json(col.value))?.sort()" class="conditions__params_item">
              {{ `${item}: ` }} <span>{{ this.json(col.value)[item] }}</span>
            </p>
          </div>
          <div v-else-if="col.name === 'action'">
            <q-btn size="sm" padding="xs" color="primary" no-wrap
                   @click="() => this.handleAddConditionForRemont(props.row.step_condition_id, props.row.type_name)">
              Добавить в ремонт
            </q-btn>
          </div>
          <span v-else>
            {{ col.value }}
          </span>
        </q-td>
      </q-tr>
    </template>
  </custom-table>
  <q-dialog v-model="showPopup">
    <AddGradeStepToRemont type="condition" :title="this.popupTitle" :reset-data="this.resetData"
                          :id="this.stepConditionId"
                          :grade_step_id="this.grade_step_id" :on-submit="this.onAddSubmit"/>
  </q-dialog>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import {
  gradeTimelineTabs, includeConditionsTableColumns,
  timelineConditionsTableColumns
} from "@/components/modal/modals/grade-timeline-step/services";
import AddGradeStepToRemont from "@/components/modal/modals/grade-timeline-step/add-grade-step-to-remont";

export default {
  name: "grade-timeline-conditions",
  components: {AddGradeStepToRemont, CustomTable},
  props: ['data', 'type', 'grade_step_id'],
  data() {
    return {
      showPopup: false,
      popupTitle: '',
      stepConditionId: null,
      columns: this.type === gradeTimelineTabs.conditions ? timelineConditionsTableColumns : includeConditionsTableColumns
    }
  },
  methods: {
    json(value) {
      try {
        if (!value) return {}
        return JSON.parse(value)
      } catch (e) {
        return {}
      }
    },
    resetData() {
      this.stepConditionId = null
      this.popupTitle = ''
    },
    async handleAddConditionForRemont(step_condition_id, type_name) {
      this.resetData()
      this.showPopup = true
      this.stepConditionId = step_condition_id
      this.popupTitle = type_name
    },
    async onAddSubmit(res) {
      this.rowsData = res?.conditions || []
      this.resetData()
      this.showPopup = false
    }
  }
}
</script>

<style scoped lang="scss">
.conditions {
  &__params {
    &_item {
      margin: 0;
      font-size: 13px;
      font-weight: bold;

      span {
        font-weight: normal;
      }
    }
  }

  &__paramValue {
    width: 83px;
  }
}
</style>