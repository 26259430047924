import { downloadExcel } from "@/services";
import { gprStageAPI } from "@/views/ClientRequests/gprStage/api";

const gprStageBlockNames = {
  OA__RemontFormGPRStageBlock: "gprStageBlock",
};

export const refactorGprData = (dataArr) => {
  try {
    const sortedData = [];
    dataArr?.forEach((item) => {
      if (!item?.works?.length) sortedData.push(item);
      else
        item.works?.forEach((work, idx) => {
          const newDataItem = {
            ...work,
          };
          if (idx === 0) newDataItem.stage_name = item.stage_name;
          sortedData.push(newDataItem);
        });
    });
    return sortedData;
  } catch (e) {
    return [];
  }
};
export const getGprStageData = async (client_request_id) => {
  try {
    const res = await gprStageAPI.getList(client_request_id);
    const data = res?.data;
    if (!data?.length) return
    return data?.map((item) => {
      if (item.grant_code === "OA__RemontFormGPRStageBlock") {
        const dataArr = item.data;
        const sortedData = refactorGprData(dataArr);
        return {
          ...item,
          data: sortedData,
          component: gprStageBlockNames[item.grant_code],
        };
      }
      return { ...item, component: gprStageBlockNames[item.grant_code] };
    });
  } catch (e) {}
};
export const recountGprDates = async (client_request_id) => {
  try {
    const res = await gprStageAPI.recountGprDates(client_request_id);
    if (!res?.data) return;
    return refactorGprData(res?.data);
  } catch (e) {}
};
export const uploadExcel = async (client_request_id) => {
  try {
    const res = await gprStageAPI.uploadExcel(client_request_id);
    downloadExcel(res, 'ГПР');
  } catch (e) {}
};
export const gprStageColumns = [
  {
    name: "stage_name",
    label: "Этап",
    field: "stage_name",
    align: "left",
  },
  {
    name: "work_name",
    label: "Работа",
    field: "work_name",
    align: "left",
  },
  {
    name: "date",
    label: "Период проведения работ",
    field: "date",
    align: "center",
  },
  {
    name: "is_edit_gpr",
    label: "Может ли редактировать бригадир",
    field: "is_edit_gpr",
    align: "center",
  },
  {
    name: "comments",
    label: "Комментарии",
    field: "comments",
    align: "left",
  },
];
