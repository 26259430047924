import instance from "@/services/api";

export const remontWarrantyRequestsAPI = {
  async searchRemonts(body) {
    return await instance().post(`/common/remont/search/`, body).then(res => res?.data)
  },
  async getManagers() {
    return await instance().get(`/common/employees/grt_managers/read/`).then(res => res?.data)
  },
  async getRemakeTypes() {
    return await instance().get(`/common/grt_remake_types/read/`).then(res => res?.data)
  },
  async getDefectActDocuments(grt_request_id) {
    return await instance().get(`/client_request/grt_requests/${grt_request_id}/documents/defect/read/`).then(res => res?.data)
  },
  async getAppActDocuments(grt_request_id) {
    return await instance().get(`/client_request/grt_requests/${grt_request_id}/documents/app/read/`).then(res => res?.data)
  },
  async getList(client_request_id) {
    return await instance().get(`/client_request/${client_request_id}/grt_requests/read/`).then(res => res?.data)
  },
  async getAllRequests(params) {
    return await instance().get(`/client_request/grt_requests/read/`, {params}).then(res => res?.data)
  },
  async getDetail(client_request_id, grt_request_id) {
    return await instance().get(`/client_request/${client_request_id}/grt_requests/${grt_request_id}/info/`).then(res => res?.data)
  },
  async changeWarrantyStatus(client_request_id, grt_request_id, body) {
    return await instance().put(`/client_request/${client_request_id}/grt_requests/${grt_request_id}/status/update/`, body).then(res => res?.data)
  },
  async changeWarrantyStatusAll(grt_request_id, body, params) {
    return await instance().put(`/client_request/grt_requests/${grt_request_id}/status/update/`, body, {params}).then(res => res?.data)
  },
  async getDocuments(client_request_id, grt_request_id) {
    return await instance().get(`/client_request/${client_request_id}/grt_requests/${grt_request_id}/documents/client/read/`).then(res => res?.data)
  },
  async getDocumentsAll(grt_request_id) {
    return await instance().get(`/client_request/grt_requests/${grt_request_id}/documents/client/read/`).then(res => res?.data)
  },
  async deleteWarrantyDocument(client_request_id, grt_request_id, grt_photo_id) {
    return await instance().delete(`/client_request/${client_request_id}/grt_requests/${grt_request_id}/documents/${grt_photo_id}/delete/`).then(res => res?.data)
  },
  async deleteWarrantyDocumentAll(grt_request_id, grt_photo_id, params) {
    return await instance().delete(`/client_request/grt_requests/${grt_request_id}/documents/${grt_photo_id}/delete/`, {params}).then(res => res?.data)
  },
  async changeContractorAll(grt_request_id, body, params) {
    return await instance().put(`/client_request/grt_requests/${grt_request_id}/contractor/set/`, body, {params}).then(res => res?.data)
  },
  async changeContractor(client_request_id, grt_request_id, body) {
    return await instance().put(`/client_request/${client_request_id}/grt_requests/${grt_request_id}/contractor/set/`, body).then(res => res?.data)
  },
  async changeManagerAll(grt_request_id, body, params) {
    return await instance().put(`/client_request/grt_requests/${grt_request_id}/manager/set/`, body, {params}).then(res => res?.data)
  },
  async changeManager(client_request_id, grt_request_id, body) {
    return await instance().put(`/client_request/${client_request_id}/grt_requests/${grt_request_id}/manager/set/`, body).then(res => res?.data)
  },
  async changeDefectActAll(grt_request_id, body, params) {
    return await instance().put(`/client_request/grt_requests/${grt_request_id}/documents/defect/set/`, body, {
      headers: {
        "Content-Type": "multipart/form-data"
      }, params
    }).then(res => res?.data)
  },
  async changeDefectAct(client_request_id, grt_request_id, body) {
    return await instance().put(`/client_request/${client_request_id}/grt_requests/${grt_request_id}/documents/defect/set/`, body, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then(res => res?.data)
  },
  async changeAppActAll(grt_request_id, body, params) {
    return await instance().put(`/client_request/grt_requests/${grt_request_id}/documents/app/set/`, body, {
      headers: {
        "Content-Type": "multipart/form-data"
      }, params
    }).then(res => res?.data)
  },
  async changeAppAct(client_request_id, grt_request_id, body) {
    return await instance().put(`/client_request/${client_request_id}/grt_requests/${grt_request_id}/documents/app/set/`, body, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then(res => res?.data)
  },
  async changeWarrantyRequestAll(grt_request_id, body, params) {
    return await instance().put(`/client_request/grt_requests/${grt_request_id}/update/`, body, {
      headers: {
        "Content-Type": "multipart/form-data"
      }, params
    }).then(res => res?.data)
  },
  async changeWarrantyRequest(client_request_id, grt_request_id, body) {
    return await instance().put(`/client_request/${client_request_id}/grt_requests/${grt_request_id}/update/`, body, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then(res => res?.data)
  },
  async addWarrantyRequestAll(body, params) {
    return await instance().post(`/client_request/grt_requests/add/`, body, {
      headers: {
        "Content-Type": "multipart/form-data"
      }, params
    }).then(res => res?.data)
  },
  async addWarrantyRequest(client_request_id, body) {
    return await instance().post(`/client_request/${client_request_id}/grt_requests/add/`, body, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then(res => res?.data)
  },
  async deleteWarrantyRequestAll(grt_request_id, params) {
    return await instance().delete(`/client_request/grt_requests/${grt_request_id}/delete/`, {params}).then(res => res?.data)
  },
  async deleteWarrantyRequest(client_request_id, grt_request_id) {
    return await instance().delete(`/client_request/${client_request_id}/grt_requests/${grt_request_id}/delete/`).then(res => res?.data)
  },
}