<template>
  <q-linear-progress indeterminate v-if="this.loading"/>
  <div v-if="this.userData?.token && !!this.client_request_id">
    <iframe width="100%" height="700px" class="wrapper" @load="this.onLoad"
            :src="`${MAIN_SYSTEM_URL}/system/login-url/token/${this.userData?.token}/?redirect_page=/dict/ds-list/view_mode/frame/client_request_id/${this.client_request_id}`"></iframe>
  </div>
</template>

<script>
import {MAIN_SYSTEM_URL} from "@/constants"

export default {
  name: "DSLegacy",
  props: ["client_request_id"],
  data() {
    return {
      MAIN_SYSTEM_URL,
      loading: true
    }
  },
  computed: {
    userData() {
      return this.$store.state.app?.userData
    },
  },
  methods: {
    onLoad() {
      this.loading = false
    }
  }
}
</script>

<style scoped lang="scss">
  .wrapper {
    border: none !important;
    height: calc(100vh - 56px) !important;
  }
</style>