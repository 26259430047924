<template>
  <div class="wrapper">
    <q-tabs v-model="tab" class="text-teal">
      <q-tab v-for="item in this.tabs" :name="item.name" :label="item.label"/>
    </q-tabs>

    <CustomLoader v-if="this.loading"/>
    <q-tab-panels v-else v-model="tab">
      <q-tab-panel class="tabPanel" :name="this.remontTimelineTabs.conditions">
        <remont-timeline-conditions
            :updateData="updateTabData"
            :remont_step_id="this.data.remont_step_id"
            :data="this.tabsData[this.remontTimelineTabs.conditions]"
        />
      </q-tab-panel>
      <q-tab-panel class="tabPanel" :name="this.remontTimelineTabs.logs">
        <remont-timeline-logs
            :data="this.tabsData[this.remontTimelineTabs.logs]"
        />
      </q-tab-panel>
      <q-tab-panel class="tabPanel" :name="this.remontTimelineTabs.actions">
        <remont-timeline-actions
            :updateData="updateTabData"
            :remont_step_id="this.data.remont_step_id"
            :data="this.tabsData[this.remontTimelineTabs.actions]"
        />
      </q-tab-panel>
    </q-tab-panels>
  </div>
</template>

<script>
import {
  gerRemontTimelineStepData,
  getRemontTimelineCount,
  remontTimelineTabs
} from "@/components/modal/modals/remont-timeline-step/services";
import RemontTimelineConditions from "@/components/modal/modals/remont-timeline-step/remont-timeline-conditions";
import RemontTimelineActions from "@/components/modal/modals/remont-timeline-step/remont-timeline-actions";
import CustomLoader from "@/components/common/CustomLoader";
import RemontTimelineLogs from "@/components/modal/modals/remont-timeline-step/remont-timeline-logs";

export default {
  name: "RemontTimelineStep",
  components: {RemontTimelineLogs, RemontTimelineConditions, CustomLoader, RemontTimelineActions},
  props: ['data'],
  data() {
    return {
      loading: false,
      count: {},
      remontTimelineTabs,
      tab: remontTimelineTabs.conditions,
      tabsData: {}
    }
  },
  computed: {
    tabs() {
      const tabsList = [
        {name: remontTimelineTabs.conditions, label: "Условия"},
        {name: remontTimelineTabs.logs, label: "История"},
        {name: remontTimelineTabs.actions, label: "Действия"},
      ]
      return tabsList.map(item => {
        if (Object.keys(this.count)?.length && !!this.count[item.name])
          return {...item, label: `${item.label} (${this.count[item.name]})`}
        return item
      })
    }
  },
  methods: {
    async getData(tab) {
      this.loading = true
      const res = await gerRemontTimelineStepData(this.data.remont_step_id, tab)
      this.loading = false
      if (!res) return
      this.tabsData = {...this.tabsData, ...res}
    },
    async getCounts() {
      const res = await getRemontTimelineCount(this.data?.remont_step_id)
      if (!res) return
      this.count = res
    },
    async updateTabData(key, data) {
      this.tabsData = {...this.tabsData, [key]: {data}}
      await this.getCounts()
      if(this.data?.updateData)
        await this.data.updateData()
    }
  },
  watch: {
    async tab(newValue) {
      await this.getData(newValue)
    }
  },
  mounted() {
    this.getData(this.tab)
    this.getCounts()
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  width: 800px;
  min-height: 300px;

  @media (max-width: 991px) {
    width: 100%;
  }
}
.tabPanel {
  @media (max-width: 400px) {
    padding: 15px 5px 15px 5px;
  }
}
</style>
