<template>
  <custom-table :hide-footer-title="true" :columns="this.columns" :rowsData="this.data?.data" />
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import {timelineLogsTableColumns} from "@/components/modal/modals/remont-timeline-step/services";

export default {
  name: "remont-timeline-logs",
  components: {CustomTable},
  props: ['data'],
  data() {
    return {
      columns: timelineLogsTableColumns
    }
  }
}
</script>

<style scoped>

</style>