<template>
  <div class="warrantyRequests__header">
    <q-btn color="primary" size="sm" @click="this.handleAddWarrantyRequest">Добавить заявку</q-btn>
  </div>
  <CustomTable
      title="Заявки для гарантии"
      :rowsData="this.data"
      :columns="this.isAll ? allRemontsWarrantyRequestsColumns : remontWarrantyRequestsColumns"
      :loading="isFetching"
      :custom-body="true"
  >
    <template #customBody="props">
      <q-tr :style="props.row.is_expired === 1 ? 'background-color: rgba(255, 24, 0, 0.15) !important' : ''">
        <custom-table-cell v-if="this.isAll" align="center">
          <Clickable router-link :href="`/requests/${props.row.client_request_id}`">
            {{ props.row.remont_id || '' }}
          </Clickable>
        </custom-table-cell>
        <custom-table-cell align="left">
          Дата:
          <div class="warrantyRequests__info_value">{{ props.row.grt_date_create }}</div>
          {{ !!props.row?.employee_id && !props.row?.client_id ? 'Сотрудник' : 'Клиент' }}:
          <div class="warrantyRequests__info_value fio">{{ props.row.grt_fio }}</div>
          <div class="warrantyRequests__info_span" v-if="props.row.is_back_to_work !== 0">Открыта заново</div>
          <div class="warrantyRequests__info_span default" v-if="props.row.unilateral_act === 1">Односторонний акт:
            <CheckOrClose only-true value no-padding/>
          </div>
        </custom-table-cell>
        <custom-table-cell align="left" style="width: 300px">
          <div><span class="warrantyRequests__info_value">{{
              props.row.is_new_client === 0 ? 'ФИО' : 'Новый собственник'
            }}:</span> {{ props.row.client_name }}
            <q-icon v-if="props.row.is_new_client !== 0"
                    @click="() => this.showApartmentOwnersHistory(props.row.client_request_id)"
                    class="warrantyRequests__icon_group" name="groups"/>
          </div>
          <div><span class="warrantyRequests__info_value">Контакты:</span>
            {{ this.formatPhoneNumber(props.row.phone_number) }}
          </div>
          <div><span class="warrantyRequests__info_value">ЖК, №кв:</span>
            {{ `${props.row.resident_name}, №${props.row.flat_num}` }}
          </div>
          <div><span class="warrantyRequests__info_value">Пакет:</span> {{ props.row.preset_name }}</div>
        </custom-table-cell>
        <custom-table-cell align="left">
          <div><span class="warrantyRequests__info_value">Дата:</span> {{ props.row.grt_status_date }}</div>
          <div><span class="warrantyRequests__info_value">Статус:</span> <br/> <span class="warrantyRequests__info_mark"
                                                                                     :style="`background-color: ${props.row.grt_status_colour};color: ${props.row.grt_status_code === '200' ? '#000' : '#f3f3f3'}`">{{
              props.row.grt_status_name
            }}</span>
            <q-icon class="warrantyRequests__icon_exchange"
                    @click="() => this.handleChangeWarrantyStatus(props.row.client_request_id, props.row.grt_request_id)"
                    name="fa fa-exchange"/>
          </div>
        </custom-table-cell>
        <custom-table-cell align="left">
          <div class="warrantyRequests__info_value margin">Типы замечания:</div>
          <div class="warrantyRequests__info_mark gray">{{ props.row.grt_remake_types }}</div>
          <div class="warrantyRequests__info_value margin">Комментарии:</div>
          <div class="warrantyRequests__info_mark gray">{{ props.row.comments }}</div>
          <div v-if="props.row.is_big_request === 1">
            <div class="warrantyRequests__info_wrapper">
              <q-icon name="home" class="warrantyRequests__info_wrapperIcon"/>
              <div class="warrantyRequests__info_value"> BIG, Номер заявки:</div>
            </div>
            <div class="warrantyRequests__info_mark gray">{{ props.row.big_request_num }}</div>
          </div>
          <div class="warrantyRequests__info_row"><span class="warrantyRequests__info_value">Документов:</span>
            {{ props.row.grt_documents }}
          </div>
          <Clickable v-if="!!Number(props.row.grt_documents)"
                     @click="() => this.handleOpenDocuments(props.row.client_request_id, props.row.grt_request_id)">
            Открыть
          </Clickable>
        </custom-table-cell>
        <custom-table-cell align="left">
          <div class="warrantyRequests__info_row high"><span class="warrantyRequests__info_value">До гарантии:</span>
            {{ props.row.contractor_remont_name }}
          </div>
          <div v-if="props.row.contractor_grt_name" class="warrantyRequests__info_row high"><span
              class="warrantyRequests__info_value">Во время гарантии:</span>
            {{ props.row.contractor_grt_name }}
          </div>
          <q-btn class="warrantyRequests__info_btn"
                 @click="() => this.handleChangeContractor(props.row.client_request_id, props.row.grt_request_id, props.row.contractor_grt_id)"
                 size="sm" padding="xs" color="green" no-wrap>Назначить подрядчика
          </q-btn>
          <div class="warrantyRequests__info_row high"><span
              class="warrantyRequests__info_value">Менеджер гарантии:</span> {{ props.row.grt_manager_fio }}
          </div>
          <q-btn class="warrantyRequests__info_btn" size="sm" padding="xs" color="primary" no-wrap
                 @click="() => this.handleChangeManager(props.row.client_request_id, props.row.grt_request_id, props.row.grt_manager_id)">
            Назначить менеджера
            ГО
          </q-btn>
        </custom-table-cell>
        <custom-table-cell align="left">
          <template v-if="props.row.defect_akt_num !== null">
            <div class="warrantyRequests__info_row"><span
                class="warrantyRequests__info_value">Номер:</span>{{ props.row.defect_akt_num }}
            </div>
            <div class="warrantyRequests__info_row"><span
                class="warrantyRequests__info_value">Дата:</span>{{ props.row.defect_akt_date }}
            </div>
          </template>
          <template v-if="props.row.grt_defect_documents !== null">
            <div class="warrantyRequests__info_row">
              <span class="warrantyRequests__info_value">Документы:</span>
              <p class="warrantyRequests__info_valueLink" v-html="props.row.grt_defect_documents"/>
            </div>
          </template>
          <q-btn @click="() => this.handleDefectAct(props.row.client_request_id, props.row.grt_request_id, props.row)"
                 class="warrantyRequests__info_btn" size="sm" padding="xs" color="primary" no-wrap>Дефектный акт
          </q-btn>
        </custom-table-cell>
        <custom-table-cell align="left">
          <template v-if="props.row.app_akt_num !== null">
            <div class="warrantyRequests__info_row"><span
                class="warrantyRequests__info_value">Номер:</span>{{ props.row.app_akt_num }}
            </div>
            <div class="warrantyRequests__info_row"><span
                class="warrantyRequests__info_value">Дата:</span>{{ props.row.app_akt_date }}
            </div>
          </template>
          <template v-if="props.row.grt_app_documents !== null">
            <div class="warrantyRequests__info_row">
              <span class="warrantyRequests__info_value">Документы:</span>
              <p class="warrantyRequests__info_valueLink" v-html="props.row.grt_app_documents"/>
            </div>
          </template>
          <q-btn @click="() => this.handleAppAct(props.row.client_request_id, props.row.grt_request_id, props.row)"
                 class="warrantyRequests__info_btn" size="sm" padding="xs" color="primary" no-wrap>Акт об устранении
          </q-btn>
        </custom-table-cell>
        <custom-table-cell align="center">
          <CustomIcon icon="edit"
                      @click="() => this.handleChangeWarrantyRequest(props.row.client_request_id, props.row.grt_request_id, props.row)"/>
        </custom-table-cell>
        <custom-table-cell align="center">
          <CustomIcon icon="trash" approve-mode
                      :approve="() => this.onDeleteWarrantyRequest(props.row.client_request_id, props.row.grt_request_id)"/>
        </custom-table-cell>
      </q-tr>
    </template>
  </CustomTable>
  <q-dialog v-model="showPopup">
    <q-card class="dialog">
      <q-linear-progress v-if="this.documentsFetching" indeterminate/>
      <div class="dialog_header">
        <h4 class="dialog_title">Документы</h4>
        <q-btn @click="() => this.resetData && this.resetData()"
               icon="close" flat round dense
               v-close-popup/>
      </div>
      <FileList :files="this.files" :on-open-gallery="this.openGallery" :on-delete-file-click="this.handleDeleteFile"
                file-url-prop="document_url" file-name-prop="document_name"/>
    </q-card>
  </q-dialog>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import CustomIcon from "@/components/common/CustomIcon";
import {formatPhoneNumber} from "@/services";
import {
  addWarrantyRequest,
  addWarrantyRequestAll,
  allRemontsWarrantyRequestsColumns,
  changeWarrantyAppAct,
  changeWarrantyAppActAll,
  changeWarrantyContractor,
  changeWarrantyContractorAll,
  changeWarrantyDefectAct,
  changeWarrantyDefectActAll,
  changeWarrantyManager,
  changeWarrantyManagerAll,
  changeWarrantyRequest,
  changeWarrantyRequestAll,
  changeWarrantyStatus,
  changeWarrantyStatusAll,
  deleteWarrantyDocument,
  deleteWarrantyDocumentAll,
  deleteWarrantyRequest,
  deleteWarrantyRequestAll,
  getWarrantyDocuments,
  getWarrantyDocumentsAll,
  remontWarrantyRequestsColumns
} from "@/views/ClientRequests/warrantyRequests/services";
import Clickable from "@/components/common/Clickable";
import CheckOrClose from "@/components/common/CheckOrClose";
import {appActions} from "@/services/store/modules/app_module/app_actions";
import {MODAL_NAMES} from "@/constants";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";
import FileList from "@/components/common/FileList";
import {getFileExt, isFileImg} from "@/_helpers";

export default {
  name: "WarrantyRequestsBlock",
  components: {FileList, CheckOrClose, Clickable, CustomIcon, CustomTable, CustomTableCell},
  props: ["client_request_id", "data", "isFetching", "isAll", "filters", "onChange"],
  data() {
    return {
      remontWarrantyRequestsColumns,
      allRemontsWarrantyRequestsColumns,
      files: [],
      showPopup: false,
      documentsFetching: false,
      clientRequestId: null,
      grtRequestId: null,
    }
  },
  methods: {
    formatPhoneNumber,
    getFileExt,
    isFileImg,
    showApartmentOwnersHistory(client_request_id) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.ApartmentOwnersHistory, {
        title: 'История собственников квартиры',
        client_request_id,
      }));
    },
    handleChangeWarrantyStatus(client_request_id, grt_request_id) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.ChangeWarrantyStatus, {
        title: 'Редактирование статуса',
        client_request_id,
        grt_request_id,
        onSubmit: body => this.onChangeWarrantyStatus(client_request_id, grt_request_id, body)
      }));
    },
    handleAddWarrantyRequest() {
      this.$store.commit(appActions.showModal(MODAL_NAMES.ChangeWarrantyRequest, {
        title: 'Создание заявки',
        body: {},
        addMode: true,
        isAll: this.isAll,
        onSubmit: this.onAddWarrantyRequest
      }));
    },
    handleChangeWarrantyRequest(client_request_id, grt_request_id, row) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.ChangeWarrantyRequest, {
        title: 'Редактирование заявки',
        client_request_id,
        grt_request_id,
        body: row,
        isAll: this.isAll,
        onSubmit: body => this.onChangeWarrantyRequest(client_request_id, grt_request_id, body)
      }));
    },
    handleChangeContractor(client_request_id, grt_request_id, contractor_id) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.ChangeWarrantyEmployee, {
        title: 'Редактирование подрядчика',
        type: 'contractor',
        body: {contractor_id},
        onSubmit: body => this.onChangeWarrantyContractor(client_request_id, grt_request_id, body)
      }));
    },
    handleChangeManager(client_request_id, grt_request_id, grt_manager_id) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.ChangeWarrantyEmployee, {
        title: 'Редактирование менеджера гарантии',
        type: 'manager',
        body: {grt_manager_id},
        onSubmit: body => this.onChangeWarrantyManager(client_request_id, grt_request_id, body)
      }));
    },
    handleDefectAct(client_request_id, grt_request_id, row) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.WarrantyAct, {
        title: 'Дефектный акт',
        type: 'defect',
        isAll: this.isAll,
        grt_request_id,
        client_request_id,
        body: row,
        updateData: this.onChange,
        onSubmit: body => this.onChangeWarrantyDefectAct(client_request_id, grt_request_id, body)
      }));
    },
    handleAppAct(client_request_id, grt_request_id, row) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.WarrantyAct, {
        title: 'Акт об устранении',
        isAll: this.isAll,
        type: 'app',
        grt_request_id,
        client_request_id,
        body: row,
        updateData: this.onChange,
        onSubmit: body => this.onChangeWarrantyAppAct(client_request_id, grt_request_id, body)
      }));
    },
    resetData() {
      this.documentsFetching = false
      this.showPopup = false
      this.clientRequestId = null
      this.grtRequestId = null
      this.files = []
    },
    openGallery(file) {
      const images = this.files?.filter(item => this.isFileImg(this.getFileExt(item.document_name)))
      const imgIndex = images.findIndex(item => item.grt_photo_id === file?.grt_photo_id)
      return {imgIndex, files: images}
    },
    handleOpenDocuments(client_request_id, grt_request_id) {
      this.clientRequestId = client_request_id
      this.grtRequestId = grt_request_id
      this.getDocuments(client_request_id, grt_request_id)
      this.showPopup = true
    },
    async getDocuments(client_request_id, grt_request_id) {
      this.documentsFetching = true
      const res = this.isAll ? await getWarrantyDocumentsAll(grt_request_id)
          : await getWarrantyDocuments(client_request_id, grt_request_id)
      this.documentsFetching = false
      this.files = res
    },
    async onAddWarrantyRequest(body) {
      const res = this.isAll ? await addWarrantyRequestAll(body, this.filters) : await addWarrantyRequest(this.client_request_id, body)
      if (!res) return
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
      if (this.onChange)
        this.onChange(res)
    },
    async onChangeWarrantyRequest(client_request_id, grt_request_id, body) {
      const res = this.isAll ? await changeWarrantyRequestAll(grt_request_id, body, this.filters) : await changeWarrantyRequest(client_request_id, grt_request_id, body)
      if (!res) return
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
      if (this.onChange)
        this.onChange(res)
    },
    async onDeleteWarrantyRequest(client_request_id, grt_request_id) {
      const res = this.isAll ? await deleteWarrantyRequestAll(grt_request_id, this.filters) : await deleteWarrantyRequest(client_request_id, grt_request_id)
      if (!res) return
      this.showSucceedSnackbar()
      if (this.onChange)
        this.onChange(res)
    },
    async onChangeWarrantyStatus(client_request_id, grt_request_id, body) {
      const res = this.isAll ? await changeWarrantyStatusAll(grt_request_id, body, this.filters) : await changeWarrantyStatus(client_request_id, grt_request_id, body)
      if (!res) return
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
      if (this.onChange)
        this.onChange(res)
    },
    async onChangeWarrantyContractor(client_request_id, grt_request_id, body) {
      const res = this.isAll ? await changeWarrantyContractorAll(grt_request_id, body, this.filters) : await changeWarrantyContractor(client_request_id, grt_request_id, body)
      if (!res) return
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
      if (this.onChange)
        this.onChange(res)
    },
    async onChangeWarrantyManager(client_request_id, grt_request_id, body) {
      const res = this.isAll ? await changeWarrantyManagerAll(grt_request_id, body, this.filters) : await changeWarrantyManager(client_request_id, grt_request_id, body)
      if (!res) return
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
      if (this.onChange)
        this.onChange(res)
    },
    async onChangeWarrantyDefectAct(client_request_id, grt_request_id, body) {
      const res = this.isAll ? await changeWarrantyDefectActAll(grt_request_id, body, this.filters) : await changeWarrantyDefectAct(client_request_id, grt_request_id, body)
      if (!res) return
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
      if (this.onChange)
        this.onChange(res)
    },
    async onChangeWarrantyAppAct(client_request_id, grt_request_id, body) {
      const res = this.isAll ? await changeWarrantyAppActAll(grt_request_id, body, this.filters) : await changeWarrantyAppAct(client_request_id, grt_request_id, body)
      if (!res) return
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
      if (this.onChange)
        this.onChange(res)
    },
    async handleDeleteFile(file) {
      const res = this.isAll ? await deleteWarrantyDocumentAll(this.grtRequestId, file?.grt_photo_id, this.filters) : await deleteWarrantyDocument(this.clientRequestId, this.grtRequestId, file?.grt_photo_id)
      if (!res) return
      this.files = this.files?.filter(item => item.grt_photo_id !== file?.grt_photo_id)
      if (!this.files?.length) this.resetData()
      this.showSucceedSnackbar()
      if (this.onChange)
        this.onChange(res)
    },
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar();
    return {showSucceedSnackbar};
  },
}
</script>

<style scoped lang="scss">

.warrantyRequests {
  &__header {
    display: flex;
    justify-content: flex-end;
  }

  &__info {
    &_wrapper {
      display: flex;
      align-items: center;
      gap: 5px;
      margin-top: 10px;

      &Icon {
        font-size: 18px;
        color: #1d50a9;
      }
    }

    &_row {
      margin-top: 10px;

      &.high {
        margin-top: 20px;

        &:first-child {
          margin-top: 0;
        }
      }
    }

    &_span {
      margin-top: 5px;
      font-weight: 500;
      color: #199819;
      font-size: 14px;

      &.default {
        color: #000;
      }
    }

    &_btn {
      margin-top: 5px;
    }

    &_value {
      font-weight: 500;

      &Link {
        color: #21549d;
        margin: 0;
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
          color: #437dd2;
          transition: all .3s;
        }
      }

      &.margin {
        margin-top: 10px;
      }

      &.fio {
        background: #1f5ac2;
        color: #fff;
        padding: 2px 4px;
        border-radius: 1px;
        width: fit-content;
      }
    }

    &_mark {
      color: #f3f3f3;
      padding: 2px;

      &.gray {
        background-color: #e5e5e5;
        color: #000000;
        padding: 5px 8px;
        width: 100%;
      }
    }
  }

  &__icon {
    &_group {
      font-size: 20px;
      color: #18ad2c;
      cursor: pointer;

      &:hover {
        color: #097018;
        transition: all .4s;
      }
    }

    &_exchange {
      font-size: 16px;
      color: #1050a8;
      cursor: pointer;
      margin: 5px;

      &:hover {
        color: #285dad;
        transition: all .4s;
      }
    }
  }
}

.dialog {
  position: relative;
  padding: 15px 15px 30px;
  min-width: 350px;
  @media (max-width: 370px) {
    min-width: 100%;
  }

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    margin-bottom: 10px;
  }

  &_title {
    font-size: 16px;
    margin: 0;
    line-height: 16px;
  }
}
</style>