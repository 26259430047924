<template>
  <div class="steps__wrapper">
    <CustomTable
      title="Галочки"
      :rowsData="rowsData"
      :columns="remontMarkStepColumns"
      :loading="isFetching"
      :custom-body="true"
    >
      <template #customBody="props">
        <q-tr>
          <custom-table-cell align="center">
            <div
              v-if="!!props.row.mark_desc"
              class="hover-element"
              @mouseover="showPopup(props.row.mark_type_code)"
              @mouseout="hidePopup(props.row.mark_type_code)"
            >
              <q-icon name="fa-solid fa-circle-info" class="steps__icon" />
              <q-popup-proxy
                :ref="`popup#${props.row.mark_type_code}`"
                transition-show="scale"
                transition-hide="scale"
              >
                <q-banner class="steps__help_wrapper"
                  ><span class="steps__help">{{ props.row.mark_desc }}</span>
                </q-banner>
              </q-popup-proxy>
            </div>
          </custom-table-cell>
          <custom-table-cell align="center">
            <CustomCheckbox
              :disable="!props.row.is_edit"
              :value="!!this.selectedItems[props.row.mark_type_code]"
              @change="() => onCheckboxChange(props.row.mark_type_code)"
            />
          </custom-table-cell>
          <custom-table-cell align="left" v-html="props.row.mark_type_name" />
          <custom-table-cell align="center">
            {{ props.row.date_mark }}
          </custom-table-cell>
          <custom-table-cell align="left">
            {{ props.row.fio }}
          </custom-table-cell>
        </q-tr>
      </template>
    </CustomTable>
  </div>
</template>

<script>
import BlockItem from "@/components/common/BlockItem";
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import {remontMarkStepColumns, checkMarkStep} from "@/views/ClientRequests/remontMarkStep/services";
import CustomCheckbox from "@/components/form/CustomCheckbox";

export default {
  name: "RemontMarkStepBlock",
  components: {BlockItem, CustomTable, CustomTableCell, CustomCheckbox},
  props: ["client_request_id", "data", "isFetching"],
  data() {
    return {
      selectedItems: this.data?.selectedItems || {},
      remontMarkStepColumns,
      rowsData: this.data?.data
    }
  },
  methods: {
    async onCheckboxChange(mark_type_code) {
      const res = await checkMarkStep(this.client_request_id, mark_type_code)
      if(!res) return
      const {selectedItems, data} = res
      this.rowsData = data
      this.selectedItems = selectedItems
    },
    showPopup(id) {
      this.$refs[`popup#${id}`].show()
    },
    hidePopup(id) {
      this.$refs[`popup#${id}`].hide()
    }
  },
}
</script>

<style scoped lang="scss">
.steps {
  &__wrapper {
    max-width: 700px;
    padding-bottom: 150px;
  }
  &__icon {
    color: #375AD8;
    font-size: 20px;
    cursor: pointer;
  }

  &__help {
    font-size: 12px;

    &_wrapper {
      max-width: 270px !important;
    }
  }
}
</style>
