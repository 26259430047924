<template>
  <div ref="filters">
    <ChangeMaterialsReqFilters :values="this.filters" @filter_change="this.onFiltersChange"/>
  </div>
  <ChangeMaterialsRequestsPrice :header-height="this.$refs.filters?.clientHeight" :data="this.data" :loading="this.loading" :change-data="changeData"
                                v-if="this.changeType === this.types.PRICE"/>
  <ChangeMaterialsRequestsAvail :header-height="this.$refs.filters?.clientHeight" :data="this.data" :loading="this.loading" :change-data="changeData"
                                v-if="this.changeType === this.types.AVAILABILITY"/>
</template>

<script>
import {getChangeMaterialRequestsData} from "@/views/ChangeMaterialRequests/services";
import ChangeMaterialsRequestsAvail from "./ChangeMaterialsRequestsAvail"
import ChangeMaterialsRequestsPrice from "./ChangeMaterialsRequestsPrice"
import {changeMaterialsReqTypes} from "./services"
import {appActionTypes} from "@/services/store/modules/app_module/app_mutation_types";
import ChangeMaterialsReqFilters from "@/views/ChangeMaterialRequests/filters";

export default {
  name: "ChangeMaterialRequests",
  components: {ChangeMaterialsReqFilters, ChangeMaterialsRequestsPrice, ChangeMaterialsRequestsAvail},
  data() {
    return {
      data: [],
      loading: false,
      type: "",
      types: changeMaterialsReqTypes,
      filters: {
        provider_id: "",
        status_id: "",
      }
    }
  },
  computed: {
    changeType() {
      const params = this.$route.params
      return params?.type
    }
  },
  watch: {
    changeType() {
      this.getData()
    }
  },
  mounted() {
    this.getData()
    this.$store.dispatch(appActionTypes.getChangeMaterialsReqStatuses);
  },
  methods: {
    async getData() {
      this.loading = true;
      const res = await getChangeMaterialRequestsData(this.changeType, this.filters);
      this.loading = false;
      this.data = res
    },
    changeData(newData) {
      this.data = newData
    },
    onFiltersChange(key, value) {
      if(this.filters[key] === value) return
      this.filters = {...this.filters, [key]: value}
      this.getData()
    }
  }
}
</script>

<style scoped>

</style>