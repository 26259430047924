import instance from "@/services/api";

export const remontTimelineStepAPI = {
  async getRemontTimelineConditions(remont_step_id) {
    return await instance().get(`/timeline/remont_step/${remont_step_id}/read/condition/`).then(res => res?.data)
  },
  async getRemontTimelineLogs(remont_step_id) {
    return await instance().get(`/timeline/remont_step/${remont_step_id}/read/condition/log/`).then(res => res?.data)
  },
  async getRemontTimelineCount(remont_step_id) {
    return await instance().get(`/timeline/remont_step/${remont_step_id}/count/`).then(res => res?.data)
  },
  async getRemontTimelineActions(remont_step_id) {
    return await instance().get(`/timeline/remont_step/${remont_step_id}/read/activity/`).then(res => res?.data)
  },
  async updateRemontActionStepActivity(remont_step_id, remont_step_activity_id, body) {
    return await instance().put(`/timeline/remont_step/${remont_step_id}/activity/${remont_step_activity_id}/update/`, body).then(res => res?.data)
  },
  async updateRemontConditionStepActivity(remont_step_id, remont_step_condition_id, body) {
    return await instance().put(`/timeline/remont_step/${remont_step_id}/condition/${remont_step_condition_id}/update/`, body).then(res => res?.data)
  }
}