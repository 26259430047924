<template>
  <q-form @submit="this.onSubmit" class="form">
    <div class="form__item">
      <CustomInput
          :required="true"
          :label="this.inputLabel"
          :value="this.formData[this.inputKey] || ''"
          @change_input="(val) => onChange(this.inputKey, val)"
      />
    </div>
    <div class="form__item">
      <p>Дата составления</p>
      <CustomDate
          placeholder="Дата составления"
          :value="this.formData[this.dateKey] || ''"
          @change_date="(val) => onChange(this.dateKey, val)"
      />
    </div>
    <div class="form__item">
      <CustomUploader :value="this.formData?.files" :multiple="true"
                      :on-change="(val, removed) => this.onChange('files', val, {file: true, removed})"
                      label="Загрузите документ"/>
    </div>
    <div class="form__item">
      <p>Документы</p>
      <FileList :files="this.documents" :on-delete-file-click="this.handleDeleteFile" :on-open-gallery="this.openGallery" file-name-prop="document_name"
                file-url-prop="document_url"/>
    </div>
    <CustomModalFooter :btn-loading="this.loading"/>
  </q-form>
  <q-dialog v-model="showPopup">
    <chat-dialog :images="this.images" :img-index="this.imgIndex" :is-fetching="this.isFetching" :reset-data="this.resetData" />
  </q-dialog>
</template>
<script>

import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomUploader from "@/components/form/CustomUploader";
import Clickable from "@/components/common/Clickable";
import {getFullUrl} from "@/services";
import {
  deleteWarrantyDocument,
  deleteWarrantyDocumentAll,
  getWarrantyAppActDocuments,
  getWarrantyDefectActDocuments
} from "@/views/ClientRequests/warrantyRequests/services";
import CustomInput from "@/components/form/CustomInput";
import CustomDate from "@/components/form/CustomDate";
import FileList from "@/components/common/FileList";
import {getFileExt, isFileImg} from "@/_helpers";
import ChatGallery from "@/components/modal/modals/chat-gallery";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";
import ChatDialog from "@/components/modal/modals/chat-gallery/chat-dialog";

export default {
  name: "warranty-act",
  components: {ChatDialog, ChatGallery, FileList, CustomDate, CustomInput, Clickable, CustomUploader, CustomModalFooter},
  props: ['data'],
  data() {
    return {
      showPopup: false,
      loading: false,
      isFetching: false,
      imgIndex: 0,
      images: [],
      documents: [],
      formData: {
        defect_akt_date: this.data?.body?.defect_akt_date || '',
        defect_akt_num: this.data?.body?.defect_akt_num || '',
        app_akt_date: this.data?.body?.app_akt_date || '',
        app_akt_num: this.data?.body?.app_akt_num || '',
        files: [],
      }
    }
  },
  methods: {
    getFullUrl,
    onChange(key, value, info) {
      if (info?.file) {
        if (info.removed)
          this.formData[key] = value
        else
          this.formData.files = [...this.formData.files, ...value]
        return
      }
      this.formData[key] = value
    },
    resetData() {
      this.showPopup = false
      this.images = []
      this.imgIndex = 0
    },
    openGallery(file) {
      const images = this.documents?.filter(item => isFileImg(getFileExt(item.document_name))).map(item => ({
        ...item,
        file_url: getFullUrl(item?.document_url),
        file_name: item?.document_name,
      }))
      const imgIndex = images.findIndex(item => item.grt_photo_id === file?.grt_photo_id)
      this.images = images
      this.imgIndex = imgIndex
      this.showPopup = true
    },
    async onSubmit() {
      const formData = new FormData()
      if (this.data.type === 'defect') {
        formData.append('defect_akt_num', this.formData.defect_akt_num)
        formData.append('defect_akt_date', this.formData.defect_akt_date)
      } else {
        formData.append('app_akt_num', this.formData.app_akt_num)
        formData.append('app_akt_date', this.formData.app_akt_date)
      }
      if (this.formData.files?.length) {
        this.formData.files.forEach(item => {
          formData.append('files', item)
        })
      }
      this.loading = true
      await this.data?.onSubmit(formData)
      this.loading = false
    },
    async handleDeleteFile(file) {
      const res = this.data?.isAll ? await deleteWarrantyDocumentAll(this.data?.grt_request_id, file?.grt_photo_id) : await deleteWarrantyDocument(this.data?.client_request_id, this.data?.grt_request_id, file?.grt_photo_id)
      if (!res) return
      this.documents = this.documents?.filter(item => item.grt_photo_id !== file?.grt_photo_id)
      this.showSucceedSnackbar()
      if(this.data?.updateData)
        this.data.updateData(res)
    },
    async getData() {
      this.isFetching = true
      const res = this.data?.type === 'defect' ? await getWarrantyDefectActDocuments(this.data?.grt_request_id) : await getWarrantyAppActDocuments(this.data?.grt_request_id)
      this.isFetching = false
      if (!res) return
      this.documents = res
    },
  },
  computed: {
    inputLabel() {
      return this.data?.type === 'defect' ? 'Номер дефектного акта' : 'Номер акта об устранении'
    },
    inputKey() {
      return this.data?.type === 'defect' ? 'defect_akt_num' : 'app_akt_num'
    },
    dateKey() {
      return this.data?.type === 'defect' ? 'defect_akt_date' : 'app_akt_date'
    },
  },
  mounted() {
    this.getData()
  },
  setup() {
    const { showSucceedSnackbar } = useCustomSnackbar();
    return { showSucceedSnackbar };
  },
}
</script>

<style scoped lang="scss">
.form {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  gap: 15px;

  &__item {
    p {
      margin: 0;
    }

    &_file {
      padding: 10px 7px;
      border-radius: 4px;
      border: 1px solid #ccc;
      color: #1f5ac2;
    }
  }

  @media (max-width: 428px) {
    min-width: 100%;
  }
}

</style>
