import {getMenu} from "@/components/layout/Navbar/api";

export const getMenuData = async () => {
  try {
    const res = await getMenu()
    if(!!res?.menus?.length) {
      const {menus} = res
      if (menus && menus?.length === 1 && !!menus[0]) {
        return menus[0]?.sub_menus
      }
      return menus
    }
    return []
  } catch (e) {
    return []
  }
}