<template>
  <CustomTable
      title="Список расходов"
      :rowsData="this.refactorData"
      :columns="remontPaymentsCostsColumns"
      :expand="true"
      :customBody="true"
      :on-all-expand-click="onExpandAllClick"
  >
    <template v-slot:top-right="props">
      <q-btn @click="handleAddClick" color="primary" size="sm" padding="sm">Добавить платеж</q-btn>
    </template>
    <template #customBody="props">
      <q-tr :props="props">
        <CustomTableCell align="center">{{ props.row?.index }}</CustomTableCell>
        <CustomTableCell
            align="left"
        >{{ props.row?.contragent }}
        </CustomTableCell
        >
        <CustomTableCell
            align="left"
        >{{ props.row?.remont_costs_type_name }}
        </CustomTableCell
        >
        <CustomTableCell
            align="right"
        >{{ props.row?.item_cnt }}
        </CustomTableCell
        >
        <CustomTableCell
            align="right"
        >{{ this.numberWithCommas(props.row?.payment_sum) }}
        </CustomTableCell
        >
        <CustomTableCell align="center">
          <custom-table-expand-arrow
              :active="this.expand[props.row?.id]"
              @click="onExpandClick(props.row)"
          />
        </CustomTableCell>
      </q-tr>
      <q-tr v-show="expand[props.row.id]" :props="props">
        <q-td colspan="100%">
          <CustomLoader v-if="!!itemsLoading[props.row.id]"/>
          <template v-else>
            <CustomTable
                :customBody="true"
                title=""
                rowKey="client_request_id"
                :columns="remontPaymentsCostsDetailColumns"
                :rowsData="props.row.detailData"
            >
              <template #customBody="detailProps">
                <q-tr :props="detailProps">
                  <CustomTableCell align="center">
                    <Clickable
                        @click="() => this.handleEditClick(detailProps.row?.remont_costs_id, detailProps.row, props.row)"
                    >
                      {{ detailProps.row?.remont_costs_id }}
                    </Clickable>
                  </CustomTableCell>
                  <CustomTableCell align="right" style="white-space: nowrap">
                    {{ this.numberWithCommas(detailProps.row?.payment_amount) }}
                  </CustomTableCell>
                  <CustomTableCell
                      align="center"
                  >{{ detailProps.row?.payment_date }}
                  </CustomTableCell
                  >
                  <CustomTableCell
                      align="left"
                  >{{ detailProps.row?.contragent }}
                  </CustomTableCell
                  >
                  <CustomTableCell
                      align="left"
                  >{{ detailProps.row?.comments }}
                  </CustomTableCell
                  >
                  <CustomTableCell
                      align="left"
                  >{{ detailProps.row?.remont_costs_type_name }}
                  </CustomTableCell
                  >
                  <CustomTableCell align="left"
                  >{{
                      detailProps.row?.is_payed === 1 ? 'Оплачено' : "Ожидается"
                    }}
                  </CustomTableCell>
                  <custom-table-cell align="center">
                    <CustomIcon
                        icon="trash"
                        :approve="() => this.onDeleteSubmit(detailProps.row.remont_costs_id, props.row)"
                        :approve-mode="true"
                        :loading="this.deleting"
                    />
                  </custom-table-cell>
                </q-tr>
              </template>
            </CustomTable>
          </template>
        </q-td>
      </q-tr>
    </template>
  </CustomTable>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import {
  addCost,
  deleteCost,
  editCost,
  getRemontPaymentDetail,
  remontPaymentsCostsColumns,
  remontPaymentsCostsDetailColumns
} from "@/views/ClientRequests/remontPayments/services";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import CustomTableExpandArrow from "@/components/common/CustomTable/custom-table-expand-arrow";
import CustomLoader from "@/components/common/CustomLoader";
import {numberWithCommas} from '@/services'
import {appActionTypes} from "@/services/store/modules/app_module/app_mutation_types";
import CustomIcon from "@/components/common/CustomIcon";
import {appActions} from "@/services/store/modules/app_module/app_actions";
import {MODAL_NAMES} from "@/constants";
import Clickable from "@/components/common/Clickable";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";

export default {
  name: "remontPaymentsCosts",
  components: {Clickable, CustomIcon, CustomLoader, CustomTableExpandArrow, CustomTableCell, CustomTable},
  props: ["data", "client_request_id"],
  data() {
    return {
      remontPaymentsCostsColumns,
      remontPaymentsCostsDetailColumns,
      expand: {},
      itemsLoading: {},
      dataState: this.data
    }
  },
  mounted() {
    this.$store.dispatch(appActionTypes.getCostsTypes);
  },
  methods: {
    numberWithCommas,
    onExpandAllClick() {
      this.expand = {};
    },
    async onExpandClick(row) {
      const id = row.id
      this.expand[id] = !this.expand[id];
      const expand = this.expand[id];
      if (!expand) return;
      this.handleGetDetailData(id, row)
    },
    async handleGetDetailData(id, row) {
      this.itemsLoading[id] = true;
      const body = {
        remont_costs_type_id: row?.remont_costs_type_id,
        contragent: row?.contragent
      }
      await this.getDetailData(body, id);
      this.itemsLoading[id] = false;
    },
    async getDetailData(body, id) {
      const res = await getRemontPaymentDetail(
          this.client_request_id,
          body,
          this.refactorData, id
      );
      if (res)
        this.dataState = res;
    },
    handleAddClick() {
      this.$store.commit(appActions.showModal(MODAL_NAMES.AddCost, {
        title: 'Добавить расход',
        onSubmit: this.onSubmit,
      }));
    },
    handleEditClick(id, body, row) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.AddCost, {
        title: 'Редактировать расход',
        onSubmit: body => this.onEditSubmit(id, body, row),
        body
      }));
    },
    async onSubmit(body) {
      const res = await addCost(this.client_request_id, body)
      if (!res) return
      this.dataState = res
      this.expand = {};
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    async onEditSubmit(id, body, row) {
      const res = await editCost(this.client_request_id, id, body)
      if (!res) return
      this.dataState = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
      this.handleGetDetailData(row.id, row)
    },
    async onDeleteSubmit(id, row) {
      this.deleting = true
      const res = await deleteCost(this.client_request_id, id)
      this.deleting = false
      if (!res) return
      this.dataState = res
      this.showSucceedSnackbar()
      this.handleGetDetailData(row.id, row)
    },
  },
  computed: {
    refactorData() {
      return this.dataState?.map((item, i) => ({
        ...item,
        index: i + 1,
        id: `${item.contragent}-${item.remont_costs_type_name}`
      }))
    }
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar();
    return {showSucceedSnackbar};
  },
}
</script>
