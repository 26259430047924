<template>
  <div :class="[{purpose: this.data?.body?.purpose}]">
    <q-form @submit="this.onSubmit" class="form">
      <div class="form__item">
        <CustomSelect
            :options="this.data?.modalData?.contractor"
            label="Подрядчик"
            optionValueKey="id"
            optionLabelKey="name"
            :value="this.formData.contractor_id || ''"
            @change="(value) => onChange('contractor_id', value)"
        />
      </div>
      <div class="form__item">
        <CustomSelect
            :options="this.data?.modalData?.manager_project"
            label="Прораб"
            optionValueKey="id"
            optionLabelKey="name"
            :value="this.formData.manager_project_employee_id || ''"
            @change="(value) => onChange('manager_project_employee_id', value)"
        />
      </div>
      <div class="form__item">
        <CustomSelect
            :options="this.data?.modalData?.techproject_developer"
            label="Разработчик тех.проекта(Контрагент)"
            optionValueKey="id"
            optionLabelKey="name"
            :value="this.formData.techproject_developer_id || ''"
            @change="(value) => onChange('techproject_developer_id', value)"
        />
      </div>
      <div class="form__item">
        <CustomSelect
            :options="this.data?.modalData?.techproject_employee"
            label="Разработчик тех.проекта(Пользователь)"
            optionValueKey="id"
            optionLabelKey="name"
            :value="this.formData.techproject_employee_id || ''"
            @change="(value) => onChange('techproject_employee_id', value)"
        />
      </div>
      <div class="form__item">
        <CustomSelect
            :options="this.data?.modalData?.okk_employee"
            label="ОКК"
            optionValueKey="id"
            optionLabelKey="name"
            :value="this.formData.okk_employee_id || ''"
            @change="(value) => onChange('okk_employee_id', value)"
        />
      </div>
      <div class="form__item">
        <CustomSelect
            :options="this.data?.modalData?.inner_master_employee"
            label="Проектный менеджер"
            optionValueKey="id"
            optionLabelKey="name"
            :value="this.formData.inner_master_employee_id || ''"
            @change="(value) => onChange('inner_master_employee_id', value)"
        />
      </div>
      <CustomModalFooter :on-cancel="this.closeModal" :btn-loading="this.loading"/>
    </q-form>
  </div>
</template>
<script>

import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomSelect from "@/components/form/CustomSelect";
import {setExecutiveEmployee} from "@/views/ExecutiveReport/services";

export default {
  name: "executive-report-employee-set",
  components: {CustomSelect, CustomModalFooter},
  props: ['data', 'closeModal'],
  data() {
    return {
      loading: false,
      formData: {
        contractor_id: this.data?.body?.contractor_id || '',
        inner_master_employee_id: this.data?.body?.inner_master_employee_id || '',
        manager_project_employee_id: this.data?.body?.manager_project_employee_id || '',
        techproject_developer_id: this.data?.body?.techproject_developer_id || '',
        techproject_employee_id: this.data?.body?.techproject_employee_id || '',
        okk_employee_id: this.data?.body?.okk_employee_id || '',
        remont_id: this.data?.body?.remont_id || '',
      }
    }
  },
  methods: {
    onChange(key, value) {
      this.formData[key] = value
    },
    async onSubmit() {
      this.loading = true
      const res = await setExecutiveEmployee(this.formData)
      this.loading = false
      if(res)
        this.closeModal()
    },
  },
}
</script>

<style scoped lang="scss">
.form {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  gap: 20px;

  @media (max-width: 428px) {
    min-width: 100%;
  }
}
</style>
