<template>
  <q-btn title="Выгрузка excel" :loading="this.loading" :disable="this.loading || this.disabled" class="print">
    <i :class="`material-icons`"
    >print</i>
    <span class="title">Выгрузка <br>в Excel</span>
  </q-btn>
</template>

<script>
export default {
  name: "UploadExcelBtn",
  props: ['loading', 'disabled']
}
</script>

<style scoped>
.print {
  background: #21830b !important;
  color: #fff;
  max-height: 100% !important;
  min-height: 33px !important;
}
.title {
  margin-left: 5px;
  font-size: 10px;
  line-height: 12px;
}
</style>