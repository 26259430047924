<template>
  <div class="wrapper">
    <q-form @submit="this.onSubmit" class="form">
      <CustomDate
          placeholder="Дата завершения"
          :value="this.formData.finish_date || ''"
          @change_date="(value) => onChange('finish_date', value)"
      />
      <CustomInput :value="this.formData.comment"
                   @change_input="(value) => this.onChange('comment', value)"
                   label="Комментарии" type="textarea" :required="true"/>
      <CustomModalFooter :onCancel="this.closeModal" cancel-btn-text="Закрыть" :btn-loading="this.loading"/>
    </q-form>
  </div>
</template>
<script>

import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomDate from "@/components/form/CustomDate";
import {setExecutiveReportDeadline} from "@/views/ExecutiveReport/services";
import CustomInput from "@/components/form/CustomInput";

export default {
  name: "executive-report-set-deadline",
  components: {CustomInput, CustomDate, CustomModalFooter},
  props: ['data', 'closeModal'],
  data() {
    return {
      loading: false,
      formData: {
        comment: '',
        finish_date: '',
      }
    }
  },
  methods: {
    onChange(key, value) {
      this.formData[key] = value
    },
    async onSubmit() {
      const body = {
        ...this.data.body, ...this.formData
      }
      this.loading = true
      const res = await setExecutiveReportDeadline(body)
      this.loading = false
      if (!res) return
      this.rowsData = res
    },
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  max-width: 500px;
}

.form {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  gap: 20px;

  @media (max-width: 428px) {
    min-width: 100%;
  }
}

@media (max-width: 576px) {
  .wrapper {
    max-width: 100%;
  }
}
</style>
