<template>
  <q-td :class="['cell', `cell__align_${this.align}`,`cell__verticalAlign_${this.verticalAlign}`, {cell__nowrap: this.nowrap}]">
    <slot/>
  </q-td>
</template>

<script>
export default {
  name: "custom-table-cell",
  props: ['align', 'verticalAlign', 'nowrap']
}
</script>

<style scoped lang="scss">
.cell {
  vertical-align: top;

  &__verticalAlign {
    &_center {
      vertical-align: middle;
    }
  }
  &__align {
    &_center {
      text-align: center;
    }

    &_right {
      text-align: right;
    }

    &_left {
      text-align: left;
    }
  }

  &__nowrap {
    white-space: nowrap;
  }
}
</style>