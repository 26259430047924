<template>
  <p class="notFound__title">{{ this.text || 'Ничего не найдено' }}</p>
</template>

<script>
export default {
  name: "NotFoundText",
  props: ['text']
}
</script>

<style scoped>
.notFound__title {
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 15px 0;
  color: #cccccc;
  font-size: 17px;
}
</style>