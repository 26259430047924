<template>
  <div :class="[{purpose: this.data?.body?.purpose}]">
    <q-form @submit="this.onSubmit" class="form">
      <div class="form__item">
        <CustomInput
            label="Название версии"
            :required="true"
            :value="this.formData.version_name"
            @change_input="(value) => onChange('version_name', value)"
        />
      </div>
      <div class="form__item">
        <CustomUploader :value="this.formData?.file"
                        :on-change="(value) => this.onChange('file', value)" label="Файл"/>
      </div>
      <CustomModalFooter :btn-loading="this.loading"/>
    </q-form>
  </div>
</template>
<script>

import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomUploader from "@/components/form/CustomUploader";
import {getFullUrl} from "@/services";
import CustomInput from "@/components/form/CustomInput";

export default {
  name: "add-mobile-app-version",
  components: {CustomInput, CustomUploader, CustomModalFooter},
  props: ['data'],
  data() {
    return {
      loading: false,
      formData: {
        version_name: '',
        file: ''
      }
    }
  },
  methods: {
    getFullUrl,
    onChange(key, value) {
      this.formData[key] = value
    },
    async onSubmit() {
      let formData = new FormData()
      formData.append('version_name', this.formData.version_name)
      if (this.formData.file)
        formData.append('file', this.formData.file[0])
      this.loading = true
      await this.data?.onSubmit(formData)
      this.loading = false
    },
  },
}
</script>

<style scoped lang="scss">
.form {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  gap: 15px;

  &__item {
    &_file {
      padding: 10px 7px;
      border-radius: 4px;
      border: 1px solid #ccc;
      color: #1f5ac2;

      &Label {
        margin-bottom: 3px;
        font-size: 13px;
        display: block;
        color: #3b3b3b;
      }
    }
  }

  @media (max-width: 428px) {
    min-width: 100%;
  }
}
</style>
