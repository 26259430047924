<template>
  <q-linear-progress v-if="this.loading" indeterminate/>
  <div v-else>
    <slot v-if="this.dataExist ? this.data?.length : true"></slot>
    <NotFoundText v-else/>
  </div>
</template>

<script>
import NotFoundText from "@/components/common/NotFoundText";

export default {
  name: "FetchingWrapper",
  components: {NotFoundText},
  props: ['loading', 'data'],
  computed: {
    dataExist() {
      return !!this.data
    }
  }
}
</script>

<style scoped>

</style>