import {remontSmetaWholeAPI} from "@/views/ClientRequests/remontSmetaWhole/api";
import {numberWithCommas} from "@/services";

const blockNames = {
  OA__RemontFormSebesSmetaWholeBlock: "remontSmetaWholeBlock",
}
export const getRemontSmetaWholeData = async (client_request_id) => {
  try {
    const res = await remontSmetaWholeAPI.getList(client_request_id)
    const data = res?.data
    if (!data?.length) return
    return data?.map(item => ({...item, component: blockNames[item.grant_code]}))
  } catch (e) {
  }
}
export const remontSmetaWholeColumns = [
  {
    name: "material_name",
    label: "Материал",
    field: "material_name",
    align: "left",
  },
  {
    name: "material_type_name",
    label: "Тип материала",
    field: "material_type_name",
    align: "left",
  },
  {
    name: "is_dirty",
    label: "Черновой материал",
    field: "is_dirty",
    align: "center",
  },
  {
    name: "provider_name",
    label: "Поставщик",
    field: "provider_name",
    align: "left",
  },
  {
    name: "cnt_consume_unit",
    label: "Кол-во в единицах расхода",
    field: "cnt_consume_unit",
    align: "right",
  },
  {
    name: "cnt_of_sell",
    label: "Кол-во в упаковке",
    field: "cnt_of_sell",
    align: "right",
  },
  {
    name: "cnt_sell_unit",
    label: "Кол-во в ед. продажи",
    field: "cnt_sell_unit",
    align: "right",
  },
  {
    name: "retail_price",
    label: "Цена (розничная)",
    field: "retail_price",
    align: "right",
    format: (val) => numberWithCommas(val),
  },
  {
    name: "retail_amount",
    label: "Сумма (розничная)",
    field: "retail_amount",
    align: "right",
    format: (val) => numberWithCommas(val),
  },
  {
    name: "price_sell_unit",
    label: "Цена (оптовая)",
    field: "price_sell_unit",
    align: "right",
    format: (val) => numberWithCommas(val),
  },
  {
    name: "amount",
    label: "Сумма (оптовая)",
    field: "amount",
    align: "right",
    format: (val) => numberWithCommas(val),
  },
  {
    name: "residue",
    label: "Остаток",
    field: "residue",
    align: "right",
  },
]
