import instance from "@/services/api";

export const remontProjectsAPI = {
  async getList(client_request_id) {
    return await instance().get(`/client_request/${client_request_id}/project_remont/read/`).then(res => res?.data)
  },
  async updateMeasureReference(client_request_id, body) {
    return await instance().put(`/client_request/${client_request_id}/project_remont/measure_reference/update/`, body).then(res => res?.data)
  },
  async addRemontProject(client_request_id, body) {
    return await instance().post(`/client_request/${client_request_id}/project_remont/add/`, body, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then(res => res?.data)
  },
  async updateRemontProject(client_request_id, project_remont_id, body) {
    return await instance().put(`/client_request/${client_request_id}/project_remont/${project_remont_id}/update/`, body, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then(res => res?.data)
  },
  async deleteRemontProject(client_request_id, project_remont_id) {
    return await instance().delete(`/client_request/${client_request_id}/project_remont/${project_remont_id}/delete/`).then(res => res?.data)
  },
  async acceptRemontProject(client_request_id) {
    return await instance().post(`/client_request/${client_request_id}/project_remont/accept/`).then(res => res?.data)
  },
  async refundRemontProject(client_request_id, project_remont_id) {
    return await instance().get(`/client_request/${client_request_id}/project_remont/${project_remont_id}/refund/`).then(res => res?.data)
  },
  async chatSendRemontProject(client_request_id, project_remont_id) {
    return await instance().get(`/client_request/${client_request_id}/project_remont/${project_remont_id}/chat_send/`).then(res => res?.data)
  },
}