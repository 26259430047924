import instance from "@/services/api";

export const gradeTimelineStepsAPI = {
  async getSteps(grade_id) {
    return await instance().get('/timeline/handbook/grade_steps/read/', {params: {grade_id}}).then(res => res?.data)
  },
  async addStep(grade_id, body) {
    return await instance().post(`/timeline/handbook/grade_steps/add/`, body, {params: {grade_id}}).then(res => res?.data)
  },
  async updateStep(grade_id, grade_step_id, body) {
    return await instance().put(`/timeline/handbook/grade_steps/${grade_step_id}/is_active/`, body, {params: {grade_id}}).then(res => res?.data)
  },
  async deleteStep(grade_id, grade_step_id) {
    return await instance().delete(`/timeline/handbook/grade_steps/${grade_step_id}/delete/`, {params: {grade_id}}).then(res => res?.data)
  },
  async getNestedSteps(grade_id, body) {
    return await instance().post(`/timeline/handbook/grade_step_links/read/`, body,{params: {grade_id}}).then(res => res?.data)
  },
  async addNestedSteps(grade_id, body) {
    return await instance().post(`/timeline/handbook/grade_step_links/add/`, body, {params: {grade_id}}).then(res => res?.data)
  },
  async deleteNestedSteps(grade_id, body) {
    return await instance().post(`/timeline/handbook/grade_step_links/delete/`, body, {params: {grade_id}}).then(res => res?.data)
  },
}

