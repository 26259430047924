<template>
  <div class="wrapper">
    <q-form v-if="!!this.values?.length" @submit="this.onSubmit" class="form">
      <div class="container nps__form_container">
        <div class="nps__form_content">
          <div class="nps__form" v-for="item in values" :key="item.nps_question_id">
            <h3 class="nps__form_title">{{ item.nps_question_title }}</h3>
            <div v-if="(item.require_type === 3 || item.require_type === 1) && !!item.max_rating"
                 class="nps__form__answersContainer">
              <div class="nps__form__answersWrapper">
                <div class="nps__form__answersHead">
                  <div>Плохо</div>
                  <div>Очень хорошо</div>
                </div>
                <div class="nps__form__answers rating">
                  <div v-for="value in Array.from({length: item.max_rating}, (v, i) => i)" :key="value"
                       :class="['nps__form__answer', {selected: item.nps_answer === (value + 1)}]"
                       @click="() => onChange(item.nps_question_id, 'nps_answer', value + 1 )"
                  >
                    {{ value + 1 }}
                  </div>
                </div>
              </div>
            </div>
            <CustomInput
                v-if="(item.require_type === 3 || item.require_type === 2)" :value="item.nps_comment" name="nps_comment"
                :disable="this.loading"
                @change_input="(value) => this.onChange(item.nps_question_id, 'nps_comment', value)"
                label="Ответ" type="textarea" :required="true"/>

          </div>
        </div>
        <CustomModalFooter :onCancel="this.closeModal" :btn-loading="this.loading"
                           cancel-btn-text="Закрыть"/>
      </div>
    </q-form>
    <NotFoundText v-else/>
  </div>
</template>
<script>

import CustomInput from "@/components/form/CustomInput";
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import {setExecutiveReportNps} from "@/views/ExecutiveReport/services";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";
import NotFoundText from "@/components/common/NotFoundText";

export default {
  name: "executive-report-nps",
  components: {NotFoundText, CustomInput, CustomModalFooter},
  props: ['data', 'closeModal'],
  data() {
    return {
      loading: false,
      values: this.data.modalData || []
    }
  },
  methods: {
    onChange(id, key, value) {
      this.values = this.values?.map(item => {
        if (item.nps_question_id === id) return {...item, [key]: value}
        return item
      })
    },
    async onSubmit() {
      let errorText = ''
      const body = {
        ...this.data.body,
        answers: this.values?.map(item => {
          const {nps_question_id, nps_answer, nps_comment, require_type} = item
          let ratingEdited = nps_answer
          if (require_type === 1 || require_type === 3)
            if (!nps_answer) errorText = 'Поставьте оценку'

          if (require_type === 2 || require_type === 3) {
            if (!nps_comment) errorText = 'Заполните обязательные поля'
            if (require_type === 2) ratingEdited = -1
          }
          return {nps_question_id, nps_comment, nps_answer: ratingEdited}
        })
      }
      if (!!errorText)
        return this.showErrorSnackbar(errorText)
      this.loading = true
      const res = await setExecutiveReportNps(body)
      this.loading = false
      if (!res) return
      this.showSucceedSnackbar()
      this.closeModal()
    },
  },
  setup() {
    const {showSucceedSnackbar, showErrorSnackbar} = useCustomSnackbar()
    return {showSucceedSnackbar, showErrorSnackbar}
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  max-width: 500px;
}

.nps__form {
  form {
    padding-bottom: 20px;
  }

  &_container {
    padding: 0;
  }

  &_title {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
  }

  &_content {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .d-flex {
      font-size: 10px;
      color: #757575;
    }
  }

  form > .d-flex {
    font-size: 10px;
    color: #757575;
    margin-top: 15px;
  }

  &__answers {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    &.rating {
      gap: 3px;
      justify-content: unset;

      .nps__form__answer {
        border: 1px #e3e3e3 solid;
      }
    }

    &Container {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    &Wrapper {
      width: max-content;
    }

    &Head {
      display: flex;
      align-content: center;
      justify-content: space-between;
    }
  }

  &__answer {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px #f4f4f4 solid;
    padding: 10px;
    cursor: pointer;

    &:hover {
      background-color: #1374cf;
      color: #fff;
    }
  }

  .selected {
    background-color: #1374cf;
    color: #fff;
  }

  button {
    margin-top: 20px;
  }

  &_btn {
    margin-top: 20px;
  }
}

@media (max-width: 576px) {
  .wrapper {
    max-width: 100%;
  }
}
</style>
