<template>
  <custom-table
      :customBody="true" :hide-footer-title="true"
      :columns="this.columns" :rowsData="this.data?.data">
    <template #customBody="props">
      <q-tr :props="props" :style="(!props.row.is_active && !props.row.is_completed) ? 'background-color: #bbb !important': '' ">
        <q-td v-for="col in props.cols" :key="col.name" :props="props" >
          <div v-if="col.name === 'param_json' || col.name === 'param_value_json'">
            <p v-for="item in Object.keys(this.json(col.value))?.sort()" class="actions__params_item">
              {{`${item}: `}} <span>{{this.json(col.value)[item]}}</span>
            </p>
          </div>
          <div v-else-if="col.name === 'is_completed'"><i :class="`material-icons timeline__check timeline__check_${col.value ? 'completed' : 'close'}`">{{ col.value ? 'check' : 'close' }}</i></div>
          <div v-else-if="col.name === 'is_active'" class="timeline__pointer"
               @click="() => this.updateActivity(props.row.remont_step_activity_id, !col.value)">
            <template v-if="!props.row.is_completed">
              <CustomLoader v-if="this.loading[props.row.remont_step_activity_id]" small/>
              <i v-else
                 :class="`material-icons amaterial-symbols-outlined timeline__toggle timeline__toggle_${col.value ? 'completed' : ''}`">
                {{ col.value ? 'toggle_on' : 'toggle_off' }}
              </i>
            </template>
          </div>
          <span v-else>
            {{ col.value }}
          </span>
        </q-td>
      </q-tr>
    </template>
  </custom-table>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import {
  remontActionsTableColumns,
  remontTimelineTabs,
  updateRemontActionStepActivity
} from "@/components/modal/modals/remont-timeline-step/services";
import CustomIcon from "@/components/common/CustomIcon";
import CustomLoader from "@/components/common/CustomLoader";

export default {
  name: "remont-timeline-actions",
  components: {CustomLoader, CustomIcon, CustomTable},
  props: ['data', 'remont_step_id', 'updateData'],
  data() {
    return {
      remontTimelineTabs,
      columns: remontActionsTableColumns,
      loading: {}
    }
  },
  methods: {
    json(value) {
      try {
        if (!value) return {}
        return JSON.parse(value)
      } catch (e) {
        return {}
      }
    },
    async updateActivity(remont_step_activity_id, is_active) {
      if (this.loading[remont_step_activity_id]) return
      this.loading[remont_step_activity_id] = true
      const res = await updateRemontActionStepActivity(this.remont_step_id, remont_step_activity_id,
          {is_active})
      this.loading[remont_step_activity_id] = false
      if (!res) return
      this.updateData(this.remontTimelineTabs.actions, res)
    }
  }
}
</script>

<style scoped lang="scss">
.actions {
  &__params {
    &_item {
      margin: 0;
      font-size: 13px;
      font-weight: bold;

      span {
        font-weight: normal;
      }
    }
  }
}
</style>