<template>
  <div class="payment">
    <q-form @submit="this.onSubmit" class="payment__form">
      <div class="payment__row payment__row_two">
        <CustomSelect
            :options="this.companies"
            :required="true"
            label="Компания инициатор"
            optionValueKey="initiator_company_id"
            optionLabelKey="initiator_company_name"
            :value="this.formData.initiator_company_id || ''"
            @change="(value) => onChange('initiator_company_id', value)"
        />
        <CustomSelect
            :options="this.paymentTypes"
            label="Тип платежа"
            :required="true"
            optionValueKey="finance_payment_type_id"
            optionLabelKey="finance_payment_type_name"
            :value="this.formData.finance_payment_type_id || ''"
            @change="(value, obj) => onChange('finance_payment_type_id', value, obj)"
        />
      </div>
      <div class="payment__row payment__row_two">
        <CustomInputNumber
            :value="this.formData.payment_amount"
            :required="true"
            label="Сумма платежа"
            @change_input="(value) => onChange('payment_amount', value)"
        />
        <CustomDate
            :value="this.formData.pay_date"
            placeholder="Дата платежа"
            :required="true"
            @change_date="(value) => onChange('pay_date', value)"
            :fullWidth="true"
        />
      </div>
      <div class="payment__row">
        <CustomInputDropdown
            :options="this.contragents"
            label="Контрагент"
            :required="true"
            @change_input="(val) => this.contragentsFilterFunc('name', val)"
            optionValueKey="contragent_id"
            :loading="this.selectLoading.name"
            optionLabelKey="contragent"
            :value="this.formData.contragent || ''"
            @select_item="this.contragentsSelectItem"
        />
        <div>
          <CustomCheckbox
              :value="!!this.formData.is_not_agreement"
              label="Без договора"
              @change="(value) => onChange('is_not_agreement', value)"
          />
          <CustomCheckbox
              :value="!!this.formData.is_return"
              label="Возврат"
              @change="(value) => onChange('is_return', value)"
          />
        </div>
      </div>
      <div class="payment__row" v-if="this.formData.finance_payment_type_code === 'AVANCE' && !this.data?.body">
        <CustomSelect
            :options="this.agreements"
            label="Выберите договор"
            :required="true"
            optionValueKey="contractor_agreement_list_id"
            optionLabelKey="agreement_name"
            :value="this.formData.contractor_agreement_list_id || ''"
            @change="(value) => onChange('contractor_agreement_list_id', value)"
        />
      </div>
      <div class="payment__row payment__row_two">
        <CustomInputDropdown
            :options="this.contragents"
            label="БИН"
            :required="true"
            @change_input="(val) => this.contragentsFilterFunc('bin', val)"
            optionValueKey="contragent_id"
            :loading="this.selectLoading.bin"
            optionLabelKey="contragent"
            :value="this.formData.bin || ''"
            @select_item="this.contragentsSelectItem"
        />
        <CustomInput
            label="ИИК"
            :required="true"
            :value="this.formData.iik"
            @change_input="(value) => onChange('iik', value)"
        />
      </div>
      <div class="payment__row payment__row_two">
        <CustomSelect
            :options="this.bikList"
            label="БИК"
            :required="true"
            optionValueKey="bik"
            optionLabelKey="name_bik"
            :value="this.formData.bik || ''"
            @change="(value) => onChange('bik', value)"
        />
        <div class="payment__subRow">
          <CustomInput
              label="КБе"
              :required="true"
              :value="this.formData.kbe"
              @change_input="(value) => onChange('kbe', value)"
          />
          <CustomInput
              label="КНП"
              :required="true"
              :value="this.formData.knp"
              @change_input="(value) => onChange('knp', value)"
          />
        </div>
      </div>
      <div class="payment__row">
        <CustomInput
            label="Назначение платежа"
            :required="true"
            type="textarea"
            :value="this.formData.payment_purpose"
            @change_input="(value) => onChange('payment_purpose', value)"
        />
      </div>
      <div class="payment__row">
        <CustomInput
            label="Комментарий"
            type="textarea"
            :value="this.formData.comments"
            @change_input="(value) => onChange('comments', value)"
        />
      </div>
      <div class="payment__remonts">
        <span class="payment__remonts_title" @click="addRemont">
          <q-icon name="fa-solid fa-plus payment__remonts_add"/>
          Добавить ремонт
        </span>
        <ul
            v-for="(item, index) in this.formData.remonts"
            :key="item.id"
            class="payment__remonts_list"
        >
          <li class="payment__remonts_listItem">
            <CustomInput
                label="ID ремонта"
                :value="item.remont_id"
                :required="true"
                type="number"
                v-debounce:600="
                (value) => this.remontItemChange('remont_id', value, index)
              "
                @clear_input="() => this.remontItemChange('remont_id', '', index)"
            />
            <CustomInputNumber
                label="Сумма платежа"
                :value="item.remont_payment_amount || ''"
                @change_input="
                (value) =>
                  this.remontItemChange('remont_payment_amount', value, index)
              "
            />
            <q-icon
                name="fa-solid fa-rectangle-xmark payment__remonts_remove"
                @click="this.removeRemont(index)"
            />
          </li>
        </ul>
      </div>
      <CustomModalFooter :btn-loading="this.loading"/>
    </q-form>
  </div>
</template>
<script>
import CustomSelect from "@/components/form/CustomSelect";
import CustomDate from "@/components/form/CustomDate";
import CustomInput from "@/components/form/CustomInput";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import {
  getAddPaymentModalMetaData, getContragentAgreements,
  getContragentInfo,
  getRemontsPaymentPurpose,
} from "@/views/FinancePayments/services";
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomInputDropdown from "@/components/form/CustomInputDropdown";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";
import CustomInputNumber from "@/components/form/CustomInputNumber";
import CustomUploader from "@/components/form/CustomUploader";

export default {
  name: "AddFinancePayment",
  props: ["data"],
  components: {
    CustomUploader,
    CustomInputNumber,
    CustomInputDropdown,
    CustomModalFooter,
    CustomSelect,
    CustomDate,
    CustomCheckbox,
    CustomInput,
  },
  data() {
    const body = this.data?.body || {};
    let remonts = []
    if (body.remonts?.length) {
      remonts = body?.remonts?.map(item => ({...item, id: item.remont_costs_id}))
    }
    return {
      formData: {
        initiator_company_id: "",
        finance_payment_type_id: "",
        bin: "",
        bik: "",
        knp: "",
        kbe: "",
        iik: "",
        payment_purpose: "",
        comments: "",
        contragent: "",
        pay_date: "",
        is_return: false,
        is_not_agreement: false,
        ...body,
        remonts
      },
      contragent: {
        contragent_id: body?.contragent_id || null,
        contragent_type_code: body?.contragent_type_code || null,
      },
      companies: [],
      bikList: [],
      agreements: [],
      paymentTypes: [],
      contragents: [],
      selectLoading: {name: false, bin: false},
      loading: false,
    };
  },
  async mounted() {
    const res = await getAddPaymentModalMetaData();
    if (!res) return;
    const [companiesResponse, bikResponse, paymentTypesResponse] = res;
    this.companies = companiesResponse.initiator_companies;
    this.bikList = bikResponse.bank_bik;
    this.paymentTypes = paymentTypesResponse.finance_payment_types;
  },
  methods: {
    async onSubmit() {
      if (
          !this.formData.payment_amount ||
          this.formData.payment_amount === "0.00"
      )
        return this.showErrorSnackbar("Введите сумму платежа");
      if (!!this.formData.remonts?.length) {
        const remontsAmount = this.getRemontsAmount();
        if (+remontsAmount !== +this.formData.payment_amount) {
          return this.showErrorSnackbar(
              "Сумма платежа не равна сумме платежей ремонтов"
          );
        }
      }
      const body = {
        ...this.formData,
        is_not_agreement: Number(this.formData.is_not_agreement),
      };
      this.loading = true;
      await this.data?.onSubmit(body);
      this.loading = false;
    },
    async getInfo(body) {
      this.contragents = await getContragentInfo(body);
    },
    async contragentsFilterFunc(type, value) {
      const key = type === "name" ? "contragent" : type;
      this.formData[key] = value;
      if (value?.length >= 3) {
        this.selectLoading[type] = true;
        await this.getInfo({[type]: value});
        this.selectLoading[type] = false;
      } else {
        this.contragents = [];
        if (!value) {
          this.agreements = []
          this.formData.contractor_agreement_list_id = null
        }
      }
    },
    contragentsSelectItem(value, obj) {
      this.formData = {
        ...this.formData,
        ...obj,
        contragent: obj?.contragent_name || "",
      };
      this.contragent = {
        contragent_id: obj.contragent_id,
        contragent_type_code: obj.contragent_type_code,
      };
      this.formData.contractor_agreement_list_id = null
      this.getAgreements()
      this.getPaymentPurpose();
    },
    onChange(type, value, obj) {
      if (type === "is_not_agreement") this.formData.payment_purpose = "Без договора";
      if (type === "finance_payment_type_id") {
        this.formData.finance_payment_type_code = obj?.finance_payment_type_code || null;
        if (obj?.finance_payment_type_code === 'AVANCE')
          this.getAgreements()
        else
          this.agreements = []
      }
      this.formData[type] = value;
    },
    addRemont() {
      const newRemont = {
        remont_id: "",
        remont_payment_amount: "0.00",
        id: Date.now(),
      };
      this.formData.remonts = this.formData.remonts.concat([newRemont]);
    },
    removeRemont(index) {
      this.formData.remonts = this.formData.remonts.filter(
          (_, i) => index !== i
      );
      this.recalculatePaymentAmount();
      this.getPaymentPurpose()
    },
    getRemontsAmount() {
      let value = this.formData.remonts?.reduce(
          (acc, current) => Number(current.remont_payment_amount) + acc,
          0
      );
      return +Number(value).toFixed(2);
    },
    recalculatePaymentAmount() {
      this.formData.payment_amount = this.getRemontsAmount();
    },
    remontItemChange(field, value, index) {
      let isEdited = false;
      this.formData.remonts = this.formData.remonts.map((item, i) => {
        if (index === i) {
          isEdited = !!item?.isEdited;
          return {...item, [field]: value, isEdited: true};
        }
        return item;
      });
      if (field === "remont_payment_amount" && isEdited)
        this.recalculatePaymentAmount();
      if (field === "remont_id") {
        this.getPaymentPurpose();
      }
    },
    async getPaymentPurpose() {
      const {contragent_type_code, contragent_id} = this.contragent;
      if (!contragent_id || !contragent_type_code) return;
      const remont_ids = this.formData.remonts?.filter(item => !!item.remont_id)
      const body = {
        contragent_id,
        contragent_type_code,
        remont_ids: remont_ids?.length ? remont_ids : null,
        finance_payment_type_id: this.formData.finance_payment_type_id,
      };
      this.formData.payment_purpose = await getRemontsPaymentPurpose(body);
    },
    async getAgreements() {
      const res = await getContragentAgreements(this.formData.contragent_id)
      if (!res) return
      this.agreements = res
    },
  },
  setup() {
    const {showErrorSnackbar} = useCustomSnackbar();
    return {showErrorSnackbar};
  },
};
</script>

<style scoped lang="scss">
.payment {
  max-width: 630px;

  &__form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &__row {
    display: grid;
    align-items: flex-end;
    gap: 20px;
    grid-template-columns: 12fr;

    &_two {
      grid-template-columns: 6fr 6fr;
    }

    &_three {
      grid-template-columns: 4fr 4fr 4fr;
    }

    @media (max-width: 576px) {
      grid-template-columns: 12fr;
    }
  }

  &__remonts {
    margin-top: 15px;

    &_title {
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;
      width: fit-content;
    }

    &_add {
      color: #1ab01a;
    }

    &_remove {
      color: #e80028;
      font-size: 21px;
      cursor: pointer;
    }

    &_list {
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 15px;

      &Item {
        display: flex;
        align-items: center;
        gap: 15px;
        list-style: none;
      }
    }
  }

  &__subRow {
    display: grid;
    grid-template-columns: 7fr 5fr;
    gap: 15px;
  }
}
</style>
