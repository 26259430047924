<template>
  <RemontTimelineComponent :headerHeight="this.headerHeight" :client_request_id_prop="this.client_request_id" :show-filters="false" />
</template>

<script>
import RemontTimelineComponent from "@/views/timeline/timeline-remont"
export default {
  name: "RemontTimeline",
  components: {RemontTimelineComponent},
  props: ['client_request_id', 'headerHeight']
}
</script>