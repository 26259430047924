<template>
  <Block title="Информация о клиенте">
    <template #actions>
    </template>
    <div class="requisites__content">
      <div>
        <BlockItem :title="this.data?.client_type_id === 4 ? 'Наименование компании' : 'ФИО собственника квартиры'">{{ this.data?.prop_fio }}</BlockItem>
        <BlockItem :title="this.data?.client_type_id === 4 ? 'БИН собственника квартиры' : 'ИИН собственника квартиры'">{{ this.data?.prop_iin }}</BlockItem>
        <BlockItem title="Документ">
          {{ this.data?.client_type_id !== 4 && this.data?.prop_doc_num ? `${this.data?.prop_doc_num}, ${this.data?.prop_doc_issued}, ${this.data?.prop_doc_date}` : '' }}
        </BlockItem>
        <BlockItem title="Телефон">
          <CustomLoader v-if="this.fetchingPhone.prop_phone" small />
          <span v-else-if="this.phoneNumber.prop_phone">{{ this.formatPhoneNumber(this?.phoneNumber.prop_phone) }}</span>
          <Clickable v-else @click="getPhone('prop_phone')" :href="`tel:${this.phoneNumber.prop_phone}`">
            Показать телефон
          </Clickable>
        </BlockItem>
        <BlockItem title="Email"><a :href="`mailto:${this.data?.email}`">{{ this.data?.email }}</a></BlockItem>
      </div>
      <div>
        <BlockItem title="Контактное лицо">{{ this.data?.prop_contact_fio }}</BlockItem>
        <BlockItem title="Телефон контактного лица">
          <CustomLoader small v-if="this.fetchingPhone?.prop_contact_phone"/>
          <span v-else-if="this.phoneNumber.prop_contact_phone">{{ this.formatPhoneNumber(this?.phoneNumber.prop_contact_phone) }}</span>
          <Clickable @click="getPhone('prop_contact_phone')" v-else :href="`tel:${this.phoneNumber?.prop_contact_phone}`">
            Показать телефон</Clickable></BlockItem>
        <BlockItem title="Тип клиента">{{ this.data?.client_type_name }}</BlockItem>
        <BlockItem title="Сотрудник БИГ">{{ this.data?.big_employee_fio || 'Не выбран' }}</BlockItem>
      </div>
    </div>
  </Block>
</template>

<script>
import Block from "@/components/common/Block";
import BlockItem from "@/components/common/BlockItem";
import {formatPhoneNumber} from "@/services";
import Clickable from "@/components/common/Clickable";
import {getRequisitesPhoneData} from "@/views/ClientRequests/requisites/services";
import CustomLoader from "@/components/common/CustomLoader";

export default {
  name: "clientInfoBlock",
  props: ["data", "client_request_id"],
  data() {
    return {
      phoneNumber: {prop_contact_phone: '', prop_phone: ''},
      fetchingPhone: {prop_contact_phone: false, prop_phone: false}
    }
  },
  components: {CustomLoader, Clickable, Block, BlockItem},
  methods: {
    formatPhoneNumber,
    async getPhone(name) {
      if (!!this.phoneNumber[name]) return
      this.fetchingPhone[name] = true
      const res = await getRequisitesPhoneData(this.client_request_id, name)
      this.fetchingPhone[name] = false
      if (!res) return
      this.phoneNumber[name] = res
    }
  }
}
</script>

<style scoped lang="scss">
.requisites {
  &__content {
    display: grid;
    gap: 25px;
    grid-template-columns: 6fr 6fr;

    & > div {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    @media (max-width: 400px) {
      grid-template-columns: 12fr;
    }
  }
}
</style>