<template>
  <q-field :dense="true"
           :unmasked-value="true"
           v-model="model" @clear="clearValue"
           :rules="this.rules()"
           :loading="this.loading" class="input"
           :label="`${this.label || 'Введите'} ${this.required ? '*' : ''}`"
  >
    <template v-slot:control="{ modelValue }">
      <money3 class="q-field__input" v-model="model"
             v-bind="moneyFormatForDirective"  />
    </template>
    <template v-if="value" v-slot:append>
      <q-icon
          name="cancel"
          @click.stop.prevent="clearValue"
          class="cursor-pointer input__icon"
      />
    </template>
  </q-field>
</template>

<script>
import {customInputRules} from "@/components/form/CustomInput/services";

export default {
  name: "CustomInputNumber",
  props: ["value", "label", "required", "loading"],
  data() {
    return {
      moneyFormatForDirective: {
        decimal: '.',
        thousands: ' ',
        prefix: '',
        disableNegative: true,
        suffix: '',
        precision: 2,
        masked: false,
      }
    }
  },
  methods: {
    clearValue() {
      this.$emit("change_input", "");
    },
    rules() {
      return customInputRules({required: this.required})
    },
  },
  computed: {
    model: {
      get() {
        return this.value || null;
      },
      set(newValue) {
        this.$emit("change_input", newValue);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.input {
  min-width: 100px;
  padding-bottom: 0 !important;

  &__icon {
    font-size: 19px;
  }
}
</style>
