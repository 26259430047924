<template>
  <div :class="[{purpose: this.data?.body?.purpose}]">
    <q-form @submit="this.onSubmit" class="form">
      <div class="form__item">
        <CustomSelect
          :options="this.grades"
          label="Грейд"
          optionValueKey="grade_id"
          optionLabelKey="grade_name"
          :value="this.formData.grade_id || ''"
          @change="(value) => onChange('grade_id', value)"
          :required="true"
        />
      </div>
      <CustomModalFooter :btn-loading="this.loading" />
    </q-form>
  </div>
</template>
<script>

import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomSelect from "@/components/form/CustomSelect";
import {getGrades} from "@/views/timeline/services";

export default {
  name: "change-remont-grade",
  components: {CustomSelect, CustomModalFooter},
  props: ['data'],
  data() {
    return {
      loading: false,
      grades: [],
      formData: {
        grade_id: this.data?.body?.grade_id || '',
      }
    }
  },
  methods: {
    async getGradesData() {
      const res = await getGrades()
      if(!res) return
      this.grades = res
    },
    onChange(key, value) {
      this.formData[key] = value
    },
    async onSubmit() {
      this.loading = true
      await this.data?.onSubmit(this.formData)
      this.loading = false
    },
  },
  mounted() {
    this.getGradesData()
  }
}
</script>

<style scoped lang="scss">
.form {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  gap: 15px;

  @media (max-width: 428px) {
    min-width: 100%;
  }
}
</style>
