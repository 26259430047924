import instance from "@/services/api";

export const handbookStepActivityAPI = {
  async getList() {
    return await instance().get('/timeline/handbook/step_activity/read/').then(res => res?.data)
  },
  async addStepActivity(body) {
    return await instance().post(`/timeline/handbook/step_activity/add/`, body).then(res => res?.data)
  },
  async updateStepActivity(step_activity_id, body) {
    return await instance().put(`/timeline/handbook/step_activity/${step_activity_id}/update/`, body).then(res => res?.data)
  },
  async deleteStepActivity(step_activity_id) {
    return await instance().delete(`/timeline/handbook/step_activity/${step_activity_id}/delete/`).then(res => res?.data)
  },
  async getSteps() {
    return await instance().get(`/timeline/handbook/steps/read/`).then(res => res?.data)
  },
}

