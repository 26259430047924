<template>
  <WarrantyRequestsFilters :resetFilters="this.resetFilters" :is-fetching="this.isFetching" :filters="this.filters"
                           :handle-filters-submit="this.onFiltersSubmit" :on-filters-change="this.onFiltersChange" />
  <WarrantyRequestsBlock :is-fetching="this.isFetching" :is-all="true" :filters="this.filters" :data="this.data" :on-change="this.onDataChange"/>
</template>

<script>
import WarrantyRequestsBlock from "@/views/ClientRequests/warrantyRequests/warrantyRequests";
import {getAllWarrantyRequests, warrantyFiltersInitialValue} from "@/views/ClientRequests/warrantyRequests/services";
import WarrantyRequestsFilters from "@/views/WarrantyRequests/filters";

export default {
  name: "WarrantyRequests",
  components: {WarrantyRequestsFilters, WarrantyRequestsBlock},
  data() {
    return {
      isFetching: false,
      data: [],
      filters: {
        ...warrantyFiltersInitialValue
      }
    }
  },
  methods: {
    async getData(params = {}) {
      this.isFetching = true
      const res = await getAllWarrantyRequests(params)
      this.isFetching = false
      if (!res) return
      const item = res[0]
      this.data = item?.data || []
    },
    async onDataChange(res) {
      if (!res) return
      const item = res[0]
      this.data = item?.data || []
    },
    resetFilters() {
      this.filters = {...warrantyFiltersInitialValue}
    },
    onFiltersChange(key, value) {
      if(key === 'is_big_request' && !value)
        return this.filters.is_big_request = '0'
      this.filters[key] = value
    },
    onFiltersSubmit() {
      this.getData(this.filters)
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style scoped>

</style>