<template>
  <div :class="[{purpose: this.data?.body?.purpose}]">
    <q-form @submit="this.onSubmit" class="form">
      <div class="form__item">
        <CustomInput label="Сообщение" type="textarea" rows="5" :value="this.formData.message"
                     @change_input="(value) => onChange('message', value)" :required="true"/>
      </div>
      <div class="form__item">
        <CustomUploader :value="this.formData?.files" :on-change="(value) => this.onChange('files', value)" label="Файлы" />
      </div>
      <CustomModalFooter :btn-loading="this.loading"/>
    </q-form>
  </div>
</template>
<script>

import CustomInput from "@/components/form/CustomInput";
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomUploader from "@/components/form/CustomUploader";

export default {
  name: "edit-chat-message",
  components: {CustomUploader, CustomInput, CustomModalFooter},
  props: ['data'],
  data() {
    return {
      loading: false,
      formData: {
        message: this.data?.body?.message || '',
        files: []
      }
    }
  },
  methods: {
    onChange(key, value) {
      this.formData[key] = value
    },
    async onSubmit() {
      this.loading = true
      await this.data?.onSubmit(this.formData)
      this.loading = false
    },
  },
  computed: {
    costsTypes() {
      return this.$store.state.app.costsTypes;
    },
  }
}
</script>

<style scoped lang="scss">
.form {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  gap: 20px;

  @media (max-width: 428px) {
    min-width: 100%;
  }
}
</style>