<template>
  <div class="wrapper">
    <CustomTable
        title="Фото"
        :rowsData="this.photosData"
        :columns="checkListModalColumns"
        :loading="isFetching"
        :custom-body="true"
        :hide-footer-title="true"
    >
      <template #customBody="props">
        <q-tr>
          <custom-table-cell align="center">
            {{ props.row.number }}
          </custom-table-cell>
          <custom-table-cell align="center">
            {{ props.row.date_create }}
          </custom-table-cell>
          <custom-table-cell align="left">
            {{ props.row.fio }}
          </custom-table-cell>
          <custom-table-cell align="left">
            {{ props.row.comments }}
          </custom-table-cell>
          <custom-table-cell align="left">
            <a :href="this.getFullUrl(props?.row?.content_url)" target="_blank">
              <CustomImg :src="props?.row?.content_url" class-name="image" />
            </a>
          </custom-table-cell>
          <custom-table-cell align="center">
            {{ props.row.is_accepted === 1 ? 'Принята' : 'Не принята' }}
          </custom-table-cell>
        </q-tr>
      </template>
    </CustomTable>
  </div>
</template>

<script>
import CustomLoader from "@/components/common/CustomLoader";
import {checkListModalColumns, getCheckListPhotos} from "@/components/modal/modals/check-list-photos/services";
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import CustomImg from "@/components/common/CustomImg";
import {getFullUrl} from "@/services";

export default {
  name: "CheckListPhotosModal",
  components: {CustomImg, CustomTable, CustomLoader, CustomTableCell},
  props: ['data'],
  data() {
    return {
      checkListModalColumns,
      isFetching: false,
      photosData: [],
    }
  },
  methods: {
    getFullUrl,
    async getData() {
      this.loading = true
      const res = await getCheckListPhotos(this.data.client_request_id, this.data.check_list_id)
      this.loading = false
      if (!res) return
      this.photosData = res
    },
  },
  mounted() {
    this.getData()
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  width: 800px;
  min-height: 300px;

  @media (max-width: 991px) {
    width: 100%;
  }
}

.image {
  width: 180px;
}
</style>