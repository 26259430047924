<template>
  <div class="login">
    <div class="login__wrapper">
      <img src="@/assets/img/logo_full.svg" alt="logo_picture" />

      <q-form @submit="onSubmit" class="login__form">
        <BackBtn v-if="this.showCodeInput" @click="this.onBack"/>
        <CustomInput
          class="login__form_item q-mb-sm"
          v-model="email"
          type="email"
          label="Email *"
          lazy-rules
          :rules="[(val) => (val && val.length > 0) || 'Введите email']"
        />
        <CustomInput
          class="login__form_item q-mb-sm"
          type="password"
          v-model="password"
          label="Password *"
          lazy-rules
          :rules="[(val) => (val && val.length > 0) || 'Введите пароль']"
        />

        <div v-if="this.showCodeInput" class="login__form_itemCode">
          <CustomInput
            class="login__form_item q-mb-sm"
            v-model="code_key"
            label="Код *"
            input-class="login__form_itemInput"
            lazy-rules
            type="number"
            :rules="[(val) => (val && val.length > 0) || 'Введите код']"
          />
          <q-btn
            color="secondary"
            :loading="this.codeLoading"
            :disable="this.codeLoading"
            class="login__form_itemCodeBtn"
            @click="this.sendCodeData"
          >
            <div>
              Получить код
              <q-icon
                name="fa-solid fa-paper-plane"
                class="login__form_itemIcon"
              />
            </div>
          </q-btn>
        </div>
        <q-btn
          class="login__submit"
          label="Войти"
          type="submit"
          color="primary"
          :disable="this.loading || this.btnDisabled"
          :loading="this.loading"
        />
        <router-link :to="pages.forgetPassword" class="login__link">
          <p class="login__forget">Забыли пароль</p>
        </router-link>
      </q-form>
    </div>
  </div>
</template>

<script>
import {doLogin, sendLoginCode} from "./services";
import {PAGES} from "@/constants";
import CustomInput from "@/components/form/CustomInput";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";
import BackBtn from "@/components/common/BackBtn";

export default {
  name: "Login",
  components: {BackBtn, CustomInput},
  data() {
    return {
      loading: false,
      btnDisabled: false,
      codeLoading: false,
      showCodeInput: false,
      email: "",
      password: "",
      code_key: "",
    };
  },
  setup() {
    const { showSucceedSnackbar } = useCustomSnackbar();
    return { showSucceedSnackbar };
  },
  methods: {
    async onSubmit() {
      const body = { login: this.email, password: this.password };
      if (this.code_key && this.showCodeInput) {
        body.code_key = this.code_key;
      }
      this.loading = true;
      const res = await doLogin(body);
      this.loading = false;
      if (res) {
        if (res.is_device_exist === false) {
          this.showCodeInput = true;
          return;
        }
        this.btnDisabled = true
        window.location.reload()
      }
    },
    async sendCodeData() {
      const body = {
        login: this.email,
        password: this.password,
      };
      this.codeLoading = true;
      const res = await sendLoginCode(body);
      this.codeLoading = false;
      if (res) this.showSucceedSnackbar("Код был отправлен на вашу почту");
    },
    onBack() {
      this.showCodeInput = false;
    },
  },
  mounted() {
    if (this.isAuth) this.$router.push("/");
  },
  computed: {
    pages() {
      return PAGES;
    },
    isAuth() {
      return this.$store.state.app.auth;
    },
  },
};
</script>

<style scoped lang="scss">
.login {
  display: flex;
  justify-content: center;
  background-color: #f5f5f5;

  &__wrapper {
    width: 440px;
    background-color: #fff;
    padding: 50px 45px;
    height: 100vh;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  &__form {
    width: 100%;
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    &_item {
      margin-top: 0;
      margin-left: 0;
      width: 100%;
      border-radius: 4px !important;

      &Code {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 15px;

        &Btn {
          font-size: 12px;
          white-space: nowrap;

          & > div {
            display: flex;
            align-items: center;
            gap: 5px;
            justify-content: space-between;
          }
        }
      }

      &Icon {
        font-size: 15px !important;
        margin-left: 3px;
      }
    }
  }

  &__submit {
    width: 100%;
    height: 45px;
    margin-left: 0;
    margin-top: 10px;
  }

  &__forget {
    color: #262446;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    margin-top: 20px;
    transition: all 0.3s;

    &:hover {
      color: #2a2759;
      text-decoration: underline;
    }
  }

  &__link {
    text-decoration: none;
  }
}
</style>
