<template>
  <div class="login">
    <div class="login__wrapper">
      <img src="@/assets/img/logo_full.svg" alt="logo_picture" />
      <q-form @submit="onSubmit" class="login__form">
        <CustomInput
          class="login__form_item q-mb-sm"
          v-model="email"
          label="Email *"
          lazy-rules
          :rules="[(val) => (val && val.length > 0) || 'Введите email']"
        />
        <q-btn
          class="login__submit"
          label="Сбросить"
          type="submit"
          color="primary"
          :disable="this.loading"
          :loading="this.loading"
        />
        <router-link :to="pages.login" class="login__link">
          <p class="login__forget">Войти</p>
        </router-link>
      </q-form>
    </div>
  </div>
</template>

<script>
import {PAGES} from "@/constants";
import CustomInput from "@/components/form/CustomInput";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";
import {onForgetPasswordSubmit} from "@/views/ForgetPassword/services";

export default {
  name: "ForgetPassword",
  components: {CustomInput},
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar();
    return {showSucceedSnackbar};
  },
  methods: {
    async onSubmit() {
      const body = { login: this.email };
      this.loading = true;
      const res = await onForgetPasswordSubmit(body)
      this.loading = false;
      if (res) {
        this.showSucceedSnackbar("Новый пароль отправлен на почту");
        this.$router.push("/login");
      }
    },
  },
  computed: {
    pages() {
      return PAGES;
    },
  },
  data() {
    return {
      loading: false,
      email: "",
    };
  },
};
</script>

<style scoped lang="scss">
.login {
  display: flex;
  justify-content: center;
  background-color: #f5f5f5;
  &__wrapper {
    width: 440px;
    background-color: #fff;
    padding: 50px 45px;
    height: 100vh;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  &__form {
    width: 100%;
    margin-top: 45px;

    &_item {
      margin-top: 0;
      margin-left: 0;
      width: 100%;
      border-radius: 4px !important;
    }
  }

  &__submit {
    width: 100%;
    height: 45px;
    margin-left: 0;
    margin-top: 10px;
  }

  &__forget {
    color: #262446;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    margin-top: 20px;
    transition: all 0.3s;

    &:hover {
      color: #2a2759;
      text-decoration: underline;
    }
  }

  &__link {
    text-decoration: none;
  }
}
</style>
