<template>
  <div class="wrapper">
    <CustomTable
        :rows-data="this.history"
        :columns="this.participantsHistoryColumns"
        :custom-body="false"
        :loading="this.isFetching"
        :hide-footer-title="true"
    />
  </div>
</template>

<script>
import {getParticipantsHistoryData, participantsHistoryColumns} from "@/components/modal/modals/participants-replacement-history/services";
import CustomTable from "@/components/common/CustomTable";

export default {
  name: "ParticipantsReplacementHistory",
  components: {CustomTable},
  props: ['data'],
  data() {
    return {
      history: [],
      isFetching: false,
      participantsHistoryColumns
    }
  },
  methods: {
    async getData() {
      this.isFetching = true
      const res = await getParticipantsHistoryData(this.data?.client_request_id)
      this.isFetching = false
      this.history = res || []
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style scoped>
  .wrapper {
    max-width: 700px;
    margin-top: 10px;
  }
</style>