<template>
  <div class="wrapper">
    <q-linear-progress v-if="this.isFetching" indeterminate/>
    <div class="content" v-else>
      <CustomTable
          v-if="!!this.lostRemonts?.length"
          title="Затерянные ремонты"
          :rowsData="this.lostRemonts" :show-search="true"
          :columns="this.getHeadItems(this.lostRemonts)"
          :custom-body="true" :small="true"
          :hide-footer-title="true" :sticky-header="false"
      >
        <template #customBody="props">
          <q-tr :props="props">
            <custom-table-cell v-for="col in props.cols" :key="col.name" auto-width vertical-align="top">
            <span v-if="col.name==='ID заявки' || col.name==='client_request_id'">
                      <Clickable router-link :href="`/${PAGES_HEADS.requests}/${col.value}`">
                        {{ col.value }}
                      </Clickable>
                    </span>
              <span v-else-if="col.name==='ID ремонта' || col.name==='remont_id'">
                      <Clickable router-link :href="`/${PAGES_HEADS.remont}/${col.value}`">
                        {{ col.value }}
                      </Clickable>
                    </span>
              <span v-else>{{ col.value }}</span>
            </custom-table-cell>
          </q-tr>
        </template>
      </CustomTable>
      <div class="info" v-else>
        Затерянные ремонты отсутствуют
      </div>
      <CustomTable
          v-if="!!this.doubleRemonts?.length"
          title="Двойные ремонты"
          :rowsData="this.doubleRemonts" :show-search="true"
          :columns="this.getHeadItems(this.doubleRemonts)"
          :custom-body="true" :small="true"
          :hide-footer-title="true" :sticky-header="false"
      >
        <template #customBody="props">
          <q-tr :props="props">
            <custom-table-cell v-for="col in props.cols" :key="col.name" auto-width vertical-align="top">
            <span v-if="col.name==='ID заявки' || col.name==='client_request_id'">
                      <Clickable router-link :href="`/${PAGES_HEADS.requests}/${col.value}`">
                        {{ col.value }}
                      </Clickable>
                    </span>
              <span v-else-if="col.name==='ID ремонта' || col.name==='remont_id'">
                      <Clickable router-link :href="`/${PAGES_HEADS.remont}/${col.value}`">
                        {{ col.value }}
                      </Clickable>
                    </span>
              <span v-else>{{ col.value }}</span>
            </custom-table-cell>
          </q-tr>
        </template>
      </CustomTable>
      <div class="info" v-else>
        Двойные ремонты отсутствуют
      </div>
    </div>
    <CustomModalFooter :onCancel="this.closeModal" :hide-submit-btn="true" cancel-btn-text="Закрыть"/>
  </div>
</template>
<script>

import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import {executiveReportDeadlineViewColumns} from "@/components/modal/modals/executive-report-deadline-view/services";
import {getExecutiveReportAnalyze} from "@/views/ExecutiveReport/services";
import Clickable from "@/components/common/Clickable";
import {PAGES_HEADS} from "@/constants";

export default {
  name: "executive-report-analyze",
  components: {Clickable, CustomTableCell, CustomTable, CustomModalFooter},
  props: ['data', 'closeModal'],
  data() {
    return {
      PAGES_HEADS,
      executiveReportDeadlineViewColumns,
      lostRemonts: [],
      doubleRemonts: [],
      isFetching: false
    }
  },
  methods: {
    async getData() {
      this.isFetching = true
      const res = await getExecutiveReportAnalyze(this.data?.filters);
      this.isFetching = false
      if (!res) return
      const {doubleRemonts, lostRemonts} = res
      this.doubleRemonts = doubleRemonts
      this.lostRemonts = lostRemonts
    },
    getHeadItems(data) {
      if (!data?.length) return [];
      return Object.keys(data[0])
          .map(label => ({label, name: label, field: label, align: 'left', sortable: true}))
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  margin-top: 5px;
  min-width: 500px;
}
.content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.info {
  display: flex;
  align-items: center;
  background-color: #d9eef9;
  color: #3867b3;
  font-size: 13px;
  padding: 10px;
  border: 1px solid #b7d7e7;
}

@media (max-width: 576px) {
  .wrapper {
    min-width: 100%;
  }
}
</style>
