import {remontProjectsAPI} from "@/views/ClientRequests/remontProjects/api";

const remontProjectsBlockNames = {
  OA__RemontFormProjectRemontBlock: "remontProjectsBlock",
  OA__RemontForm3DMeasurementsBlock: "remontProjectMeasurement",
}
export const getRemontProjectsData = async (client_request_id) => {
  try {
    const res = await remontProjectsAPI.getList(client_request_id)
    const data = res?.data
    if (!data?.length) return
    return data?.map(item => ({...item, component: remontProjectsBlockNames[item.grant_code]})).sort((a, b) => {
      if (a.component === "remontProjectMeasurement")
        return -1
      else if (b.component === 'remontProjectMeasurement')
        return 1
      return 0
    })
  } catch (e) {
  }
}

export const addRemontProject = async (client_request_id, body) => {
  try {
    const res = await remontProjectsAPI.addRemontProject(client_request_id, body)
    return res?.data
  } catch (e) {
  }
}
export const updateRemontProject = async (client_request_id, project_remont_id, body) => {
  try {
    const res = await remontProjectsAPI.updateRemontProject(client_request_id, project_remont_id, body)
    return res?.data
  } catch (e) {
  }
}
export const updateMeasureReference = async (client_request_id, body) => {
  try {
    const res = await remontProjectsAPI.updateMeasureReference(client_request_id, body)
    return res?.data
  } catch (e) {
  }
}
export const deleteRemontProject = async (client_request_id, project_remont_id) => {
  try {
    const res = await remontProjectsAPI.deleteRemontProject(client_request_id, project_remont_id)
    return res?.data
  } catch (e) {
  }
}
export const acceptRemontProject = async (client_request_id) => {
  try {
    const res = await remontProjectsAPI.acceptRemontProject(client_request_id)
    return res?.data
  } catch (e) {
  }
}
export const refundRemontProject = async (client_request_id, project_remont_id) => {
  try {
    const res = await remontProjectsAPI.refundRemontProject(client_request_id, project_remont_id)
    return res?.data
  } catch (e) {
  }
}
export const chatSendRemontProject = async (client_request_id, project_remont_id) => {
  try {
    const res = await remontProjectsAPI.chatSendRemontProject(client_request_id, project_remont_id)
    return res?.data
  } catch (e) {
  }
}
export const remontProjectsColumns = [
  {
    name: "project_remont_id",
    label: "ID",
    field: "project_remont_id",
    align: "center",
  },
  {
    name: "project_remont_name",
    label: "Наименование",
    field: "project_remont_name",
    align: "left",
  },
  {
    name: "project_remont_url",
    label: "Файл",
    field: "project_remont_url",
    align: "center",
  },
  {
    name: "3d_visualization",
    label: "3D визуализация",
    field: "3d_visualization",
    align: "center",
  },
  {
    name: "project_remont_comment",
    label: "Комментарий",
    field: "project_remont_comment",
    align: "left",
  },
  {
    name: "is_final",
    label: "Финальный",
    field: "is_final",
    align: "center",
  },
  {
    name: "project_accepted",
    label: "Утвержден",
    field: "project_accepted",
    align: "center",
  },
  {
    name: "is_send_chat",
    label: "Отправка в чат",
    field: "is_send_chat",
    align: "center",
  },
  {
    name: "fio",
    label: "Пользователь",
    field: "fio",
    align: "left",
  },
  {
    name: "rowversion",
    label: "Версия записи",
    field: "rowversion",
    align: "center",
  },
]