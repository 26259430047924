<template>
  <div class="techFeatures__header">
    Технические особенности
  </div>
  <div class="techFeatures__editor">
    <quill-editor v-model:content="techFeatureString" content-type="html" toolbar="full"/>
  </div>
  <div class="techFeatures__footer">
    <q-btn class="techFeatures__submit" color="primary" size="md" padding="s" :loading="this.loading"
           @click="this.updateTextFeatureString">Сохранить
    </q-btn>
  </div>
</template>

<script>
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";
import {updateTechFeatures} from "@/views/ClientRequests/techFeatures/services";
import {QuillEditor} from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'

export default {
  name: "TechFeaturesBlock",
  components: {
    QuillEditor
  },
  props: ["client_request_id", "data", "isFetching"],
  data() {
    return {
      loading: false,
      techFeatureString: this.data || '',
    }
  },
  methods: {
    async updateTextFeatureString() {
      this.loading = true
      const res = await updateTechFeatures(this.client_request_id, this.techFeatureString)
      this.loading = false
      if (!res) return
      this.techFeatureString = res
      this.showSucceedSnackbar()
    },
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar();
    return {showSucceedSnackbar};
  },
}
</script>

<style scoped lang="scss">
.techFeatures {

  &__header {
    margin-bottom: 12px;
  }

  &__editor {
    height: 400px;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
  }
  &__submit {
    margin-top: 55px;
  }
}
</style>
