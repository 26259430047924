<template>
  <div class="draft__wrapper">
    <h3 class="draft__title">История проверок ЧО</h3>
    <CustomTable
        :rowsData="this.history"
        :columns="draftCheckHistoryColumns"
        :custom-body="true"
        :hide-footer-title="true"
    >
      <template #customBody="props">
        <q-tr>
          <custom-table-cell align="center">
            {{ props.row.index }}
          </custom-table-cell>
          <custom-table-cell align="left">
            {{ props.row.client_request_check_type_name }}
          </custom-table-cell>
          <custom-table-cell align="left">
            <span :class="['draft__status', {draft__status_accept: !!props.row.check_status}]">{{ !!props.row.check_status ? 'Принята' : 'Не принята' }}</span>
          </custom-table-cell>
          <custom-table-cell align="left">
            {{ props.row.check_date }}
          </custom-table-cell>
          <custom-table-cell align="left">
            {{ props.row.fio }}
          </custom-table-cell>
          <custom-table-cell align="center">
            <div v-for="item in props.row.check_file_json">
              <Clickable :link="true" :href="getFullUrl(item?.doc_url)">{{ item?.doc_name }}</Clickable>
            </div>
          </custom-table-cell>
        </q-tr>
      </template>
    </CustomTable>
    <h3 class="draft__title small">Вложения</h3>
    <CustomTable
        :rowsData="this.documents"
        :columns="draftCheckAttachmentsColumns"
        :custom-body="true"
        :hide-footer-title="true"
    >
      <template #customBody="props">
        <q-tr>
          <custom-table-cell align="center">
            {{ props.row.index }}
          </custom-table-cell>
          <custom-table-cell align="left">
            {{ props.row.document_type_name }}
          </custom-table-cell>
          <custom-table-cell align="left">
            {{ props.row.doc_date }}
          </custom-table-cell>
          <custom-table-cell align="left">
            {{ props.row.fio }}
          </custom-table-cell>
          <custom-table-cell align="center">
            <a target="_blank" :href="getFullUrl(props.row?.document_url)">
              <q-icon class="draft__download" name="download"/>
            </a>
          </custom-table-cell>
        </q-tr>
      </template>
    </CustomTable>

  </div>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import {draftCheckAttachmentsColumns, draftCheckHistoryColumns} from "@/views/ClientRequests/remontStages/services";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import Clickable from "@/components/common/Clickable";
import {getFullUrl} from "@/services";

export default {
  name: "DraftCheckBlock",
  components: {Clickable, CustomTableCell, CustomTable},
  props: ["data", "client_request_id"],
  data() {
    return {
      draftCheckHistoryColumns,
      draftCheckAttachmentsColumns,
      isFetching: false,
      history: this.data.history?.map((item, i) => ({...item, index: i + 1})),
      documents: this.data.documents?.map((item, i) => ({...item, index: i + 1}))
    }
  },
  methods: {
    getFullUrl
  }
}
</script>

<style scoped lang="scss">
.draft {
  &__wrapper {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  &__title {
    font-size: 20px;
    margin: 0;

    &.small {
      font-size: 16px;
    }
  }

  &__download {
    font-size: 20px;
    color: #1f5ac2;
    cursor: pointer;
  }
  &__status {
    color: #d90d0d;
    &_accept {
      color: #1ab01a;
    }
  }
}
</style>