import instance from "@/services/api";

export const timelineAPI = {
  async getGrades() {
    return await instance().get('/common/grades/read/').then(res => res?.data)
  },
  async getGradeTimeline(params) {
    return await instance().get('/timeline/grade_step/read/', {params}).then(res => res?.data)
  },
  async saveGradeNodePosition(body, params) {
    return await instance().post('/timeline/grade_step/move/', body, {params}).then(res => res?.data)
  },
  async getRemontTimeline(params) {
    return await instance().get('/timeline/remont_step/read/', {params}).then(res => res?.data)
  },
  async isHaveCheckAccess() {
    return await instance().get('/timeline/remont_step/check/has_access/').then(res => res?.data)
  },
  async changeActive(body, params) {
    return await instance().put('/timeline/remont_step/is_stop_timeline/update/', body, {params}).then(res => res?.data)
  },
  async remontStepCheck(params) {
    return await instance().post('/timeline/remont_step/check/', {}, {params}).then(res => res?.data)
  },
  async getNotIncludedRequests(grade_step_id) {
    return await instance().get(`/timeline/grade_step/${grade_step_id}/read/step/not_included/`).then(res => res?.data)
  },
  async addStepToRemont(grade_step_id, body) {
    return await instance().post(`/timeline/grade_step/${grade_step_id}/add/step/not_included/`, body).then(res => res?.data)
  },
}