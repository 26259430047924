import { remontMeasureAPI } from "@/views/ClientRequests/remontMeasure/api";

export const remontStagesBlockNames = {
  OA__RemontFormMeasureBlock: "remontMeasureBlock",
  OA__RemontFormDesignMeasureBlock: "remontDesignMeasureBlock",
  OA__RemontFormContractorMeasureBlock: "managerMeasureBlock",
  OA__RemontFormNewMeasureBlock: "roomChange",
};
export const changeDataRow = (rowsData, room_id, material_id, client_material_id, key, value) => {
  try {
    return rowsData?.map((item) => {
      if (item.room_id === room_id)
        return {
          ...item,
          materials: item.materials?.map((material) => {
            if (material.material_id === material_id && material.client_material_id === client_material_id)
              return { ...material, [key]: value };
            return material;
          }),
        };
      return item;
    });
  } catch (error) {
    return rowsData;
  }
};
export const getRemontMeasureData = async (client_request_id) => {
  try {
    const res = await remontMeasureAPI.getList(client_request_id);
    const data = res?.data;
    if (!data?.length) return;
    return data?.map((item) => ({
      ...item,
      component: remontStagesBlockNames[item.grant_code],
    }));
  } catch (e) {}
};
export const getRemontMeasureRoomData = async (
  client_request_id,
  planirovka_room_id
) => {
  try {
    const res = await remontMeasureAPI.getRoomData(
      client_request_id,
      planirovka_room_id
    );
    return res?.data;
  } catch (e) {}
};

export const addMeasureRoom = async (client_request_id, body) => {
  try {
    const res = await remontMeasureAPI.addRoom(client_request_id, body);
    return res?.data;
  } catch (e) {}
};
export const saveMeasureRoomData = async (
  client_request_id,
  planirovka_room_id,
  body
) => {
  try {
    const res = await remontMeasureAPI.saveRoomData(
      client_request_id,
      planirovka_room_id,
      body
    );
    return res?.data;
  } catch (e) {}
};
export const confirmMeasure = async (client_request_id, body) => {
  try {
    const res = await remontMeasureAPI.confirmMeasure(client_request_id, body);
    return res?.data;
  } catch (e) {}
};
export const getMaterialCount = async (
  client_request_id,
  { material_id, room_id },
  data
) => {
  let atom_cnt = null;
  data?.forEach((item) => {
    if (item.room_id === room_id) {
      item.materials?.forEach((material) => {
        if (material.material_id === material_id) atom_cnt = material.atom_cnt;
      });
    }
  });
  if (atom_cnt === null) return;
  const body = { atom_cnt, material_id };
  try {
    const res = await remontMeasureAPI.getMaterialCount(
      client_request_id,
      body
    );
    return res?.data;
  } catch (e) {}
};
export const saveDesignMeasureData = async (client_request_id, data) => {
  try {
    const measures = [];
    data?.forEach((item) => {
      item.materials?.forEach((material) => {
        measures.push(material);
      });
    });
    const body = {
      measures,
    };
    const res = await remontMeasureAPI.saveDesignMeasureData(
      client_request_id,
      body
    );
    return res?.data;
  } catch (e) {}
};
export const saveManagerMeasureData = async (client_request_id, data) => {
  try {
    const measures = [];
    data?.forEach((item) => {
      item.materials?.forEach((material) => {
        measures.push(material);
      });
    });
    const body = {
      measures,
    };
    const res = await remontMeasureAPI.saveManagerMeasureData(
      client_request_id,
      body
    );
    return res?.data;
  } catch (e) {}
};
export const getMaterialDoorDepend = async (client_request_id, body) => {
  try {
    const res = await remontMeasureAPI.getMaterialDoorDepend(
      client_request_id,
      body
    );
    return res?.data;
  } catch (e) {}
};

export const designerMeasureColumns = [
  {
    name: "material_id",
    label: "ID",
    align: "center",
  },
  {
    name: "photo_url",
    label: "Фото",
    align: "center",
  },
  {
    name: "material_name",
    label: "Материал",
    align: "left",
  },
  {
    name: "unit_name",
    label: "Ед.изм.",
    align: "left",
  },
  {
    name: "formula",
    label: "Формула",
    align: "left",
  },
  {
    name: "cnt",
    label: "Кол-во",
    align: "center",
  },
  {
    name: "is_remove",
    label: "Исключить",
    align: "center",
  },
  {
    name: "atom_cnt",
    label: "Кол-во в мин. ед.",
    align: "left",
  },
  {
    name: "material_cnt",
    label: "Кол-во на продаже",
    align: "right",
  },
];

export const managerMeasureColumns = [
  {
    name: "material_id",
    label: "ID",
    align: "center",
  },
  {
    name: "photo_url",
    label: "Фото",
    align: "center",
  },
  {
    name: "material_name",
    label: "Материал",
    align: "left",
  },
  {
    name: "unit_name",
    label: "Ед.изм.",
    align: "left",
  },
  {
    name: "formula",
    label: "Формула",
    align: "left",
  },
  {
    name: "cnt",
    label: "Кол-во",
    align: "center",
  },
  {
    name: "atom_cnt",
    label: "Параметр",
    align: "left",
  },
];
