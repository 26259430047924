<template>
  <div class="agreement__wrapper">
    <FetchingWrapper :loading="this.isFetching" :data="this.data">
      <div v-for="item in this.data">
        <component
          :is="item.component"
          :isFetching="this.isFetching"
          :client_request_id="this.client_request_id"
          :data="item?.data"
        />
      </div>
    </FetchingWrapper>
  </div>
</template>

<script>
import {getAgreementData} from "./services";
import FetchingWrapper from "@/components/common/FetchingWrapper";
import ContractorAgreementBlock from "./contractorAgreement.vue"

export default {
  name: "ContractorAgreement",
  props: ["client_request_id"],
  components: {FetchingWrapper, ContractorAgreementBlock},
  data() {
    return {
      isFetching: false,
      data: [],
    }
  },
  methods: {
    async getData() {
      this.isFetching = true
      const res = await getAgreementData(this?.client_request_id)
      this.isFetching = false
      if (!res) return
      this.data = res
    },
  },
  mounted() {
    this.getData()
  },
}
</script>

<style scoped lang="scss">
.agreement {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
}
</style>
