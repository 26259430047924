<template>
  <component :is="this.pageType"/>
</template>

<script>
import Worksets from './WorkSets'
import NoticeTemplates from './EmailTemplates'
import Providers from './Providers'
import ProvidersAgreement from './ProvidersAgreement'
import MobileApps from './MobileApps'
import Documents from './documents'
import ServiceMasters from './serviceMasters'
import GradeActions from './gradeTimelineActions'
import StepActivities from './stepActivity'
import TimelineSteps from './timelineSteps'
import GradeTimelineSteps from './gradeTimelineSteps'
import TimelineStepConditions from './timlineStepConditions'

export default {
  name: "Handbook",
  components: {
    Worksets,
    NoticeTemplates,
    Providers,
    ProvidersAgreement,
    Documents,
    ServiceMasters,
    MobileApps,
    GradeActions,
    TimelineSteps,
    GradeTimelineSteps,
    TimelineStepConditions,
    StepActivities
  },
  computed: {
    pageType() {
      return this.$route.params?.type
    }
  },
}
</script>
