import {remontWarrantyRequestsAPI} from "@/views/ClientRequests/warrantyRequests/api";
import {getCommonContractors} from "@/views/Costs/СostsFilters/services";
import {getResidentsByCity} from "@/services/auth";
import {getWarrantyStatuses} from "@/components/modal/modals/change-warranty-status/services";

const warrantyBlockNames = {
  OA__RemontFormGrtRequestBlock: "warrantyRequestsBlock",
}

const sortResponse = (res) => {
  try {
    const data = res?.data
    if (!data?.length) return
    return data?.map(item => ({...item, component: warrantyBlockNames[item.grant_code]}))
  } catch (e) {
  }
}
export const getRemontWarrantyRequestsData = async (client_request_id) => {
  try {
    const res = await remontWarrantyRequestsAPI.getList(client_request_id)
    return sortResponse(res)
  } catch (e) {
  }
}
export const getAllWarrantyRequests = async (params = {}) => {
  try {
    const res = await remontWarrantyRequestsAPI.getAllRequests(params)
    return sortResponse(res)
  } catch (e) {
  }
}
export const getRemontWarrantyRequest = async (client_request_id, grt_request_id) => {
  try {
    const res = await remontWarrantyRequestsAPI.getDetail(client_request_id, grt_request_id)
    return res?.data
  } catch (e) {
  }
}
export const changeWarrantyStatus = async (client_request_id, grt_request_id, body) => {
  try {
    const res = await remontWarrantyRequestsAPI.changeWarrantyStatus(client_request_id, grt_request_id, body)
    return sortResponse(res)
  } catch (e) {
  }
}
export const changeWarrantyStatusAll = async (grt_request_id, body, params) => {
  try {
    const res = await remontWarrantyRequestsAPI.changeWarrantyStatusAll(grt_request_id, body, params)
    return sortResponse(res)
  } catch (e) {
  }
}
export const getWarrantyDocuments = async (client_request_id, grt_request_id) => {
  try {
    const res = await remontWarrantyRequestsAPI.getDocuments(client_request_id, grt_request_id)
    return res?.data || []
  } catch (e) {
    return []
  }
}
export const getWarrantyManagers = async () => {
  try {
    const res = await remontWarrantyRequestsAPI.getManagers()
    return res?.data || []
  } catch (e) {
    return []
  }
}
export const searchRemonts = async (search_query) => {
  try {
    const res = await remontWarrantyRequestsAPI.searchRemonts({search_query})
    if (!res?.data?.length) return []
    return res?.data?.map(item => ({
      ...item,
      label: `${item.remont_id}, ${item.client_name}, ${item.resident_name}, ${item.flat_num}, ${item.prop_number}`
    }))
  } catch (e) {
    return []
  }
}
export const getWarrantyRemakeTypes = async () => {
  try {
    const res = await remontWarrantyRequestsAPI.getRemakeTypes()
    return res?.data || []
  } catch (e) {
    return []
  }
}
export const getWarrantyDefectActDocuments = async (grt_request_id) => {
  try {
    const res = await remontWarrantyRequestsAPI.getDefectActDocuments(grt_request_id)
    return res?.data || []
  } catch (e) {
    return []
  }
}
export const getWarrantyAppActDocuments = async (grt_request_id) => {
  try {
    const res = await remontWarrantyRequestsAPI.getAppActDocuments(grt_request_id)
    return res?.data || []
  } catch (e) {
    return []
  }
}
export const getWarrantyDocumentsAll = async (grt_request_id) => {
  try {
    const res = await remontWarrantyRequestsAPI.getDocumentsAll(grt_request_id)
    return res?.data || []
  } catch (e) {
    return []
  }
}
export const deleteWarrantyDocumentAll = async (grt_request_id, grt_photo_id, params) => {
  try {
    const res = await remontWarrantyRequestsAPI.deleteWarrantyDocumentAll(grt_request_id, grt_photo_id, params)
    return sortResponse(res)
  } catch (e) {
  }
}
export const deleteWarrantyDocument = async (client_request_id, grt_request_id, grt_photo_id) => {
  try {
    const res = await remontWarrantyRequestsAPI.deleteWarrantyDocument(client_request_id, grt_request_id, grt_photo_id)
    return sortResponse(res)
  } catch (e) {
  }
}
export const changeWarrantyContractor = async (client_request_id, grt_request_id, body) => {
  try {
    const res = await remontWarrantyRequestsAPI.changeContractor(client_request_id, grt_request_id, body)
    return sortResponse(res)
  } catch (e) {
  }
}
export const changeWarrantyContractorAll = async (grt_request_id, body, params) => {
  try {
    const res = await remontWarrantyRequestsAPI.changeContractorAll(grt_request_id, body, params)
    return sortResponse(res)
  } catch (e) {
  }
}
export const changeWarrantyManager = async (client_request_id, grt_request_id, body) => {
  try {
    const res = await remontWarrantyRequestsAPI.changeManager(client_request_id, grt_request_id, body)
    return sortResponse(res)
  } catch (e) {
  }
}
export const changeWarrantyManagerAll = async (grt_request_id, body, params) => {
  try {
    const res = await remontWarrantyRequestsAPI.changeManagerAll(grt_request_id, body, params)
    return sortResponse(res)
  } catch (e) {
  }
}
export const changeWarrantyDefectAct = async (client_request_id, grt_request_id, body) => {
  try {
    const res = await remontWarrantyRequestsAPI.changeDefectAct(client_request_id, grt_request_id, body)
    return sortResponse(res)
  } catch (e) {
  }
}
export const changeWarrantyDefectActAll = async (grt_request_id, body, params) => {
  try {
    const res = await remontWarrantyRequestsAPI.changeDefectActAll(grt_request_id, body, params)
    return sortResponse(res)
  } catch (e) {
  }
}
export const changeWarrantyAppAct = async (client_request_id, grt_request_id, body) => {
  try {
    const res = await remontWarrantyRequestsAPI.changeAppAct(client_request_id, grt_request_id, body)
    return sortResponse(res)
  } catch (e) {
  }
}
export const changeWarrantyAppActAll = async (grt_request_id, body, params) => {
  try {
    const res = await remontWarrantyRequestsAPI.changeAppActAll(grt_request_id, body, params)
    return sortResponse(res)
  } catch (e) {
  }
}
export const changeWarrantyRequestAll = async (grt_request_id, body, params) => {
  try {
    const res = await remontWarrantyRequestsAPI.changeWarrantyRequestAll(grt_request_id, body, params)
    return sortResponse(res)
  } catch (e) {
  }
}
export const changeWarrantyRequest = async (client_request_id, grt_request_id, body) => {
  try {
    const res = await remontWarrantyRequestsAPI.changeWarrantyRequest(client_request_id, grt_request_id, body)
    return sortResponse(res)
  } catch (e) {
  }
}
export const addWarrantyRequestAll = async (body, params) => {
  try {
    const res = await remontWarrantyRequestsAPI.addWarrantyRequestAll(body, params)
    return sortResponse(res)
  } catch (e) {
  }
}
export const addWarrantyRequest = async (client_request_id, body) => {
  try {
    const res = await remontWarrantyRequestsAPI.addWarrantyRequest(client_request_id, body)
    return sortResponse(res)
  } catch (e) {
  }
}
export const deleteWarrantyRequestAll = async (grt_request_id, params) => {
  try {
    const res = await remontWarrantyRequestsAPI.deleteWarrantyRequestAll(grt_request_id, params)
    return sortResponse(res)
  } catch (e) {
  }
}
export const deleteWarrantyRequest = async (client_request_id, grt_request_id) => {
  try {
    const res = await remontWarrantyRequestsAPI.deleteWarrantyRequest(client_request_id, grt_request_id)
    return sortResponse(res)
  } catch (e) {
  }
}


export const getWarrantyFiltersData = async () => {
  return await Promise.all([
    getResidentsByCity(),
    getWarrantyStatuses(),
    getWarrantyManagers(),
    getCommonContractors(),
    getWarrantyRemakeTypes(),
  ]).catch(() => {
    return null;
  });
};

export const bigRequestOptions = [
  {label: 'Все заявки', value: '0'},
  {label: 'Заявки BIG', value: '1'},
]

export const warrantyFiltersInitialValue = {
  client_name: '',
  date_begin: '',
  date_end: '',
  resident_id: '',
  grt_status_id: '',
  manager_guarantee_employee_id: '',
  is_big_request: '0',
  contractor_grt_id: '',
  grt_request_remake_arr: [],
}
export const remontWarrantyRequestsColumns = [
  {
    name: "info",
    label: "Инфо о заявке",
    align: "left",
  },
  {
    name: "client_info",
    label: "Инфо по клиенту",
    align: "left"
  },
  {
    name: "status",
    label: "Статус заявки",
    align: "left",
  },
  {
    name: "problems",
    label: "Обращение",
    align: "left",
  },
  {
    name: "users",
    label: "Ответственные",
    align: "left",
  },
  {
    name: "defects",
    label: "Дефектный акт",
    align: "left",
  },
  {
    name: "app",
    label: "АПП",
    align: "left",
  },
  {
    name: "actions_edit",
    label: "",
    align: "center",
  },
  {
    name: "actions_remove",
    label: "",
    align: "center",
  },
]

export const allRemontsWarrantyRequestsColumns = [{
  name: "remont_id",
  label: "Ремонт ID",
  align: "center",
}, ...remontWarrantyRequestsColumns]