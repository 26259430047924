<template>
  <div class="footer" >
    <q-btn
        class="footer__btn footer__btn_cancel"
        :label="this.cancelBtnText || 'Отмена'" v-if="!this.hideCancelBtn"
        color="red" icon="close" @click="() => !this.cancelApproveMode && closePopup()"
        :disable="this.loading"
    >
      <PopupConfirm v-if="this.cancelApproveMode" :title="this.cancelApproveTitle || 'Подтвердите действие'"
                    :approve="this.closePopup"/>
    </q-btn>
    <q-btn
        :class="['footer__btn footer__btn_submit', {footer__btn_disabled: this.disableSubmit}]"
        :label="this.submitBtnText || 'Сохранить'" icon="check"
        type="submit"
        color="secondary" v-if="!this.hideSubmitBtn"
        :disable="this.loading || btnLoading || !!this.disableSubmit"
        :loading="this.loading || btnLoading" @click="handleSubmit"
    />
  </div>
</template>

<script>
import {appActions} from "@/services/store/modules/app_module/app_actions.js";
import PopupConfirm from "@/components/common/PopupConfirm";

export default {
  name: "CustomModalFooter",
  components: {PopupConfirm},
  props: ["onSubmit", "onCancel", "hideCancelBtn", "cancelBtnText", "hideSubmitBtn", "submitBtnText", "btnLoading", "cancelApproveMode", "cancelApproveTitle", "disableSubmit"],
  data() {
    return {
      loading: false
    }
  },
  methods: {
    handleSubmit() {
      if(!this.onSubmit) return
      this.loading = true
      this.onSubmit()
      this.loading = false
    },
    closePopup() {
      if(this.onCancel)
        return this.onCancel()
      this.$store.commit(appActions.closeModal());
    },
  }
}
</script>

<style scoped lang="scss">
.footer {
  display: flex;
  gap: 15px;
  justify-content: flex-end;
  margin-top: 20px;
  &__btn {
    font-size: 12px;
    &_disabled {
      background: #c7c7c7 !important;
    }
  }
}
</style>