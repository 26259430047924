<template>
  <div :class="[{purpose: this.data?.body?.purpose}]">
    <q-form @submit="this.onSubmit" class="form">
      <div class="form__item">
        <CustomInput
            label="Название"
            :value="this.formData.notice_template_name"
            @change_input="(value) => onChange('notice_template_name', value)"
        />
      </div>
      <div class="form__item">
        <CustomInput
            label="Код"
            :value="this.formData.notice_template_code"
            @change_input="(value) => onChange('notice_template_code', value)"
        />
      </div>
      <div class="form__item">
        <CustomInput
            rows="15"
            label="Текст"
            type="textarea"
            :value="this.formData.notice_template_content"
            @change_input="(value) => onChange('notice_template_content', value)"
        />
      </div>
      <CustomModalFooter :btn-loading="this.loading"/>
    </q-form>
  </div>
</template>
<script>

import CustomInput from "@/components/form/CustomInput";
import CustomModalFooter from "@/components/modal/modals/modal-footer";

export default {
  name: "add-notice-template",
  components: {CustomInput, CustomModalFooter},
  props: ['data'],
  data() {
    return {
      loading: false,
      formData: {
        notice_template_name: this.data?.body?.notice_template_name || '',
        notice_template_code: this.data?.body?.notice_template_code || '',
        notice_template_content: this.data?.body?.notice_template_content || '',
      }
    }
  },
  methods: {
    onChange(key, value) {
      this.formData[key] = value
    },
    async onSubmit() {
      this.loading = true
      await this.data?.onSubmit(this.formData)
      this.loading = false
    },
  },
}
</script>

<style scoped lang="scss">
.form {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  gap: 15px;

  @media (max-width: 428px) {
    min-width: 100%;
  }
}
</style>
