import instance from "@/services/api";

export const getChangeMaterialRequests = async (type, params = {}) => {
  return await instance().get(`/request/change_req/read/${type}/`, {params}).then(res => res.data)
}
export const getChangeMaterialRequestsStatuses = async () => {
  return await instance().get(`/common/provider_change_req_statuses/read/`).then(res => res.data)
}
export const getChangeMaterialRequestsStatusesAll = async () => {
  return await instance().get(`/common/provider_change_req_statuses/read/all/`).then(res => res.data)
}

export const approveChangeMaterialRequests = async (reqType, body) => {
  return await instance().post(`/request/change_req/approve/${reqType}/`, body).then(res => res.data)
}