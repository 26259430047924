<template>
  <div class="executive__wrapper">
    <div class="executive__header">
      <div style="width: 250px">
        <CustomSelect
            :options="this.reportGroups"
            label="Отчет"
            optionValueKey="mng_report_group_id"
            optionLabelKey="mng_report_group_name"
            :value="this.filters.mng_report_group_id"
            @change="(value) => handleFiltersChange('mng_report_group_id', value)"
        />
      </div>
      <q-btn :disable="!this.filters.mng_report_group_id" type="submit" @click="this.showReportAnalyze" size="sm" no-wrap color="primary">
        Анализ отчета
      </q-btn>
    </div>
    <ExecutiveReportFilters
        :filters="this.filters" :is-fetching="this.isFetching" :handle-filters-submit="this.getData"
        :onFiltersChange="this.handleFiltersChange"
    />
    <q-linear-progress v-if="this.isFetching" indeterminate/>
    <div class="executive__list">
      <NotFoundText v-if="!this.filters.mng_report_group_id && !this.executiveData?.length" text="Выберите отчет"/>
      <div v-for="item in this.executiveData" :key="item.mng_report_id" class="executive__item_wrapper">
        <div :class="['executive__item', {executive__item_active: !!openedItems[item.mng_report_id]?.show}]">
          <div class="executive__item_title">
            <span>{{ item.stage_num }}. </span>
            {{ item.mng_report_name }}
          </div>
          <div class="executive__colors">
            <div
                v-for="color in item.colors"
                :key="color.color_id"
                :class="`executive__colors_item ${
              color?.color_id === this.getRowItem(item.mng_report_id)?.color?.color_id
              ? 'executive__colors_itemActive'
              : ''}`"
                @click="() => onMngColorClick(item.mng_report_id, color)"
                :style="`background-color: #${color.color_hex_code}`"
            >
              <span v-if="!!color.cnt">{{ color.cnt }}</span>
            </div>
          </div>
          <div class="executive__item_right">
            <q-icon @click="() => this.downloadFile(item.mng_report_id, item.mng_report_name)" name="fa-solid fa-file"
                    class="executive__item_rightIcon">
              <q-tooltip>{{ `Печать в excel - ${item.mng_report_name}` }}</q-tooltip>
            </q-icon>
            <CustomPopup v-if="!!item.mng_report_desc">
              <template #head>
                <q-icon name="help" class="executive__item_help"/>
              </template>
              <template #default>
                {{ item.mng_report_desc }}
              </template>
            </CustomPopup>
            <div v-else style="width: 25px;flex-shrink: 0"></div>
          </div>
        </div>
        <div v-show="!!this.getRowItem(item.mng_report_id)?.show"
             style="width: 100%">
          <div :style="`border: 2px solid #${this.getRowItem(item.mng_report_id)?.color?.color_hex_code}`">
            <CustomTable
                v-if="this.getRowItem(item.mng_report_id)?.color?.data?.length" :hide-footer-title="true"
                pagination-position="bottom" :small="true" :small-font="true" :dense="true"
                :custom-body="true" :rows-data="this.getRowItem(item.mng_report_id)?.color?.data"
                :columns="getHeadItems(this.getRowItem(item.mng_report_id), item)">
              <template #customBody="props">
                <q-tr :props="props">
                  <custom-table-cell v-for="col in props.cols" :key="col.name" auto-width vertical-align="top">
                    <div v-if="col.name==='actions'" class="executive__comment_wrapper">
                      <span v-if="!!props.row.comment_cnt" title="Количество комментариев"
                            class="executive__comment">{{ props.row.comment_cnt }}</span>
                      <div class="executive__actions">
                        <q-btn v-if="item.actions?.length === 1" color="primary" v-for="action in item.actions"
                               :loading="!!this.actionLoading[`${this.getRequestInfo(props.row)?.client_request_id}-${this.getRequestInfo(props.row)?.remont_id}-${action?.action_code}`]"
                               size="sm" padding="xs"
                               @click="() => this.handleAction(action, props.row, item)">
                          {{ action.action_name }}
                        </q-btn>
                        <CustomDropdown
                            v-else-if="item.actions?.length > 1"
                            :loading="!!this.actionLoading[`${this.getRequestInfo(props.row)?.client_request_id}-${this.getRequestInfo(props.row)?.remont_id}-${item.mng_report_id}`]"
                            @click="(action) => this.handleAction(action, props.row, item)"
                            :options="getActionList(item.actions)"
                            title="Действия"/>
                      </div>
                    </div>
                    <span v-else-if="col.name==='ID заявки' || col.name==='client_request_id'">
                      <Clickable router-link :href="`/${PAGES_HEADS.requests}/${col.value}`">
                        {{ col.value }}
                      </Clickable>
                    </span>
                    <span v-else-if="col.name==='ID ремонта' || col.name==='remont_id'">
                      <Clickable router-link :href="`/${PAGES_HEADS.remont}/${col.value}`">
                        {{ col.value }}
                      </Clickable>
                    </span>
                    <span v-else>{{ col.value }}</span>
                  </custom-table-cell>
                </q-tr>
              </template>
            </CustomTable>
            <div v-else class="executive__item_noData">
              Нет данных для отображения
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  actionInfoCodes,
  checkMaterialsAvail,
  downloadExecutiveReportExcel,
  executiveReportActionsCodes,
  executiveReportMeasureReady,
  fieldsToDelete,
  getActionInfo,
  getExecutiveReportData,
  getExecutiveReportTypesData,
  skipExecutiveReportNps
} from "@/views/ExecutiveReport/services";
import {MODAL_NAMES, PAGES_HEADS} from "@/constants";
import ExecutiveReportFilters from "@/views/ExecutiveReport/filters";
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import Clickable from "@/components/common/Clickable";
import CustomPopup from "@/components/common/CustomPopup";
import CustomSelect from "@/components/form/CustomSelect";
import NotFoundText from "@/components/common/NotFoundText";
import {appActions} from "@/services/store/modules/app_module/app_actions";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";
import CustomDropdown from "@/components/common/CustomDropdown";

export default {
  name: "ExecutiveReport",
  components: {
    CustomDropdown,
    NotFoundText,
    CustomSelect,
    CustomPopup,
    Clickable,
    CustomTableCell,
    CustomTable,
    ExecutiveReportFilters
  },
  data() {
    return {
      PAGES_HEADS,
      executiveReportActionsCodes,
      isFetching: false,
      executiveData: [],
      reportGroups: [],
      openedItems: {},
      actionLoading: {},
      filters: {
        mng_report_group_id: '',
        contractor_main_id: '',
        resident_id_arr: [],
        contractor_id_arr: [],
        techproject_employee_id_arr: [],
        inner_master_id_arr: [],
        manager_project_id_arr: []
      }
    }
  },
  mounted() {
    this.onMounted()
  },
  methods: {
    async onMounted() {
      const query = {}
      Object.keys(this.$route.query).forEach(key => {
        if (key === 'mng_report_group_id' && !!this.$route.query[key])
          query[key] = Number(this.$route.query[key])
        else if (!!this.$route.query[key] && key !== 'mng_report_group_id') {
          query[key] = this.$route.query[key].split(',').filter(item => !!item).map(item => Number(item))
        }
      })
      this.filters = query
      this.reportGroups = await getExecutiveReportTypesData()
      await this.getData()
    },
    getActionList(actions) {
      return actions?.map(item => ({...item, label: item.action_name}))
    },
    async showReportAnalyze() {
      this.$store.commit(appActions.showModal(MODAL_NAMES.ExecutiveReportAnalyze, {
        title: 'Анализ отчета',
        filters: this.filters,
      }));
    },
    async getData() {
      if (!this.filters.mng_report_group_id) return
      this.changeRoute()
      this.isFetching = true
      const res = await getExecutiveReportData(this.filters);
      this.isFetching = false
      this.executiveData = res
      this.updateOpenedItems()
    },
    updateOpenedItems() {
      const isOpenedExist = Object.keys(this.openedItems).find(key => !!this.openedItems[key].show)
      if (!isOpenedExist) return
      const openedItems = {...this.openedItems}
      Object.keys(this.openedItems).filter(key => !!this.openedItems[key].show).forEach(key => {
        const mng_report_id = Number(key)
        const color_id = this.openedItems[key].color?.color_id
        openedItems[key].color = this.executiveData.find(item => item.mng_report_id === mng_report_id).colors?.find(color => color.color_id === color_id)
      })
    },
    getRequestInfo(data) {
      const client_request_id = data['ID заявки'] || data?.client_request_id
      const remont_id = data['ID ремонта'] || data?.remont_id
      const resident_id = data?.resident_id
      return {client_request_id, remont_id, resident_id}
    },
    async downloadFile(mng_report_id, title) {
      await downloadExecutiveReportExcel({mng_report_id, mng_report_group_id: this.filters.mng_report_group_id}, title)
    },
    async handleAction(action, data, mng_report) {
      const {mng_report_id} = mng_report
      let modalData
      const {remont_id, client_request_id, resident_id} = this.getRequestInfo(data)
      if (actionInfoCodes.includes(action.action_code)) {
        const body = {
          action_code: action?.action_code,
          client_request_id,
          resident_id,
          remont_id
        }
        modalData = await getActionInfo(body)
      }

      const loadingId = `${client_request_id}-${remont_id}-${mng_report_id}`
      switch (action.action_code) {
        case executiveReportActionsCodes.COMMENT: {
          this.$store.commit(appActions.showModal(MODAL_NAMES.ExecutiveReportComments, {
            title: 'Комментарий',
            modalData,
            body: {
              client_request_id,
              mng_report_id,
            },
            onClose: () => {
              this.getData()
            },
          }));
          break
        }
        case executiveReportActionsCodes.EMPLOYEE_SET: {
          this.$store.commit(appActions.showModal(MODAL_NAMES.ExecutiveReportEmployeeSet, {
            title: 'Назначение ответственных на ремонт',
            modalData,
            body: modalData?.remont_info ? {
              ...modalData.remont_info,
              mng_report_group_id: this.filters.mng_report_group_id
            } : {},
            onClose: () => {
              this.getData()
            },
          }));
          break
        }
        case executiveReportActionsCodes.RESIDENT_OKK: {
          this.$store.commit(appActions.showModal(MODAL_NAMES.ExecutiveReportOkk, {
            title: 'Назначить ОКК',
            modalData,
            onClose: this.getData,
          }));
          break
        }
        case executiveReportActionsCodes.NPS_SUPPORT: {
          this.$store.commit(appActions.showModal(MODAL_NAMES.ExecutiveReportNps, {
            title: 'NPS',
            modalData,
            body: {client_request_id},
            onClose: this.getData,
          }));
          break
        }
        case executiveReportActionsCodes.NPS_SUPPORT_SKIP: {
          await this.npsSupportSkip(loadingId, {
            client_request_id,
            action_code: action?.action_code,
            mng_report_group_id: this.filters.mng_report_group_id
          })
          break
        }
        case executiveReportActionsCodes.MATERIAL_ISSET_ACCEPT: {
          await this.checkMaterials(loadingId, {
            client_request_id,
            mng_report_group_id: this.filters.mng_report_group_id
          })
          break
        }
        case executiveReportActionsCodes.MEASURE_READY: {
          await this.measureReady(loadingId, {client_request_id, mng_report_group_id: this.filters.mng_report_group_id})
          break
        }
        case executiveReportActionsCodes.WCHECK_DEADLINE_VIEW: {
          this.$store.commit(appActions.showModal(MODAL_NAMES.ExecutiveReportDeadlineView, {
            title: 'История дедлайнов',
            modalData,
          }));
          break
        }
        case executiveReportActionsCodes.RESIDENT_FLAT_LIST: {
          this.$store.commit(appActions.showModal(MODAL_NAMES.ExecutiveReportFlatList, {
            title: 'Квартиры ЖК',
            modalData,
          }));
          break
        }
        case executiveReportActionsCodes.WARRANTY_ACCEPT: {
          this.$store.commit(appActions.showModal(MODAL_NAMES.ExecutiveReportWarrantyAccept, {
            title: 'Изменить статус',
            modalData,
            body: {client_request_id, mng_report_group_id: this.filters.mng_report_group_id},
            onClose: this.getData,
          }));
          break
        }
        case executiveReportActionsCodes.WCHECK_DEADLINE: {
          this.$store.commit(appActions.showModal(MODAL_NAMES.ExecutiveReportSetDeadline, {
            title: 'Проставление дедлайна',
            body: {
              client_request_id,
              mng_report_group_id: this.filters.mng_report_group_id,
              work_set_check_group_id: action?.work_set_check_group_id
            },
            onClose: this.getData,
          }));
          break
        }
      }
    },
    async npsSupportSkip(id, body) {
      this.actionLoading[id] = true
      const res = await skipExecutiveReportNps(body)
      this.actionLoading[id] = false
      if (!res) return
      this.executiveData = res
      this.updateOpenedItems()
      this.showSucceedSnackbar()
    },
    async checkMaterials(id, body) {
      this.actionLoading[id] = true
      const res = await checkMaterialsAvail(body)
      this.actionLoading[id] = false
      if (!res) return
      this.executiveData = res?.mng_report
      this.updateOpenedItems()
      if (res?.text)
        this.$store.commit(appActions.showModal(MODAL_NAMES.TEXT_MODAL, {text: res?.text}))
      this.showSucceedSnackbar()
    },
    async measureReady(id, body) {
      this.actionLoading[id] = true
      const res = await executiveReportMeasureReady(body)
      this.actionLoading[id] = false
      if (!res) return
      this.executiveData = res
      this.updateOpenedItems()
      this.showSucceedSnackbar()
    },
    onMngColorClick(mng_report_id, color) {
      const activeItem = this.openedItems[mng_report_id];
      const isActive = activeItem?.show;
      const isColorSame = color?.color_id === activeItem?.color?.color_id;
      this.openedItems = {
        ...this.openedItems,
        [mng_report_id]: {
          show: isActive && !isColorSame ? true : !this.openedItems[mng_report_id]?.show,
          color: isActive && isColorSame ? null : color
        }
      }
    },
    handleFiltersChange(key, value) {
      this.filters = {...this.filters, [key]: value}
      key === 'mng_report_group_id' && this.getData()
    },
    changeRoute() {
      const query = {}
      Object.keys(this.filters).forEach(key => {
        if (typeof this.filters[key] === 'object')
          query[key] = this.filters[key].join(',')
        else
          query[key] = this.filters[key]
      })
      this.$router.push({path: this.$route.path, query});
    },
    getRowItem(mng_report_id) {
      return this.openedItems[mng_report_id]
    },
    getHeadItems(mng_report, item) {
      if (!mng_report?.show) return [];
      const activeColor = mng_report?.color;
      if (!activeColor) return [];
      const dataItem = activeColor.data?.at(0);
      if (!dataItem) return [];
      const headItems = Object.keys(dataItem)
          .filter(item => !fieldsToDelete.includes(item))
          .map(label => ({label, name: label, field: label, align: 'left', sortable: true}));
      if (!item?.actions?.length) return headItems
      return [...headItems, {label: '', name: 'actions', field: 'actions', align: 'center', sortable: false}]
    }
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar();
    return {showSucceedSnackbar};
  },
}
</script>

<style scoped lang="scss">
.executive {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #dfe7ff;
    padding: 3px 15px;
    border-radius: 3px;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__comment {
    background-color: #247724;
    color: #fff;
    border-radius: 50%;
    padding: 1px 7px;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;

    &_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
    width: 100%;
  }

  &__item {
    width: 100%;
    display: grid;
    grid-template-columns: 5fr 7fr 30px;
    align-items: center;
    gap: 10px;
    padding: 5px 10px;
    background-color: #fff;
    border: 1px solid #ddd;

    &_wrapper {
      width: 100%;
    }

    &_noData {
      padding: 12px 15px;
      color: #666;
      background: #f5f5f5;
      font-weight: 300;
    }

    &_title {
      font-size: 12px;
      span {
        font-weight: bold;
      }
    }

    &_help {
      cursor: pointer;
      color: #1a5fa1;
      font-size: 21px;
    }

    &_right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;

      &Icon {
        font-size: 16px;
        color: #0da105;
        cursor: pointer;
      }
    }

    &_active {
      position: sticky;
      top: 50px;
      z-index: 10
    }

    @media (max-width: 576px) {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
    }
  }

  &__colors {
    display: flex;
    gap: 2px;

    &_item {
      width: 80px;
      height: 23px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 2px;

      &Active {
        border: 2px solid #5e5e5e
      }

      span {
        font-size: 11px;
        color: #fff;
        background: #3b7dcf;
        padding: 0 7px;
        height: 18px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      @media (max-width: 576px) {
        width: 60px;
        height: 35px;
      }
    }
  }

  &__filters {
    margin-bottom: 20px;
    display: flex;
    align-items: flex-end;
    gap: 15px;
    flex-wrap: wrap;

    &_item {
      width: 220px;

      &Long {
        width: 265px;
      }
    }
  }
}
</style>