import { appMutationTypes } from "./app_mutation_types";

export const appActions = {
  setAuth: () => ({
    type: appMutationTypes.SET_AUTH,
  }),
  logout: () => ({
    type: appMutationTypes.LOGOUT,
  }),
  setInit: () => ({
    type: appMutationTypes.APP_INIT,
  }),
  showModal: (type, data = {}) => ({
    type: appMutationTypes.SHOW_MODAL,
    payload: { type, data },
  }),
  setUserData: (data) => ({
    type: appMutationTypes.SET_USER_DATA,
    payload: data,
  }),
  setCities: (data) => ({
    type: appMutationTypes.SET_CITIES,
    payload: data,
  }),
  setCompanies: (data) => ({
    type: appMutationTypes.SET_COMPANIES,
    payload: data,
  }),
  chooseCity: (city) => ({
    type: appMutationTypes.CHANGE_CITY_ID,
    payload: city,
  }),
  chooseCompany: (company) => ({
    type: appMutationTypes.CHANGE_COMPANY_ID,
    payload: company,
  }),
  setChangeMaterialsReqStatuses: (data = {}) => ({
    type: appMutationTypes.CHANGE_MATERIALS_REQ_STATUSES,
    payload: data,
  }),
  setFinancePaymentStatuses: (data = {}) => ({
    type: appMutationTypes.FINANCE_PAYMENT_STATUSES,
    payload: data,
  }),
  setCostsTypes: (data = {}) => ({
    type: appMutationTypes.SET_COSTS_TYPES,
    payload: data,
  }),
  closeModal: () => ({
    type: appMutationTypes.CLOSE_MODAL,
  }),
  toggleMenuDrawer: () => ({
    type: appMutationTypes.TOGGLE_MENU_DRAWER,
  }),
};
