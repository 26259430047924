import {destroyCookie, parseCookies, setCookie} from "nookies";
import {LOCALSTORAGEFIELDS} from "@/constants";
import {getTokenExpiresDuration} from "./tokenService";

const cookiesService = (function () {
  let _service;

  function _getService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }

  function _setToken(tokenObj) {
    setCookie(null, LOCALSTORAGEFIELDS.accessToken, tokenObj?.access, {
      path: "/",
      maxAge: getTokenExpiresDuration(tokenObj?.access),
      sameSite: "None",
      secure: true
    });
    tokenObj?.refresh && setCookie(null, LOCALSTORAGEFIELDS.refreshToken, tokenObj?.refresh, {
      path: "/",
      maxAge: getTokenExpiresDuration(tokenObj?.refresh),
      sameSite: "None",
      secure: true
    });
  }

  function _getAccessToken() {
    try {
      const cookies = parseCookies(null);
      return cookies[LOCALSTORAGEFIELDS.accessToken];
    } catch (e) {
      return "";
    }
  }

  function _getRefreshToken() {
    try {
      const cookies = parseCookies(null);
      return cookies[LOCALSTORAGEFIELDS.refreshToken];
    } catch (e) {
      return "";
    }
  }

  function _clearToken(ctx = null) {
    destroyCookie(ctx, LOCALSTORAGEFIELDS.accessToken, {
      path: "/",
      sameSite: "None",
      secure: true
    });
    destroyCookie(ctx, LOCALSTORAGEFIELDS.refreshToken, {
      path: "/",
      sameSite: "None",
      secure: true
    });
  }

  return {
    getService: _getService,
    setToken: _setToken,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    clearToken: _clearToken,
  };
})();

export default cookiesService;
