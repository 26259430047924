import {remontResponsibleAPI} from "@/components/modal/modals/change-remont-responsible/api";

export const getRemontResponsibleData = async (code) => {
  try {
    const {getFunc,
      labelKey,
      valueKey,
      label} = getContentDataByCode[code]
    const res = await remontResponsibleAPI[getFunc]()
    return {data: res?.data, labelKey,
      valueKey,
      label}
  } catch (e) {
  }
}
export const getResponsibleAgreementSum = async (client_request_id, code) => {
  try {
    const {updateFunc} = getContentDataByCode[code]
    const body = {}
    const res = await remontResponsibleAPI[updateFunc](client_request_id, body)
    return res?.data
  } catch (e) {
  }
}

export const updateRemontResponsibleData = async (client_request_id, code, body) => {
  try {
    const {updateFunc} = getContentDataByCode[code]
    const res = await remontResponsibleAPI[updateFunc](client_request_id, body)
    return res?.data
  } catch (e) {}
}

export const getContentDataByCode = {
  contractor_id: {
    getFunc: 'getContractors',
    updateFunc: 'updateContractor',
    labelKey: 'contractor_name',
    valueKey: 'contractor_id',
    label: 'Подрядчик'
  },
  okk_employee_id: {
    getFunc: 'getOKK',
    updateFunc: 'updateOKK',
    labelKey: 'fio',
    valueKey: 'employee_id',
    label: 'Отдел контроля качества'
  },
  general_contractor_id: {
    getFunc: 'getGeneralContractors',
    updateFunc: 'updateGeneralContractor',
    labelKey: 'contractor_name',
    valueKey: 'contractor_id',
    label: 'Генеральный подрядчик'
  },
  contractor_main_id: {
    getFunc: 'getMainContractors',
    updateFunc: 'updateMainContractor',
    labelKey: 'contractor_main_name',
    valueKey: 'contractor_main_id',
    label: 'Главный подрядчик'
  },
  inner_master_employee_id: {
    getFunc: 'getProjectManagers',
    updateFunc: 'updateProjectManager',
    labelKey: 'fio',
    valueKey: 'employee_id',
    label: 'Проектный менеджер'
  },
  techproject_developer_id: {
    getFunc: 'getTechprojectDevelopers',
    updateFunc: 'updateTechprojectDeveloper',
    labelKey: 'techproject_developer_name',
    valueKey: 'techproject_developer_id',
    label: 'Разработчик тех.проекта (Контрагент)'
  },
  techproject_employee_id: {
    getFunc: 'getTechprojectEmployees',
    updateFunc: 'updateTechprojectEmployee',
    labelKey: 'techproject_name',
    valueKey: 'employee_id',
    label: 'Разработчик тех.проекта (Пользователь)'
  },
  manager_project_employee_id: {
    getFunc: 'getProrabs',
    updateFunc: 'updateProrab',
    labelKey: 'fio',
    valueKey: 'employee_id',
    label: 'Прораб'
  },
  head_direction_id: {
    getFunc: 'getHeadDirections',
    updateFunc: 'updateHeadDirection',
    labelKey: 'fio',
    valueKey: 'employee_id',
    label: 'Начальник дирекции'
  },
  support_manager_id: {
    getFunc: 'getSupportManagers',
    updateFunc: 'updateSupportManager',
    labelKey: 'techproject_developer_name',
    valueKey: 'techproject_developer_id',
    label: 'Контрагент по сопровождению'
  },
  supervisor_id: {
    getFunc: 'getCurators',
    updateFunc: 'updateCurator',
    labelKey: 'supervisor_fio',
    valueKey: 'supervisor_id',
    label: 'Куратор'
  },
}