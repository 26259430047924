<template>
  <h1 class="profile__title">Профиль пользователя</h1>
  <div class="profile__content">
    <BlockItem title="ФИО">
      {{userData?.fio || ''}}
    </BlockItem>
    <BlockItem title="Email" >
      {{userData?.email || ''}}
    </BlockItem>
    <BlockItem title="Город" >
      {{userData?.city_name || ''}}
    </BlockItem>
    <BlockItem title="Должность" >
      {{userData?.position_name || ''}}
    </BlockItem>
    <BlockItem title="Роли" >
      {{userData?.group_names || ''}}
    </BlockItem>
  </div>
</template>

<script>
import BlockItem from "@/components/common/BlockItem";
export default {
  name: "Profile",
  components: {BlockItem},
  computed: {
    userData() {
      return this.$store.state.app?.userData
    },
  },
}
</script>

<style scoped lang="scss">
.profile {
  &__title {
    font-size: 24px;
    padding: 0;
    margin: 0;
    line-height: unset;
  }
  &__content {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
</style>