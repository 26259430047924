import { remontTimelineStepAPI } from "@/components/modal/modals/remont-timeline-step/api";

export const remontTimelineTabs = {
  conditions: "conditions",
  actions: "actions",
  logs: "logs",
  info: "info",
};

export const getRemontTimelineCount = async (remont_step_id) => {
  try {
    return await remontTimelineStepAPI.getRemontTimelineCount(remont_step_id);
  } catch (e) {}
};
export const getRemontTimelineConditions = async (remont_step_id) => {
  try {
    const res = await remontTimelineStepAPI.getRemontTimelineConditions(
      remont_step_id
    );
    const { remont_step_conditions } = res;
    return { [remontTimelineTabs.conditions]: { data: remont_step_conditions } };
  } catch (e) {}
};
export const getRemontTimelineLogs = async (remont_step_id) => {
  try {
    const res = await remontTimelineStepAPI.getRemontTimelineLogs(
      remont_step_id
    );
    const { remont_step_condition_log } = res;
    return { [remontTimelineTabs.logs]: { data: remont_step_condition_log } };
  } catch (e) {}
};
export const getRemontTimelineActions = async (remont_step_id) => {
  try {
    const res = await remontTimelineStepAPI.getRemontTimelineActions(
      remont_step_id
    );
    const { remont_step_activities } = res;
    return { [remontTimelineTabs.actions]: { data: remont_step_activities } };
  } catch (e) {}
};

export const updateRemontActionStepActivity = async (remont_step_id, remont_step_activity_id, body) => {
  try {
    const res = await remontTimelineStepAPI.updateRemontActionStepActivity(
      remont_step_id, remont_step_activity_id, body
    );
    return res.remont_step_activities
  } catch (e) {}
};
export const updateRemontConditionStepActivity = async (remont_step_id, remont_step_condition_id, body) => {
  try {
    const res = await remontTimelineStepAPI.updateRemontConditionStepActivity(
      remont_step_id, remont_step_condition_id, body
    );
    return res.remont_step_conditions
  } catch (e) {}
};

export const gerRemontTimelineStepData = async (remont_step_id, activeTab) => {
  switch (activeTab) {
    case remontTimelineTabs.info: {
      break;
    }
    case remontTimelineTabs.conditions: {
      return await getRemontTimelineConditions(remont_step_id);
    }
    case remontTimelineTabs.logs: {
      return await getRemontTimelineLogs(remont_step_id);
    }
    case remontTimelineTabs.actions: {
      return await getRemontTimelineActions(remont_step_id);
    }
  }
};

export const timelineConditionsTableColumns = [
  {
    name: "is_checked",
    label: "Выполнено",
    field: "is_checked",
    sortable: true,
    align: "center",
  },
  {
    name: "checked_date",
    label: "Дата изменения",
    field: "checked_date",
    sortable: true,
    align: "center",
  },
  {
    name: "comment",
    label: "Комментарий",
    field: "comment",
    align: "left",
  },
  {
    name: "is_active",
    field: "is_active",
    label: "Активен",
    align: "center",
  },
];
export const timelineLogsTableColumns = [
  {
    name: "fio",
    label: "Пользователь",
    field: "fio",
    sortable: true,
    align: "left",
  },
  ...timelineConditionsTableColumns,
];
export const remontActionsTableColumns = [
  {
    name: "is_active",
    field: "is_active",
    label: "Активен",
    align: "center",
  },
  {
    name: "activity_name",
    label: "Название",
    field: "activity_name",
    sortable: true,
    align: "left",
  },
  {
    name: "fio",
    label: "Сотрудник",
    field: "fio",
    align: "left",
  },
  {
    name: "type_name",
    label: "Тип действия",
    field: "type_name",
    align: "left",
  },
  {
    name: "param_value_json",
    label: "Значение параметров",
    field: "param_value_json",
    sortable: true,
    align: "left",
  },
  {
    name: "param_json",
    label: "Параметры",
    field: "param_json",
    sortable: true,
    align: "left",
  },
  {
    name: "is_completed",
    label: "Завершено",
    field: "is_completed",
    align: "center",
  },
  {
    name: "source_name",
    label: "Источник",
    field: "source_name",
    align: "left",
  },
  {
    name: "activity_date",
    label: "Дата",
    field: "activity_date",
    align: "center",
  },
];
