import {handbookGradeTimelineAPI} from "@/views/handbook/gradeTimelineActions/api";

export const getGradeActions = async () => {
  try {
    const res = await handbookGradeTimelineAPI.getList()
    return res?.data
  } catch (e) {
  }
}
export const addGradeAction = async (body = {}) => {
  try {
    const res = await handbookGradeTimelineAPI.addAction(body)
    return res?.data
  } catch (e) {
  }
}
export const updateGradeAction = async (activity_id, body = {}) => {
  try {
    const res = await handbookGradeTimelineAPI.updateAction(activity_id, body)
    return res?.data
  } catch (e) {
  }
}
export const deleteGradeAction = async (activity_id) => {
  try {
    const res = await handbookGradeTimelineAPI.deleteAction(activity_id)
    return res?.data
  } catch (e) {
  }
}
export const getActivityTypes = async () => {
  try {
    const res = await handbookGradeTimelineAPI.getActivityTypes()
    return res?.data
  } catch (e) {
  }
}

export const gradeActionsHandbookColumns = [
  {
    name: "activity_id",
    field: "activity_id",
    label: "ID",
    align: "center",
  },
  {
    name: "activity_name",
    field: "activity_name",
    label: "Название",
    align: "left",
  },
  {
    name: "activity_code",
    field: "activity_code",
    label: "Код",
    align: "left",
  },
  {
    name: "type_name",
    field: "type_name",
    label: "Тип",
    align: "left",
  },
  {
    name: "param_json",
    field: "param_json",
    label: "Параметры (json)",
    align: "left",
  },
  {
    name: "actions",
    label: "",
    align: "center",
  },
]