export const appMutationTypes = {
  APP_INIT: "APP_INIT",
  SHOW_MODAL: "SHOW_MODAL",
  TOGGLE_INPUT_DROPDOWN: "TOGGLE_INPUT_DROPDOWN",
  CLOSE_MODAL: "CLOSE_MODAL",
  TOGGLE_MENU_DRAWER: "TOGGLE_MENU_DRAWER",
  SET_AUTH: "SET_AUTH",
  SET_USER_DATA: "SET_USER_DATA",
  SET_CITIES: "SET_CITIES",
  SET_COMPANIES: "SET_COMPANIES",
  LOGOUT: "LOGOUT",
  CHANGE_MATERIALS_REQ_STATUSES: "CHANGE_MATERIALS_REQ_STATUSES",
  FINANCE_PAYMENT_STATUSES: "FINANCE_PAYMENT_STATUSES",
  CHANGE_CITY_ID: "CHANGE_CITY_ID",
  CHANGE_COMPANY_ID: "CHANGE_COMPANY_ID",
  SET_COSTS_TYPES: "SET_COSTS_TYPES",
};

export const appActionTypes = {
  checkAuth: "checkAuth",
  logout: "logout",
  getChangeMaterialsReqStatuses: "getChangeMaterialsReqStatuses",
  getUserData: "getUserData",
  getCities: "getCities",
  getCompanies: "getCompanies",
  getFinancePaymentStatuses: "getFinancePaymentStatuses",
  chooseCity: "chooseCity",
  chooseCompany: "chooseCompany",
  getCostsTypes: "getCostsTypes",
  getCommonContractors: "getCommonContractors",
  getCommonProviders: "getCommonProviders",
  getStages: "getStages",
  getStageStatuses: "getStageStatuses",
  getDocTypes: "getDocTypes",
  getDSTypes: "getDSTypes",
  getAgreementTypes: "getAgreementTypes",
  getWorksetCheckGroups: "getWorksetCheckGroups",
  getSpecialities: "getSpecialities",
};
