import {remontMaterialSmetaAPI} from "@/views/ClientRequests/remontMaterialSmeta/api";

const remontMaterialSmetaBlockNames = {
  OA__RemontFormMaterialNotDirtyBlock: "remontMaterialSmetaNotDirty",
  OA__RemontFormMaterialDirtyBlock: "remontMaterialSmetaDirty",
}
export const getRemontMaterialSmetaData = async (client_request_id) => {
  try {
    const res = await remontMaterialSmetaAPI.getList(client_request_id)
    const data = res?.data
    if (!data?.length) return
    return data?.map(item => ({...item, component: remontMaterialSmetaBlockNames[item.grant_code]}))
  } catch (e) {
  }
}
export const createSmetaMaterialsRequests = async (client_request_id) => {
  try {
    const res = await remontMaterialSmetaAPI.createRequests(client_request_id)
    const data = res?.data
    if (!data?.length) return
    return data?.map(item => ({...item, component: remontMaterialSmetaBlockNames[item.grant_code]}))
  } catch (e) {
  }
}
export const remontMaterialSmetaColumns = [
  {
    name: "smeta_material_id",
    label: "ID",
    field: "smeta_material_id",
    align: "center",
  },
  {
    name: "material_id",
    label: "ID материала",
    field: "material_id",
    align: "center",
  },
  {
    name: "material_name",
    label: "Материал",
    field: "material_name",
    align: "left",
  },
  {
    name: "material_name_extra",
    label: "У поставщика",
    field: "material_name_extra",
    align: "left",
  },
  {
    name: "date_shipping",
    label: "Дата отгрузки",
    field: "date_shipping",
    align: "center",
  },
  {
    name: "is_active",
    label: "Активный",
    field: "is_active",
    align: "left",
  },
  {
    name: "param_value",
    label: "Параметр",
    field: "param_value",
    align: "right",
  },
  {
    name: "material_type_name",
    label: "Тип материала",
    field: "material_type_name",
    align: "left",
  },
  {
    name: "provider_name",
    label: "Поставщик",
    field: "provider_name",
    align: "left",
  },
  {
    name: "consume_unit_name",
    label: "Расход",
    field: "consume_unit_name",
    align: "right",
  },
  {
    name: "sell_unit_name",
    label: "Продажа (поставщик)",
    field: "sell_unit_name",
    align: "right",
  },
  {
    name: "delivery_unit_name",
    label: "Отпуск (поставщик)",
    field: "delivery_unit_name",
    align: "right",
  },
  {
    name: "atom_unit_name",
    label: "В минимальных ед.",
    field: "atom_unit_name",
    align: "right",
  },
  {
    name: "price",
    label: "Цена материала на момент формирования заявки",
    field: "price",
    align: "right",
  },
  {
    name: "amount",
    label: "Итоговая сумма по пункту",
    field: "amount",
    align: "right",
  },
  {
    name: "residue",
    label: "Остаток материала в единицах измерения материала",
    field: "residue",
    align: "right",
  },
  {
    name: "rowversion",
    label: "Дата пересчета",
    field: "rowversion",
    align: "center",
  },
]