<template>
  <remont-timeline-filters
      v-if="this.showFilters !== false"
      :loading="this.loading"
      :on-submit="this.getData"
      :timelineData="this.timelineData"
      :changeActive="this.changeActive"
      :values="this.filters"
      :onChange="this.onFiltersChange"
      :loadingCheck="this.loadingCheck"
      :show-check-button="!!this.graphData?.nodes?.length"
      :timelineCheck="this.timelineCheck"
  />
  <timeline :updateData="() => this.getData(true)" :height="this.getContentHeight()" :graphData="this.graphData" type="remont"/>
</template>

<script>
import {changeTimelineActive, getRemontTimelineData, remontTimelineCheck} from "@/views/timeline/services";
import Timeline from "@/views/timeline";
import RemontTimelineFilters from "@/views/timeline/timeline-remont/filters";

export default {
  name: "timeline-remont",
  components: {RemontTimelineFilters, Timeline},
  props: ['showFilters', 'client_request_id_prop', 'headerHeight'],
  data() {
    return {
      loading: false,
      loadingCheck: false,
      filters: {
        client_request_id: this.client_request_id_prop || "",
        remont_id: '',
      },
      timelineData: {
        client_request_id: "",
        remont_id: '',
      },
      graphData: {},
    };
  },
  mounted() {
    if(this.client_request_id_prop) {
      this.getData()
    }
  },
  methods: {
    getContentHeight() {
      if(this.headerHeight) {
        return window.innerHeight - this.headerHeight - 100
      }
    },
    async getData(isUpdateData = false) {
      this.loading = true;
      const res = await getRemontTimelineData(isUpdateData === true ? this.timelineData : this.filters);
      this.loading = false;
      if (!res) return;
      this.timelineData = {...this.filters}
      const {nodes, lines, ...others} = res;
      this.graphData = {nodes, lines};
      this.timelineData = {...others}
    },
    async changeActive() {
      if(!this.timelineData?.client_request_id) return
      const body = {client_request_id: this.timelineData.client_request_id, is_stop_timeline: !this.timelineData.is_stop_timeline}
      const res = await changeTimelineActive(body, this.timelineData);
      if (!res) return;
      const {nodes, lines, ...others} = res;
      this.graphData = {nodes, lines};
      this.timelineData = {...others}
    },
    async timelineCheck() {
      this.loadingCheck = true;
      const res = await remontTimelineCheck(this.timelineData);
      this.loadingCheck = false;
      if (!res) return;
      const {nodes, lines} = res;
      this.graphData = {nodes, lines};
    },
    onFiltersChange(key, value) {
      if (this.filters[key] === value) return
      if (key === 'remont_id')
        this.filters.client_request_id = ''
      else
        this.filters.remont_id = ''
      this.filters[key] = value;
    },
  },
};
</script>

<style scoped></style>
