import cookiesService from "@/services/cookiesService";
import {getFingerKey} from "@/services";
import {login, loginWithUrl, sendCode} from "@/views/Login/api";

const cookiesServiceInstance = cookiesService.getService();

export const doLogin = async (body = {}) => {
  const finger = getFingerKey();
  try {
    const res = await login({...body, finger});
    if (res) {
      cookiesServiceInstance.setToken(res.token);
      return res
    }
  } catch (e) {
    const error = e?.response?.data;
    if (error?.is_device_exist === false) {
      return {is_device_exist: false}
    }
  }
}

export const doLoginWithUrl = async (token) => {
  try {
    const res = await loginWithUrl(token);
    if (res) {
      await cookiesServiceInstance.setToken(res.token);
      return res
    }
  } catch (e) {
  }
}
export const sendLoginCode = async (body) => {
  body.finger = getFingerKey()
  try {
    const res = await sendCode(body);
    return !!res?.status
  } catch (e) {
    return false
  }
}