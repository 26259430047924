<template>
  <i :class="['material-icons expand', {expand__active: this.active}]">expand_more</i>
</template>

<script>
export default {
  name: "custom-table-expand-arrow",
  props: ['active']
}
</script>

<style scoped lang="scss">
.expand {
  font-size: 25px;
  cursor: pointer;
  color: #096509;
  transition: all .3s;

  &__active {
    transform: rotate(180deg);
  }
}
</style>