<template>
  <q-layout view="hHh LpR lFf">
    <Header @toggle-navbar="toggleLeftDrawer"/>
    <Navbar @toggle-navbar="toggleLeftDrawer" :value="this.leftDrawerCollapsed" @change="this.toggleLeftDrawer"/>
    <q-page-container>
      <q-page :padding="padding">
        <router-view/>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script>
import Header from "@/components/layout/Header";
import Navbar from "@/components/layout/Navbar";
import {appActions} from "@/services/store/modules/app_module/app_actions";
import {LOCALSTORAGEFIELDS} from "@/constants";

export default {
  name: "layout",
  components: {Header, Navbar},
  data() {
    return {
      leftDrawerCollapsed: false
    }
  },
  props: {
    padding: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    toggleLeftDrawer() {
      this.$store.commit(appActions.toggleMenuDrawer());
      this.leftDrawerCollapsed = !this.leftDrawerCollapsed;
      localStorage.setItem(LOCALSTORAGEFIELDS.menuDrawerOpen, this.leftDrawerCollapsed ? '1' : '0')
    },
  },
};
</script>
