import {executiveReportAPI} from "./api";
import {remontResponsibleAPI} from "@/components/modal/modals/change-remont-responsible/api";
import {financePaymentsAPI} from "@/views/FinancePayments/api";
import {fastSearchAPI} from "@/views/FastSearch/api";
import {downloadExcel} from "@/services";
import {getMainContractors} from "@/services/api/api";

export const getExecutiveReportData = async body => {
  try {
    const res = await executiveReportAPI.getData(body);
    return res?.mng_report;
  } catch (e) {
  }
};
export const getExecutiveReportAnalyze = async body => {
  try {
    const res = await executiveReportAPI.getReportAnalyze(body);
    if(!res) return
    const {data_1, data_2} = res
    return {doubleRemonts: data_2, lostRemonts: data_1}
  } catch (e) {
  }
};
export const executiveReportMeasureReady = async (body) => {
  try {
    const res = await executiveReportAPI.measureReady(body);
    return res?.mng_report;
  } catch (e) {
  }
};
export const downloadExecutiveReportExcel = async (body, title) => {
  try {
    const res = await executiveReportAPI.downloadExcel(body);
    downloadExcel(res, title);
  } catch (e) {
  }
};

export const getExecutiveReportTypesData = async () => {
  try {
    const res = await executiveReportAPI.getReportTypes();
    return res?.data;
  } catch (e) {
  }
};
export const getActionInfo = async (body) => {
  try {
    const res = await executiveReportAPI.getActionInfo(body);
    return res?.data;
  } catch (e) {
  }
};
export const addExecutiveReportComment = async (body) => {
  try {
    const res = await executiveReportAPI.addReportComment(body);
    return res?.data;
  } catch (e) {
  }
};
export const setExecutiveReportDeadline = async (body) => {
  try {
    const res = await executiveReportAPI.setDeadline(body);
    return res?.data;
  } catch (e) {
  }
};
export const setExecutiveReportNps = async (body) => {
  try {
    const res = await executiveReportAPI.setNps(body);
    return res?.data;
  } catch (e) {
  }
};
export const executiveReportAcceptWarranty = async (body) => {
  try {
    const res = await executiveReportAPI.warrantyAccept(body);
    return res?.data;
  } catch (e) {
  }
};
export const skipExecutiveReportNps = async (body) => {
  try {
    const res = await executiveReportAPI.skipNps(body);
    return res?.mng_report;
  } catch (e) {
  }
};
export const checkMaterialsAvail = async (body) => {
  try {
    const res = await executiveReportAPI.checkMaterialsAvail(body);
    if(!res) return
    const {mng_report, text} = res
    return {mng_report, text}
  } catch (e) {
  }
};
export const setExecutiveEmployee = async (body) => {
  try {
    const res = await executiveReportAPI.setEmployee(body);
    return res?.data;
  } catch (e) {
  }
};
export const setExecutiveReportOkk = async (body) => {
  try {
    const res = await executiveReportAPI.setOkk(body);
    return res?.data;
  } catch (e) {
  }
};
export const deleteReportComment = async (mng_report_comment_id, body) => {
  try {
    const res = await executiveReportAPI.deleteReportComment(mng_report_comment_id, body);
    return res?.data;
  } catch (e) {
  }
};

export const getFiltersData = async () => {
  try {
    const res = await Promise.all([
      executiveReportAPI.getContractorsWithMain(),
      getMainContractors(),
      executiveReportAPI.getSalePoints(),
      fastSearchAPI.getResidents(),
      remontResponsibleAPI.getTechprojectEmployees(),
      remontResponsibleAPI.getProrabs(),
      remontResponsibleAPI.getProjectManagers(),
      financePaymentsAPI.getTechprojectDevelopers(),
      remontResponsibleAPI.getHeadDirections(),
    ]);

    const [
      contractors,
      mainContractors,
      salePoints,
      residentsRes,
      techProjectEmployeesRes,
      prorabsRes,
      projectManagersRes,
      techProjectDevsRes,
      headDirectionsRes
    ] = res;
    return {
      contractors,
      mainContractors,
      salePoints,
      residents: residentsRes?.residents,
      techProjectEmployees: techProjectEmployeesRes?.data,
      prorabs: prorabsRes?.data,
      projectManagers: projectManagersRes?.data,
      techProjectDevs: techProjectDevsRes?.techproject_developers,
      headDirections: headDirectionsRes?.data
    };
  } catch (e) {
    return {};
  }
};

export const fieldsToDelete = ["client_request_id", "comment_cnt", "color_id"];

export const executiveReportActionsCodes = {
  COMMENT: 'COMMENT',
  EMPLOYEE_SET: 'EMPLOYEE_SET',
  NPS_SUPPORT: 'NPS_SUPPORT',
  WCHECK_DEADLINE: 'WCHECK_DEADLINE',
  WCHECK_DEADLINE_VIEW: 'WCHECK_DEADLINE_VIEW',
  NPS_SUPPORT_SKIP: 'NPS_SUPPORT_SKIP',
  MATERIAL_ISSET_ACCEPT: 'MATERIAL_ISSET_ACCEPT',
  WARRANTY_ACCEPT: 'WARRANTY_ACCEPT',
  RESIDENT_OKK: 'RESIDENT_OKK',
  RESIDENT_FLAT_LIST: 'RESIDENT_FLAT_LIST',
  MEASURE_READY: 'MEASURE_READY',
}
export const actionInfoCodes = [executiveReportActionsCodes.COMMENT, executiveReportActionsCodes.EMPLOYEE_SET, executiveReportActionsCodes.NPS_SUPPORT, executiveReportActionsCodes.WCHECK_DEADLINE_VIEW
  , executiveReportActionsCodes.WARRANTY_ACCEPT, executiveReportActionsCodes.RESIDENT_OKK, executiveReportActionsCodes.RESIDENT_FLAT_LIST]