<template>
  <div :style="this.getItemPadding(this.levelDeep)" class="customMultiselectItem__header"
       @click="toggleSelection(opt[this.optionValueKey])">
    <q-checkbox @update:model-value="toggleSelection(opt[this.optionValueKey])"
                :model-value="false" class="checkboxOverlay"/>
    <CustomCheckbox :with-null="true" size="xs" dense class="customSelectCheckbox"
                    label=""
                    :value="this.checkValue"
                    @change="e => handleSelect(e, opt[this.optionValueKey], opt[this.childrenKey],
                    this.parentValue ? [...this.parentValue] : [])"
    />
    <q-item-label class="customMultiselectItem__label">{{ opt[this.optionLabelKey] }}</q-item-label>
    <q-icon v-if="!!opt[this.childrenKey]?.length" name="arrow_drop_down"
            :class="['customCheckboxArrow', { 'customCheckboxArrow__expanded': this.expandedOptions.includes(opt[this.optionValueKey])}]"/>

  </div>
  <div class="customMultiselectItem__section"
       v-if="this.expandedOptions.includes(opt[this.optionValueKey])">
    <div class="customMultiselectItem__child customMultiselectItem__child_under"
         v-for="item in opt[this.childrenKey]">
      <MultiSelectItem :value="this.value" :level-deep="this.levelDeep + 1" :children-key="this.childrenKey"
                       :option-label-key="this.optionLabelKey"
                       :opt="item" :expanded-options="this.expandedOptions" :toggle-selection="this.toggleSelection"
                       :handle-select="this.handleSelect" :option-value-key="this.optionValueKey" :all-selected="this.allSelected"
                       :parent-value="this.parentValue ? [...this.parentValue, opt[this.optionValueKey]] : [opt[this.optionValueKey]]"/>
    </div>
  </div>
</template>

<script>
import CustomCheckbox from "@/components/form/CustomCheckbox";

export default {
  name: "MultiSelectItem",
  components: {CustomCheckbox},
  props: ['toggleSelection', 'value', 'opt', 'expandedOptions', 'optionValueKey', 'childrenKey',
    'optionLabelKey', 'handleSelect', 'levelDeep', 'parentValue', 'allSelected'],
  methods: {
    getItemPadding(deepLevel = 1) {
      return `padding-left: ${deepLevel * 12}px;padding-right: 15px`
    }
  },
  computed: {
    checkValue() {
      if(this.opt[this.optionValueKey] === 'ALL') {
        return this.allSelected || (this.value?.length ? null : false)
      }
      return !!this.value?.includes(this.opt[this.optionValueKey]) || (this.opt[this.childrenKey]?.some(item =>
          item[this.childrenKey]?.length
              ? item[this.childrenKey]?.some(child => this.value.includes(child[this.optionValueKey]))
              : this.value.includes(item[this.optionValueKey])
      )? null : false)
    }
  }
}
</script>

<style lang="scss">
.customCheckboxArrow {
  font-size: 26px !important;
  color: #3d3d3d;

  &__expanded {
    transform: rotate(180deg);
  }
}

.checkboxOverlay {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  opacity: 0;

  & > div {
    width: 100%;
    height: 100%;
  }
}

.customSelectCheckbox {
  z-index: 2;
  position: relative;
}

.customMultiselectItem {

  &__label {
    font-size: 12px;
  }

  &__child {
    width: 100% !important;
    justify-content: flex-start !important;
    position: relative;
  }

  &__header {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    justify-content: flex-start !important;
    align-items: center;
    width: 100% !important;
    z-index: 2;
    position: relative;
  }

  &__section {
    width: 100% !important;
  }
}
</style>