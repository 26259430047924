<template>
  <div class="wrapper">
    <div class="header">
      <q-btn color="deep-orange" flat icon-right="add" @click="this.handleAddStepForRemont"
             label="Добавить в ремонт" size="sm" padding="3px" class="removeBtn"/>
    </div>
    <q-tabs
        v-model="tab"
        class="text-teal"
    >
      <q-tab v-for="item in this.tabs" :name="item.name" :label="item.label"/>
    </q-tabs>

    <CustomLoader v-if="this.loading"/>
    <q-tab-panels v-else
                  v-model="tab"
    >
      <q-tab-panel class="tabPanel" :name="this.gradeTimelineTabs.conditions">
        <grade-timeline-conditions :type="this.gradeTimelineTabs.conditions" :grade_step_id="this.data.grade_step_id"
                                   :data="this.tabsData[this.gradeTimelineTabs.conditions]"/>
      </q-tab-panel>
      <q-tab-panel class="tabPanel" :name="this.gradeTimelineTabs.include_conditions">
        <grade-timeline-conditions :type="this.gradeTimelineTabs.include_conditions"
                                   :data="this.tabsData[this.gradeTimelineTabs.include_conditions]"/>
      </q-tab-panel>
      <q-tab-panel class="tabPanel" :name="this.gradeTimelineTabs.actions">
        <grade-timeline-actions :data="this.tabsData[this.gradeTimelineTabs.actions]"
                                :grade_step_id="this.data.grade_step_id"/>
      </q-tab-panel>
    </q-tab-panels>
  </div>
  <q-dialog v-model="showPopup">
    <AddGradeStepToRemont type="step" :title="this.data?.step_name"
                          :grade_step_id="this.data.grade_step_id" />
  </q-dialog>
</template>

<script>
import CustomLoader from "@/components/common/CustomLoader";
import GradeTimelineConditions from "@/components/modal/modals/grade-timeline-step/grade-timeline-conditions";
import {
  gerGradeTimelineStepData,
  getGradeTimelineCount,
  gradeTimelineTabs
} from "@/components/modal/modals/grade-timeline-step/services";
import GradeTimelineActions from "@/components/modal/modals/grade-timeline-step/grade-timeline-actions";
import AddGradeStepToRemont from "@/components/modal/modals/grade-timeline-step/add-grade-step-to-remont";

export default {
  name: "GradeTimelineStep",
  components: {AddGradeStepToRemont, GradeTimelineActions, GradeTimelineConditions, CustomLoader},
  props: ['data'],
  data() {
    return {
      loading: false,
      showPopup: false,
      count: {},
      gradeTimelineTabs,
      tab: gradeTimelineTabs.conditions,
      tabsData: {}
    }
  },
  computed: {
    tabs() {
      const tabsList = [{name: gradeTimelineTabs.conditions, label: "Условия"},
        {name: gradeTimelineTabs.include_conditions, label: "Условия вхождения"},
        {name: gradeTimelineTabs.actions, label: "Действия"}]

      return tabsList.map(item => {
        if (Object.keys(this.count)?.length && !!this.count[item.name])
          return {...item, label: `${item.label} (${this.count[item.name]})`}
        return item
      })
    }
  },
  methods: {
    async getData(tab) {
      this.loading = true
      const res = await gerGradeTimelineStepData(this.data.grade_step_id, tab)
      this.loading = false
      if (!res) return
      this.tabsData = {...this.tabsData, ...res}
    },
    async handleAddStepForRemont() {
      this.showPopup = true
    },
    async getCounts() {
      const res = await getGradeTimelineCount(this.data?.grade_step_id)
      if (!res) return
      this.count = res
    }
  },
  watch: {
    async tab(newValue) {
      await this.getData(newValue)
    }
  },
  mounted() {
    this.getData(this.tab)
    this.getCounts()
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  min-height: 300px;

  @media (max-width: 991px) {
    width: 100%;
  }
}
.header {
  display: flex;
  justify-content: flex-end;
}
.tabPanel {
  @media (max-width: 400px) {
    padding: 15px 5px 15px 5px;
  }
}
</style>