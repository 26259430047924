import instance from "@/services/api";

export const requisitesAPI = {
  async getRequisitesData(client_request_id) {
    return await instance().get(`/client_request/${client_request_id}/requisites/read/`).then(res => res?.data)
  },
  async getPhone(client_request_id) {
    return await instance().get(`/client_request/${client_request_id}/requisites/phone/read/`).then(res => res?.data)
  },
  async getContactPhone(client_request_id) {
    return await instance().get(`/client_request/${client_request_id}/requisites/prop_contact_phone/read/`).then(res => res?.data)
  },
}