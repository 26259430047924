<template>
  <BackBtn class="back" @click="this.goBack"/>
  <GeneratorReportFiltersAndActions :filterBlocks="this.filterBlocks" :is-filter-fetching="this.isFilterFetching" :report_name="this.title" :filters="this.filters"
                                    :handle-change="this.handleChange" :loading="this.isFetching"
                                    :get-data="this.getData" :selected="this.selected?.report_id"/>
  <q-tabs
      v-model="tab"
      dense
      class="text-grey"
      active-color="primary"
      indicator-color="primary"
      narrow-indicator v-if="this.otherTables?.length"
  >
    <q-tab v-for="item in this.tabs" :name="item.name" :label="item.label" />
  </q-tabs>
  <q-tab-panels v-model="tab" animated class="tabPanel__parent">
    <q-tab-panel name="main" class="tabPanel">
      <div :class="['content', {content__initial: !this.additionalData?.length}]"
           :style="`grid-template-columns: 150px calc(100vw - ${(this.menuDrawerOpen ? 250 : 0) + 150 + 100}px)`">
        <div :class="['additional', {additional__initial: !this.additionalData?.length}]">
          <div v-for="item in this.additionalData" class="additional__item">
            {{ item.isNum ? this.numberWithCommas(item.value) : item.value }}
            <span>{{ item.label }}</span>
          </div>
        </div>
        <CustomTable
            :columns="this.columns"
            :loading="this.isFetching" :dense="true"
            :rows-data="this.data" pagination-position="top"
            :title="this.selected?.report_name || this.title"
        />
      </div>
    </q-tab-panel>
    <q-tab-panel v-for="item in this.otherTables" :name="item.name" class="tabPanel">
        <CustomTable :columns="item.columns" :dense="true" :loading="this.isFetching"
                     :rows-data="item.data" pagination-position="top" title="" />
    </q-tab-panel>
  </q-tab-panels>

</template>

<script>
import {getReportFilters, getSelectedReport} from "@/views/ReportGenerator/services";
import BackBtn from "@/components/common/BackBtn";
import CustomTable from "@/components/common/CustomTable";
import GeneratorReportFiltersAndActions from "@/views/ReportGenerator/filters";
import axios from "axios";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";
import {numberWithCommas} from "@/services";

export default {
  name: "SelectedReport",
  components: {GeneratorReportFiltersAndActions, CustomTable, BackBtn},
  props: ['selected', 'goBack'],
  data() {
    return {
      data: null,
      axiosSource: null,
      isFetching: false,
      isFilterFetching: false,
      filters: {},
      title: '',
      tab: 'main',
      columns: [],
      tabs: [],
      otherTables: [],
      additionalData: [],
      filterBlocks: [],
    }
  },
  computed: {
    menuDrawerOpen() {
      return this.$store.state.app.menuDrawerOpen;
    },
  },
  mounted() {
    this.getFilters()
  },
  unmounted() {
    this.axiosSource?.cancel();
  },
  methods: {
    numberWithCommas,
    async getData() {
      let isCheckPass = true
      this.filterBlocks?.forEach(item => {
        if (item.is_required && this.filters[item.report_param_code] === undefined)
          isCheckPass = false
      })
      if (!isCheckPass)
        return this.showErrorSnackbar('Заполните обязательные поля!')

      this.axiosSource = axios.CancelToken.source();
      this.isFetching = true
      const res = await getSelectedReport(this.selected?.report_id, this.filters, this.axiosSource?.token)
      this.isFetching = false
      if (!res) return
      const {data, columns, name, additional, tables, tabs} = res
      this.data = data
      this.columns = columns
      this.tabs = tabs
      this.otherTables = tables
      this.additionalData = additional
      this.title = name
    },
    async getFilters() {
      this.isFilterFetching = true
      const res = await getReportFilters(this.selected?.report_id)
      this.isFilterFetching = false
      if (!res) return
      const {data, isRequiredExist, defaultValue} = res
      this.filterBlocks = data
      if (defaultValue)
        this.filters = {...this.filters, ...defaultValue}
      if (isRequiredExist)
        return this.showWarningSnackbar('Заполните обязательные поля!')
      this.getData()
    },
    handleChange(name, value) {
      if (value === '' || (typeof value === "object" && !value?.length)) {
        const params = {...this.filters}
        params[name] = null
        return this.filters = params
      }
      this.filters[name] = value
    },
  },
  setup() {
    const {showErrorSnackbar, showWarningSnackbar} = useCustomSnackbar();
    return {showErrorSnackbar, showWarningSnackbar};
  },
}
</script>

<style scoped lang="scss">
.back {
  margin-bottom: 15px;
}

.content {
  display: grid;
  gap: 20px;
  overflow-x: auto;

  &__initial {
    display: block;
    gap: 0;
    overflow: visible;
  }

  @media (max-width: 1007px) {
    grid-template-columns: 150px calc(100vw - 250px) !important;
  }
  @media (max-width: 768px) {
    display: block;
  }
}

.tabPanel {
  padding-right: 4px;
  padding-left: 4px;
}

.additional {
  display: flex;
  width: 150px;
  flex-direction: column;
  gap: 15px;
  padding-top: 30px;

  @media (max-width: 768px) {
    flex-direction: row;
    width: auto;
    margin-bottom: 15px;
    padding-top: 10px;
    overflow-x: auto;
  }

  &__initial {
    display: none;
  }

  &__item {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    font-size: 20px;
    font-weight: 700;
    width: 100%;
    overflow: auto;
    @media (max-width: 768px) {
      width: 150px;
      flex-shrink: 0;
    }

    span {
      font-weight: 400;
      font-size: 12px;
      display: block;
      margin-top: 5px;
      color: #6b6b6b;
    }
  }
}
</style>