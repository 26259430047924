<template>
  <CollapseBlock>
    <p class="warning">
      Фильтр по периоду для накладных поставщика работает по дате накладной, для
      остальных платежей по дате создания
    </p>
    <div class="filters">
      <FiltersWrapper>
        <CustomDate
            placeholder="Дата создания (от)"
            :value="this.values.created_date_from || ''"
            @change_date="(value) => onChange('created_date_from', value)"
        />
      </FiltersWrapper>
      <FiltersWrapper>
        <CustomDate
            placeholder="Дата создания (до)"
            :value="this.values.created_date_to || ''"
            @change_date="(value) => onChange('created_date_to', value)"
        />
      </FiltersWrapper>
      <div class="filters__item">
        <CustomSelect
            :options="this.contragentsTypes"
            label="Тип контрагента"
            optionValueKey="value"
            optionLabelKey="label"
            :value="this.values.cost_contragent_type"
            @change="(value) => onChange('cost_contragent_type', value)"
        />
      </div>
      <div class="filters__item">
        <CustomSelect
            :options="this.contractors"
            label="Подрядчик"
            optionValueKey="contractor_id"
            optionLabelKey="contractor_name"
            :value="this.values.contractor_id"
            @change="(value) => onChange('contractor_id', value)"
        />
      </div>
      <div class="filters__item">
        <CustomSelect
            :options="this.providers"
            label="Поставщик"
            optionValueKey="provider_id"
            optionLabelKey="provider_name"
            :value="this.values.provider_id"
            @change="(value) => onChange('provider_id', value)"
        />
      </div>
      <div class="filters__item">
        <CustomSelect
            :options="this.providers"
            label="Склад"
            optionValueKey="provider_id"
            optionLabelKey="provider_name"
            :value="this.values.provider_id_other"
            @change="(value) => onChange('provider_id_other', value)"
        />
      </div>
      <div class="filters__item">
        <CustomSelect
            :options="this.techprojectDevelopers"
            label="Разработчик тех. проекта"
            optionValueKey="techproject_developer_id"
            optionLabelKey="techproject_developer_name"
            :value="this.values.techproject_developer_id"
            @change="(value) => onChange('techproject_developer_id', value)"
        />
      </div>
      <div class="filters__item">
        <CustomInput
            :value="this.values.remont_id"
            type="number"
            label="ID ремонта"
            @clear_input="onChange('remont_id', '')"
            v-debounce:600="(value) => onChange('remont_id', value)"
        />
      </div>
      <div class="filters__item">
        <CustomInput
            :value="this.values.provider_request_invoice_id"
            type="number"
            label="ID накладной"
            @clear_input="onChange('provider_request_invoice_id', '')"
            v-debounce:600="(value) => onChange('provider_request_invoice_id', value)"
        />
      </div>
      <CustomCheckbox :value="this.values.is_warranty" label="На гарантии"
                      @change="(value) => onChange('is_warranty', value)"/>
    </div>
  </CollapseBlock>
</template>

<script>
import CustomSelect from "@/components/form/CustomSelect";
import CustomDate from "@/components/form/CustomDate";
import CustomInput from "@/components/form/CustomInput";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import {contragentsTypes, getFiltersMetaData} from "./services";
import CollapseBlock from "@/components/common/CollapseBlock";
import FiltersWrapper from "@/components/common/FiltersWrapper";

export default {
  props: ["values"],
  components: {FiltersWrapper, CollapseBlock, CustomSelect, CustomDate, CustomInput, CustomCheckbox},
  data() {
    return {
      contragentsTypes,
      providers: [],
      contractors: [],
      paymentTypes: [],
      techprojectDevelopers: [],
    };
  },
  methods: {
    onChange(type, value) {
      this.$emit('filter_change', type, value);
    },
  },
  async mounted() {
    const res = await getFiltersMetaData();
    if (!res) return;
    const [
      providersResponse,
      contractorsResponse,
      techprojectDevelopersResponse,
    ] = res;
    this.providers = providersResponse;
    this.contractors = contractorsResponse;
    this.techprojectDevelopers = techprojectDevelopersResponse.techproject_developers;
  },
};
</script>
<style scoped lang="scss">
.filters {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 15px;

  &__item {
    width: 230px;
    max-width: 230px;
  }
}

.warning {
  margin: 0;
  color: #e15d00;
}
</style>
