<template>
  <AgreementsTable
      title="Спецификации"
      :rowsData="this.rowsData"
      :is-fetching="isFetching"
      remont-mode
      :loading="this.loading"
      :on-cancel-click="this.cancelAgreementData"
      :on-delete-click="this.deleteAgreementData"
      :modal-request="this.agreementDocAction"
      :sign-spec="this.signSpec"
  />
</template>

<script>
import AgreementsTable from "@/views/AgreementsJournal/AgreementsTable";
import {cancelRemontAgreement, deleteRemontAgreement} from "@/views/ClientRequests/contractorAgreement/services";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";
import {agreementDocActionReq, signAgreement} from "@/views/AgreementsJournal/services";
import {appActions} from "@/services/store/modules/app_module/app_actions";

export default {
  name: "ContractorAgreementBlock",
  components: {
    AgreementsTable,
  },
  props: ["client_request_id", "isFetching", "data"],
  data() {
    return {
      rowsData: this.data,
      loading: {
        cancel: false,
        delete: false,
      },
    }
  },
  methods: {
    async cancelAgreementData(contractor_agreement_list_id) {
      this.loading.cancel = true
      const res = await cancelRemontAgreement(this.client_request_id, contractor_agreement_list_id)
      this.loading.cancel = false
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
    },
    async deleteAgreementData(contractor_agreement_list_id) {
      this.loading.delete = true
      const res = await deleteRemontAgreement(this.client_request_id, contractor_agreement_list_id)
      this.loading.delete = false
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
    },
    async agreementDocAction(body, actionName) {
      const res = await agreementDocActionReq(body, actionName, {}, this.client_request_id)
      if(!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    async signSpec(contractor_agreement_list_id) {
      const redirect_url = await signAgreement(contractor_agreement_list_id)
      if(!redirect_url) return
      window.open(redirect_url, "_blank")
    },
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar();
    return {showSucceedSnackbar};
  },
}
</script>

<style scoped lang="scss"></style>
