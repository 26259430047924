import instance from "../../../services/api";

export const executiveReportAPI = {
  async getReportTypes() {
    return await instance(true)
      .get("/mng_report/groups/read/")
      .then(res => res?.data);
  },
  async getData(body = {}) {
    return await instance(true)
      .post("/mng_report/read/", body)
      .then(res => res?.data);
  },
  async getReportAnalyze(body = {}) {
    return await instance(true)
      .post("/mng_report/data_scan/", body)
      .then(res => res?.data);
  },
  async downloadExcel(body = {}) {
    return await instance(true)
      .post("/mng_report/excel/", body, {
        responseType: "arraybuffer",
      })
      .then(res => res?.data);
  },
  async getSalePoints() {
    return await instance(true)
      .get("/common/sale_points/read/")
      .then(res => res?.data?.data);
  },
  async getContractorsWithMain() {
    return await instance(true)
      .get("/common/contractors/with_contractor_main/read/")
      .then(res => res?.data?.contractors);
  },
  async getActionInfo(body) {
    return await instance(true)
      .post("/mng_report/action/read/", body)
      .then(res => res?.data);
  },
  async addReportComment(body) {
    return await instance(true)
      .post("/mng_report/action/comment/add/", body)
      .then(res => res?.data);
  },
  async deleteReportComment(mng_report_comment_id, params) {
    return await instance(true)
      .delete(`mng_report/action/comment/${mng_report_comment_id}/delete/`, {params})
      .then(res => res?.data);
  },
  async setDeadline(body) {
    return await instance(true)
      .post(`mng_report/action/wcheck_deadline/`, body)
      .then(res => res?.data);
  },
  async setNps(body) {
    return await instance(true)
      .post(`mng_report/action/nps_support/`, body)
      .then(res => res?.data);
  },
  async warrantyAccept(body) {
    return await instance(true)
      .post(`mng_report/action/warranty/accept/`, body)
      .then(res => res?.data);
  },
  async skipNps(body) {
    return await instance(true)
      .post(`mng_report/action/nps_support/skip/`, body)
      .then(res => res?.data);
  },
  async checkMaterialsAvail(body) {
    return await instance(true)
      .post(`mng_report/action/material_isset/accept/`, body)
      .then(res => res?.data);
  },
  async setEmployee(body) {
    return await instance(true)
      .post(`mng_report/action/employee/set/`, body)
      .then(res => res?.data);
  },
  async setOkk(body) {
    return await instance(true)
      .post(`/mng_report/action/resident_okk/`, body)
      .then(res => res?.data);
  },
  async measureReady(body) {
    return await instance(true)
      .post(`/mng_report/action/measure_ready/`, body)
      .then(res => res?.data);
  },
};
