import {checkListModalAPI} from "@/components/modal/modals/check-list-photos/api";

export const getCheckListPhotos = async (client_request_id, check_list_id) => {
  try {
    const res = await checkListModalAPI.getPhotos(client_request_id, check_list_id)
    if(!res?.data?.length) return
    return res?.data?.map((item, idx) => ({...item, number: idx + 1}))
  } catch (e) {
  }
}
export const checkListModalColumns = [
  {
    name: "remont_check_id",
    label: "ID",
    field: "remont_check_id",
    sortable: true,
    align: 'center',
  },
  {
    name: "date_create",
    label: "Дата",
    field: "date_create",
    align: 'center',
  },
  {
    name: "fio",
    label: "ОКК",
    field: "fio",
    sortable: true,
    align: 'left',
  },
  {
    name: "comments",
    label: "Комментарий",
    field: "comments",
    sortable: true,
    align: 'left',
  },
  {
    name: "content_url",
    label: "Файл",
    field: "content_url",
    align: 'center',
  },
  {
    name: "is_accepted",
    label: "Статус",
    field: "is_accepted",
    align: 'center',
  },
]