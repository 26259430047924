<template>
  <div class="remontProjectsMeasurement__wrapper">
    <h2 class="remontProjectsMeasurement__title">Ссылка на 3D замеры</h2>
    <CustomInput
        label="Ссылка на 3D замеры"
        :value="this.linkValue"
        @change_input="(value) => this.onChange(value)"
    />
    <p class="remontProjectsMeasurement__link">Ссылка: <Clickable :link="true" :href="this.measureReference">{{this.measureReference}}</Clickable></p>
    <div class="remontProjectsMeasurement__submit">
      <q-btn @click="this.updateMeasureLink" :loading="this.measureLoading" :disable="this.measureLoading" color="primary" class="remontProjectsMeasurement__submit_btn">Сохранить</q-btn>
    </div>
  </div>
</template>

<script>
import CustomInput from "@/components/form/CustomInput";
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import Clickable from "@/components/common/Clickable";
import {updateMeasureReference} from "@/views/ClientRequests/remontProjects/services";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";

export default {
  name: "RemontProjectMeasurement",
  components: {Clickable, CustomModalFooter, CustomInput},
  props: ["data", "client_request_id"],
  data() {
    return {
      linkValue: this.data.measure_reference,
      measureReference: this.data.measure_reference,
      measureLoading: false
    }
  },
  methods: {
    onChange(value) {
      this.linkValue = value
    },
    async updateMeasureLink() {
      this.measureLoading = true
      const res = await updateMeasureReference(this.client_request_id, {measure_reference: this.linkValue})
      this.measureLoading = false
      if(!res) return
      this.linkValue = res?.measure_reference
      this.measureReference = res?.measure_reference
      this.showSucceedSnackbar()
    }
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar()
    return {showSucceedSnackbar}
  }
}
</script>

<style scoped lang="scss">
.remontProjectsMeasurement {
  &__wrapper {
    max-width: 400px;
    padding: 25px;
    border-radius: 4px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
  }
  &__title {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
    line-height: 20px;
    margin-bottom: 15px;
  }
  &__link {
    margin-top: 20px;
  }
  &__submit {
    margin-top: 25px;
    display: flex;
    justify-content: flex-end;
  }
}
</style>