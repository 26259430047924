<template>
  <div class="remontBlock__wrapper">
    <FetchingWrapper :loading="this.isFetching" :data="this.data">
      <div v-for="item in this.data">
        <component :is="item.component" :isFetching="this.isFetching" :client_request_id="this.client_request_id"
                   :data="item?.data"/>
      </div>
    </FetchingWrapper>
  </div>
</template>

<script>
import RemontMaterialsSaleUnitsBlock from "./remontMaterialsSaleUnitsBlock";
import FetchingWrapper from "@/components/common/FetchingWrapper";
import {getRemontMaterialsSaleUnitsData} from "@/views/ClientRequests/remontMaterialsSaleUnits/services";

export default {
  name: "RemontMaterials",
  components: {FetchingWrapper, RemontMaterialsSaleUnitsBlock},
  props: ["client_request_id"],
  data() {
    return {
      data: [],
      isFetching: false,
    }
  },
  methods: {
    async getData() {
      this.isFetching = true
      const res = await getRemontMaterialsSaleUnitsData(this?.client_request_id)
      this.isFetching = false
      if (!res) return
      this.data = res
    },
  },
  mounted() {
    this.getData()
  },
}
</script>

<style scoped lang="scss">
.remontBlock {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
}
</style>