import instance from "@/services/api";

export const remontMaterialSmetaAPI = {
  async getList(client_request_id) {
    return await instance()
      .get(`/client_request/${client_request_id}/smeta_material/read/`)
      .then((res) => res?.data);
  },
  async createRequests(client_request_id) {
    return await instance()
      .post(
        `/client_request/${client_request_id}/smeta_material/request/create/`
      )
      .then((res) => res?.data);
  },
};
