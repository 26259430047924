<template>
  <p class="text">{{ this.data.text }}</p>
</template>

<script>
export default {
  name: "modal-text",
  props: ['data'],
}
</script>

<style scoped lang="scss">
.text {
  max-width: 570px;
  min-width: 250px;

  @media (max-width: 576px) {
    max-width: 100%;
  }
}
</style>