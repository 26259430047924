<template>
  <CustomTable
      title="Сервисы"
      :sticky-header="true"
      :rowsData="this.rowsData"
      :columns="remontServicesColumns"
      :custom-body="true"
  >
    <template #customBody="props">
      <q-tr>
        <custom-table-cell align="center">
          {{ props.row.remont_service_id }}
        </custom-table-cell>
        <custom-table-cell align="left">
          <Clickable @click="() => this.handleUpdateClick(props.row)">
            {{ props.row.service_name }}
          </Clickable>
        </custom-table-cell>
        <custom-table-cell align="right">
          {{ numberWithCommas(props.row.service_price) }}
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.fio }}
        </custom-table-cell>
        <custom-table-cell align="center">
          {{ props.row.last_update_time }}
        </custom-table-cell>
      </q-tr>
    </template>
  </CustomTable>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import CustomIcon from "@/components/common/CustomIcon";
import PopupConfirm from "@/components/common/PopupConfirm";
import {appActions} from "@/services/store/modules/app_module/app_actions";
import {MODAL_NAMES} from "@/constants";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";
import {updateRemontService, remontServicesColumns} from "@/views/ClientRequests/remontServices/services";
import {numberWithCommas} from "@/services";
import Clickable from "@/components/common/Clickable";

export default {
  name: "remontsServicesBlock",
  components: {Clickable, PopupConfirm, CustomIcon, CustomTable, CustomTableCell},
  props: ["data", "client_request_id"],
  data() {
    return {
      remontServicesColumns,
      rowsData: this.data
    }
  },
  methods: {
    numberWithCommas,
    handleUpdateClick(row) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.AddRemontService, {
        title: 'Изменить сервис',
        onSubmit: body => this.onUpdateSubmit(body, row.remont_service_id),
        body: {service_price: row.service_price}
      }))
    },
    async onUpdateSubmit(body, remont_service_id) {
      const res = await updateRemontService(this.client_request_id, remont_service_id, body)
      if (!res) return
      this.showSucceedSnackbar()
      this.rowsData = res
      this.$store.commit(appActions.closeModal());
    },
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar();
    return {showSucceedSnackbar};
  },
}
</script>