<template>
  <div>
    <q-form @submit="this.onSubmit" class="form">
      <div class="form__item">
        <CustomInput
            label="Название"
            type="text"
            :required="true"
            :value="this.formData.activity_name"
            @change_input="(value) => onChange('activity_name', value)"
        />
      </div>
      <div class="form__item">
        <CustomInput
            label="Код"
            :required="true"
            :value="this.formData.activity_code"
            @change_input="(value) => onChange('activity_code', value)"
        />
      </div>
      <div class="form__item">
        <CustomSelect
            :options="this.activityTypes"
            label="Тип действия"
            :required="true"
            optionValueKey="activity_type_id"
            optionLabelKey="type_name"
            :value="this.formData.activity_type_id || ''"
            @change="(value) => onChange('activity_type_id', value)"
        />
      </div>
      <div class="form__item">
        <CustomInput
            label="Параметры (json)"
            type="textarea" rows="5"
            :value="this.formData.param_json"
            @change_input="(value) => onChange('param_json', value)"
        />
      </div>
      <CustomModalFooter :btn-loading="this.loading" />
    </q-form>
  </div>
</template>
<script>

import CustomInput from "@/components/form/CustomInput";
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomSelect from "@/components/form/CustomSelect";
import {getActivityTypes} from "@/views/handbook/gradeTimelineActions/services";

export default {
  name: "add-grade-action",
  components: {CustomSelect, CustomInput, CustomModalFooter},
  props: ['data'],
  data() {
    return {
      loading: false,
      activityTypes: [],
      formData: {
        activity_name: this.data?.body?.activity_name || '',
        activity_type_id: this.data?.body?.activity_type_id || '',
        param_json: this.data?.body?.param_json || '',
        activity_code: this.data?.body?.activity_code || '',
      }
    }
  },
  methods: {
    onChange(key, value) {
      this.formData[key] = value
    },
    async onSubmit() {
      this.loading = true
      await this.data?.onSubmit(this.formData)
      this.loading = false
    },
    async fetchActivityTypes() {
      const res = await getActivityTypes()
      this.activityTypes = res || []
    },
  },
  mounted() {
    this.fetchActivityTypes()
  }
}
</script>

<style scoped lang="scss">
.form {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  gap: 20px;

  @media (max-width: 428px) {
    min-width: 100%;
  }
}
</style>
