<template>
  <CollapseBlock tablet-mode>
    <div class="filters">
      <div class="filters__left">
        <div class="filters__item">
          <CustomInput
              type="number"
              @enter="this.onSubmit"
              label="ID ремонта"
              :value="values.remont_id"
              @change_input="(value) => this.onChange('remont_id', value)"
          />
        </div>
        <div class="filters__item">
          <CustomInput
              type="number"
              @enter="this.onSubmit"
              label="ID заявки"
              :value="values.client_request_id"
              @change_input="(value) => this.onChange('client_request_id', value)"
          />
        </div>
        <q-btn
            :loading="this.loading"
            :disable="this.loading"
            @click="this.onSubmit"
            color="primary"
        >
          <q-icon name="search"/>
        </q-btn>
      </div>
      <div class="filters__actions">
        <q-btn
            :loading="this.loadingActive"
            v-if="this.userData?.permissions?.show_is_stop_timeline_button && this.timelineData?.is_stop_timeline !== undefined"
            size="sm"
            @click="this.handleChangeActive"
            :color="this.timelineData?.is_stop_timeline ? 'green' : 'red'">
          {{ this.timelineData?.is_stop_timeline ? 'Активировать' : 'Деактивировать' }}
          <q-tooltip>{{ `${this.timelineData?.is_stop_timeline ? 'Активировать' : 'Отключить'}` }} движение по таймлайну</q-tooltip>
        </q-btn>
        <q-btn
            :loading="this.loadingCheck"
            :disable="this.loadingCheck"
            v-if="this.isCheckAccess && this.showCheckButton"
            size="sm"
            @click="this.timelineCheck"
            color="primary"
        >
          Запуск проверки
        </q-btn>
      </div>
    </div>
  </CollapseBlock>
</template>

<script>
import CustomInput from "@/components/form/CustomInput";
import {isTimelineHaveCheckAccess} from "@/views/timeline/services";
import CollapseBlock from "@/components/common/CollapseBlock";

export default {
  name: "remont-timeline-filters",
  props: ["values", "onChange", "timelineData", "changeActive", "onSubmit", "loading", 'loadingCheck', 'timelineCheck', 'showCheckButton'],
  data() {
    return {
      isCheckAccess: false,
      loadingActive: false,
    }
  },
  components: {CollapseBlock, CustomInput},
  mounted() {
    this.checkIsHaveAccess()
  },
  computed: {
    userData() {
      return this.$store.state.app?.userData
    },
  },
  methods: {
    async handleChangeActive() {
      this.loadingActive = true
      const res = await this.changeActive()
      this.loadingActive = false
      return res
    },
    async checkIsHaveAccess() {
      const res = await isTimelineHaveCheckAccess()
      this.isCheckAccess = !!res
    }
  }
};
</script>

<style scoped lang="scss">
.filters {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: space-between;
  row-gap: 10px;
  padding: 15px 15px 5px;
  flex-wrap: wrap;

  &__actions {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
  }

  &__left {
    display: flex;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;

    &__item {
      width: 100px;
    }
  }
}
</style>
