import {remontServicesAPI} from "@/views/ClientRequests/remontServices/api";

const remontServicesBlockNames = {
  OA__RemontFormRemontServiceBlock: "remontsServicesBlock",
}

export const getRemontServicesData = async (client_request_id) => {
  try {
    const res = await remontServicesAPI.getList(client_request_id)
    const data = res?.data
    if (!data?.length) return
    return data?.map(item => ({...item, component: remontServicesBlockNames[item.grant_code]}))
  } catch (e) {
  }
}
export const updateRemontService = async (client_request_id, remont_service_id, body) => {
  if(isNaN(body.service_price)) return
  try {
    const res = await remontServicesAPI.updateService(client_request_id, remont_service_id, body)
    return res?.data
  } catch (e) {
  }
}
export const remontServicesColumns = [
  {
    name: "remont_service_id",
    label: "ID",
    field: "remont_service_id",
    align: "center",
  },
  {
    name: "service_name",
    label: "Название",
    field: "service_name",
    align: "left",
  },
  {
    name: "service_price",
    label: "Цена",
    field: "service_price",
    align: "right",
  },
  {
    name: "fio",
    label: "Пользователь",
    field: "fio",
    align: "left",
  },
  {
    name: "last_update_time",
    label: "Последнее изменение",
    field: "last_update_time",
    align: "center",
  },
]