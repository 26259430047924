<template>
  <CustomTable
      :title="this.title"
      :sticky-header="true"
      :rowsData="this.data"
      :columns="this.remontMaterialSmetaColumns"
      :custom-body="true"
      :small="true"
  >
    <template #customBody="props">
      <q-tr>
        <custom-table-cell align="center">
          {{ props.row.smeta_material_id }}
        </custom-table-cell>
        <custom-table-cell align="center">
          {{ props.row.material_id }}
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.material_name }}
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.material_name_extra }}
        </custom-table-cell>
        <custom-table-cell align="center">
          {{ props.row.date_shipping }}
        </custom-table-cell>
        <custom-table-cell align="center">
          <CheckOrClose only-true :value="props.row.is_active" />
        </custom-table-cell>
        <custom-table-cell align="right">
          {{ props.row.param_value }}
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.material_type_name }}
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.provider_name }}
        </custom-table-cell>
        <custom-table-cell align="right">
          {{ `${props.row.material_cnt} ${props.row.consume_unit_name}` }}
        </custom-table-cell>
        <custom-table-cell align="right">
          {{ `${props.row.cnt_unit} ${props.row.sell_unit_name}` }}
        </custom-table-cell>
        <custom-table-cell align="right">
          {{ `${props.row.cnt_delivery} ${props.row.delivery_unit_name}` }}
        </custom-table-cell>
        <custom-table-cell align="right">
          {{ `${props.row.qty_atom} ${props.row.atom_unit_name}` }}
        </custom-table-cell>
        <custom-table-cell align="right">
          {{ props.row.price }}
        </custom-table-cell>
        <custom-table-cell align="right">
          {{ props.row.amount }}
        </custom-table-cell>
        <custom-table-cell align="right">
          {{ props.row.residue }}
        </custom-table-cell>
        <custom-table-cell align="center">
          {{ props.row.rowversion }}
        </custom-table-cell>
      </q-tr>
    </template>
  </CustomTable>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import CustomIcon from "@/components/common/CustomIcon";
import {remontMaterialSmetaColumns} from "@/views/ClientRequests/remontMaterialSmeta/services";
import CheckOrClose from "@/components/common/CheckOrClose";

export default {
  name: "remontsMaterialSmetaTable",
  components: {CheckOrClose, CustomIcon, CustomTable, CustomTableCell},
  props: ["data", "title"],
  data() {
    return {
      remontMaterialSmetaColumns
    }
  },
}
</script>

<style scoped lang="scss">
</style>