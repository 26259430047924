<template>
  <div class="fastSearch">
    <h4 class="fastSearch__title">Быстрый поиск</h4>
    <div class="fastSearch__content">
      <CollapseBlock tablet-mode>
        <q-form class="fastSearch__form" @submit="handleSearch">
          <CustomInput type="number" label="ID заявки" :value="this.formData.client_request_id"
                       @change_input="val => onChange('client_request_id', val)" />
          <CustomInput type="number" label="ID ремонта" :value="this.formData.remont_id"
                       @change_input="val => onChange('remont_id', val)" />
          <CustomInput label="ФИО клиента" :value="this.formData.client_name"
                       @change_input="val => onChange('client_name', val)" />
          <CustomInput label="Email" :value="this.formData.email" type="email"
                       @change_input="val => onChange('email', val)" />
          <CustomInput label="Телефон" :value="this.formData.client_phone" type="phone"
                       @change_input="val => onChange('client_phone', val)" />
          <CustomInput label="Номер договора" :value="this.formData.request_num"
                       @change_input="val => onChange('request_num', val)" />
          <CustomInput label="ФИО в рассрочке" :value="this.formData.credit_client_fio"
                       @change_input="val => onChange('credit_client_fio', val)" />
          <div class="fastSearch__resident">
            <CustomSelect class="fastSearch__select"
                          @change="(value) => onChange('resident_id', value)"
                          optionValueKey="resident_id"
                          optionLabelKey="resident_name" label="Выберите ЖК" :options="this.residents" :value="this.formData.resident_id" />
            <CustomInput :disable="!this.formData?.resident_id" label="№ кв." :value="this.formData.flat_num"
                         @change_input="val => onChange('flat_num', val)" />
          </div>
          <div class="fastSearch__actions">
            <q-btn type="submit" :loading="this.loading" no-wrap :disable="this.loading" color="primary"><q-icon name="search" /> Поиск</q-btn>
            <q-btn @click="handleResetForm" no-wrap color="red"><q-icon name="sync" /> Cбросить</q-btn>
          </div>
        </q-form>
      </CollapseBlock>
      <div class="fastSearch__result">
        <CustomTable
            title="Результат поиска"
            :sticky-header="true"
            :rowsData="this.searchResult"
            :columns="this.fastSearchColumns"
            :custom-body="true"
            :small="true"
            :loading="this.loading"
        >
          <template #customBody="props">
            <q-tr>
              <custom-table-cell align="center">
                <Clickable router-link :href="`/requests/${props.row.client_request_id}`">
                  {{ props.row.client_request_id }}
                </Clickable>
              </custom-table-cell>
              <custom-table-cell align="center">
                {{ props.row.remont_id }}
              </custom-table-cell>
              <custom-table-cell align="left">
                {{ props.row.client_fio }}
              </custom-table-cell>
              <custom-table-cell align="center" style="width: 100px">
                {{ this.formatPhoneNumber(props.row.client_phone) }}
              </custom-table-cell>
              <custom-table-cell align="left">
                {{ `ЖК ${props.row?.resident_name}, кв.№${props.row?.flat_num}` }}
              </custom-table-cell>
              <custom-table-cell align="left">
                {{ props.row?.remont_type }}
              </custom-table-cell>
              <custom-table-cell align="left">
                {{ props.row?.request_status_name }}
              </custom-table-cell>
              <custom-table-cell align="left">
                {{ props.row?.remont_status_name }}
              </custom-table-cell>
              <custom-table-cell align="left">
                {{ `${props.row?.preset_name} (${props.row?.preset_id})` }}
              </custom-table-cell>
              <custom-table-cell align="center">
                {{ props.row?.warranty_date_begin }}
              </custom-table-cell>
              <custom-table-cell align="center">
                {{ props.row?.waranty_date_end }}
              </custom-table-cell>
              <custom-table-cell align="left">
                <div><b>ОКК:</b> {{props.row?.okk_fio}}</div>
                <div><b>Проектный менеджер:</b> {{props.row?.inner_fio}}</div>
              </custom-table-cell>
            </q-tr>
          </template>
        </CustomTable>
        <CustomTable
            title="Последние просмотренные ремонты и заявки"
            :sticky-header="true"
            :rowsData="this.history"
            :columns="this.quickSearchHistoryColumns"
            :custom-body="true"
            :small="true"
            :loading="this.fetchingHistory"
        >
          <template #customBody="props">
            <q-tr>
              <custom-table-cell align="center">
                <Clickable router-link :href="`/requests/${props.row.client_request_id}`">
                  {{ props.row.client_request_id }}
                </Clickable>
              </custom-table-cell>
              <custom-table-cell align="center">
                {{ props.row.remont_id }}
              </custom-table-cell>
              <custom-table-cell align="left">
                {{ props.row.client_name }}
              </custom-table-cell>
              <custom-table-cell align="left">
                {{ props.row?.address }}
              </custom-table-cell>
              <custom-table-cell align="center">
                {{ props.row?.rowversion }}
              </custom-table-cell>
            </q-tr>
          </template>
        </CustomTable>
      </div>
    </div>
  </div>
</template>

<script>
import CustomInput from "@/components/form/CustomInput";
import CustomSelect from "@/components/form/CustomSelect";
import {
  getCommonResidents,
  getSearchData,
  fastSearchColumns,
  quickSearchHistory,
  quickSearchHistoryColumns
} from "@/views/FastSearch/services";
import {formatPhoneNumber} from "@/services";
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import Clickable from "@/components/common/Clickable";
import CollapseBlock from "@/components/common/CollapseBlock";

export default {
  name: "FastSearch",
  components: {CollapseBlock, Clickable, CustomTable, CustomSelect, CustomInput, CustomTableCell},
  data() {
    return {
      fastSearchColumns,
      quickSearchHistoryColumns,
      loading: false,
      fetchingHistory: false,
      searchResult: [],
      history: [],
      formData: {},
      residents: []
    }
  },
  methods: {
    formatPhoneNumber,
    handleResetForm() {
      this.formData = {}
      this.searchResult = []
    },
    onChange(key, val) {
      this.formData[key] = val
    },
    async getResidents() {
      const res = await getCommonResidents()
      if(!res) return
      this.residents = res
    },
    async handleSearch() {
      this.loading = true
      const res = await getSearchData(this.formData)
      this.loading = false
      this.searchResult = res || []
    },
    async getHistory() {
      this.fetchingHistory = true
      const res = await quickSearchHistory()
      this.fetchingHistory = false
      this.history = res || []
    }
  },
  mounted() {
    this.getResidents()
    this.getHistory()
  }
}
</script>

<style scoped lang="scss">
.fastSearch {
  &__content {
    display: grid;
    gap: 30px;
    margin-top: 25px;
    grid-template-columns: 300px auto;

    @media (max-width: 991px) {
      display: block;
    }
  }
  &__title {
    padding: 0;
    margin: 0;
  }
  &__select {
    max-width: 170px;
    width: 170px;
  }
  &__form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 300px;
    min-width: 300px;
    overflow-x: hidden;
    padding-bottom: 8px;
  }
  &__resident {
    width: 100%;
    display: grid;
    gap: 15px;
    align-items: center;
    grid-template-columns: 8fr 4fr;
  }
  &__actions {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  &__result {
    display: flex;
    flex-direction: column;
    gap: 15px;
    @media (max-width: 991px) {
      margin-top: 20px;
    }
  }
}
</style>