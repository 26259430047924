<template>
  <q-select
      v-model="model"
      :options="this.editedOptions"
      color="teal"
      clearable
      ref="multiSelect"
      class="customMultiselect"
      multiple
      :label="`${this.label || 'Выберите'} ${this.required ? '*' : ''}`"
      :use-input="false"
      :dense="true"
      :map-options="true"
      :options-dense="true"
      :loading="this.loading"
      :option-value="this.optionValueKey"
      :option-label="this.optionLabelKey || ''"
      dropdown-icon="expand_more"
      :rules="this.rules"
  >
    <template v-slot:selected="scope">
      <div v-if="this.value?.length">{{ isAllSelected ? 'Все' : 'Выбрано' }}</div>
    </template>
    <template v-slot:option="{itemProps, opt}">
      <div class="customMultiselectItem" v-bind="itemProps">
        <MultiSelectItem :value="this.value" :level-deep="1" :children-key="this.childrenKey"
                         :option-label-key="this.optionLabelKey" :all-selected="this.value?.length === this.allChecked?.length"
                         :opt="opt" :expanded-options="this.expandedOptions" :toggle-selection="this.toggleSelection"
                         :handle-select="this.handleSelect" :option-value-key="this.optionValueKey"/>
      </div>
    </template>
  </q-select>
</template>

<script>
import CustomCheckbox from "@/components/form/CustomCheckbox";
import MultiSelectItem from "@/components/form/CustomMultiSelect/MultiSelectItem";

export default {
  name: "CustomMultiSelect",
  components: {MultiSelectItem, CustomCheckbox},
  props: [
    "options",
    "value",
    "onChange",
    "label",
    "required",
    "triggerValidationOnMount",
    "loading",
    "optionChildrenKey",
    "optionLabelKey",
    "optionValueKey",
  ],
  data() {
    return {
      expandedOptions: [],
    }
  },
  mounted() {
    if (this.triggerValidationOnMount)
      this.$refs.multiSelect.validate()
  },
  computed: {
    model: {
      get() {
        return this.value || [];
      },
      set(row) {
        let value = []
        if (row)
          value = row?.map(item => typeof item === 'object' ? item[this.optionValueKey] : item)
        this.$emit("change", value, row, true);
      },
    },
    editedOptions() {
      if (!this.options?.length) return []
      return [{[this.optionLabelKey]: 'Выбрать все', [this.optionValueKey]: 'ALL'}, ...this.options]
    },
    childrenKey() {
      return this.optionChildrenKey || 'children'
    },
    isAllSelected() {
      return this.value?.length === this.allChecked?.length
    },
    allChecked() {
      const newValue = []
      this.editedOptions?.forEach(option => {
        if (option[this.optionValueKey] !== 'ALL')
          newValue.push(option[this.optionValueKey])
        option[this.childrenKey]?.forEach(item => {
          newValue.push(item[this.optionValueKey])
          item[this.childrenKey]?.forEach(child => {
            newValue.push(child[this.optionValueKey])
          })
        })
      })
      return newValue
    },
    rules() {
      const rules = []
      if (this.required) {
        const reqFunc = val => val?.length || 'Это поле обязательно'
        rules.push(reqFunc)
      }
      return rules
    },
  },
  methods: {
    handleSelect(checked, selectedValue, children, parentValue) {
      let newValue = [...this.value]
      if (checked === null)
        checked = true
      if (checked) {
        if (selectedValue === 'ALL') {
          newValue = this.allChecked
        } else {
          newValue.push(selectedValue)
          if (parentValue?.length) newValue = this.checkParentsStatus(parentValue, newValue)

          children?.forEach(item => {
            newValue.push(item[this.optionValueKey])
            item[this.childrenKey]?.forEach(child => {
              newValue.push(child[this.optionValueKey])
            })
          })
        }
      } else {
        if (selectedValue === "ALL") {
          newValue = []
        } else {
          if (this.isEveryItemChecked(children, newValue))
            children?.forEach(item => {
              newValue = newValue.filter(value => value !== item[this.optionValueKey])
              item[this.childrenKey]?.forEach(child => {
                newValue = newValue.filter(value => value !== child[this.optionValueKey])
              })
            })
          newValue = newValue?.filter(item => item !== selectedValue && item !== 'ALL' && !parentValue?.includes(item))
        }
      }

      const uniqueSet = new Set(newValue);
      const uniqueArray = Array.from(uniqueSet);
      this.$emit("change", uniqueArray)
    },
    checkParentsStatus(parentValue = [], oldValues) {
      const newValues = []
      this.editedOptions?.forEach(item => {
        if (item[this.optionValueKey] === parentValue[0]) {
          if (parentValue[1]) {
            item[this.childrenKey]?.forEach(child => {
              if (child[this.optionValueKey] === parentValue[1]) {
                if (this.isEveryItemChecked(child[this.childrenKey], oldValues)) newValues.push(parentValue[1])
              }
            })
          }
          if (this.isEveryItemChecked(item[this.childrenKey], oldValues)) newValues.push(parentValue[0])
        }
      })
      return [...oldValues, ...newValues]
    },
    toggleSelection(value) {
      const isExist = this.expandedOptions?.includes(value)
      if (isExist)
        return this.expandedOptions = this.expandedOptions?.filter(item => item !== value)
      this.expandedOptions = [...this.expandedOptions, value]
    },
    isEveryItemChecked(children = [], arr = []) {
      return children?.every(item => arr.includes(item[this.optionValueKey]))
    }
  }
}
</script>

<style lang="scss">

.customMultiselect {
  font-size: 14px;
  padding-bottom: 0 !important;
}

.customSelectCheckbox {
  z-index: 2;
  position: relative;
}

.customMultiselectItem {
  display: flex;
  flex-direction: column !important;
  align-items: flex-start !important;
  color: #262626;
  cursor: pointer;
  width: 100% !important;

  &:first-child {
    padding-top: 10px;
    padding-bottom: 5px;
  }
}
</style>