<template>
  <div class="receive__header">
    <CustomInput :controls="true" :integer="true" label="Кол-во ключей" type="number"
                 :value="this.keysData.key_cnt" @enter="handleSaveCount"
                 @change_input="(value) => this.onInputChange('key_cnt', value)"/>
    <CustomInput :controls="true" :integer="true" label="Кол-во брелоков" type="number"
                 :value="this.keysData.trinket_cnt" @enter="handleSaveCount"
                 @change_input="(value) => this.onInputChange('trinket_cnt', value)"/>
    <q-btn size="sm" padding="xs" color="primary" :loading="this.loading.count" :disable="this.loading.count" @click="handleSaveCount" >Сохранить</q-btn>
  </div>
  <CustomTable
      title="Передача ключей"
      :rowsData="this.rowsData"
      :columns="receiveKeysColumns"
      :loading="isFetching"
      :custom-body="true"
      :add-btn="true"
      :on-add-click="this.handleAddClick"
  >
    <template #customBody="props">
      <q-tr>
        <custom-table-cell align="center">
          <Clickable @click="handleEditClick(props.row)">
            {{ props.row.key_receive_id }}
          </Clickable>
        </custom-table-cell>
        <custom-table-cell align="center">
            {{ props.row.receive_date }}
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.key_from }}
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.key_to }}
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.comments }}
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.employee_fio }}
        </custom-table-cell>
        <custom-table-cell align="center">
          <CustomIcon :loading="this.loading.delete" :approve="() => this.handleDeleteReceive(props.row.key_receive_id)" icon="trash"/>
        </custom-table-cell>
      </q-tr>
    </template>
  </CustomTable>
</template>

<script>
import BlockItem from "@/components/common/BlockItem";
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import {
  addKeys,
  deleteKey,
  getKeysCount,
  receiveKeysColumns, updateKeys,
  updateKeysCount
} from "@/views/ClientRequests/receiveKeys/services";
import CustomIcon from "@/components/common/CustomIcon";
import {appActions} from "@/services/store/modules/app_module/app_actions";
import {MODAL_NAMES} from "@/constants";
import Clickable from "@/components/common/Clickable";
import CustomInput from "@/components/form/CustomInput";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";

export default {
  name: "ReceiveKeysBlock",
  components: {CustomInput, Clickable, CustomIcon, BlockItem, CustomTable, CustomTableCell, CustomCheckbox},
  props: ["client_request_id", "isFetching", "data"],
  data() {
    return {
      selectedItems: {},
      receiveKeysColumns,
      keysData: {},
      rowsData: this.data,
      loading: {
        count: false,
        delete: false
      }
    }
  },
  methods: {
    handleEditClick(formData) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.RECEIVE_KEYS_EDIT, {
        title: "Передача ключей",
        formData,
        onSubmit: this.onReceiveUpdateSubmit,
      }));
    },
    handleAddClick() {
      this.$store.commit(appActions.showModal(MODAL_NAMES.RECEIVE_KEYS_EDIT, {
        title: "Передача ключей",
        formData: {},
        onSubmit: this.addReceive,
      }));
    },
    onInputChange(name, value) {
      this.keysData[name] = value
    },
    async handleSaveCount() {
      this.loading.count = true
      const res = await updateKeysCount(this.client_request_id, this.keysData)
      this.loading.count = false
      if (!res) return
      this.showSucceedSnackbar()
      this.keysData = res
    },
    async handleDeleteReceive(id) {
      this.loading.delete = true
      const res = await deleteKey(this.client_request_id, id)
      this.loading.delete = false
      if (!res) return
      this.showSucceedSnackbar()
      this.rowsData = res
    },
    async addReceive(id, body) {
      const res = await addKeys(this.client_request_id, body)
      if (!res) return
      this.showSucceedSnackbar()
      this.rowsData = res
      return true
    },
    async getKeysCountData() {
      const res = await getKeysCount(this.client_request_id)
      if (!res) return
      this.keysData = res
    },
    async onReceiveUpdateSubmit(id, body) {
      const res = await updateKeys(this.client_request_id, id, body)
      if (!res) return
      this.showSucceedSnackbar()
      this.rowsData = res
      return true
    }
  },
  mounted() {
    this.getKeysCountData()
  },
  setup() {
    const { showSucceedSnackbar } = useCustomSnackbar();
    return { showSucceedSnackbar };
  },
}
</script>

<style scoped lang="scss">
.receive {
  &__header {
    display: grid;
    grid-template-columns: 110px 110px 85px;
    gap: 20px;
    margin-bottom: 15px;

    @media(max-width: 360px) {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
</style>