const wt_decode = (token) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
};

export const getTokenExpires = (token) => {
  if (!token) return;
  try {
    const decodedToken = wt_decode(token);
    return decodedToken.exp;
  } catch (e) {
    return 0;
  }
};

export const getTokenExpiresDuration = (token) => {
  if (!token) return;
  try {
    const tokenExpiresDate = getTokenExpires(token) * 1000;
    let expires;
    if (tokenExpiresDate && !isNaN(tokenExpiresDate)) {
      expires =
        (new Date(tokenExpiresDate).getTime() - new Date().getTime()) / 1000;
    }
    return expires;
  } catch (e) {
    return 0;
  }
};
