import {remontStagesAPI} from "@/views/ClientRequests/remontStages/api";

const remontStagesBlockNames = {
  OA__RemontFormRemontStageBlock: "remontsStagesBlock",
  OA__RemontFormWorkSetCheckGroupBlock: "callWorkGroupsBlock",
  OA__RemontFormAcceptWaitBlock: "clientAcceptWaitBlock",
  OA__RemontFormDraftCheckBlock: "draftCheckBlock",
}

export const getRemontStagesData = async (client_request_id) => {
  try {
    const res = await remontStagesAPI.getList(client_request_id)
    const data = res?.data
    if (!data?.length) return
    return data?.map(item => ({...item, component: remontStagesBlockNames[item.grant_code]}))
  } catch (e) {
  }
}
export const getRequestStagesList = async (client_request_id) => {
  try {
    const res = await remontStagesAPI.getStages(client_request_id)
    return res?.data
  } catch (e) {
  }
}
export const getStageStatusesList = async () => {
  try {
    const res = await remontStagesAPI.getStageStatuses()
    return res?.data
  } catch (e) {
  }
}
export const addStage = async (client_request_id, body) => {
  try {
    const res = await remontStagesAPI.addStage(client_request_id, body)
    return res?.data
  } catch (e) {
  }
}
export const acceptWaitEliminate = async (client_request_id, check_group_call_id) => {
  try {
    const res = await remontStagesAPI.waitEliminate(client_request_id, {check_group_call_id})
    return res?.data
  } catch (e) {
  }
}
export const acceptDesignCheck = async (client_request_id, check_group_call_id) => {
  try {
    const res = await remontStagesAPI.designCheck(client_request_id, {check_group_call_id})
    return res?.data
  } catch (e) {
  }
}
export const remontStagesColumns = [
  {
    name: "id",
    label: "ID",
    field: "id",
    align: "center",
  },
  {
    name: "stage",
    label: "Этап",
    field: "stage",
    align: "left",
  },
  {
    name: "status",
    label: "Статус этапа",
    field: "status",
    align: "left",
  },
  {
    name: "date",
    label: "Дата создания",
    field: "date",
    align: "center",
  },
  {
    name: "fio",
    label: "Пользователь",
    field: "fio",
    align: "left",
  },
  {
    name: "desc",
    label: "Примечание",
    field: "desc",
    align: "left",
  },
  {
    name: "comment",
    label: "Комментарий",
    field: "comment",
    align: "left",
  },
]
export const callWorkGroupsColumns = [
  {
    name: "check_group_call_id",
    label: "ID",
    field: "check_group_call_id",
    align: "center",
  },
  {
    name: "work_set_check_group_name",
    label: "Группа работ",
    field: "work_set_check_group_name",
    align: "left",
  },
  {
    name: "call_type_name",
    label: "Тип вызова",
    field: "call_type_name",
    align: "left",
  },
  {
    name: "call_date",
    label: "Дата вызова",
    field: "call_date",
    align: "center",
  },
  {
    name: "fio",
    label: "ФИО",
    field: "fio",
    align: "left",
  },
  {
    name: "is_design_checked",
    label: "Проверен МП",
    field: "is_design_checked",
    align: "center",
  },
  {
    name: "checked_status",
    label: "Статус",
    field: "checked_status",
    align: "left",
  },
  {
    name: "check_date",
    label: "Дата проверки",
    field: "check_date",
    align: "center",
  },
]
export const acceptWaitColumns = [
  {
    name: "accept_wait_id",
    label: "ID",
    field: "accept_wait_id",
    align: "center",
  },
  {
    name: "date_begin",
    label: "Дата начала",
    field: "date_begin",
    align: "center",
  },
  {
    name: "date_end",
    label: "Дата окончания",
    field: "date_end",
    align: "center",
  },
  {
    name: "comments",
    label: "Комментарий",
    field: "comments",
    align: "left",
  },
  {
    name: "rowversion",
    label: "Дата создания",
    field: "rowversion",
    align: "center",
  },
]

export const draftCheckHistoryColumns = [
  {
    name: "index",
    label: "ID",
    align: "center",
  },
  {
    name: "client_request_check_type_name",
    label: "Чеклист",
    align: "left",
  },
  {
    name: "check_status",
    label: "Статус",
    align: "left",
  },
  {
    name: "check_date",
    label: "Дата создания",
    align: "center",
  },
  {
    name: "fio",
    label: "ОКК",
    align: "left",
  },
  {
    name: "check_file",
    label: "Вложения",
    align: "center",
  },
]
export const draftCheckAttachmentsColumns = [
  {
    name: "index",
    label: "ID",
    align: "center",
  },
  {
    name: "document_type_name",
    label: "Тип",
    align: "left",
  },
  {
    name: "doc_date",
    label: "Дата создания",
    align: "center",
  },
  {
    name: "fio",
    label: "ОКК",
    align: "left",
  },
  {
    name: "document_url",
    label: "Скачать",
    align: "center",
  },
]