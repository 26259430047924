<template>
  <div class="templates">
    <CustomTable
        title="Шаблоны уведомлений"
        :rowsData="this.rowsData"
        :columns="this.noticeTemplatesColumns"
        :loading="isFetching"
        :small="true"
        :show-search="true"
        :custom-body="true"
        :sticky-header="true"
        :add-btn="true"
        :on-add-click="this.handleAddClick">
      <template #customBody="props">
        <q-tr>
          <custom-table-cell align="left">
            <Clickable @click="() => this.handleUpdateClick(props.row)">
              {{ props.row.notice_template_name }}
            </Clickable>
          </custom-table-cell>
          <custom-table-cell align="left">
            {{ props.row.notice_template_code }}
          </custom-table-cell>
          <custom-table-cell align="left" style="font-size: 12px">
            {{ props.row.notice_template_content }}
          </custom-table-cell>
          <custom-table-cell align="center" vertical-align="center" >
            <CustomIcon icon="trash" :approve-mode="true" :loading="this.loading"
                        :approve="() => this.onDeleteSubmit(props.row.notice_template_id)"/>
          </custom-table-cell>
        </q-tr>
      </template>
    </CustomTable>
  </div>
</template>

<script>
import {
  addNoticeTemplate,
  deleteNoticeTemplate,
  getNoticeTemplates,
  noticeTemplatesColumns,
  updateNoticeTemplate
} from "@/views/handbook/EmailTemplates/services";
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";
import {appActions} from "@/services/store/modules/app_module/app_actions";
import CustomIcon from "@/components/common/CustomIcon";
import {MODAL_NAMES} from "@/constants";
import Clickable from "@/components/common/Clickable";

export default {
  name: "EmailTemplates",
  components: {Clickable, CustomIcon, CustomTableCell, CustomTable},
  data() {
    return {
      noticeTemplatesColumns,
      rowsData: [],
      isFetching: false,
      loading: false
    }
  },
  methods: {
    async getData() {
      this.isFetching = true
      const res = await getNoticeTemplates()
      this.isFetching = false
      if (!res) return
      this.rowsData = res
    },
    async handleAddClick() {
      this.$store.commit(appActions.showModal(MODAL_NAMES.Add_NOTICE_TEMPLATE, {
        onSubmit: this.onAddSubmit,
        title: 'Добавить шаблон'
      }));
    },
    async handleUpdateClick(row) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.Add_NOTICE_TEMPLATE, {
        onSubmit: (body) => this.onUpdateSubmit(row.notice_template_id, body),
        title: 'Изменить шаблон',
        body: row
      }));
    },
    async onAddSubmit(body) {
      const res = await addNoticeTemplate(body)
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    async onUpdateSubmit(notice_template_id, body) {
      const res = await updateNoticeTemplate(notice_template_id, body)
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    async onDeleteSubmit(notice_template_id) {
      this.loading = true
      const res = await deleteNoticeTemplate(notice_template_id)
      this.loading = false
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
  },
  mounted() {
    this.getData()
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar();
    return {showSucceedSnackbar};
  },
}
</script>

<style scoped>
.templates {
  max-width: 900px;
}
</style>