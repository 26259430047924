<template>
  <q-form @submit="this.onSubmit" class="form">
    <div class="form__item">
      <CustomSelect
          :options="this.steps"
          label="Шаг"
          optionValueKey="step_id"
          optionLabelKey="step_name"
          :value="this.formData.step_id || ''"
          @change="(value) => onChange('step_id', value)"
      />
    </div>
    <div class="form__item">
      <CustomCheckbox
          color="primary"
          label="Активный"
          :value="!!this.formData.is_active"
          @change="value => onChange('is_active', value)"
      />
    </div>
    <CustomModalFooter :btn-loading="this.loading"/>
  </q-form>
</template>
<script>

import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomSelect from "@/components/form/CustomSelect";
import {getTimelineSteps} from "@/views/handbook/timelineSteps/services";
import CustomCheckbox from "@/components/form/CustomCheckbox";

export default {
  name: "add-grade-timeline-step",
  components: {CustomCheckbox, CustomSelect, CustomModalFooter},
  props: ['data'],
  data() {
    return {
      loading: false,
      steps: [],
      formData: {
        step_id: this.data?.body?.step_id || '',
        is_active: !!this.data?.body?.is_active,
      },
    }
  },
  methods: {
    onChange(key, value) {
      this.formData[key] = value
    },
    async onSubmit() {
      this.loading = true
      await this.data?.onSubmit(this.formData)
      this.loading = false
    },
    async getSteps() {
      const res = await getTimelineSteps()
      this.steps = res || []
    },
  },
  mounted() {
    this.getSteps()
  }
}
</script>

<style scoped lang="scss">
.form {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  gap: 20px;

  @media (max-width: 428px) {
    min-width: 100%;
  }
}
</style>
