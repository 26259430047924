<template>
  <q-popup-proxy :ref="`popupProxy`">
    <q-banner>
      <p class="accept__title">{{ this.title || 'Подтвердите действие' }}</p>
      <div class="accept__actions">
        <q-btn size="sm" :disable="!!this.loading || this.localLoading"
               color="negative" label="Отмена" @click="this.close()"/>
        <q-btn size="sm" label="Подтвердить"
               :loading="!!this.loading || this.localLoading" :disable="!!this.loading || this.localLoading"
               color="primary" @click="this.onClick()"/>
      </div>
    </q-banner>
  </q-popup-proxy>
</template>

<script>
export default {
  name: "PopupConfirm",
  props: ["closePopup", "approve", "loading", "title"],
  data() {
    return {
      localLoading: false
    }
  },
  methods: {
    async onClick() {
      if(!this.approve) return
      this.localLoading = true
      await this.approve()
      this.localLoading = false
      this.close()
    },
    close() {
      try {
        this.$refs.popupProxy.hide();
        this.closePopup && this.closePopup()
      } catch (e) {}
    }
  }
}
</script>

<style scoped lang="scss">
.accept {
  &__title {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 5px;
    max-width: 300px;
  }

  &__actions {
    display: flex;
    align-content: center;
    justify-content: flex-end;
    gap: 10px;
  }
}
</style>