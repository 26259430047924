import {loggingAPI} from "@/views/logging/api";

export const activateLogging = async (is_debug) => {
  try {
    const res = await loggingAPI.activateLogging({is_debug})
    return res?.data
  } catch (e) {}
}
export const getLogs = async () => {
  try {
    const res = await loggingAPI.getLogs()
    return res?.data
  } catch (e) {}
}