import instance from "@/services/api";

export const handbookWorksetsAPI = {
  async getList() {
    return await instance().get('/handbook/work_set/read/').then(res => res?.data)
  },
  async getWorksetCheckGroups() {
    return await instance().get('/common/work_set_check_groups/read/').then(res => res?.data)
  },
  async updateHandbookWorkset(work_set_id, body) {
    return await instance().put(`/handbook/work_set/${work_set_id}/update/`, body).then(res => res?.data)
  },
  async getSpecialities() {
    return await instance().get(`/common/specialities/read/`).then(res => res?.data)
  },
}

