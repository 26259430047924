<template>
  <div class="responsibleTab__wrapper">
    <FetchingWrapper :loading="this.isFetching" :data="this.data">
      <div v-for="item in this.data">
        <component :is="item.component" :isFetching="this.isFetching" :client_request_id="this.client_request_id"
                   :data="item?.data"/>
      </div>
    </FetchingWrapper>
  </div>
</template>

<script>
import {getResponsibleOfficialData} from "@/views/ClientRequests/responsibleOfficial/services";
import ResponsibleOfficialBlock from "./responsibleOfficial";
import FetchingWrapper from "@/components/common/FetchingWrapper";

export default {
  name: "ResponsibleOfficial",
  components: {FetchingWrapper, ResponsibleOfficialBlock},
  props: ["client_request_id"],
  data() {
    return {
      data: {},
      isFetching: false
    }
  },
  methods: {
    async getData() {
      this.isFetching = true
      const res = await getResponsibleOfficialData(this?.client_request_id)
      this.isFetching = false
      if (!res) return
      this.data = res
    }
  },
  mounted() {
    this.getData()
  },
}
</script>

<style scoped lang="scss">
.responsibleTab {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
}

</style>