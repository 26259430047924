<template>
  <q-form @submit="this.handleSubmit" class="receiveKeysEdit__form">
    <CustomDate :required="true" :value="this.formData.receive_date"
                @change_date="(value) => handleChange('receive_date', value)" placeholder="Дата передачи"
                :full-width="true"/>
    <CustomInput :required="true" :value="this.formData.key_from"
                 @change_input="(value) => this.handleChange('key_from', value)"
                 label="Кто передал" type="text"/>
    <CustomInput :required="true" :value="this.formData.key_to"
                 @change_input="(value) => this.handleChange('key_to', value)"
                 label="Кто принял" type="text"/>
    <CustomInput :required="true" :value="this.formData.comments" @change_input="(value) => this.handleChange('comments', value)"
                 label="Комментарии" type="textarea"/>
    <CustomModalFooter :btn-loading="this.loading"/>
  </q-form>
</template>

<script>
import CustomInput from "@/components/form/CustomInput";
import CustomDate from "@/components/form/CustomDate";
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";
import {appActions} from "@/services/store/modules/app_module/app_actions";

export default {
  name: "ReceiveKeysEdit",
  props: ['data'],
  components: {CustomModalFooter, CustomDate, CustomInput},
  data() {
    return {
      formData: {...this.data?.formData},
      loading: false
    }
  },
  methods: {
    handleChange(key, value) {
      this.formData[key] = value
    },
    async handleSubmit() {
      this.loading = true
      const res = this.data.onSubmit && await this.data.onSubmit(this.data.formData.key_receive_id, this.formData)
      this.loading = false
      if (res)
        this.$store.commit(appActions.closeModal());
    }
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar();
    return {showSucceedSnackbar};
  },
}
</script>

<style scoped lang="scss">
.receiveKeysEdit {
  &__form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
  }
}
</style>