<template>
  <div class="actions">
    <q-btn
        v-for="item in this.changeMaterialsStatuses"
        :class="{actions__denied: item.provider_change_req_status_code === changeStatusesNames.DENIED}"
        :label="item.provider_change_req_status_verb"
        type="submit"
        :disable="this.loading || this.processing[item.provider_change_req_status_code] || !getSelectedLength()"
        color="primary" :loading="this.processing[item.provider_change_req_status_code]"
        @click="this.deny(item.provider_change_req_status_id, item.provider_change_req_status_code, item.provider_change_req_status_name)"
    >
      <PopupConfirm v-if="item.provider_change_req_status_code !== this.changeStatusesNames.DENIED"
                    :approve="() => this.approve(item.provider_change_req_status_id, item.provider_change_req_status_code)"/>
    </q-btn>
  </div>
  <CustomTable
      :title="`${title}${!!getSelectedLength() ? ` (Выбрано: ${getSelectedLength()})` : ''}`"
      rowKey="id"
      :rowsData="data"
      :columns="columns"
      :page-header-height="this.headerHeightData"
      :showSearch="true"
      :stickyHeader="true"
      :loading="loading"
      :on-all-check-click="onAllCheckClick"
      :checkbox="true"
      :checkbox-value="allSelected"
      :customBody="true"
  >
    <template #customBody="props">
      <q-tr :props="props">
        <q-td auto-width>
          <div class="check__wrapper">
            <CustomCheckbox v-if="props.row.is_approvable"
                            :value="!!this.selectedItems[props.row.provider_change_req_id]"
                            @change="(value) => onCheckboxChange(props.row.provider_change_req_id, value)"/>
            <span v-else :class="{status: true,
              status__denied: props.row.provider_change_req_status_code === changeStatusesNames.DENIED,
              status__accepted: props.row.provider_change_req_status_code === changeStatusesNames.ACCEPTED}">
              {{ props.row.provider_change_req_status_name }}
            </span>
          </div>
        </q-td>
        <q-td v-for="col in props.cols" :key="col.name" :props="props">
          {{ col.value }}
        </q-td>
      </q-tr>
    </template>
  </CustomTable>
</template>

<script>
import CustomTable from "../../components/common/CustomTable"
import {approveChangeReq} from "@/views/ChangeMaterialRequests/services";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import PopupConfirm from "@/components/common/PopupConfirm";
import {appActions} from "@/services/store/modules/app_module/app_actions.js";
import {MODAL_NAMES, changeStatusesNames} from "@/constants";
import {getDefaultCheckedValues} from "@/services"

export default {
  name: "ChangeMaterialRequestsContent",
  components: {PopupConfirm, CustomCheckbox, CustomTable},
  props: ["data", "reqType", "changeData", "loading", "title", "columns", "headerHeight"],
  data() {
    return {
      changeStatusesNames,
      processing: {},
      allSelected: false,
      selectedItems: {},
    }
  },
  watch: {
    data(newValue) {
      this.selectedItems = this.getDefaultCheckedValues(newValue, 'is_approvable', 'provider_change_req_id')
    },
    selectedItems(newValue) {
      const checkedLength = this.getSelectedLength(newValue)
      const isAllChecked = Object.keys(newValue).every(item => newValue[item])
      if (isAllChecked && !!checkedLength)
        return this.allSelected = true
      if (checkedLength > 0)
        return this.allSelected = ""
      this.allSelected = false
    }
  },
  computed: {
    changeMaterialsStatuses() {
      return this.$store.state.app.changeMaterialsStatuses;
    },
    headerHeightData() {
      return (this.headerHeight || 0) + 36
    }
  },
  methods: {
    getDefaultCheckedValues,
    getSelectedLength(selectedItems) {
      if (selectedItems)
        return Object.keys(selectedItems).filter(item => !!selectedItems[item]).length
      return Object.keys(this.selectedItems).filter(item => !!this.selectedItems[item]).length
    },
    onCheckboxChange(id, value) {
      this.selectedItems = {...this.selectedItems, [id]: value}
    },
    onAllCheckClick(value) {
      if (!Object.keys(this.selectedItems).length) return
      this.allSelected = value
      const newSelectedItems = {...this.selectedItems}
      Object.keys(newSelectedItems).forEach(item => {
        newSelectedItems[item] = value
      })
      this.selectedItems = newSelectedItems
    },
    async deny(status_id, type, status_name) {
      if (type === changeStatusesNames.DENIED) {
        this.$store.commit(appActions.showModal(MODAL_NAMES.CHANGE_MATERIAL_STATUS, {
          title: `Изменить статус материала (${status_name || ''})`,
          onSubmit: (body) => this.approve(status_id, type, body),
        }));
      }
    },
    async approve(status_id, type, body = {}) {
      this.processing[type] = true
      const res = await approveChangeReq(this.reqType, {status_id, ids: this.selectedItems, ...body})
      this.processing[type] = false
      if (res) {
        this.showSucceedSnackbar()
        this.changeData(res)
        this.$store.commit(appActions.closeModal());
      }
    },
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar()
    return {showSucceedSnackbar}
  }
}
</script>

<style scoped lang="scss">
.actions {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 15px;

  &__denied {
    background: #9d1010 !important;
  }
}

.check {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%
  }
}

.status {
  padding: 2px;
  color: #fff;
  border-radius: 2px;
  font-size: 12px;
  background: #cc6219;

  &__denied {
    background: #ea2626;
  }

  &__accepted {
    background: #2ed523;
  }
}
</style>