
export const filteredRowsData = (columns, rowsData, searchTerm) => {
  let keys = columns?.map(item => {
    return item?.field === item.name ? item.name : item.field
  })
  keys = keys.filter(item => !!item && (typeof item === 'number' || typeof item === 'string'))
  return rowsData?.filter(item => {
    return !!keys.find(key => {
      return String(item[key]).toLowerCase().includes(searchTerm.toLowerCase())
    })
  })
}