<template>
  <div class="templates">
    <CustomTable
        title="Поставщики"
        :rowsData="this.rowsData"
        :columns="this.providersHandbookColumns"
        :loading="isFetching"
        :show-search="true"
        :custom-body="true"
        :sticky-header="true">
      <template #customBody="props">
        <q-tr>
          <custom-table-cell align="center">
            <Clickable @click="() => this.handleUpdateClick(props.row)">
              {{ props.row.provider_id }}
            </Clickable>
          </custom-table-cell>
          <custom-table-cell align="left">
            {{ props.row.provider_name }}
          </custom-table-cell>
          <custom-table-cell align="center" vertical-align="center">
            <CheckOrClose :value="props.row.is_not_create_auto_payment"/>
          </custom-table-cell>
        </q-tr>
      </template>
    </CustomTable>
  </div>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";
import {appActions} from "@/services/store/modules/app_module/app_actions";
import CustomIcon from "@/components/common/CustomIcon";
import {MODAL_NAMES} from "@/constants";
import Clickable from "@/components/common/Clickable";
import {
  getHandbookProviders,
  providersHandbookColumns,
  updateHandbookProvider
} from "@/views/handbook/Providers/services";
import CheckOrClose from "@/components/common/CheckOrClose";

export default {
  name: "HandbookProviders",
  components: {CheckOrClose, Clickable, CustomIcon, CustomTableCell, CustomTable},
  data() {
    return {
      providersHandbookColumns,
      rowsData: [],
      isFetching: false,
      loading: false
    }
  },
  methods: {
    async getData() {
      this.isFetching = true
      const res = await getHandbookProviders()
      this.isFetching = false
      if (!res) return
      this.rowsData = res
    },
    async handleUpdateClick(row) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.Add_HANDBOOK_PROVIDER, {
        onSubmit: (body) => this.onUpdateSubmit(row.provider_id, body),
        title: 'Изменить поставщика',
        body: row
      }));
    },
    async onUpdateSubmit(notice_template_id, body) {
      const res = await updateHandbookProvider(notice_template_id, body)
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
  },
  mounted() {
    this.getData()
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar();
    return {showSucceedSnackbar};
  },
}
</script>

<style scoped>
.templates {
  max-width: 900px;
}
</style>