<template>
  <div class="dialog">
    <q-linear-progress v-if="this.isFetching" indeterminate/>
    <div class="dialog_header">
      <q-btn @click="() => this.resetData && this.resetData()"
             icon="close" color="red" flat round dense
             v-close-popup/>
    </div>
    <chat-gallery :data="{files: this.images, imgIndex: this.imgIndex}"/>
  </div>
</template>

<script>
import ChatGallery from "@/components/modal/modals/chat-gallery/index";
export default {
  name: "chat-dialog",
  components: {ChatGallery},
  props: ['isFetching', 'resetData', 'images', 'imgIndex']
}
</script>

<style scoped lang="scss">
.dialog {
  position: relative;
  padding: 15px 15px 30px;
  min-width: 350px;
  @media (max-width: 370px) {
    min-width: 100%;
  }

  &_header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
    margin-bottom: 10px;
  }
}
</style>