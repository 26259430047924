<template>
  <div>
    <div class="header">
      <div class="header__item">
        <CustomSelect :options="this.apps" :value="this.mobileAppCode" label="Приложение"
                      option-label-key="mobile_app_name"
                      option-value-key="mobile_app_code"
                      @change="this.onChange"
        />
      </div>
    </div>
    <CustomTable
        title="Версии мобильного приложения"
        :rowsData="this.data"
        :columns="this.mobileAppVersionColumns"
        :loading="isFetching"
        :show-search="true"
        :custom-body="true" :on-add-click="this.onAddClick"
        :sticky-header="false" :add-btn="true"
    >
      <template #customBody="props">
        <q-tr>
          <custom-table-cell align="center" vertical-align="center">
            {{ props.row.index }}
          </custom-table-cell>
          <custom-table-cell align="center" vertical-align="center">
            <span title="mobile_app_version_id">{{props.row.mobile_app_version_id}}</span>
            <span title="version_name">{{` (${props.row.version_name})`}}</span>
          </custom-table-cell>
          <custom-table-cell align="center" vertical-align="center">
            <Clickable link :href="getFullUrl(props.row.file_url, this.isNewOffice(props.row.file_url))">
              Скачать
              <q-icon name="download"/>
            </Clickable>
          </custom-table-cell>
          <custom-table-cell align="left" vertical-align="center">
            {{ props.row.date_create }}
          </custom-table-cell>
          <custom-table-cell align="center" vertical-align="center">
            <CustomIcon icon="trash" :approve-mode="true"
                        :loading="!!this.deleteLoading[props.row.mobile_app_version_id]"
                        :approve="() => this.onDeleteSubmit(props.row.mobile_app_version_id)"/>
          </custom-table-cell>
        </q-tr>
      </template>
    </CustomTable>
  </div>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import {
  addAppVersion,
  deleteAppVersion,
  getMobileApps,
  getMobileAppVersions,
  mobileAppVersionColumns
} from "@/views/handbook/MobileApps/services";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import Clickable from "@/components/common/Clickable";
import CustomIcon from "@/components/common/CustomIcon";
import CustomSelect from "@/components/form/CustomSelect";
import {getFullUrl} from "@/services";
import {appActions} from "@/services/store/modules/app_module/app_actions";
import {MODAL_NAMES} from "@/constants";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";

export default {
  name: "MobileApps",
  components: {CustomSelect, CustomIcon, Clickable, CustomTableCell, CustomTable},
  data() {
    return {
      apps: [],
      rowsData: [],
      mobileAppVersionColumns,
      mobileAppCode: '',
      isFetching: false,
      deleteLoading: {},
    }
  },
  mounted() {
    this.onMounted()
  },
  computed: {
    data() {
      return this.rowsData?.map((item, i) => ({...item, index: i + 1}))
    }
  },
  methods: {
    getFullUrl,
    async onMounted() {
      this.apps = await getMobileApps()
      if (!this.apps?.length) return
      const appCode = this.apps[0].mobile_app_code
      this.mobileAppCode = appCode || ''
      await this.getData(appCode)
    },
    async getData(appCode) {
      this.isFetching = true
      const res = await getMobileAppVersions(appCode)
      this.isFetching = false
      if (!res) return
      this.rowsData = res
    },
    async onDeleteSubmit(version) {
      this.deleteLoading = {...this.deleteLoading, [version]: true}
      const res = await deleteAppVersion(this.mobileAppCode, version)
      this.deleteLoading = {...this.deleteLoading, [version]: false}
      if (!res) return
      this.rowsData = res
    },
    async onAddSubmit(body) {
      const res = await addAppVersion(this.mobileAppCode, body)
      if (!res) return
      this.rowsData = res
      this.$store.commit(appActions.closeModal());
      this.showSucceedSnackbar()
    },
    onAddClick() {
      this.$store.commit(appActions.showModal(MODAL_NAMES.AddMobileAppVersion, {
        title: 'Добавить новую версию приложения',
        onSubmit: this.onAddSubmit
      }));
    },
    onChange(appCode) {
      this.mobileAppCode = appCode
      this.getData(appCode)
    },
    isNewOffice(fileUrl) {
      if(!fileUrl) return
      const directoryName = fileUrl.split('/')[1]
      return directoryName === 'media'
    }
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar();
    return {showSucceedSnackbar};
  },
}
</script>

<style scoped lang="scss">
.header {
  margin-bottom: 15px;
  &__item {
    width: 300px;
  }
  @media (max-width: 400px) {
    &__item {
      width: 100%;
    }
  }
}
</style>