<template>
  <q-drawer
      :width="250"
      show-if-above
      v-model="this.model"
      side="left"
      bordered
      class="menu"
  >
    <q-linear-progress v-if="this.loading" indeterminate/>
    <div
        class="menu__header">
      <q-toolbar-title class="menu__logo">
        <img src="@/assets/img/logo.svg" alt="menu_logo"/>
        <q-btn
            dense
            flat
            round class="menu__logo_toggle"
            color="#fff"
            icon="menu"
            @click.prevent="$emit('toggle-navbar')"
        />
      </q-toolbar-title>
      <div class="menu__flex">
        <CityBlock/>
        <CompaniesBlock/>
      </div>
    </div>
    <menu-list :menu="menu"/>
  </q-drawer>
</template>
<script>
import MenuList from './MenuList'
import {getMenuData} from "@/components/layout/Navbar/services";
import CityBlock from "@/components/features/cityBlock";
import CompaniesBlock from "@/components/features/companiesBlock";

export default {
  props: ["value"],
  name: "Navbar",
  components: {CompaniesBlock, CityBlock, MenuList},
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("change", newValue);
      },
    },
  },
  async mounted() {
    this.loading = true
    this.menu = await getMenuData()
    this.loading = false
  },
  data() {
    return {
      menu: [],
      loading: false
    };
  },
};
</script>

<style scoped lang="scss">
.menu {
  &__list {
    padding: 0;
  }

  &__flex {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 10px;
    padding: 0 10px 5px;
  }

  &__logo {
    display: none;
    align-items: center;

    & > img {
      height: 35px;
    }

    &_toggle {
      color: #fff;
    }
  }
}

@media (max-width: 576px) {
  .menu {
    &__header {
      display: flex;
      flex-direction: column;
      gap: 10px;
      background-color: #1976d2;
    }

    &__logo {
      display: flex;
      justify-content: space-between;
      padding: 10px !important;

      & > img {
        display: block;
      }
    }
  }
}
</style>
