import {
  approveChangeMaterialRequests,
  getChangeMaterialRequests,
  getChangeMaterialRequestsStatuses, getChangeMaterialRequestsStatusesAll
} from "@/views/ChangeMaterialRequests/api";
import {getProvidersData} from "@/views/Costs/СostsFilters/services";
import {getSelectedIds} from "@/_helpers";
import {numberWithCommas} from "@/services";

export const getChangeMaterialRequestsData = async (type, params = {}) => {
  if(!type) return
  try {
    const res = await getChangeMaterialRequests(type, params)
    return res?.provider_change_reqs || []
  } catch (e) {
    return []
  }
}

export const getChangeMaterialReqStatuses = async () => {
  try {
    const res = await getChangeMaterialRequestsStatuses()
    return res?.provider_change_req_statuses || []
  } catch (e) {
    return []
  }
}

export const changeMaterialsReqTypes = {
  "AVAILABILITY": "AVAILABILITY",
  "PRICE": "PRICE"
}

export const approveChangeReq = async (reqType, body) => {
  try {
    const id_arr = getSelectedIds(body.ids)
    const {status_id, reason_for_denial} = body
    const res = await approveChangeMaterialRequests(reqType, {status_id, reason_for_denial,  id_arr})
    return res?.provider_change_reqs
  } catch (e) {
  }
}

export const getMaterialsReqStatuses = async () => {
  try {
    const res = await getChangeMaterialRequestsStatusesAll()
    return res?.provider_change_req_statuses
  } catch (e) {
    return []
  }
}

export const getMaterialsFiltersMetaData = async () => {
  return await Promise.all([
    getProvidersData(),
    getMaterialsReqStatuses(),
  ]).catch(() => {
    return null;
  });
};
export const reqPriceTableColumns = [
  {
    name: "material_id",
    label: "ID Материала",
    field: "material_id",
    sortable: true,
    align: 'left'
  },
  {
    name: "material_name",
    label: "Материал",
    field: "material_name",
    sortable: true,
    align: 'left'
  },
  {
    name: "created_date",
    label: "Дата создания",
    field: "created_date",
    sortable: true,
    align: 'center'
  },
  {
    name: "provider_name",
    label: "Поставщик",
    field: "provider_name",
    sortable: true,
    align: 'left'
  },
  {
    name: "sell_unit_name",
    label: "Ед. продажи",
    field: "sell_unit_name",
    sortable: true,
    align: 'left'
  },
  {
    name: "old_price_date",
    label: "Дата пред.",
    field: "old_price_date",
    sortable: true,
    align: 'center'
  },
  {
    name: "new_price_date",
    label: "Дата нов.",
    field: "new_price_date",
    sortable: true,
    align: 'center'
  },
  {
    name: "price_old",
    label: "Активная цена",
    field: "price_old",
    sortable: true,
    style: {width: "120px"},
    align: 'right',
    format(val, row) {
      const isExist = !!row.old_price_whole || !!row.old_price
      if (!isExist)
        return ''
      return `${numberWithCommas(row.old_price_whole) || '-'} / ${numberWithCommas(row.old_price) || '-'}`
    },
  },
  {
    name: "price",
    label: "Нов. цена",
    field: "price",
    sortable: true,
    style: {width: "120px"},
    align: 'right',
    format(val, row) {
      const isExist = !!row.new_price_whole || !!row.new_price
      if (!isExist)
        return ''
      return `${numberWithCommas(row.new_price_whole) || '-'} / ${numberWithCommas(row.new_price) || '-'}`
    },
  },
  {
    name: "reason_for_denial",
    label: "Причина",
    field: "reason_for_denial",
    sortable: true,
    style: {width: "250px"},
    align: 'left'
  },
]
export const reqAvailTableColumns = [
  {
    name: "material_id",
    label: "ID Материала",
    field: "material_id",
    sortable: true,
    align: 'left'
  },
  {
    name: "material_name",
    label: "Материал",
    field: "material_name",
    sortable: true,
    align: 'left'
  },
  {
    name: "created_date",
    label: "Дата создания",
    field: "created_date",
    sortable: true,
    align: 'center'
  },
  {
    name: "provider_name",
    label: "Поставщик",
    field: "provider_name",
    sortable: true,
    align: 'left'
  },
  {
    name: "sell_unit_name",
    label: "Ед. продажи",
    field: "sell_unit_name",
    sortable: true,
    align: 'left'
  },
  {
    name: "new_avail",
    label: "Нов. статус",
    field: "new_avail",
    align: 'left',
    sortable: true,
    format(val) {
      if(typeof val !== "boolean") return ''
      return val ? 'Есть в наличии' : 'Нет в наличии'
    },
  },
  {
    name: "comments",
    label: "Комментарии",
    field: "comments",
    style: {width: "200px"},
    align: 'left'
  },
  {
    name: "reason_for_denial",
    label: "Причина",
    field: "reason_for_denial",
    sortable: true,
    style: {width: "200px"},
    align: 'left'
  },
]