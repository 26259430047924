export const executiveReportFlatList = [
  {
    name: "flat_num",
    label: "№ квартиры",
    field: "flat_num",
    align: "center",
  },
  {
    name: "client_request_id",
    label: "№ заявки",
    field: "client_request_id",
    align: "center",
  },
]