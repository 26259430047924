<template>
  <Block title="Скидки / Рассрочки / Акции">
    <template #actions>
    </template>
    <div class="requisites__content">
      <div>
        <BlockItem title="Рассрочка">
        </BlockItem>
        <BlockItem title="Скидка" :multiple="true">
          <span v-for="item in this.data?.discount">
            {{`${item.discount_name} - ${numberWithCommas(item.discount_amount)}`}}</span>
        </BlockItem>
        <BlockItem title="Акция">
          <span v-for="item in this.data?.sale">
            {{item?.sale_name}}</span>
          </BlockItem>
      </div>
    </div>
  </Block>
</template>

<script>
import Block from "@/components/common/Block";
import BlockItem from "@/components/common/BlockItem";
import {numberWithCommas} from "@/services";

export default {
  name: "salesBlock",
  props: ["data"],
  components: {Block, BlockItem},
  methods: {
    numberWithCommas
  }
}
</script>

<style scoped lang="scss">
.requisites {
  &__content {
    display: grid;
    gap: 25px;
    grid-template-columns: 12fr;

    & > div {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }
}
</style>