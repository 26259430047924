import instance from "@/services/api";

export const remontDocsAPI = {
  async getList(client_request_id) {
    return await instance().get(`/client_request/${client_request_id}/documents/read/`).then(res => res?.data)
  },
  async getDocTypes() {
    return await instance().get('/common/document_types/read/').then(res => res?.data)
  },
  async getDSTypesByDocs() {
    return await instance().get('/common/ds_types/for_doc/read/').then(res => res?.data)
  },
  async getApproveDocTypes() {
    return await instance().get('/common/approve_document_types/read/').then(res => res?.data)
  },
  async addDoc(client_request_id, body) {
    return await instance().post(`/client_request/${client_request_id}/documents/add/`, body, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then(res => res?.data)
  },
  async updateDoc(client_request_id, client_request_document_id, body) {
    return await instance().put(`/client_request/${client_request_id}/documents/${client_request_document_id}/update/`, body, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then(res => res?.data)
  },
  async deleteDoc(client_request_id, client_request_document_id) {
    return await instance().delete(`/client_request/${client_request_id}/documents/${client_request_document_id}/delete/`).then(res => res?.data)
  },
}