<template>
  <CustomTable
      title="Условия шага"
      :rowsData="this.rowsData"
      :columns="this.stepConditionsColumns"
      :loading="isFetching"
      :small="true"
      :show-search="true"
      :custom-body="true" :on-add-click="this.handleAddClick"
      :showHeaderAdd="true"
      :sticky-header="true">
    <template #customBody="props">
      <q-tr>
        <custom-table-cell align="center">
          {{ props.row.step_condition_id }}
        </custom-table-cell>
        <custom-table-cell align="left">
          <Clickable @click="() => this.handleUpdateClick(props.row)">
            {{ props.row.comment }}
          </Clickable>
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.step_label }}
        </custom-table-cell>
        <custom-table-cell align="center">
          <CheckOrClose :value="!!props.row.is_active"/>
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.condition_type_name }}
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.param_json }}
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.param_value }}
        </custom-table-cell>
        <custom-table-cell align="center" vertical-align="center">
          <CustomIcon icon="trash" :approve-mode="true"
                      :loading="!!this.deleteLoading[props.row.step_condition_id]"
                      :approve="() => this.onDeleteSubmit(props.row.step_condition_id)"/>
        </custom-table-cell>
      </q-tr>
    </template>
  </CustomTable>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import {appActions} from "@/services/store/modules/app_module/app_actions";
import {MODAL_NAMES} from "@/constants";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";
import CustomIcon from "@/components/common/CustomIcon";
import Clickable from "@/components/common/Clickable";
import {
  addTimelineStepCondition,
  deleteTimelineStepCondition,
  getTimelineStepConditions,
  stepConditionsColumns,
  updateTimelineStepCondition
} from "@/views/handbook/timlineStepConditions/services";
import CheckOrClose from "@/components/common/CheckOrClose";

export default {
  name: "TimelineStepConditions",
  data() {
    return {
      stepConditionsColumns,
      rowsData: [],
      deleteLoading: {},
      isFetching: false,
    }
  },
  components: {CheckOrClose, Clickable, CustomIcon, CustomTableCell, CustomTable},
  methods: {
    async getData() {
      this.isFetching = true
      const res = await getTimelineStepConditions()
      this.isFetching = false
      if (!res) return
      this.rowsData = res
    },
    async handleAddClick() {
      this.$store.commit(appActions.showModal(MODAL_NAMES.AddStepCondition, {
        onSubmit: this.onAddCondition,
        title: 'Добавление условия',
      }));
    },
    async onAddCondition(body) {
      const res = await addTimelineStepCondition(body)
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    async handleUpdateClick(row) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.AddStepCondition, {
        onSubmit: body => this.onUpdateCondition(row.step_condition_id, body),
        title: 'Редактировать условие',
        body: row
      }));
    },
    async onUpdateCondition(step_condition_id, body) {
      const res = await updateTimelineStepCondition(step_condition_id, body)
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    async onDeleteSubmit(step_condition_id) {
      this.deleteLoading = {...this.deleteLoading, [step_condition_id]: true}
      const res = await deleteTimelineStepCondition(step_condition_id)
      this.deleteLoading = {...this.deleteLoading, [step_condition_id]: false}
      if (!res) return
      this.rowsData = res
    },
  },
  mounted() {
    this.getData()
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar();
    return {showSucceedSnackbar};
  },
}
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
</style>