<template>
  <div class="payment">
    <q-form @submit="this.onSubmit" class="payment__form">
      <CustomSelect
          :options="this.paymentStatuses"
          label="Статус платежа" :required="true"
          optionValueKey="finance_payment_status_id"
          optionLabelKey="finance_payment_status_name"
          :value="this.formData.finance_payment_status_id || ''"
          @change="(value) => onChange('finance_payment_status_id', value)"
      />
      <CustomModalFooter submit-btn-text="Сохранить" :btnLoading="this.loading" />
    </q-form>
  </div>
</template>

<script>
import CustomSelect from "@/components/form/CustomSelect";
import {getPaymentStatusesData} from "@/views/FinancePayments/services";
import CustomModalFooter from "@/components/modal/modals/modal-footer";

export default {
  name: "ChangePaymentStatus",
  props: ["data"],
  components: {
    CustomModalFooter,
    CustomSelect,
  },
  data() {
    return {
      loading: false,
      formData: {
        finance_payment_status_id: this.data?.status_id || ''
      },
      paymentStatuses: [],
    };
  },
  async mounted() {
    this.paymentStatuses = await getPaymentStatusesData()
  },
  methods: {
    async onSubmit() {
      this.loading = true
      await this.data?.onSubmit(this.formData)
      this.loading = false
    },
    async onChange(type, value) {
      this.formData[type] = value
    },
  },
}
</script>

<style scoped lang="scss">
.payment {
  max-width: 630px;

  &__form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}
</style>