import instance from "@/services/api/index";

export const AppAPI = {
  async getUserData() {
    return await instance().get('/common/user_info/read/').then(res => res?.data)
  },
  async getCities() {
    return await instance().get('/common/cities/read/').then(res => res?.data)
  },
  async getCompanies() {
    return await instance().get('/common/companies/read/').then(res => res?.data)
  },
  async chooseCity(body) {
    return await instance().post('/common/cities/choose/', body).then(res => res?.data)
  },
  async chooseCompany(body) {
    return await instance().post('/common/companies/change/', body).then(res => res?.data)
  },
  async getResidentsByCity() {
    return await instance().get('/common/residents/read/city_based/').then(res => res?.data)
  }
}