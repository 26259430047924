<template>
  <RemontsMaterialSmetaTable title="Черновые материалы" :data="this.data"/>
</template>

<script>
import RemontsMaterialSmetaTable from "@/views/ClientRequests/remontMaterialSmeta/remontMaterialSmetaTable";
export default {
  name: "MaterialSmetaDirtyBlock",
  props: ['data'],
  components: {RemontsMaterialSmetaTable}
}
</script>

<style scoped>

</style>