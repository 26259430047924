import instance from "@/services/api";

export const orgFilesAPI = {
  async getList() {
    return await instance().get('/handbook/org_files/read/').then(res => res?.data)
  },
  async addFile(body) {
    return await instance().post('/handbook/org_files/add/', body).then(res => res?.data)
  },
  async deleteFile(org_file_id) {
    return await instance().delete(`/handbook/org_files/${org_file_id}/delete/`).then(res => res?.data)
  }
}

