<template>
  <q-form @submit="this.onSubmit" class="form">
    <div class="form__item">
      <CustomInput
          label="Название шага"
          :value="this.formData.step_name" :required="true"
          @change_input="(value) => onChange('step_name', value)"/>
    </div>
    <div class="form__item">
      <CustomInput
          label="Код"
          :value="this.formData.step_code" :required="true"
          @change_input="(value) => onChange('step_code', value)"/>
    </div>
    <div class="form__item">
      <CustomInput
          label="Комментарии" type="textarea" rows="4"
          :value="this.formData.comment"
          @change_input="(value) => onChange('comment', value)"/>
    </div>
    <CustomModalFooter :btn-loading="this.loading"/>
  </q-form>
</template>
<script>

import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomSelect from "@/components/form/CustomSelect";
import CustomInput from "@/components/form/CustomInput";

export default {
  name: "add-timeline-step",
  components: {CustomInput, CustomSelect, CustomModalFooter},
  props: ['data'],
  data() {
    return {
      loading: false,
      isFetching: false,
      formData: {
        step_name: this.data?.body?.step_name || '',
        step_code: this.data?.body?.step_code || '',
        comment: this.data?.body?.comment || '',
      },
    }
  },
  methods: {
    onChange(key, value) {
      this.formData[key] = value
    },
    async onSubmit() {
      this.loading = true
      await this.data?.onSubmit(this.formData)
      this.loading = false
    },
  },
}
</script>

<style scoped lang="scss">
.form {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  gap: 20px;

  @media (max-width: 428px) {
    min-width: 100%;
  }
}
</style>
