<template>
  <div class="gpr__wrapper">
    <FetchingWrapper :loading="this.isFetching" :data="this.data">
      <div v-for="item in this.data">
        <component :is="item.component" :isFetching="this.isFetching" :client_request_id="this.client_request_id"
                   :data="item?.data"/>
      </div>
    </FetchingWrapper>
  </div>
</template>

<script>
import GprStageBlock from "./gprStage";
import {getGprStageData} from "@/views/ClientRequests/gprStage/services";
import FetchingWrapper from "@/components/common/FetchingWrapper";

export default {
  name: "GprStage",
  props: ["client_request_id"],
  components: {FetchingWrapper, GprStageBlock},
  data() {
    return {
      isFetching: false,
      data: [],
    }
  },
  methods: {
    async getData() {
      this.isFetching = true
      const res = await getGprStageData(this?.client_request_id)
      this.isFetching = false
      if (!res) return
      this.data = res
    },
  },
  mounted() {
    this.getData()
  },
}
</script>

<style scoped lang="scss">
.gpr {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
}

</style>