<template>
  <CustomTable
      title="Список действий"
      :rowsData="this.rowsData"
      :columns="this.gradeActionsHandbookColumns"
      :loading="isFetching"
      :small="true"
      :show-search="true"
      :custom-body="true" :on-add-click="this.handleAddClick"
      :showHeaderAdd="true"
      :sticky-header="true">
    <template #customBody="props">
      <q-tr>
        <custom-table-cell align="center">
          {{ props.row.activity_id }}
        </custom-table-cell>
        <custom-table-cell align="left">
          <Clickable @click="() => this.handleUpdateClick(props.row)">
            {{ props.row.activity_name }}
          </Clickable>
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.activity_code }}
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.type_name }}
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.param_json }}
        </custom-table-cell>
        <custom-table-cell align="center" vertical-align="center">
          <CustomIcon icon="trash" :approve-mode="true"
                      :loading="!!this.deleteLoading[props.row.activity_id]"
                      :approve="() => this.onDeleteSubmit(props.row.activity_id)"/>
        </custom-table-cell>
      </q-tr>
    </template>
  </CustomTable>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import {
  addGradeAction,
  deleteGradeAction,
  getGradeActions,
  gradeActionsHandbookColumns, updateGradeAction
} from "@/views/handbook/gradeTimelineActions/services";
import {appActions} from "@/services/store/modules/app_module/app_actions";
import {MODAL_NAMES} from "@/constants";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";
import CustomIcon from "@/components/common/CustomIcon";
import Clickable from "@/components/common/Clickable";

export default {
  name: "GradeTimelineActions",
  data() {
    return {
      gradeActionsHandbookColumns,
      rowsData: [],
      deleteLoading: {},
      isFetching: false,
    }
  },
  components: {Clickable, CustomIcon, CustomTableCell, CustomTable},
  methods: {
    async getData() {
      this.isFetching = true
      const res = await getGradeActions()
      this.isFetching = false
      if (!res) return
      this.rowsData = res
    },
    async handleAddClick() {
      this.$store.commit(appActions.showModal(MODAL_NAMES.AddGradeAction, {
        onSubmit: this.onAddGradeAction,
        title: 'Добавление действия',
      }));
    },
    async onAddGradeAction(body) {
      const res = await addGradeAction(body)
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    async handleUpdateClick(row) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.AddGradeAction, {
        onSubmit: body => this.onUpdateGradeAction(row.activity_id, body),
        title: 'Редактировать действие',
        body: row
      }));
    },
    async onUpdateGradeAction(activity_id, body) {
      const res = await updateGradeAction(activity_id, body)
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    async onDeleteSubmit(activity_id) {
      this.deleteLoading = {...this.deleteLoading, [activity_id]: true}
      const res = await deleteGradeAction(activity_id)
      this.deleteLoading = {...this.deleteLoading, [activity_id]: false}
      if (!res) return
      this.rowsData = res
    },
  },
  mounted() {
    this.getData()
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar();
    return {showSucceedSnackbar};
  },
}
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
</style>