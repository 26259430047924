import { agreementAPI } from "./api";

const agreementBlockNames = {
  OA__RemontFormContractorAgreementBlock: "contractorAgreementBlock",
};
export const getAgreementData = async (client_request_id) => {
  try {
    const res = await agreementAPI.getList(client_request_id);
    const data = res?.data;
    if (!data?.length) return;
    return data?.map((item) => ({
      ...item,
      component: agreementBlockNames[item.grant_code],
    }));
  } catch (e) {}
};
export const cancelRemontAgreement = async (client_request_id, contractor_agreement_list_id) => {
  try {
    const res = await agreementAPI.cancelAgreement(client_request_id, contractor_agreement_list_id);
    return res?.data;
  } catch (e) {}
};
export const deleteRemontAgreement = async (client_request_id, contractor_agreement_list_id) => {
  try {
    const res = await agreementAPI.deleteAgreement(client_request_id, contractor_agreement_list_id);
    return res?.data;
  } catch (e) {}
};
