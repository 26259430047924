import instance from "@/services/api";

export const remontStagesAPI = {
  async getList(client_request_id) {
    return await instance().get(`/client_request/${client_request_id}/stages/read/`).then(res => res?.data)
  },
  async getStages(client_request_id) {
    return await instance().post('/common/stages/read/', {client_request_id}).then(res => res?.data)
  },
  async getStageStatuses() {
    return await instance().get('/common/stage_statuses/read/').then(res => res?.data)
  },
  async addStage(client_request_id, body) {
    return await instance().post(`/client_request/${client_request_id}/stages/add/`, body).then(res => res?.data)
  },
  async waitEliminate(client_request_id, body) {
    return await instance().post(`/client_request/${client_request_id}/stages/accept_wait/eliminate/`, body).then(res => res?.data)
  },
  async designCheck(client_request_id, body) {
    return await instance().post(`/client_request/${client_request_id}/stages/design_check/`, body).then(res => res?.data)
  },
}