<template>
  <div :class="['form', {purpose: this.data?.body?.purpose}]">
    <q-form @submit="this.onSubmit">
      <div class="form__item">
        <CustomDate v-if="this.data?.date" :fullWidth="true" :required="true"
                    placeholder="Дата оплаты"
                    :value="this.formData.pay_date || ''"
                    @change_date="(value) => onChange('pay_date', value)"
        />
      </div>
      <div class="form__item" v-if="this.data?.purpose">
        <CustomInput label="Назначение платежа" :required="true" type="textarea" :value="this.formData.payment_purpose"
                     @change_input="(value) => onChange('payment_purpose', value)"/>
      </div>
      <CustomModalFooter :btn-loading="this.loading"/>
    </q-form>
  </div>
</template>
<script>

import CustomDate from "@/components/form/CustomDate";
import CustomInput from "@/components/form/CustomInput";
import CustomModalFooter from "@/components/modal/modals/modal-footer";

export default {
  name: "change-finance-payment",
  components: {CustomDate, CustomInput, CustomModalFooter},
  props: ['data'],
  data() {
    return {
      loading: false,
      formData: {
        pay_date: this.data?.body?.pay_date || '',
        payment_purpose: this.data?.body?.payment_purpose || ''
      }
    }
  },
  methods: {
    onChange(key, value) {
      this.formData[key] = value
    },
    async onSubmit() {
      let body
      const {pay_date, payment_purpose} = this.formData
      if (this.data?.date) body = {pay_date}
      if (this.data?.purpose) body = {payment_purpose}
      this.loading = true
      await this.data?.onSubmit(body)
      this.loading = false
    },
  }
}
</script>

<style scoped lang="scss">
.form {
  &__item {
    margin-top: 15px;
  }
}
</style>