import instance from "@/services/api";

export const handbookProvidersAPI = {
  async getList() {
    return await instance().get('/handbook/providers/read/').then(res => res?.data)
  },
  async updateProvider(provider_id, body) {
    return await instance().put(`/handbook/providers/${provider_id}/update/`, body).then(res => res?.data)
  },
}

