<template>
  <CustomLoader v-if="this.isFetching"/>
  <div v-else class="responsible__wrapper">
    <div class="responsible__actions">
      <q-icon class="responsible__actions_item" name="history" @click="openParticipantsHistoryModal">
        <q-tooltip>История замены участников ремонта</q-tooltip>
      </q-icon>
    </div>
    <div class="responsible__group responsible__group_blue">
      <BlockItem>
        <template v-slot:default>
          <span class="responsible__value responsible__group_blueValue">
            {{ this.responsibleData?.contractor_name || '-' }}
            <span class="responsible__value_edit">
              <CustomIcon icon="edit" @click="openChangeParticipantsModal('contractor_id')"/>
            </span>
          </span>
        </template>
        <template v-slot:title>
          <span class="responsible__group_blueTitle">Подрядчик</span>
        </template>
      </BlockItem>
      <BlockItem>
        <template v-slot:default>
          <span class="responsible__value responsible__group_blueValue">
            {{ this.responsibleData?.general_contractor_name || '-' }}
            <span class="responsible__value_edit">
              <CustomIcon icon="edit" @click="openChangeParticipantsModal('general_contractor_id')"/>
            </span>
          </span>
        </template>
        <template v-slot:title>
          <span class="responsible__group_blueTitle">Генеральный подрядчик</span>
        </template>
      </BlockItem>
      <BlockItem>
        <template v-slot:default>
          <span class="responsible__value responsible__group_blueValue">
            {{ this.responsibleData?.contractor_main_name || '-' }}
            <span class="responsible__value_edit">
              <CustomIcon icon="edit" @click="openChangeParticipantsModal('contractor_main_id')"/>
            </span>
          </span>
        </template>
        <template v-slot:title>
          <span class="responsible__group_blueTitle">Главный подрядчик</span>
        </template>
      </BlockItem>
    </div>
    <div class="responsible__group responsible__group_orange">
      <BlockItem>
        <template v-slot:title>
          <div class="responsible__group_orangeTitle">Проектный менеджер</div>
        </template>
        <template v-slot:default>
            <span class="responsible__value responsible__group_orangeValue">
          {{
                this.responsibleData?.inner_master_fio || '-'
              }}
          <span class="responsible__value_edit">
            <CustomIcon icon="edit" @click="openChangeParticipantsModal('inner_master_employee_id')"/>
          </span>
        </span>
        </template>
      </BlockItem>
      <BlockItem>
        <template v-slot:title>
          <div class="responsible__group_orangeTitle">Контрагент по сопровождению</div>
        </template>
        <template v-slot:default>
            <span class="responsible__value responsible__group_orangeValue">
            {{ this.responsibleData?.support_manager_name || '-' }}
            <span class="responsible__value_edit">
              <CustomIcon icon="edit" @click="openChangeParticipantsModal('support_manager_id')"/>
            </span>
        </span>
        </template>

      </BlockItem>
    </div>
    <div class="responsible__group responsible__group_green">
      <BlockItem>
        <template v-slot:title><span class="responsible__group_greenTitle">Разработчик тех.проекта (Пользователь)</span>
        </template>
        <template v-slot:default>
          <span class="responsible__value responsible__group_greenValue">
            {{ this.responsibleData?.techproject_employee_fio || '-' }}
            <span class="responsible__value_edit">
              <CustomIcon icon="edit" @click="openChangeParticipantsModal('techproject_employee_id')"/>
            </span>
          </span>
        </template>
      </BlockItem>
      <BlockItem>
        <template v-slot:title><span class="responsible__group_greenTitle">Разработчик тех.проекта (Контрагент)</span>
        </template>
        <template v-slot:default>
          <span class="responsible__value responsible__group_greenValue">
        {{ this.responsibleData?.techproject_developer_name || '-' }}
        <span class="responsible__value_edit">
          <CustomIcon icon="edit" @click="openChangeParticipantsModal('techproject_developer_id')"/>
        </span>
      </span>
        </template>
      </BlockItem>
    </div>
    <BlockItem title="Отдел контроля качества">
      <span class="responsible__value">
        {{
          this.responsibleData?.okk_fio || '-'
        }}
        <span class="responsible__value_edit">
          <CustomIcon icon="edit" @click="openChangeParticipantsModal('okk_employee_id')"/>
        </span>
      </span>
    </BlockItem>
    <BlockItem title="Прораб">
      <span class="responsible__value">
        {{
          this.responsibleData?.manager_project_fio || '-'
        }}
        <span class="responsible__value_edit">
          <CustomIcon icon="edit" @click="openChangeParticipantsModal('manager_project_employee_id')"/>
        </span>
      </span>
    </BlockItem>
    <BlockItem title="Начальник дирекции">
      <span class="responsible__value">
        {{
          this.responsibleData?.head_direction_fio || '-'
        }}
        <span class="responsible__value_edit">
          <CustomIcon icon="edit" @click="openChangeParticipantsModal('head_direction_id')"/>
        </span>
      </span>
    </BlockItem>
    <BlockItem title="Куратор">
      <span class="responsible__value">
        {{
          this.responsibleData?.supervisor_fio || '-'
        }}
        <span class="responsible__value_edit">
          <CustomIcon icon="edit" @click="openChangeParticipantsModal('supervisor_id')"/>
        </span>
      </span>
    </BlockItem>
  </div>
</template>

<script>
import BlockItem from "@/components/common/BlockItem";
import CustomLoader from "@/components/common/CustomLoader";
import {appActions} from "@/services/store/modules/app_module/app_actions";
import {MODAL_NAMES} from "@/constants";
import {getContentDataByCode} from "@/components/modal/modals/change-remont-responsible/services";
import CustomIcon from "@/components/common/CustomIcon";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";

export default {
  name: "ResponsibleOfficialBlock",
  components: {CustomIcon, CustomLoader, BlockItem},
  props: ["client_request_id", "data", "isFetching"],
  data() {
    return {
      getContentDataByCode,
      responsibleData: this.data
    }
  },
  methods: {
    openParticipantsHistoryModal() {
      this.$store.commit(appActions.showModal(MODAL_NAMES.Participants_Replacement_History, {
        client_request_id: this.client_request_id,
        title: 'Истроия замены участников ремонта'
      }))
    },
    onChangeSubmit(newData) {
      if (!newData) return
      this.responsibleData = newData
      this.showSucceedSnackbar()
    },
    openChangeParticipantsModal(valueCode) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.Change_Remont_Responsible, {
        client_request_id: this.client_request_id,
        valueCode,
        id: this.responsibleData[valueCode],
        title: `Смена участника ремонта (${this.getContentDataByCode[valueCode]?.label})`,
        onSubmit: this.onChangeSubmit
      }))
    }
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar();
    return {showSucceedSnackbar};
  },
}
</script>

<style scoped lang="scss">
.responsible {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    border-radius: 15px;
    background: #FFF;
    padding: 40px 20px 25px 20px;
    box-shadow: 0 0 2px #ccc;
    position: relative;

    & > div {
      width: 40%;
    }

    @media (max-width: 991px) {
      gap: 25px;

      & > div {
        width: 45%;
      }
    }

    @media (max-width: 768px) {
      & > div {
        width: 100%;
      }
    }
    @media (max-width: 768px) {
      grid-template-columns: 6fr 6fr;
      gap: 15px;
    }
    @media (max-width: 475px) {
      grid-template-columns: 12fr;
    }
  }

  &__value {
    min-height: 20px;
    display: flex;
    align-items: center;
    gap: 10px;

    &_edit {
      min-width: 20px;

      & > span {
        display: none;
      }
    }

    &:hover {
      & .responsible__value_edit > span {
        display: initial;
      }
    }
  }

  &__actions {
    position: absolute;
    top: 15px;
    right: 15px;
    display: flex;
    align-items: center;
    gap: 10px;

    &_item {
      font-size: 22px;
      cursor: pointer;
      color: #1f5ac2;
    }
  }

  &__group {
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    &_blue {
      border: 1px solid #a8c8ff;
      background: #cee3ff;

      &Title {
        color: #394b6b;
      }

      &Value {
        color: #546e9d;
        font-weight: 400;
      }
    }

    &_orange {
      border: 1px solid #ffb194;
      background: #f6ceb5;

      &Title {
        color: #9d580a;
      }

      &Value {
        color: #b76723;
        font-weight: 400;
      }
    }

    &_green {
      border: 1px solid #60c43c;
      background: #a8e0a2;

      &Title {
        color: #14801a;
      }

      &Value {
        color: #2fa112;
        font-weight: 400;
      }
    }
  }
}

</style>