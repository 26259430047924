<template>
  <CustomTable
      title="Смета"
      :rowsData="this.rowsData"
      :columns="remontSmetaColumns"
      :loading="isFetching"
      :custom-body="true" :small="true"
      :striped="false"
  >
    <template #customBody="props">
      <q-tr :props="props">
        <custom-table-cell v-for="col in props.cols" :key="col.name" auto-width :align="col.align" vertical-align="top">
          {{ col.value }}
        </custom-table-cell>
      </q-tr>
    </template>
  </CustomTable>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import {remontSmetaColumns} from "@/views/ClientRequests/remontSmeta/services";

export default {
  name: "RemontSmetaBlock",
  components: {CustomTable, CustomTableCell},
  props: ["client_request_id", "isFetching", "data"],
  data() {
    return {
      remontSmetaColumns,
      rowsData: this.data
    }
  },
}
</script>