<template>
  <div class="payment">
    <q-form @submit="this.onSubmit" class="payment__form">
      <CustomInput v-model="this.formData.reason_for_denial" label="Причина" :required="true" />
      <CustomModalFooter submit-btn-text="Сохранить" :btnLoading="this.loading" />
    </q-form>
  </div>
</template>

<script>
import CustomInput from "@/components/form/CustomInput";
import CustomModalFooter from "@/components/modal/modals/modal-footer";

export default {
  name: "ChangeMaterialStatus",
  props: ["data"],
  components: {
    CustomModalFooter,
    CustomInput,
  },
  data() {
    return {
      loading: false,
      formData: {
        reason_for_denial: ''
      },
    };
  },
  methods: {
    async onSubmit() {
      this.loading = true
      await this.data?.onSubmit(this.formData)
      this.loading = false
    },
    async onChange(type, value) {
      this.formData[type] = value
    },
  },
}
</script>

<style scoped lang="scss">
.payment {
  max-width: 630px;

  &__form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}
</style>