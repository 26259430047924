<template>
  <FetchingWrapper :loading="this.isFetching" :data="this.data">
    <div class="remontMeasure__wrapper">
      <CustomTabs :tab="tab" :data="this.data" label-option="grant_name" name-option="component" @change_tab="this.onChange">
        <template v-slot:default="item">
          <component :is="item.data?.component" :data="item.data?.data" :dsData="item.data?.data" :measure="true" :client_request_id="this.client_request_id"/>
        </template>
      </CustomTabs>
    </div>
  </FetchingWrapper>
</template>

<script>
import {getRemontMeasureData} from "@/views/ClientRequests/remontMeasure/services";
import RemontMeasureBlock from "./measure";
import RemontDesignMeasureBlock from "./designerMeasure";
import ManagerMeasureBlock from "./managerMeasure";
import RoomChange from "../remontDS/dsCreationTemplates/roomChange";
import FetchingWrapper from "@/components/common/FetchingWrapper";
import CustomTabs from "@/components/common/CustomTabs";
import CustomTabPanel from "@/components/common/CustomTabs/CustomTabPanel";

export default {
  name: "RemontMeasure",
  components: {
    CustomTabPanel,
    CustomTabs, FetchingWrapper, RemontMeasureBlock, RemontDesignMeasureBlock, ManagerMeasureBlock, RoomChange},
  props: ["client_request_id"],
  data() {
    return {
      data: [],
      isFetching: false,
      tab: '',
    }
  },
  methods: {
    async getData() {
      this.isFetching = true
      const res = await getRemontMeasureData(this?.client_request_id)
      this.isFetching = false
      if (!res) return
      this.data = res
      if (!this.tab)
        this.tab = res[0].component
    },
    onChange(tab) {
      this.tab = tab
    }
  },
  mounted() {
    this.getData()
  },
}
</script>

<style scoped lang="scss">
.remontMeasure {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
}

</style>