import {gradeTimelineStepsAPI} from "@/views/handbook/gradeTimelineSteps/api";

export const getGradeSteps = async (grade_id) => {
  try {
    const res = await gradeTimelineStepsAPI.getSteps(grade_id)
    return res?.data
  } catch (e) {
  }
}
export const addGradeStep = async (grade_id, body = {}) => {
  try {
    const res = await gradeTimelineStepsAPI.addStep(grade_id, body)
    return res?.data
  } catch (e) {
  }
}
export const updateGradeStep = async (grade_id, grade_step_id, body = {}) => {
  try {
    const res = await gradeTimelineStepsAPI.updateStep(grade_id, grade_step_id, body)
    return res?.data
  } catch (e) {
  }
}
export const deleteGradeStep = async (grade_id, grade_step_id) => {
  try {
    const res = await gradeTimelineStepsAPI.deleteStep(grade_id, grade_step_id)
    return res?.data
  } catch (e) {
  }
}
export const getNestedGradeSteps = async (grade_id, step_id) => {
  const body = {step_id}
  try {
    const res = await gradeTimelineStepsAPI.getNestedSteps(grade_id, body)
    return res?.data
  } catch (e) {}
}
export const addNestedGradeSteps = async (grade_id, step_id, step_ids) => {
  const body = {step_id, prev_step_ids: step_ids}
  try {
    const res = await gradeTimelineStepsAPI.addNestedSteps(grade_id, body)
    return res?.data
  } catch (e) {}
}
export const deleteNestedGradeSteps = async (grade_id, step_id, checked = {}) => {
  const grade_step_link_ids = Object.keys(checked)?.map(key => checked[key] ? Number(key) : null)?.filter(item => !!item)
  const body = {step_id, grade_step_link_ids}
  try {
    const res = await gradeTimelineStepsAPI.deleteNestedSteps(grade_id, body)
    return res?.data
  } catch (e) {}
}

export const gradeStepsColumns = [
  {
    name: "step_id",
    field: "step_id",
    label: "ID",
    align: "center",
    sortable: true
  },
  {
    name: "step_name",
    field: "step_name",
    label: "Название",
    align: "left",
    sortable: true
  },
  {
    name: "is_active",
    field: "is_active",
    label: "Активный",
    align: "center",
    sortable: true
  },
  {
    name: "action",
    label: "",
    align: "center",
  },
]
export const gradeNestedStepsColumns = [
  {
    name: "step_id",
    field: "step_id",
    label: "ID",
    align: "center",
    sortable: true
  },
  {
    name: "step_name",
    field: "step_name",
    label: "Название",
    align: "left",
    sortable: true
  },
]