import instance from "@/services/api";

export const timelineStepsAPI = {
  async getSteps() {
    return await instance().get('/timeline/handbook/steps/read/').then(res => res?.data)
  },
  async getIndividualSteps(body) {
    return await instance().post('/timeline/handbook/steps/read/for_link/', body).then(res => res?.data)
  },
  async addStep(body) {
    return await instance().post(`/timeline/handbook/steps/add/`, body).then(res => res?.data)
  },
  async updateStep(step_id, body) {
    return await instance().put(`/timeline/handbook/steps/${step_id}/update/`, body).then(res => res?.data)
  },
  async deleteStep(step_id) {
    return await instance().delete(`/timeline/handbook/steps/${step_id}/delete/`).then(res => res?.data)
  },
}

