import {receiveKeysAPI} from "@/views/ClientRequests/receiveKeys/api";

const receiveKeysBlockNames = {
  OA__RemontFormKeyReceiveBlock: "receiveKeysBlock",
}
export const getReceiveKeysData = async (client_request_id) => {
  try {
    const res = await receiveKeysAPI.getList(client_request_id)
    const data = res?.data
    if (!data?.length) return
    return data?.map(item => ({...item, component: receiveKeysBlockNames[item.grant_code]}))
  } catch (e) {
  }
}
export const getKeysCount = async (client_request_id) => {
  try {
    const res = await receiveKeysAPI.getKeysCount(client_request_id)
    return res?.data
  } catch (e) {
  }
}
export const updateKeysCount = async (client_request_id, body) => {
  try {
    const res = await receiveKeysAPI.updateKeysCount(client_request_id, body)
    return res?.data
  } catch (e) {
  }
}
export const addKeys = async (client_request_id, body) => {
  try {
    const res = await receiveKeysAPI.addKeys(client_request_id, body)
    return res?.data
  } catch (e) {
  }
}
export const updateKeys = async (client_request_id, key_receive_id, body) => {
  try {
    const res = await receiveKeysAPI.updateKeys(client_request_id, key_receive_id, body)
    return res?.data
  } catch (e) {
  }
}
export const deleteKey = async (client_request_id, key_receive_id) => {
  try {
    const res = await receiveKeysAPI.deleteKey(client_request_id, key_receive_id)
    return res?.data
  } catch (e) {
  }
}
export const receiveKeysColumns = [
  {
    name: "key_receive_id",
    label: "ID",
    field: "key_receive_id",
    align: "center",
  },
  {
    name: "receive_date",
    label: "Дата передачи",
    field: "receive_date",
    align: "center",
  },
  {
    name: "key_from",
    label: "Кто передал",
    field: "key_from",
    align: "left",
  },
  {
    name: "key_to",
    label: "Кто принял",
    field: "key_to",
    align: "left",
  },
  {
    name: "comments",
    label: "Комментарии",
    field: "comments",
    align: "left",
  },
  {
    name: "employee_fio",
    label: "Кем добавлен",
    field: "employee_fio",
    align: "left",
  },
]