<template>
  <template v-if="init || this.layout === 'check-layout'">
    <component :is="layout" :padding="padding">
      <router-view/>
    </component>
    <Modal/>
  </template>
  <CustomLoader v-else/>
</template>

<script>
import CheckLayout from "@/components/layout/CheckLayout.vue";
import EmptyLayout from "@/components/layout/EmptyLayout.vue";
import CustomLoader from "@/components/common/CustomLoader";
import MainLayout from "@/components/layout/MainLayout.vue";
import Modal from "@/components/modal/index.vue";
import {appActionTypes} from "@/services/store/modules/app_module/app_mutation_types";
import {PAGES} from "@/constants";
import {notInitRoutes} from "@/router";

export default {
  mounted() {
    const isNotInitRoute = !!notInitRoutes?.find(route => window.location.pathname?.includes(route))
    if (isNotInitRoute)
      return
    this.$store.dispatch(appActionTypes.checkAuth);
  },
  watch: {
    init: function (newVal) {
      if (newVal) {
        const auth = this.$store.state.app.auth
        if (auth) {
          this.$store.dispatch(appActionTypes.getUserData)
          this.$store.dispatch(appActionTypes.getCities)
          this.$store.dispatch(appActionTypes.getCompanies)
        } else {
          this.$router.push(PAGES.login)
        }
      }
    },
    auth: function (newVal) {
      const init = this.$store.state.app.init
      if (init) {
        if (!newVal) {
          this.$router.push(PAGES.login)
        }
      }
    },
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || "empty") + "-layout";
    },
    padding() {
      return this.$route.meta.padding === undefined ? true : this.$route.meta.padding;
    },
    init() {
      return this.$store.state.app.init;
    },
    auth() {
      return this.$store.state.app.auth;
    },
  },
  components: {
    EmptyLayout,
    CheckLayout,
    MainLayout,
    CustomLoader,
    Modal,
  },
};
</script>
