import {textConstructorAPI} from "@/views/ClientRequests/textConstructor/api";

const textConstructorBlockNames = {
  OA__RemontFormClientMaterialBlock: "textConstructorBlock",
}
export const getTextConstructorData = async (client_request_id) => {
  try {
    const res = await textConstructorAPI.getList(client_request_id)
    const data = res?.data
    if (!data?.length) return
    return data?.map(item => ({...item, component: textConstructorBlockNames[item.grant_code]}))
  } catch (e) {
  }
}
export const fillMaterialCount = async (client_request_id) => {
  try {
    const res = await textConstructorAPI.fillMaterialCount(client_request_id)
    return res?.data
  } catch (e) {
  }
}
export const textConstructorColumns = [
  {
    name: "client_material_id",
    label: "ID",
    field: "client_material_id",
    align: "center",
  },
  {
    name: "room_name",
    label: "Комната",
    field: "room_name",
    align: "left",
  },
  {
    name: "work_set_name",
    label: "Конструктив",
    field: "work_set_name",
    align: "left",
  },
  {
    name: "photo",
    label: "",
    field: "photo",
    align: "center",
  },
  {
    name: "material_name",
    label: "Материал",
    field: "material_name",
    align: "left",
  },
  {
    name: "material_set_name",
    label: "Набор",
    field: "material_set_name",
    align: "left",
  },
]