<template>
  <span class="amount">
    {{ this.numberWithCommas(this.value, '', this.round) }}
  </span>
</template>

<script>
import {numberWithCommas} from "@/services"
export default {
  name: "Amount",
  props: ["value", "round"],
  methods: {numberWithCommas}
}
</script>

<style scoped lang="scss">
.amount {
  white-space: nowrap;
}
</style>
