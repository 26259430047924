import {remontChatAPI} from "@/views/ClientRequests/remontChat/api";

const chatBlockNames = {
  OA__RemontFormChatBlock: "chatBlock",
}
export const getRemontChatData = async (client_request_id) => {
  try {
    const res = await remontChatAPI.getList(client_request_id)
    const data = res?.data
    if (!data?.length) return
    return data?.map(item => ({...item, component: chatBlockNames[item.grant_code]}))
  } catch (e) {
  }
}
export const getRemontChatMessagesData = async (client_request_id, group_chat_id) => {
  try {
    const res = await remontChatAPI.getChat(client_request_id, group_chat_id)
    return res?.data
  } catch (e) {
  }
}
export const sendNewMessage = async (client_request_id, group_chat_id, body) => {
  try {
    const res = await remontChatAPI.sendMessage(client_request_id, group_chat_id, body)
    return res?.data
  } catch (e) {
  }
}
export const editMessage = async (client_request_id, group_chat_id, stage_chat_id, body) => {
  try {
    const res = await remontChatAPI.editMessage(client_request_id, group_chat_id, stage_chat_id, body)
    return res?.data
  } catch (e) {
  }
}
export const deleteMessage = async (client_request_id, group_chat_id, stage_chat_id) => {
  try {
    const res = await remontChatAPI.deleteMessage(client_request_id, group_chat_id, stage_chat_id)
    return res?.data
  } catch (e) {
  }
}
export const deleteMessageFile = async (client_request_id, group_chat_id, stage_chat_file_id) => {
  try {
    const res = await remontChatAPI.deleteMessageFile(client_request_id, group_chat_id, stage_chat_file_id)
    return res?.data
  } catch (e) {
  }
}