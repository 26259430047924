<template>
  <div class="wrapper">
    <q-form @submit="this.onSubmit">
      <CustomSelect :required="true" :options="this.options" :option-label-key="this.labelKey"
                    :option-value-key="this.valueKey"
                    :value="this.value" :label="this.label"
                    @change="val => this.onChange(this.key, val)"/>
      <CustomModalFooter :btn-loading="this.loading" :disable-submit="this.isFetching"/>
    </q-form>
  </div>
</template>

<script>
import CustomSelect from "@/components/form/CustomSelect";
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import {getCommonContractors} from "@/views/Costs/СostsFilters/services";
import {getWarrantyManagers} from "@/views/ClientRequests/warrantyRequests/services";

export default {
  name: "ChangeWarrantyEmployee",
  components: {CustomModalFooter, CustomSelect},
  props: ['data'],
  data() {
    return {
      loading: false,
      isFetching: false,
      options: [],
      formdata: {
        contractor_id: this.data?.body?.contractor_id || '',
        grt_manager_id: this.data?.body?.grt_manager_id || '',
      }
    }
  },
  computed: {
    value() {
      return this.data?.type === 'contractor' ? this.formdata.contractor_id : this.formdata.grt_manager_id
    },
    valueKey() {
      return this.data?.type === 'contractor' ? 'contractor_id' : 'employee_id'
    },
    labelKey() {
      return this.data?.type === 'contractor' ? 'legal_name' : 'fio'
    },
    label() {
      return this.data?.type === 'contractor' ? 'Подрядчик' : 'Менеджер гарантии'
    },
    key() {
      return this.data?.type === 'contractor' ? 'contractor_id' : 'grt_manager_id'
    },
  },
  methods: {
    onChange(key, value) {
      this.formdata[key] = value
    },
    async getData() {
      this.isFetching = true
      const res = this.data?.type === 'contractor' ? await getCommonContractors() : await getWarrantyManagers()
      this.isFetching = false
      if (!res) return
      this.options = res
    },
    async onSubmit() {
      if (!this.data?.onSubmit) return
      this.loading = true
      await this.data?.onSubmit(this.formdata)
      this.loading = false
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  min-width: 300px;

  @media (max-width: 576px) {
    min-width: 100%;
  }
}
</style>