<template>
  <div class="filters">
    <div class="filters__item">
      <CustomSelect :options="this.providers" :value="filters.provider_id" label="Поставщик"
                    option-label-key="provider_name"
                    option-value-key="provider_id"
                    @change="value => this.onChange('provider_id', value)"
      />
    </div>
  </div>
</template>

<script>
import CustomSelect from "@/components/form/CustomSelect";
import {appActionTypes} from "@/services/store/modules/app_module/app_mutation_types";

export default {
  name: "ProvidersAgreementFilters",
  components: {CustomSelect},
  props: ['getData', 'isFetching', 'filters', 'onChange'],
  computed: {
    providers() {
      return this.$store.state.app.providers;
    },
  },
  mounted() {
    this.$store.dispatch(appActionTypes.getCommonProviders)
  }
}
</script>

<style scoped lang="scss">
.filters {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 15px;

  &__item {
    min-width: 150px;
  }
}
</style>