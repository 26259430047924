<template>
  <div class="wrapper">
    <div class="title">{{ `№ заявки: ${this.data?.body?.client_request_id}` }}</div>
    <q-form @submit="this.onSubmit" class="form">
      <CustomSelect
          :options="this.statuses"
          label="Статус"
          optionValueKey="id"
          optionLabelKey="label"
          :value="this.formData.is_guarantee_accept"
          @change="(value) => onChange('is_guarantee_accept', value)"
      />
      <CustomInput :value="this.formData.comments"
                   @change_input="(value) => this.onChange('comments', value)"
                   label="Комментарии" type="textarea" :required="true"/>
      <div>
        <span>Документы</span>
        <div class="documents">
          <CustomImg v-for="(item, idx) in this.documents"
                     class-name="image" :show-link-on-error="true"
                     :src="item.document_url"
                     :alt="item.document_name" :onClick="() => this.showGallery(idx)"
          />
        </div>
      </div>
      <CustomModalFooter :on-cancel="this.closeModal" :btn-loading="this.loading" cancel-btn-text="Закрыть"/>
    </q-form>
  </div>
</template>
<script>

import CustomInput from "@/components/form/CustomInput";
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import {executiveReportAcceptWarranty} from "@/views/ExecutiveReport/services";
import CustomSelect from "@/components/form/CustomSelect";
import Clickable from "@/components/common/Clickable";
import {getFullUrl} from "@/services";
import CustomImg from "@/components/common/CustomImg";
import {appActions} from "@/services/store/modules/app_module/app_actions";
import {MODAL_NAMES} from "@/constants";

export default {
  name: "executive-report-warranty-accept",
  components: {CustomImg, Clickable, CustomSelect, CustomInput, CustomModalFooter},
  props: ['data', 'closeModal'],
  data() {
    return {
      loading: false,
      statuses: [
        {id: 0, label: 'Не принято'},
        {id: 2, label: 'Принято'},
        {id: 1, label: 'Не принято, с выплатой'},
      ],
      documents: this.data?.modalData?.client_document || [],
      formData: {
        comments: '',
        is_guarantee_accept: '',
      }
    }
  },
  methods: {
    getFullUrl,
    onChange(key, value) {
      this.formData[key] = value
    },
    async onSubmit() {
      const body = {
        ...this.data.body, ...this.formData
      }
      this.loading = true
      const res = await executiveReportAcceptWarranty(body)
      this.loading = false
      if (!res) return
      this.documents = res?.client_document
      this.formData = {
        comments: '',
        is_guarantee_accept: '',
      }
    },
    showGallery(imgIndex) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.ChatGallery, {
        files: this.documents?.map(item => ({...item, file_url: getFullUrl(item.document_url), file_name: item.document_name})),
        imgIndex, close: false,
        borderless: false, onClose: () => {
          this.$store.commit(appActions.showModal(MODAL_NAMES.ExecutiveReportWarrantyAccept, {
            title: 'Изменить статус',
            modalData: {client_document: this.documents},
            body: this.data.body, close: false,
            onClose: () => {
              this.data.onClose()
              this.$store.commit(appActions.closeModal());
              return true
            },
          }))
        }
      }));
    }
  },
}
</script>

<style scoped lang="scss">
.wrapper {
  max-width: 500px;
}

.title {
  margin-bottom: 10px;
  font-weight: 700;
}

.image {
  width: 80px;
  height: 80px;
  object-fit: contain;
  cursor: pointer;
}

.documents {
  display: flex;
  flex-direction: column;
  gap: 7px;
  align-items: flex-start;
}

.form {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  gap: 20px;

  @media (max-width: 428px) {
    min-width: 100%;
  }
}

@media (max-width: 576px) {
  .wrapper {
    max-width: 100%;
  }
}
</style>
