<template>
  <div class="requests__tabs">
    <div v-for="item in this.data" v-on:click="this.handleTabChange(item.component)" :class="['requests__tabs_item', {requests__tabs_itemActive: this.activeTab === item.component && item.component}]">
      {{ item.grant_name }}
    </div>
  </div>
</template>

<script>
import {getTabsData} from "@/views/ClientRequests/services";
import {useRoute} from "vue-router/dist/vue-router";

export default {
  name: "client-request-tabs",
  props: ["onTabChange", "activeTab"],
  data() {
    return {
      data: {}
    }
  },
  methods: {
    async getData() {
      const res = await getTabsData(this.tab)
      if (!res) return
      const {data, isExist} = res
      if (!data?.length) return
      this.data = data
      if(isExist)
        return this.onTabChange(this.tab)
      const existTab = data[0].component
      this.$router.push({query: {tab: existTab}})
      this.onTabChange(existTab)
    },
    handleTabChange(tab) {
      this.onTabChange(tab)
      this.$router.push({query: {tab}})
    }
  },
  mounted() {
    this.getData()
  },
  setup() {
    const router = useRoute()
    return {tab: router.query?.tab}
  }
}
</script>

<style scoped lang="scss">
.requests {
  &__tabs {
    display: flex;
    align-items: center;
    margin-top: 20px;
    gap: 7px;
    flex-wrap: wrap;

    @media (max-width: 768px) {
      flex-wrap: nowrap;
      overflow-x: scroll;
    }

    &_item {
      padding: 10px;
      color: #556079;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      cursor: pointer;
      transition: all .3s;
      border-radius: 5px;
      border-bottom: 4px solid #dcdcdc;
      white-space: nowrap;

      &:hover {
        background: #f5f5f5;
        border-radius: 5px;
      }

      &Active {
        color: #1555b0;
        border-bottom: 4px solid #1555b0;
      }
    }
  }
}

</style>