<template>
  <CustomTable
      title="История ожидания клиента"
      :rowsData="this.rowsData"
      :columns="acceptWaitColumns"
      :custom-body="false">
    <template v-slot:top-right="props">
      <q-btn :disable="!this.rowsData?.length" :loading="this.loading" @click="this.eliminate" color="primary" size="sm" padding="sm">Замечания устранены</q-btn>
    </template>
  </CustomTable>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import {acceptWaitColumns, acceptWaitEliminate} from "@/views/ClientRequests/remontStages/services";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";


export default {
  name: "ClientAcceptWait",
  components: {CustomTable},
  props: ["data", "client_request_id"],
  data() {
    return {
      acceptWaitColumns,
      isFetching: false,
      loading: false,
      rowsData: this.data
    }
  },
  methods: {
    async eliminate() {
      this.loading = true
      const res = await acceptWaitEliminate(this.client_request_id)
      this.loading = false
      if(!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
    }
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar()
    return {showSucceedSnackbar}
  }
}
</script>

<style scoped lang="scss">
</style>