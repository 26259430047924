import instance from "@/services/api";

export const remontMeasureAPI = {
  async getList(client_request_id) {
    return await instance()
      .get(`/client_request/${client_request_id}/measures/read/`)
      .then((res) => res?.data);
  },
  async getRoomData(client_request_id, planirovka_room_id) {
    return await instance()
      .get(
        `/client_request/${client_request_id}/measures/planirovka_rooms/${planirovka_room_id}/read/`
      )
      .then((res) => res?.data);
  },
  async addRoom(client_request_id, body) {
    return await instance()
      .post(`/client_request/${client_request_id}/measures/room/modify/`, body)
      .then((res) => res?.data);
  },
  async confirmMeasure(client_request_id, body) {
    return await instance()
      .post(`/client_request/${client_request_id}/measures/confirm/`, body)
      .then((res) => res?.data);
  },
  async saveRoomData(client_request_id, planirovka_room_id, body) {
    return await instance()
      .post(
        `/client_request/${client_request_id}/measures/planirovka_rooms/${planirovka_room_id}/save/`,
        body
      )
      .then((res) => res?.data);
  },
  async getMaterialCount(client_request_id, body) {
    return await instance()
      .post(
        `/client_request/${client_request_id}/measures/material_count/get/`,
        body
      )
      .then((res) => res?.data);
  },
  async saveDesignMeasureData(client_request_id, body) {
    return await instance()
      .post(
        `/client_request/${client_request_id}/measures/design_measure/save/`,
        body
      )
      .then((res) => res?.data);
  },
  async saveManagerMeasureData(client_request_id, body) {
    return await instance()
      .post(
        `/client_request/${client_request_id}/measures/contractor_measure/save/`,
        body
      )
      .then((res) => res?.data);
  },
  async getMaterialDoorDepend(client_request_id, body) {
    return await instance()
      .post(
        `/client_request/${client_request_id}/measures/material_door_depend/read/`,
        body
      )
      .then((res) => res?.data);
  },
};
