import {requisitesAPI} from "@/views/ClientRequests/requisites/api";

const requisitesBlockNames = {
  OA__RemontFormClientInfoBlock: 'clientInfoBlock',
  OA__RemontFormRemontInfoBlock: 'remontInfoBlock',
  OA__RemontFormBankRequisiteBlock: 'bankRequisitesBlock',
  OA__RemontFormSaleBlock: 'salesBlock',
  OA__RemontFormIntegration1CBlock: 'integrationBlock',
}
export const getRequisitesData = async (client_request_id) => {
  try {
    const res = await requisitesAPI.getRequisitesData(client_request_id)
    if (!res?.data) return []
    const data = []
    let currentRow = 0
    let colsPerRow = 2
    res.data.map(el => ({...el, grant_code: requisitesBlockNames[el.grant_code]})).sort((a, b) => {
      if (a.grant_code === "clientInfoBlock")
        return -1
      else if (a.grant_code === 'remontInfoBlock')
        return 1
      else if (b.grant_code === "clientInfoBlock")
        return -1
      else if (b.grant_code === 'remontInfoBlock')
        return 1
      return 0
    }).forEach((item) => {
      if (!data[currentRow])
        data[currentRow] = [item]
      else {
        if (data[currentRow].length === colsPerRow) {
          colsPerRow = colsPerRow === 2 ? 3 : 2
          currentRow += 1
          data[currentRow] = [item]
        } else
          data[currentRow].push(item)
      }
    })
    return data
  } catch (e) {
    return []
  }
}
export const getRequisitesPhoneData = async (client_request_id, name) => {
  try {
    const res = name === 'prop_phone'
      ? await requisitesAPI.getPhone(client_request_id)
      : await requisitesAPI.getContactPhone(client_request_id)
    return res?.data
  } catch (e) {
  }
}