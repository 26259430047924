<template>
  <custom-table
      :customBody="true" :hide-footer-title="true" :striped="false"
      :columns="this.columns" :rowsData="this.rowsData">
    <template #customBody="props">
      <q-tr
          :props="props" :style="!props.row.is_exist ? 'background-color: #B7B7B7 !important;' : ''"
      >
        <q-td v-for="col in props.cols" :key="col.name" :props="props" :style="!props.row.is_exist ? 'background-color: #B7B7B7 !important;' : '#fff'">
          <div v-if="col.name === 'param_json' || col.name === 'param_value_json'">
            <p v-for="item in Object.keys(this.json(col.value))?.sort()" class="actions__params_item">
              {{ `${item}: ` }} <span>{{ this.json(col.value)[item] }}</span>
            </p>
          </div>
          <div v-else-if="col.name === 'is_active'"><i
              :class="`material-icons timeline__check timeline__check_${col.value ? 'completed' : 'close'}`">{{
              col.value ? 'check' : 'close'
            }}</i></div>
          <div v-else-if="col.name === 'action'">
            <q-btn size="sm" padding="xs" color="primary" no-wrap
                   @click="() => this.handleAddActionForRemont(props.row.step_activity_id, props.row.activity_name)">
              Добавить в ремонт
            </q-btn>
          </div>
          <span v-else>
            {{ col.value }}
          </span>
        </q-td>
      </q-tr>
    </template>
  </custom-table>
  <q-dialog v-model="showPopup">
    <AddGradeStepToRemont type="action" :title="this.stepActivityName" :reset-data="this.resetData" :id="this.stepActivityId"
                          :grade_step_id="this.grade_step_id" :on-submit="this.onAddSubmit"/>
  </q-dialog>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import {gradeActionsTableColumns} from "@/components/modal/modals/grade-timeline-step/services";
import AddGradeStepToRemont from "@/components/modal/modals/grade-timeline-step/add-grade-step-to-remont";

export default {
  name: "grade-timeline-actions",
  components: {AddGradeStepToRemont, CustomTable},
  props: ['data', 'grade_step_id'],
  data() {
    return {
      columns: gradeActionsTableColumns,
      rowsData: this.data?.data || [],
      showPopup: false,
      stepActivityId: null,
      stepActivityName: '',
    }
  },
  methods: {
    json(value) {
      try {
        if (!value) return {}
        return JSON.parse(value)
      } catch (e) {
        return {}
      }
    },
    resetData() {
      this.stepActivityId = null
      this.stepActivityName = ''
    },
    async handleAddActionForRemont(step_activity_id, activity_name) {
      this.resetData()
      this.showPopup = true
      this.stepActivityId = step_activity_id
      this.stepActivityName = activity_name
    },
    async onAddSubmit(res) {
      this.rowsData = res?.actions || []
      this.resetData()
      this.showPopup = false
    }
  },
}
</script>

<style scoped lang="scss">
.actions {
  &__params {
    &_item {
      margin: 0;
      font-size: 13px;
      font-weight: bold;

      span {
        font-weight: normal;
      }
    }
  }
}
</style>