<template>
  <CustomTable
      title="Документы"
      :rowsData="this.rowsData"
      :columns="remontDocsColumns"
      :loading="isFetching"
      :custom-body="true"
      :striped="false"
  >
    <template v-slot:top-right="props">
      <q-btn @click="handleAddDoc" size="sm" padding="sm" color="primary">Добавить документ</q-btn>
    </template>
    <template #customBody="props">
      <q-tr>
        <custom-table-cell style="background-color: #d6e7ff !important;" align="left" colspan="10">
          <b>{{ props.row.document_type_name }}</b>
        </custom-table-cell>
      </q-tr>
      <q-tr v-for="item in props.row?.documents">
        <custom-table-cell align="center">
          <Clickable @click="() => this.handleUpdateDoc(item.client_request_document_id, {...item, document_type_id: props.row.document_type_id}, props.row.type_code)">
            {{ item.client_request_document_id }}
          </Clickable>
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ item.document_name }}
        </custom-table-cell>
        <custom-table-cell align="right">
          <Amount :value="item.additional_agreement_amount"/>
        </custom-table-cell>
        <custom-table-cell align="right">
          <Amount :value="item.ds_origin_sum"/>
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ item.dn_name }}
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ item.contract_signer_dn_name }}
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ item.fio }}
        </custom-table-cell>
        <custom-table-cell align="center">
          {{ item.rowversion }}
        </custom-table-cell>
        <custom-table-cell align="center">
          <Clickable :link="true" :href="getFullUrl(item.document_url)">
            Скачать
          </Clickable>
        </custom-table-cell>
        <custom-table-cell align="center">
          <CustomIcon icon="trash" approve-mode :approve="() => this.deleteDoc(item.client_request_document_id)"/>
        </custom-table-cell>
      </q-tr>
    </template>
  </CustomTable>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import CustomIcon from "@/components/common/CustomIcon";
import Clickable from "@/components/common/Clickable";
import {
  addRequestDoc,
  deleteRequestDoc,
  remontDocsColumns,
  updateRequestDoc
} from "@/views/ClientRequests/remontDocs/services";
import {getFullUrl} from "@/services";
import Amount from "@/components/common/Amount";
import {appActions} from "@/services/store/modules/app_module/app_actions";
import {MODAL_NAMES} from "@/constants";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";

export default {
  name: "RemontDocs",
  components: {Amount, Clickable, CustomIcon, CustomTable, CustomTableCell},
  props: ["client_request_id", "isFetching", "data"],
  data() {
    return {
      selectedItems: {},
      remontDocsColumns,
      deleteLoading: false,
      rowsData: this.data
    }
  },
  methods: {
    getFullUrl,
    handleAddDoc() {
      this.$store.commit(appActions.showModal(MODAL_NAMES.AddDoc, {
        title: 'Добавить документ',
        onSubmit: this.onSubmitAdd,
      }));
    },
    handleUpdateDoc(client_request_document_id, row, document_type_code) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.AddDoc, {
        title: `Изменить документ - ${client_request_document_id}`,
        body: row, document_type_code,
        onSubmit: body => this.onSubmitUpdate(body, client_request_document_id),
      }));
    },
    async onSubmitAdd(body) {
      const res = await addRequestDoc(this.client_request_id, body)
      if (!res) return
      this.rowsData = res
      this.$store.commit(appActions.closeModal());
      this.showSucceedSnackbar()
    },
    async onSubmitUpdate(body, client_request_document_id) {
      const res = await updateRequestDoc(this.client_request_id, client_request_document_id, body)
      if (!res) return
      this.rowsData = res
      this.$store.commit(appActions.closeModal());
      this.showSucceedSnackbar()
    },
    async deleteDoc(client_request_document_id) {
      this.deleteLoading = true
      const res = await deleteRequestDoc(this.client_request_id, client_request_document_id)
      this.deleteLoading = false
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
    }
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar();
    return {showSucceedSnackbar};
  },
}
</script>