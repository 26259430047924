import {techprojectRequestsAPI} from "@/views/techprojectRequests/api";


export const getTechprojectRequests = async (client_request_id) => {
  try {
    const res = await techprojectRequestsAPI.getList(client_request_id);
    return res?.data
  } catch (e) {}
};

export const techprojectRequestStatuses = [
  {id: 1, label: 'В процессе'},
  {id: 2, label: 'Утвержден'},
  {id: 3, label: 'Проверка аудитора'},
]

export const techprojectRequestsColumns = [
  {
    name: "request_id",
    label: "ID заявки",
    field: "client_request_id",
    align: "center",
  },
  {
    name: "remont_id",
    label: "ID ремонта",
    field: "remont_id",
    align: "center",
  },
  {
    name: "left",
    label: "Клиент/Пресет",
    field: "client_name",
    align: "left",
  },
  {
    name: "request_date_text",
    label: "Дата заявки",
    field: "request_date_text",
    align: "center",
  },
  {
    name: "address",
    label: "Адрес",
    field: "address",
    align: "left",
  },
  {
    name: "resident_name",
    label: "ЖК",
    field: "resident_name",
    align: "left",
  },
  {
    name: "area",
    label: "Квадратура",
    field: "area",
    align: "right",
  },
  {
    name: "days_for_project",
    label: "Остаток дней на разработку проекта",
    field: "days_for_project",
    align: "right",
  },
  {
    name: "days_for_project",
    label: "Получение ключей",
    field: "days_for_project",
    align: "right",
  },
  {
    name: "is_measure_confirm",
    label: "Замеры подтверждены",
    field: "is_measure_confirm",
    align: "center",
  },
  {
    name: "project_accepted",
    label: "Проект утвержден",
    field: "project_accepted",
    align: "center",
  },
  {
    name: "meet_on_place_date",
    label: "Встреча на объекте проведена",
    field: "meet_on_place_date",
    align: "center",
  },
  {
    name: "audit_checked",
    label: "Рабочий проект проверен Аудитором",
    field: "audit_checked",
    align: "center",
  },
  {
    name: "project_edited",
    label: "Дополнительные соглашения подписаны",
    field: "project_edited",
    align: "center",
  },
  {
    name: "project_taken",
    label: "Замеры квартиры сняты",
    field: "project_taken",
    align: "center",
  },
  {
    name: "specification_signed",
    label: "Спецификация подписана",
    field: "specification_signed",
    align: "center",
  },
  {
    name: "status_name",
    label: "Статус заявки",
    field: "status_name",
    align: "center",
  },
];
