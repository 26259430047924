<template>
  <div class="documents__wrapper">
    <CustomTable
        :title="`Список файлов платежного поручения (${this.data.finance_payment_id})`"
        :rowsData="docsData"
        :columns="financePaymentDocsColumns"
        :page-header-height="this.$refs.header?.clientHeight"
        :showSearch="true"
        :stickyHeader="true"
        :loading="isFetching"
        :custom-body="true">
      <template #customBody="props">
        <q-tr>
          <custom-table-cell align="center">
            {{ props.row.finance_payment_document_id }}
          </custom-table-cell>
          <q-td>
            <a
                download
                target="_blank"
                rel="noopener noreferrer"
                :href="`${FILE_URL}${props.row?.finance_payment_document_url}`"
                class="documents__list_itemLink"
                :title="props.row?.finance_payment_document_name || ''"
            >
              <q-icon name="fa-solid fa-download"/>
              {{
                props.row?.finance_payment_document_name
                    ? `${cropWord(props.row?.finance_payment_document_name, 30)}`
                    : "Скачать"
              }}
            </a>
          </q-td>
          <custom-table-cell align="center" auto-width>
            {{ props.row.created_date }}
          </custom-table-cell>
          <q-td auto-width>
            {{ props.row.finance_payment_document_extension }}
          </q-td>
          <q-td>
            <CustomIcon icon="trash" :loading="this.deleteLoading[props.row?.finance_payment_document_id]" approve-mode
                        :approve="() => this.deleteDocument(props.row?.finance_payment_document_id)"/>
          </q-td>
        </q-tr>
      </template>
    </CustomTable>
    <div class="documents__upload">
      <CustomUploader :value="this.newFiles" :on-change="onUploadChange" :multiple="true" label="Добавить документы"/>
      <q-btn :disable="loading || !this.newFiles?.length" :loading="loading" color="primary" @click="this.sendFiles">
        Сохранить
      </q-btn>
    </div>
  </div>

</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import {
  deleteFinancePaymentDoc,
  financePaymentDocsColumns,
  getFinancePaymentDocs,
  uploadFinancePaymentDocs
} from "@/views/FinancePayments/services";
import {FILE_URL} from "@/constants"
import {cropWord} from "@/_helpers"
import CustomUploader from "@/components/form/CustomUploader";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";
import PopupConfirm from "@/components/common/PopupConfirm";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import CustomIcon from "@/components/common/CustomIcon";

export default {
  name: "FinancePaymentDocs",
  props: ["data"],
  data() {
    return {
      deleteLoading: {},
      docsData: [],
      newFiles: [],
      isFetching: false,
      loading: false,
      FILE_URL,
      financePaymentDocsColumns
    }
  },
  components: {CustomIcon, CustomTableCell, PopupConfirm, CustomUploader, CustomTable},
  methods: {
    cropWord,
    async getData() {
      if (!this.data?.finance_payment_id) return
      this.isFetching = true
      const res = await getFinancePaymentDocs(this.data?.finance_payment_id)
      this.isFetching = false
      this.docsData = res
    },
    async deleteDocument(finance_payment_document_id) {
      if (!finance_payment_document_id) return
      this.deleteLoading[finance_payment_document_id] = true
      const res = await deleteFinancePaymentDoc(this.data?.finance_payment_id, finance_payment_document_id)
      this.deleteLoading[finance_payment_document_id] = false
      if (!res) return
      this.showSucceedSnackbar()
      this.docsData = res
    },
    async onUploadChange(files) {
      if (this.newFiles && files?.length)
        return this.newFiles = [...this.newFiles, ...files]
      this.newFiles = files
    },
    async sendFiles() {
      this.loading = true
      const res = await uploadFinancePaymentDocs(this.data?.finance_payment_id, this.newFiles)
      this.loading = false
      if (!res) return
      this.showSucceedSnackbar()
      this.docsData = res
      this.newFiles = []
    }
  },
  mounted() {
    this.getData()
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar();
    return {showSucceedSnackbar};
  },
}
</script>

<style scoped lang="scss">
.documents {
  &__wrapper {
    min-width: 700px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 15px;

    &_item {
      display: grid;
      grid-template-columns: 12fr 220px 110px;
      align-items: flex-end;
      width: 100%;
      gap: 15px;

      &Actions {
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: flex-end;
      }

      &Link {
        display: flex;
        align-items: center;
        gap: 5px;
        color: #1f5ac2;
        text-decoration: underline;
        transition: all .4s;

        &:hover {
          color: #6198ff;
        }
      }

      &Download {
        width: 17px !important;
        margin-right: 5px;
      }
    }

    &_add {
      text-align: center;
      cursor: pointer;
      color: #184f88;
      font-size: 16px;
      margin: 0;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all .4s;

      &:hover {
        color: #62a0e1;
      }

      &Wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 25px;
      }
    }
  }

  &__upload {
    margin-top: 25px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 25px;
  }

  &__icon {
    &_trash {
      font-size: 20px;
      color: #9d1010;
      transition: all .4s;
      cursor: pointer;

      &:hover {
        color: #e53b3b;
      }
    }
  }

  @media (max-width: 991px) {
    &__wrapper {
      min-width: 100%;
    }
  }
  @media (max-width: 400px) {
    &__upload {
      flex-direction: column;
    }
  }
}
</style>