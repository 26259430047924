<template>
  <div v-if="this.dsCreationMode">
    <component :is="this.dsTypeCodeComponent[this.selectedDSType.ds_type_code]" :dsData="this.selectedDSType"
               :client_request_id="this.client_request_id" :on-cancel="this.cancelCreation"
               :updateData="this.updateData"></component>
  </div>
  <CustomTable
      v-else
      title="Доп. соглашения"
      :rowsData="this.data"
      :columns="remontDsColumns"
      :loading="isFetching || this.dataFetching"
      :custom-body="true"
  >
    <template v-slot:top-right="props">
      <div class="tableHeader">
        <q-btn-dropdown :loading="this.dsTypes.isFetching"
                        :disable="this.dsTypes.isFetching || !this.dsTypes.data?.length" color="primary" size="sm"
                        padding="sm" label="Добавить ДС">
          <q-list>
            <q-item v-for="item in this.dsTypes.data" clickable v-close-popup
                    @click="() => this.handleAddDS(item)">
              <q-item-section>
                <q-item-label>{{ item.ds_type_name }}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
        <q-btn class="actions__btn" @click="this.getData">
          <i class="material-icons actions__update">
            refresh
          </i>
        </q-btn>
      </div>
    </template>
    <template #customBody="props">
      <q-tr>
        <custom-table-cell align="center">
          <Clickable @click="() => this.handleChangeDS(props.row)">{{ props.row.ds_id }}</Clickable>
        </custom-table-cell>
        <custom-table-cell align="center">
          {{ props.row.ds_date }}
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.ds_type_name }}
          <div v-if="props.row.is_zero_sum === 1 && props.row.card_id !== null">Карточка</div>
        </custom-table-cell>
        <custom-table-cell align="right" :nowrap="true">
          <div class="remontDS__changeSum">
            <span :title="props.row.ds_sum > 0 ? 'Клиент должен компании' : 'Компания должна вернуть клиенту'">
              {{ this.numberWithCommas(props.row.ds_sum) }}
            </span>
            <CustomIcon small icon="edit" @click="() => this.handleChangeSum(props.row.ds_id, props.row.ds_sum)"/>
          </div>
        </custom-table-cell>
        <custom-table-cell align="right">
          {{ props.row.fio }}
        </custom-table-cell>
        <custom-table-cell align="center">
          <CheckOrClose :value="props.row.is_accept" :only-true="true"/>
          <q-btn size="sm" padding="xs" no-wrap
                 v-if="props.row.is_accept !== 1 && props.row.ds_type_code === this.dsTypeCodes.ROOM_CHANGE"
                 color="primary">
            Утвердить
            <PopupConfirm :loading="this.acceptLoading[props.row.ds_id]"
                          :approve="() => this.acceptDS(props.row.ds_id)"/>
          </q-btn>
        </custom-table-cell>
        <custom-table-cell align="center">
          <span v-if="props.row.accept_date">Утвержден {{ props.row.accept_date }}</span>
        </custom-table-cell>
        <custom-table-cell align="center">
          <q-btn @click="() => this.downloadTemplate(props.row.ds_id)"
                 :loading="!!this.downloadingFile[props.row.ds_id]" color="green" size="sm" padding="xs" no-wrap>Скачать
            шаблон
          </q-btn>
        </custom-table-cell>
        <custom-table-cell align="center">
          <span class="bold" v-if="props.row.is_send_sign">Отправлен на подписание</span>
          <q-btn v-else color="primary" size="sm" padding="xs" no-wrap>
            Отправить на подписание
            <PopupConfirm :loading="this.sendSignLoading[props.row.ds_id]"
                          :approve="() => this.sendToSignDS(props.row.ds_id)"/>
          </q-btn>
        </custom-table-cell>
        <custom-table-cell align="left">
          <div>
            <b>Сотрудник</b>
            <br>
            <span v-if="!!props.row.signer_dn_name">{{ props.row.signer_dn_name }}</span>
          </div>
          <div>
            <b>Клиент</b>
            <br>
            <Clickable v-if="!!props.row.client_dn_name" :link="true" :href="getFullUrl(`/integration/download-ds/uuid/${props.row.uuid}`)">
              {{ props.row.client_dn_name }}
            </Clickable>
          </div>
        </custom-table-cell>
        <custom-table-cell align="center">
          <div v-if="props.row.ds_document_url !== null">
            <Clickable>{{ props.row.ds_document_name }}</Clickable>
            <div class="remontDS__margin">
              <CustomIcon icon="trash"/>
            </div>
          </div>
        </custom-table-cell>
        <custom-table-cell align="center">
          <CustomIcon v-if="props.row.is_accept !== 1" icon="trash" :loading="this.deleteLoading[props.row.ds_id]"
                      approve-mode :approve="() => this.removeDS(props.row.ds_id)"/>
        </custom-table-cell>
      </q-tr>
    </template>
  </CustomTable>
</template>

<script>
import BlockItem from "@/components/common/BlockItem";
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import CustomIcon from "@/components/common/CustomIcon";
import Clickable from "@/components/common/Clickable";
import {
  acceptDS,
  changeDSSum,
  dsTypeCodeComponent,
  dsTypeCodes,
  remontDsColumns,
  removeDS as deleteDS,
  sendAcceptDS,
  sendToSignDS
} from "@/views/ClientRequests/remontDS/services";
import RoomChange from "@/views/ClientRequests/remontDS/dsCreationTemplates/roomChange";
import {downloadFile, getFullUrl, numberWithCommas} from "@/services";
import CheckOrClose from "@/components/common/CheckOrClose";
import {appActionTypes} from "@/services/store/modules/app_module/app_mutation_types";
import PopupConfirm from "@/components/common/PopupConfirm";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";
import {appActions} from "@/services/store/modules/app_module/app_actions";
import {MODAL_NAMES} from "@/constants";

export default {
  name: "RemontDS",
  components: {
    PopupConfirm,
    CheckOrClose,
    Clickable,
    CustomIcon,
    BlockItem,
    CustomTable,
    CustomTableCell,
    CustomCheckbox,
    RoomChange
  },
  props: ["client_request_id", "data", "getData", "setData", "dataFetching"],
  data() {
    return {
      selectedItems: {},
      sendAcceptLoading: {},
      sendSignLoading: {},
      acceptLoading: {},
      deleteLoading: {},
      selectedDSType: '',
      loading: false,
      isFetching: false,
      downloadingFile: {},
      remontDsColumns,
      dsTypeCodes,
      dsTypeCodeComponent,
    }
  },
  mounted() {
    this.$store.dispatch(appActionTypes.getDSTypes)
  },
  computed: {
    dsTypes() {
      return this.$store.state.app.dsTypes;
    },
    dsCreationMode() {
      return !!this.dsTypeCodeComponent[this.selectedDSType?.ds_type_code]
    }
  },
  methods: {
    getFullUrl,
    numberWithCommas,
    cancelCreation() {
      this.selectedDSType = null
    },
    handleChangeDS(row) {
      this.selectedDSType = row
    },
    handleAddDS(ds) {
      this.selectedDSType = ds
    },
    handleChangeSum(ds_id, ds_sum) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.ChangeDSSum, {
        title: 'Изменить сумму',
        onSubmit: body => this.changeSum(ds_id, body), body: {ds_sum}
      }));
    },
    async updateData() {
      this.isFetching = true
      await this.getData(true)
      this.isFetching = false
    },
    async changeSum(ds_id, body) {
      const res = await changeDSSum(this.client_request_id, ds_id, body)
      if (!res) return
      this.setData(res)
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    async removeDS(ds_id) {
      this.deleteLoading[ds_id] = true
      const res = await deleteDS(this.client_request_id, ds_id)
      this.deleteLoading[ds_id] = false
      if (!res) return
      this.setData(res)
    },
    async acceptDS(ds_id) {
      this.acceptLoading[ds_id] = true
      const res = await acceptDS(this.client_request_id, ds_id)
      this.acceptLoading[ds_id] = false
      if (!res) return
      this.setData(res)
      this.showSucceedSnackbar()
    },
    async sendToSignDS(ds_id) {
      this.sendSignLoading[ds_id] = true
      const res = await sendToSignDS(this.client_request_id, ds_id)
      this.sendSignLoading[ds_id] = false
      if (!res) return
      this.setData(res)
      this.showSucceedSnackbar()
    },
    async sendAcceptDS(ds_id) {
      this.sendAcceptLoading[ds_id] = true
      const res = await sendAcceptDS(this.client_request_id, ds_id)
      this.sendAcceptLoading[ds_id] = false
      if (!res) return
      this.setData(res)
      this.showSucceedSnackbar()
    },
    async downloadTemplate(ds_id) {
      this.downloadingFile[ds_id] = true
      await downloadFile(`/client_request/${this.client_request_id}/ds/${ds_id}/document/download/true`, 'Шаблон ДС', {
        responseType: "arraybuffer"
      }, 'pdf', 'get')
      this.downloadingFile[ds_id] = false
    },
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar();
    return {showSucceedSnackbar};
  },
}
</script>

<style scoped lang="scss">

.remontDS {
  &__margin {
    margin-top: 5px;
  }

  &__changeSum {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
  }
}

.bold {
  font-weight: 700;
}

.tableHeader {
  display: flex;
  gap: 15px;
}
</style>