import instance from "@/services/api";

export const mobileAppAPI = {
  async getApps() {
    return await instance().get('/handbook/mobile_app_versions/types/read/').then(res => res?.data)
  },
  async getMobileAppVersions(mobile_app_code) {
    return await instance().get(`/handbook/mobile_app_versions/${mobile_app_code}/read/`).then(res => res?.data)
  },
  async deleteAppVersion(mobile_app_code, mobile_app_version_id) {
    return await instance().delete(`/handbook/mobile_app_versions/${mobile_app_code}/${mobile_app_version_id}/delete/`).then(res => res?.data)
  },
  async addAppVersion(mobile_app_code, body) {
    return await instance().post(`/handbook/mobile_app_versions/${mobile_app_code}/add/`, body, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then(res => res?.data)
  },
}