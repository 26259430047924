import {financePaymentsAPI} from "@/views/FinancePayments/api";
import {financePaymentsFiltersAPI} from "@/views/FinancePayments/FinancePaymentsFilters/api";

export const getProvidersData = async () => {
  try {
    const res = await financePaymentsFiltersAPI.getProviders();
    return res?.providers;
  } catch (e) {
    return [];
  }
};

export const getCommonContractors = async () => {
  try {
    const res = await financePaymentsFiltersAPI.getContractors();
    return res?.contractors?.map(item => ({...item, legal_name: item.legal_name ? item.legal_name : item.contractor_name}));
  } catch (e) {}
};

export const getFiltersMetaData = async () => {
  return await Promise.all([
    getProvidersData(),
    getCommonContractors(),
    financePaymentsAPI.getTechprojectDevelopers(),
  ]).catch(() => {
    return null;
  });
};

export const contragentsTypes = [
  { label: "Клиенты", value: "CLIENT" },
  { label: "Подрядчики", value: "CONTRACTOR" },
  { label: "Поставщики", value: "PROVIDER" },
  { label: "Тех. проекты", value: "TECHPROJECT" },
  { label: "Другое", value: "OTHER" },
];
