import instance from "@/services/api";

export const financePaymentsAPI = {
  async getFinancePayments(params) {
    return await instance()
      .post("/finance/finance_payment/read/", params)
      .then((res) => res?.data);
  },
  async getFinancePaymentsDetail(finance_payment_id) {
    return await instance()
      .get(
        `/finance/finance_payment/${finance_payment_id}/finance_payment_items/read/`
      )
      .then((res) => res?.data);
  },
  async getInitiatorCompanies() {
    return await instance()
      .get(`/common/initiator_companies/read/`)
      .then((res) => res?.data);
  },
  async getBankBik() {
    return await instance()
      .get(`/common/bank_bik/read/`)
      .then((res) => res?.data);
  },
  async getFinancePaymentTypes() {
    return await instance()
      .get(`/common/finance_payment_types/read/creatable/`)
      .then((res) => res?.data);
  },
  async getTechprojectDevelopers() {
    return await instance()
      .get(`/common/techproject_developers/read/`)
      .then((res) => res?.data);
  },
  async addFinancePayment(body, params) {
    return await instance()
      .post("/finance/finance_payment/add/", body, {params})
      .then((res) => res?.data);
  },
  async getContragentAgreements(contragent_id) {
    return await instance()
      .post("/finance/finance_payment/provider_agreements/read/", {contragent_id})
      .then((res) => res?.data);
  },
  async getRemontsPaymentPurpose(body) {
    return await instance()
      .post("/finance/finance_payment/specifications/get/", body)
      .then((res) => res?.data);
  },
  async changeRemontsPaymentPurpose(finance_payment_id, body, params) {
    return await instance()
      .put(`/finance/finance_payment/${finance_payment_id}/edit/payment_purpose/`, body, {params})
      .then((res) => res?.data);
  },
  async getContragentInfoReq(body) {
    return await instance()
      .post("/common/contragents/get_info/", body)
      .then((res) => res?.data);
  },
  async approveChangePaymentStatus(body) {
    return await instance()
      .post(`/finance/finance_payment/change_status/`, body)
      .then((res) => res.data);
  },
  async getFinancePaymentStatuses() {
    return await instance()
      .get(`/common/finance_payment_statuses/read/action/`)
      .then((res) => res.data);
  },
  async changeDate(body, params) {
    return await instance()
      .post(`/finance/finance_payment/change_pay_date/`, body, {params})
      .then((res) => res.data);
  },
  async changeFinancePayment(finance_payment_id, body, params) {
    return await instance()
      .put(`/finance/finance_payment/${finance_payment_id}/edit/`, body, {
        params,
      })
      .then((res) => res.data);
  },
  async financePaymentsExcel(params) {
    return await instance()
      .get(`/finance/finance_payment/excel/`, {
        params: params,
        responseType: "arraybuffer"
      })
      .then((res) => res.data);
  },
  async getFinancePaymentDocs(finance_payment_id) {
    return await instance()
      .get(`/finance/finance_payment/${finance_payment_id}/documents/read/`)
      .then((res) => res.data);
  },
  async deleteFinancePaymentDoc(finance_payment_id, finance_payment_document_id) {
    return await instance()
      .delete(`/finance/finance_payment/${finance_payment_id}/documents/${finance_payment_document_id}/delete/`)
      .then((res) => res.data);
  },
  async uploadFinancePaymentDocs(finance_payment_id, body) {
    return await instance()
      .post(`/finance/finance_payment/${finance_payment_id}/documents/upload/`, body, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then((res) => res.data);
  },
};
