<template>
  <div>
    <q-form @submit="this.onSubmit" class="form">
      <div class="form__item">
        <CustomInput
            label="Комментарий"
            type="text"
            :required="true"
            :value="this.formData.comment"
            @change_input="(value) => onChange('comment', value)"
        />
      </div>
      <div class="form__item">
        <CustomSelect
            :options="this.steps"
            label="Шаг"
            :required="true"
            optionValueKey="step_id"
            optionLabelKey="step_name"
            :value="this.formData.step_id || ''"
            @change="(value) => onChange('step_id', value)"
        />
      </div>
      <div class="form__item">
        <CustomSelect
            :options="this.types"
            label="Тип"
            :required="true"
            optionValueKey="condition_type_id"
            optionLabelKey="type_name"
            :value="this.formData.condition_type_id || ''"
            @change="(value) => onChange('condition_type_id', value)"
        />
      </div>
      <div class="form__item">
        <CustomInput
            label="Параметры (json)"
            type="textarea" rows="5"
            :value="this.formData.param_json"
            @change_input="(value) => onChange('param_json', value)"
        />
      </div>
      <div class="form__item">
        <CustomInput
            label="Значение"
            :required="true"
            :value="this.formData.param_value"
            @change_input="(value) => onChange('param_value', value)"
        />
      </div>
      <div class="form__item">
        <CustomCheckbox
            color="primary"
            label="Активный"
            :value="!!this.formData.is_active"
            @change="value => onChange('is_active', value)"
        />
      </div>
      <CustomModalFooter :btn-loading="this.loading" />
    </q-form>
  </div>
</template>
<script>

import CustomInput from "@/components/form/CustomInput";
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomSelect from "@/components/form/CustomSelect";
import {getStepConditionTypes, getTimelineSteps} from "@/views/handbook/timlineStepConditions/services";
import CustomCheckbox from "@/components/form/CustomCheckbox";

export default {
  name: "add-step-condition",
  components: {CustomCheckbox, CustomSelect, CustomInput, CustomModalFooter},
  props: ['data'],
  data() {
    return {
      loading: false,
      steps: [],
      types: [],
      formData: {
        condition_type_id: this.data?.body?.condition_type_id || '',
        step_id: this.data?.body?.step_id || '',
        param_json: this.data?.body?.param_json || '',
        param_value: this.data?.body?.param_value || '',
        comment: this.data?.body?.comment || '',
        is_active: !!this.data?.body?.is_active,
      }
    }
  },
  methods: {
    onChange(key, value) {
      this.formData[key] = value
    },
    async onSubmit() {
      this.loading = true
      await this.data?.onSubmit(this.formData)
      this.loading = false
    },
    async fetchSteps() {
      const res = await getTimelineSteps()
      this.steps = res || []
    },
    async fetchConditionTypes() {
      const res = await getStepConditionTypes()
      this.types = res || []
    },
  },
  mounted() {
    this.fetchSteps()
    this.fetchConditionTypes()
  }
}
</script>

<style scoped lang="scss">
.form {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  gap: 20px;

  @media (max-width: 428px) {
    min-width: 100%;
  }
}
</style>
