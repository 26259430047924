<template>
  <SelectedReport v-if="this.selected && !this.isForm" :selected="this.selected" :go-back="() => this.onChange(null)"/>
  <SelectedFormReport v-else-if="this.selected && this.isForm" :selected="this.selected" :go-back="() => this.onChange(null)" />
  <ReportGeneratorList v-else :search-term="this.searchTerm" :onSearchChange="this.onSearchChange"
                       :reports="this.reports" @setReports="this.onReportsChange" @contentScroll="this.onScroll"
                       :scrollTop="this.scrollValue" :selected="this.selected" :onChange="this.onChange"
                       :expanded="this.expanded" :onExpand="this.onExpand"/>
</template>

<script>
import ReportGeneratorList from "@/views/ReportGenerator/list";
import SelectedReport from "@/views/ReportGenerator/report";
import SelectedFormReport from "@/views/ReportGenerator/reportForm";

export default {
  name: "ReportGenerator",
  components: {SelectedReport, ReportGeneratorList, SelectedFormReport},
  data() {
    return {
      isFetching: false,
      selected: null,
      isForm: false,
      scrollValue: 0,
      reports: [],
      expanded: [],
      expandedAll: [],
      searchTerm: '',
    }
  },
  methods: {
    onChange(data) {
      if (!data) {
        this.isForm = false
        return this.selected = null
      }
      if (!data?.child_reports) {
        this.selected = data
        this.isForm = !!data?.is_dml
      }
    },
    onScroll(value) {
      this.scrollValue = value
    },
    onReportsChange(data, expandArr) {
      this.reports = data
      this.expandedAll = expandArr
    },
    onSearchChange(value) {
      if (!!value) this.expanded = this.expandedAll
      else this.expanded = []
      this.searchTerm = value
    },
    onExpand(report_id) {
      const isExist = this.expanded?.includes(report_id)
      if (isExist)
        return this.expanded = this.expanded?.filter(item => item !== report_id)
      this.expanded = [...this.expanded, report_id]
    },
  }
}
</script>

<style scoped lang="scss">
</style>