import useQuasar from 'quasar/src/composables/use-quasar.js';;

export const useCustomSnackbar = () => {
  const $q = useQuasar();
  return {
    showSucceedSnackbar(message = "Успешно") {
      $q.notify({
        color: "green-4",
        textColor: "white",
        badgeColor: 'green',
        position: "bottom-left",
        timeout: 5000,
        type: 'positive',
        message
      });
    },
    showErrorSnackbar(message = "Что-то пошло не так!") {
      $q.notify({
        type: "negative",
        position: "bottom-left",
        timeout: 10000,
        message
      });
    },
    showWarningSnackbar(message = "Что-то пошло не так!") {
      $q.notify({
        type: "warning",
        position: "bottom-left",
        timeout: 10000,
        message
      });
    },
  };
}

