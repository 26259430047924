<template>
  <q-uploader
      @added="this.handleChange"
      @removed="this.handleRemove"
      url="http://localhost:4444/upload"
      :label="this.label" ref="uploader"
      :multiple="!!this.multiple"
      :max-file-size="this.maxFileSize"
      :no-thumbnails="true"
      :hide-upload-btn="true"
      class="uploader"
  >
    <template v-slot:header="scope">
      <div class="row no-wrap items-center q-pa-sm q-gutter-xs">
        <q-btn v-if="scope.queuedFiles.length > 0" icon="clear" @click="scope.removeQueuedFiles" round dense flat>
          <q-tooltip>Удалить все</q-tooltip>
        </q-btn>
        <q-spinner v-if="scope.isUploading" class="q-uploader__spinner"/>
        <div class="col">
          <div class="q-uploader__title">Загрузить файлы</div>
          <div class="q-uploader__subtitle">{{ scope.uploadSizeLabel }}</div>
        </div>
        <q-btn v-if="scope.canAddFiles" type="a" icon="add_box" @click="scope.pickFiles" round dense flat>
          <q-uploader-add-trigger/>
          <q-tooltip>Выбрать файлы</q-tooltip>
        </q-btn>
        <q-btn v-if="this.instantly && scope.canUpload" icon="cloud_upload" @click="scope.upload" round dense flat>
          <q-tooltip>Загрузить файлы</q-tooltip>
        </q-btn>
        <q-btn v-if="scope.isUploading" icon="clear" @click="scope.abort" round dense flat>
          <q-tooltip>Отменить загрузку</q-tooltip>
        </q-btn>
      </div>
    </template>
  </q-uploader>
</template>

<script>
export default {
  name: "CustomUploader",
  props: ['instantly', 'multiple', 'label', 'maxFileSizeMB', 'onChange', 'value'],
  methods: {
    handleChange(files) {
      if (!this.onChange) return
      return this.onChange(files)
    },
    handleRemove(files) {
      const file = files[0]
      if (!this.onChange) return
      const edited = this.value?.filter(item => item.__key !== file.__key)
      return this.onChange(edited, true)
    }
  },
  watch: {
    value(newValue) {
      if (newValue === undefined) return
      if (newValue?.length) return;
      this.$refs.uploader?.reset()
    }
  },
  computed: {
    maxFileSize() {
      if (!this.maxFileSizeMB) return undefined
      return this.maxFileSizeMB * 1048576
    }
  }
}
</script>

<style scoped lang="scss">
.uploader {
  @media (max-width: 400px) {
    max-width: 100%
  }
}
</style>