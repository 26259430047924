<template>
  <div class="wrapper">
    <q-form @submit="this.onSubmit" class="form">
      <div class="form__item" v-if="this.data?.addMode && this.data?.isAll">
        <CustomInputDropdown
            :options="this.remonts"
            label="Найдите ремонт"
            :required="true"
            @change_input="this.remontsFilterFunc"
            optionValueKey="remont_id"
            :loading="this.selectLoading"
            optionLabelKey="label"
            :value="this.remontSearch || ''"
            @select_item="this.remontSelectItem"
        />
      </div>

      <div class="form__item">
        <CustomSelect
            :options="this.remakeTypes?.map(item => item.grt_remake_type_id === this.data?.body?.grt_remake_type_id ? ({...item, bold: true}) : item)"
            label="Тип замечания"
            optionValueKey="grt_remake_type_id"
            optionLabelKey="grt_remake_type_name"
            :value="this.formData.grt_remake_type_id || ''"
            @change="(value) => onChange('grt_remake_type_id', value)"
            :required="true"
        />
      </div>
      <div class="form__item">
        <CustomInput label="Комментарии" rows="5" type="textarea"  :value="this.formData.comments"
                     @change_input="(value) => onChange('comments', value)"/>
      </div>

      <div class="form__item">
        <CustomCheckbox
            color="primary"
            label="Заявка BIG" :binary="true"
            :value="!!this.formData.is_big_request"
            @change="value => onChange('is_big_request', value)"
        />
      </div>
      <div class="form__item" v-if="!!this.formData.is_big_request">
        <CustomInput label="Номер заявки" :value="this.formData.big_request_num"
                     @change_input="(value) => onChange('big_request_num', value)"/>
      </div>
      <div class="form__item">
        <CustomUploader :value="this.formData?.files" :multiple="true"
                        :on-change="(val, removed) => this.onChange('files', val, {file: true, removed})"
                        label="Загрузите документ"/>
      </div>
      <div class="form__item" v-if="!this.data?.addMode">
        <p>Документы</p>
        <FileList :isFetching="this.isFetching" :files="this.documents" :on-delete-file-click="this.handleDeleteFile"
                  :on-open-gallery="this.openGallery" file-name-prop="document_name"
                  file-url-prop="document_url"/>
      </div>
      <CustomModalFooter :btn-loading="this.loading"/>
    </q-form>
  </div>

  <q-dialog v-model="showPopup">
    <chat-dialog :images="this.images" :img-index="this.imgIndex" :is-fetching="this.isFetching" :reset-data="this.resetData" />
  </q-dialog>
</template>
<script>

import CustomInput from "@/components/form/CustomInput";
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import {
  deleteWarrantyDocument,
  deleteWarrantyDocumentAll,
  getWarrantyDocuments,
  getWarrantyDocumentsAll,
  getWarrantyRemakeTypes,
  searchRemonts
} from "@/views/ClientRequests/warrantyRequests/services";
import CustomSelect from "@/components/form/CustomSelect";
import CustomUploader from "@/components/form/CustomUploader";
import FileList from "@/components/common/FileList";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import CustomInputDropdown from "@/components/form/CustomInputDropdown";
import {getFileExt, isFileImg} from "@/_helpers";
import {getFullUrl} from "@/services";
import ChatGallery from "@/components/modal/modals/chat-gallery";
import ChatDialog from "@/components/modal/modals/chat-gallery/chat-dialog";

export default {
  name: "change-warranty-status",
  components: {
    ChatDialog,
    ChatGallery,
    CustomInputDropdown,
    CustomCheckbox,
    FileList, CustomUploader, CustomSelect, CustomTableCell, CustomInput, CustomModalFooter
  },
  props: ['data', 'closeModal'],
  data() {
    return {
      showPopup: false,
      imgIndex: 0,
      images: [],
      isFetching: false,
      selectLoading: false,
      loading: false,
      remakeTypes: [],
      remonts: [],
      documents: [],
      remontSearch: '',
      formData: {
        is_big_request: this.data.body?.is_big_request || '',
        grt_remake_type_id: this.data?.body?.grt_remake_type_id || '',
        big_request_num: this.data?.body?.big_request_num || '',
        remont_id: this.data?.body?.remont_id || '',
        comments: this.data?.body?.comments || '',
        files: [],
      }
    }
  },
  methods: {
    onChange(key, value, info) {
      if (info?.file) {
        if (info.removed)
          this.formData[key] = value
        else
          this.formData.files = [...this.formData.files, ...value]
        return
      }
      this.formData[key] = value
      if (key === 'is_big_request' && !value)
        this.formData.big_request_num = ''
    },
    async onSubmit() {
      if (!this.data?.onSubmit) return
      const formData = new FormData()
      formData.append('grt_remake_type_id', this.formData.grt_remake_type_id)
      formData.append('remont_id', this.formData.remont_id)
      formData.append('comments', this.formData.comments)
      formData.append('is_big_request', this.formData.is_big_request)
      if (this.formData.is_big_request)
        formData.append('big_request_num', this.formData.big_request_num)

      if (this.formData.files?.length) {
        this.formData.files.forEach(item => {
          formData.append('files', item)
        })
      }

      this.loading = true
      await this.data.onSubmit(formData)
      this.loading = false
    },
    async getInfo(search) {
      this.remonts = await searchRemonts(search);
    },
    async remontsFilterFunc(value) {
      this.remontSearch = value;
      if (value?.length >= 3) {
        this.selectLoading = true;
        await this.getInfo(value);
        this.selectLoading = false;
      } else {
        this.remonts = [];
        if(!value) {
          this.formData.remont_id = ''
        }
      }
    },
    remontSelectItem(value, obj) {
      this.formData.remont_id = value || ''
      this.remontSearch = obj?.label || ''
    },
    async getDocuments() {
      if(this.data.addMode) return
      this.isFetching = true
      const res = this.data?.isAll ? await getWarrantyDocumentsAll(this.data?.grt_request_id)
          : await getWarrantyDocuments(this.data?.client_request_id, this.data?.grt_request_id)
      this.isFetching = false
      this.documents = res
    },
    async handleDeleteFile(file) {
      const res = this.data?.isAll ? await deleteWarrantyDocumentAll(this.data?.grt_request_id, file?.grt_photo_id) : await deleteWarrantyDocument(this.data?.client_request_id, this.data?.grt_request_id, file?.grt_photo_id)
      if (!res) return
      this.documents = this.documents?.filter(item => item.grt_photo_id !== file?.grt_photo_id)
      this.showSucceedSnackbar()
      if (this.data?.updateData)
        this.data.updateData(res)
    },
    resetData() {
      this.showPopup = false
      this.images = []
      this.imgIndex = 0
    },
    openGallery(file) {
      const images = this.documents?.filter(item => isFileImg(getFileExt(item.document_name))).map(item => ({
        ...item,
        file_url: getFullUrl(item?.document_url),
        file_name: item?.document_name,
      }))
      const imgIndex = images.findIndex(item => item.grt_photo_id === file?.grt_photo_id)
      this.images = images
      this.imgIndex = imgIndex
      this.showPopup = true
    },
    async getRemakeTypes() {
      this.remakeTypes = await getWarrantyRemakeTypes()
    }
  },
  mounted() {
    this.getRemakeTypes()
    this.getDocuments()
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  min-width: 500px;
}

.form {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  gap: 20px;

  @media (max-width: 428px) {
    min-width: 100%;
  }
}

@media (max-width: 576px) {
  .wrapper {
    max-width: 100%;
  }
}
</style>
