import instance from "@/services/api";

export const handbookProvidersAgreementAPI = {
  async getList(params) {
    return await instance().get('/handbook/provider_agreements/read/', {params}).then(res => res?.data)
  },
  async addProvidersAgreement(body, params) {
    return await instance().post(`/handbook/provider_agreements/add/`, body, {params}).then(res => res?.data)
  },
  async updateProvidersAgreement(contractor_agreement_list_id, body, params) {
    return await instance().put(`/handbook/provider_agreements/${contractor_agreement_list_id}/update/`, body, {params}).then(res => res?.data)
  },
  async deleteProvidersAgreement(contractor_agreement_list_id, params) {
    return await instance().delete(`/handbook/provider_agreements/${contractor_agreement_list_id}/delete/`, {params}).then(res => res?.data)
  },
}

