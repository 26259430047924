import instance from "@/services/api";

export const remontMarkStepAPI = {
  async getList(client_request_id) {
    return await instance()
      .get(`/client_request/${client_request_id}/remont_mark/read/`)
      .then((res) => res?.data);
  },
  async checkMark(client_request_id, body) {
    return await instance()
      .post(`/client_request/${client_request_id}/remont_mark/mark/`, body)
      .then((res) => res?.data);
  },
};
