<template>
  <div class="filtersWrapper">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "FiltersWrapper"
}
</script>

<style scoped lang="scss">
.filtersWrapper {
  z-index: 1000
}
</style>