import Notify from 'quasar/src/plugins/Notify.js';;
import iconSet from "quasar/icon-set/fontawesome-v6";
import "@quasar/extras/fontawesome-v6/fontawesome-v6.css";
import "./assets/styles/quasar.scss";
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/roboto-font/roboto-font.css";

// To be used on app.use(Quasar, { ... })
export default {
  config: {
    brand: {
      primary: "#1976d2",
      secondary: "#26A69A",
      accent: "#9C27B0",
      dark: "#1d1d1d",
      "dark-page": "#121212",
      positive: "#21BA45",
      negative: "#C10015",
      info: "#31CCEC",
      warning: "#F2C037",
    },
  },
  iconSet: iconSet,
  extras: ["material-icons"],
  plugins: {
    Notify,
  },
};
