import {serviceMastersAPI} from "@/views/handbook/serviceMasters/api";

export const getServiceMasters = async () => {
  try {
    const res = await serviceMastersAPI.getList()
    return res?.data
  } catch (e) {
  }
}

export const serviceMastersColumns = [
  {
    name: "fio",
    label: "ФИО",
    align: "left",
  },
  {
    name: "provider_name",
    label: "Поставщик",
    align: "left",
  },
  {
    name: "Email",
    label: "Email",
    align: "left",
  },
  {
    name: "specialities",
    label: "Специализация",
    align: "left",
  },
]