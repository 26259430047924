<template>
  <q-linear-progress indeterminate v-if="this.isFilterFetching" />
  <form @submit.prevent="this.handleSubmit"  class="filters" v-if="!this.isFilterFetching">
    <div v-for="item in this.filterBlocks" :class="['filters__item', {filters__item_date: item.report_type_code === reportFilterCodes.DATE}]">
      <CustomDate :width="140" v-if="item.report_type_code === reportFilterCodes.DATE"
                  :required="item.is_required"
                  :triggerValidationOnMount="item.is_required"
                  :value="this.filters[item.report_param_code]"
                  @change_date="(value) => this.handleChange(item.report_param_code, value)" :placeholder="item.report_param_name"
                  :full-width="true"/>
      <CustomSelect
          v-else-if="item.report_type_code === this.reportFilterCodes.SELECT || item.report_type_code === this.reportFilterCodes.MULTISELECT"
          :options="item.parameters"
          :required="item.is_required"
          :triggerValidationOnMount="item.is_required"
          :label="item.report_param_name"
          :multiple="item.report_type_code === this.reportFilterCodes.MULTISELECT"
          :optionValueKey="item.valueKey || 'id'"
          :optionLabelKey="item.labelKey || 'name'"
          :value="this.filters[item.report_param_code]"
          @change="(value) => this.handleChange(item.report_param_code, value)"
      />
      <CustomMultiSelect
          v-else-if="item.report_type_code === reportFilterCodes.MULTIDATE"
          :options="this.dateData"
          :triggerValidationOnMount="item.is_required"
          :required="item.is_required"
          :label="item.report_param_name || 'Дата'"
          optionValueKey="value"
          optionLabelKey="label"
          :value="this.filters[item.report_param_code] || []"
          @change="(value) => this.handleChange(item.report_param_code, value)"
      />
      <CustomInput v-else-if="item.report_type_code === reportFilterCodes.INPUT"
                   :value="this.filters[item.report_param_code]"
                   :required="item.is_required"
                   :triggerValidationOnMount="item.is_required"
                   :label="item.report_param_name" @clear_input="this.handleChange(item.report_param_code, '')"
                   @change_input="(value) => this.handleChange(item.report_param_code, value)"/>
    </div>
    <q-btn type="submit" padding="sm" color="primary" :loading="this.loading" label="Сформировать" size="sm"/>
    <UploadExcelBtn :loading="this.excelLoading" @click="saveExcel" />
  </form>
</template>

<script>
import CustomDate from "@/components/form/CustomDate";
import CustomSelect from "@/components/form/CustomSelect";
import CustomInput from "@/components/form/CustomInput";
import {uploadReportExcel} from "@/views/ReportGenerator/services";
import CustomMultiSelect from "@/components/form/CustomMultiSelect";
import {dateData} from "@/_helpers";
import UploadExcelBtn from "@/components/common/UploadExcelBtn";
import axios from "axios";
import {reportFilterCodes} from "@/services";

export default {
  name: "GeneratorReportFiltersAndActions",
  components: {UploadExcelBtn, CustomMultiSelect, CustomInput, CustomSelect, CustomDate},
  data() {
    return {
      dateData,
      reportFilterCodes,
      excelLoading: false,
      axiosSource: null
    }
  },
  unmounted() {
    this.axiosSource?.cancel();
  },
  props: ['getData', 'loading', 'isFilterFetching', 'filters','report_name', 'handleChange', 'selected', 'filterBlocks'],
  methods: {
    handleSubmit() {
      this.getData()
    },
    async saveExcel() {
      this.axiosSource = axios.CancelToken.source();
      this.excelLoading = true
      await uploadReportExcel(this.selected, this.filters, this.axiosSource?.token, this.report_name)
      this.excelLoading = false
    },
  }
}
</script>

<style scoped lang="scss">
.filters {
  display: flex;
  align-items: flex-end;
  gap: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;

  &__item {
    width: 230px;

    &_date {
      width: 140px;
    }
  }
}
</style>