<template>
  <Clickable v-if="this.showLinkOnError && this.isError" :link="true" :href="this.getFullUrl(this.src)">
    {{ this.alt }}
  </Clickable>
  <img
      v-else
      :class="`${className || ''}`"
      :src="src ? (isStaticImg ? src : getFullUrl(src)) : this.logo"
      v-on:load="onImgLoad" @click="this.onClick"
      v-on:error="onImgError"
      :alt="alt || ''"/>
</template>

<script>
import {getFullUrl} from "@/services";
import Clickable from "@/components/common/Clickable";

export default {
  name: "CustomImg",
  components: {Clickable},
  data() {
    return {
      logo: 'logo_small.svg',
      isError: false
    }
  },
  props: [
    "isStaticImg",
    "defaultSrc",
    "handleError",
    "src",
    "postFix",
    "onClick",
    "showLinkOnError",
    "errorClass",
    "className",
    "alt",
    "showDefaultImg",
  ],
  methods: {
    getFullUrl,
    onImgLoad(e) {
      e.target.style.visibility = "visible";
    },
    onImgError(e) {
      this.handleError && this.handleError()
      if (this.showLinkOnError) {
        e.target.classList?.add('hide')
        this.isError = true
        return;
      }
      if (this.errorClass)
        e.target.classList?.add(this.errorClass)
      if (this.showDefaultImg === false) return
      if (!!this.logo) {
        e.target.style.visibility = "visible";
        e.target.src = require(`@/assets/img/${this.logo}`);
        e.target.style.filter = "grayscale(1)"
        e.target.style.opacity = "0.2"
        e.target.style.maxWidth = "60px"
      } else e.target.style.visibility = "hidden";
    }
  }
}
</script>

<style scoped>
.hide {
  display: none;
}
</style>
