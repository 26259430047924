<template>
  <h3 class="logs__title">Логирование вызовов функций</h3>
  <div class="logs__actions">
    <q-btn :disable="this.loading" color="primary" padding="xs" @click="() => this.activateLogs(1)">Активировать логирование</q-btn>
    <q-btn :disable="this.loading" color="red" padding="xs" @click="() => this.activateLogs(0)">Очистить</q-btn>
    <q-btn :disable="this.loading" color="green" padding="xs" @click="() => this.getData()">Обновить</q-btn>
  </div>
  <div class="logs__list">
    <q-linear-progress v-if="this.loading" indeterminate/>
    <NotFoundText v-if="!this.logs?.length"/>
    <div v-for="item in this.logs" class="logs__list_item">
      <p class="logs__list_itemTitle">{{`${item.title} ${item.func_name ? `| ${item.func_name}` : ''}`}}</p>
      <p>{{item.args}}</p>
      <p v-if="item.error" class="logs__list_itemError">{{ item.error }}</p>
      <p v-if="item.context" class="logs__list_itemError">
        <span v-for="err in item.context" style="display: block">{{err}}</span>
      </p>
    </div>
  </div>
</template>

<script>
import {activateLogging, getLogs} from "@/views/logging/services";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";
import NotFoundText from "@/components/common/NotFoundText";
import {splitArrayByElement} from "@/services";

export default {
  name: "Logging",
  components: {NotFoundText},
  data() {
    return {
      loading: false,
      logs: null
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      this.loading = true
      const res = await getLogs()
      this.loading = false
      if (!res || !res?.data?.length) return this.logs = []
      const {data} = res
      const logsData = []
      let editedData = splitArrayByElement(data, '\n')?.filter(item => !!item?.length)
      if(editedData?.length) {
        editedData?.forEach(item => {
          const [title, func_name, args, error, ...other] = item
          logsData.push({title, func_name, args, error, context: other?.length ? other : null})
        })
      }
      this.logs = logsData
    },
    async activateLogs(is_debug) {
      this.loading = true
      const res = await activateLogging(is_debug)
      this.loading = false
      if (res.status) {
        this.showSucceedSnackbar()
        this.getData()
      }
    },
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar()
    return {showSucceedSnackbar}
  }
}
</script>

<style scoped lang="scss">
.logs {
  &__title {
    margin: 0;
    font-size: 24px;
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
  }
  &__list {
    max-height: 500px;
    overflow-y: auto;
    margin-top: 15px;
    &_item {
      margin-top: 13px;
      & > p {
        font-size: 14px;
        margin: 0;
      }
      &Title {
        font-weight: 500;
      }
      &Error {
        color: #C10015;
      }
    }
  }
}
</style>