<template>
  <div>
    <FiltersWrapper>
      <div ref="header">
        <FinancePaymentsFilters
            :values="this.filters"
            @filter_change="(type, value) => onFiltersChange(type, value)"
        />
        <div class="btn__wrapper">
          <div class="actions">
            <q-btn
                class="actions__edit"
                label="Редактировать"
                type="submit" no-caps
                :disable="
              this.loading || this.processing?.edit || !this.selectedLength
            "
                color="primary"
                :loading="this.processing?.edit"
                @click="this.onEditClick"
            />
            <q-btn
                v-for="item in this.financePaymentStatuses"
                :class="{
              actions__denied:
                item.finance_payment_status_code ===
                changeStatusesNames.CANCELED
            }"
                :label="item.finance_payment_status_verb_name"
                type="submit" no-caps
                :disable="
              this.loading ||
              this.processing[item.finance_payment_status_code] ||
              !this.selectedLength
            "
                color="primary"
                :loading="this.processing[item.finance_payment_status_code]"
            >
              <PopupConfirm
                  :approve="
                () =>
                  this.approve(
                    item.finance_payment_status_id,
                    item.finance_payment_status_code
                  )
              "
              />
            </q-btn>
          </div>
          <div class="actions__right">
            <q-btn no-caps :loading="this.excelLoading" :disable="this.excelLoading" class="actions__print" @click="saveExcel">
              <i :class="`material-icons`"
              >print</i>
            </q-btn>
            <q-btn color="primary" no-caps label="Добавить" @click="addPaymentClick"/>
          </div>
        </div>
      </div>
    </FiltersWrapper>
    <CustomTable
        :title="`Платежные поручения${
        !!this.selectedLength ? ` (Выбрано: ${selectedLength}), сумма: ${this.numberWithCommas(
              this.payments_amount
            )}` : ''
      }`"
        rowKey="finance_payment_id"
        :rowsData="data"
        :columns="columns"
        :page-header-height="this.$refs.header?.clientHeight"
        :showSearch="true"
        :stickyHeader="true"
        :loading="this.loading"
        :checkbox="true"
        :expand="true"
        :customBody="true"
        :checkbox-value="this.allSelected"
        :onAllCheckClick="onAllCheckClick"
        :on-all-expand-click="onExpandAllClick"
        :update-data="getData"
        :getDetailData="getDetailData"
    >
      <template #customBody="props">
        <q-tr
            :props="props"
            :class="{
            payments_row: true,
            payments_row__selected: !!props.row?.is_canceled
          }"
            @dblclick="onExpandClick(props.row.finance_payment_id)"
        >
          <custom-table-cell auto-width vertical-align="center">
            <div class="check__wrapper">
              <CustomCheckbox
                  :value="!!this.selectedItems[props.row.finance_payment_id]"
                  @change="
                  (value) =>
                    onCheckboxChange(props.row, value)
                "
              />
            </div>
          </custom-table-cell>
          <custom-table-cell v-for="col in props.cols" :key="col.name" :vertical-align="col.name === 'edit' && 'center'">
            <div
                :title="this.getColTitle(col.name)"
                :class="this.getFinancePaymentClass(col.name)"
                @click="onColClick(col.name, props.row)"
            >
              <div v-if="col.name === 'edit'" class="payments_row__icon_wrapper">
                <i
                    @click="this.onPaymentDocsClick(props.row.finance_payment_id)"
                    class="material-icons payments_row__icon payments_row__icon_file"
                >edit_document</i>
                <i
                    @click="this.onEditPaymentClick(props.row)"
                    class="material-icons payments_row__icon payments_row__icon_edit"
                >edit</i>
              </div>
              <check-or-close v-else-if="col.name === 'is_manually_created'" :value="col.value"/>
              <span v-else-if="col.name === 'finance_payment_type_name'">
                {{ col.value }}<br v-if="props.row?.is_return">
                {{ props.row?.is_return ? '(Возврат)' : '' }}
              </span>
              <span v-else>
                {{ col.value }}
              </span>
            </div>
          </custom-table-cell>
          <custom-table-cell auto-width vertical-align="center">
            <i
                class="material-icons payments_row__expand"
                @click="onExpandClick(props.row.finance_payment_id)"
            >expand_more</i
            >
          </custom-table-cell>
        </q-tr>
        <q-tr v-show="expand[props.row.finance_payment_id]" :props="props">
          <q-td colspan="100%">
            <CustomLoader v-if="!!itemsLoading[props.row.finance_payment_id]"/>
            <template v-else>
              <span class="detail__title">Количество операций: {{ props.row.item_cnt }}</span>
              <CustomTable
                  :customBody="true"
                  title="" :hide-top-title="true"
                  rowKey="client_request_id"
                  :columns="detailTableColumns[props.row.finance_payment_id]"
                  :rowsData="getCorrectList(props.row.detailData)"
              >
                <template #customBody="detailProps">
                  <q-tr :props="detailProps">
                    <q-td
                        v-for="col in detailProps.cols"
                        :key="col.name"
                        :props="detailProps"
                    >
                      <check-or-close v-if="col.name === 'is_payed'" :value="col.value"/>
                      <span v-else-if="col.name === 'remont_id'">
                        <span>
                          {{ col.value }}
                        <q-tooltip>remont_id</q-tooltip>
                        </span>
                         /
                        <span>{{ detailProps.row.client_request_id }}
                        <q-tooltip>client_request_id</q-tooltip></span>
                      </span>
                      <span v-else
                            :class="{
                          payments_row__amount: col.name === 'payment_amount'
                        }"
                      >
                        {{ col.value }}
                      </span
                      >
                    </q-td>
                  </q-tr>
                </template>
              </CustomTable>
            </template>
          </q-td>
        </q-tr>
      </template>
    </CustomTable>
  </div>
</template>

<script>
import {
  approvePaymentsChangeStatusData,
  changeFinancePayment,
  changeFinancePaymentDate,
  changeFinancePaymentPurpose,
  financePaymentsColumns,
  getColTitle,
  getCorrectList,
  getFinancePaymentClass,
  getFinancePaymentData,
  getPaymentDetail,
  onAddSubmit,
  saveFinancePaymentsExcel,
} from "./services";
import CustomTable from "@/components/common/CustomTable";
import PopupConfirm from "@/components/common/PopupConfirm";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import FinancePaymentsFilters from "./FinancePaymentsFilters";
import {appActions} from "@/services/store/modules/app_module/app_actions.js";
import {changeStatusesNames, MODAL_NAMES} from "@/constants";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";
import CustomLoader from "@/components/common/CustomLoader";
import {appActionTypes} from "@/services/store/modules/app_module/app_mutation_types";
import CheckOrClose from "@/components/common/CheckOrClose";
import {getPaymentsNewAmountValue} from "@/views/Costs/services";
import {numberWithCommas} from "@/services";
import FiltersWrapper from "@/components/common/FiltersWrapper";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";

export default {
  data() {
    return {
      changeStatusesNames,
      data: [],
      loading: false,
      excelLoading: false,
      filters: {
        created_date_begin: new Date().toLocaleDateString("ru"),
        created_date_end: new Date().toLocaleDateString("ru"),
        finance_payment_type_id: "",
        finance_payment_status_id: "",
        techproject_developer_ids: [],
        contractor_ids: [],
        provider_ids: [],
        client_request_id: "",
        is_return: "",
        finance_payment_id_filter: "",
      },
      columns: financePaymentsColumns,
      detailTableColumns: {},
      processing: {},
      itemsLoading: {},
      allSelected: false,
      selectedItems: {},
      expand: {},
      payments_amount: 0,
    };
  },
  components: {
    CustomTableCell,
    FiltersWrapper,
    CheckOrClose,
    CustomLoader,
    CustomTable,
    FinancePaymentsFilters,
    CustomCheckbox,
    PopupConfirm
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar();
    return {showSucceedSnackbar};
  },
  computed: {
    financePaymentStatuses() {
      return this.$store.state.app.financePaymentStatuses;
    },
    selectedLength() {
      return Object.keys(this.selectedItems).filter(
          (item) => !!this.selectedItems[item]
      ).length;
    },
  },
  watch: {
    selectedItems() {
      const checkedLength = this.selectedLength;
      const isAllChecked = this.data?.length === checkedLength;
      if (isAllChecked && !!checkedLength) return (this.allSelected = true);
      if (checkedLength > 0) return (this.allSelected = "");
      this.allSelected = false;
    },
  },
  methods: {
    getColTitle,
    saveFinancePaymentsExcel,
    getCorrectList,
    numberWithCommas,
    getPaymentsNewAmountValue,
    getFinancePaymentClass,
    async getData() {
      this.loading = true;
      const res = await getFinancePaymentData(this.filters);
      this.loading = false;
      if (!res) return;
      this.setCheckInitial();
      this.data = res;
    },
    async approve(status_id, type) {
      this.processing[type] = true;
      const res = await approvePaymentsChangeStatusData({
        status_id,
        ids: this.selectedItems,
        filters: this.filters,
      });
      this.processing[type] = false;
      if (res) {
        this.showSucceedSnackbar();
        this.data = res;
        this.setCheckInitial();
      }
    },
    async onAddSubmit(data) {
      const res = await onAddSubmit(data, this.filters);
      if (!res) return;
      this.data = res;
      this.$store.commit(appActions.closeModal());
      this.showSucceedSnackbar();
    },
    async onEditSubmit(financePaymentId, data) {
      const res = await changeFinancePayment(
          financePaymentId,
          data,
          this.filters
      );
      if (!res) return;
      this.data = res;
      this.$store.commit(appActions.closeModal());
      this.showSucceedSnackbar();
    },
    showPaymentModal(data = {}) {
      this.$store.commit(
          appActions.showModal(MODAL_NAMES.ADD_FINANCE_PAYMENT, {
            persistent: true,
            ...data,
          })
      );
    },
    updatePaymentsAmount(row, checked) {
      const payment = this.data?.find(
          (item) => item.finance_payment_id === row.finance_payment_id
      );
      if (!payment) return;
      this.payments_amount = this.getPaymentsNewAmountValue(
          this.payments_amount,
          payment.payment_amount,
          checked
      );
    },
    updateAllPaymentsAmount(checked) {
      if (checked) {
        let amount = this.data.reduce(
            (previousValue, currentValue) =>
                previousValue + (currentValue.payment_amount || 0),
            0
        );
        try {
          amount = +amount.toFixed(2);
        } catch (e) {
        }
        this.payments_amount = amount;
      } else this.payments_amount = 0;
    },
    async saveExcel() {
      this.excelLoading = true
      await this.saveFinancePaymentsExcel(this.filters)
      this.excelLoading = false
    },
    addPaymentClick() {
      this.showPaymentModal({
        title: "Добавить платежное поручение",
        onSubmit: this.onAddSubmit,
      });
    },
    onEditPaymentClick(body) {
      this.showPaymentModal({
        title: "Изменить платежное поручение",
        onSubmit: (data) => this.onEditSubmit(body.finance_payment_id, data),
        body,
      });
    },
    onPaymentDocsClick(finance_payment_id) {
      this.$store.commit(
          appActions.showModal(MODAL_NAMES.FINANCE_PAYMENT_DOCS, {
            finance_payment_id,
          })
      );
    },
    onEditClick() {
      this.$store.commit(
          appActions.showModal(MODAL_NAMES.CHANGE_FINANCE_PAYMENT, {
            title: "Изменить дату оплаты платежных поручений",
            date: true,
            onSubmit: (body = {}) =>
                this.changeDate({...body, ids: this.selectedItems}),
          })
      );
    },
    onColClick(colName, row) {
      if (colName === "pay_date") {
        this.$store.commit(
            appActions.showModal(MODAL_NAMES.CHANGE_FINANCE_PAYMENT, {
              title: "Изменить дату оплаты платежного поручения",
              body: row,
              date: true,
              onSubmit: (body) =>
                  this.changeDate({
                    ...body,
                    ids: {[row.finance_payment_id]: true},
                  }),
            })
        );
      }
      if (colName === 'payment_purpose_short') {
        this.$store.commit(
            appActions.showModal(MODAL_NAMES.CHANGE_FINANCE_PAYMENT, {
              title: "Назначение платежного поручения",
              purpose: true,
              body: {payment_purpose: row.payment_purpose},
              onSubmit: (body) =>
                  this.changePurpose(row.finance_payment_id, body),
            })
        );
      }
    },
    async changeDate(body) {
      this.processing.edit = true;
      const res = await changeFinancePaymentDate(body, this.filters);
      this.processing.edit = false;
      if (res) {
        this.data = res;
        this.showSucceedSnackbar();
        this.setCheckInitial();
        this.$store.commit(appActions.closeModal());
      }
    },
    async changePurpose(financePaymentId, body) {
      this.processing.edit = true;
      const res = await changeFinancePaymentPurpose(financePaymentId, body, this.filters);
      this.processing.edit = false;
      if (res) {
        this.data = res;
        this.showSucceedSnackbar();
        this.setCheckInitial();
        this.$store.commit(appActions.closeModal());
      }
    },
    async onExpandClick(id) {
      this.expand[id] = !this.expand[id];
      const expand = this.expand[id];
      if (!expand) return;
      this.itemsLoading[id] = true;
      await this.getDetailData(id);
      this.itemsLoading[id] = false;
    },
    onAllCheckClick(value) {
      if (!this.data?.length) return;
      this.allSelected = value;
      this.updateAllPaymentsAmount(value);
      const newSelectedItems = {};
      this.data.forEach((item) => {
        newSelectedItems[item.finance_payment_id] = value;
      });
      this.selectedItems = newSelectedItems;
    },
    onExpandAllClick() {
      this.expand = {};
    },
    onCheckboxChange(row, value) {
      this.updatePaymentsAmount(row, value);
      this.selectedItems = {...this.selectedItems, [row.finance_payment_id]: value};
    },
    async getDetailData(finance_payment_id) {
      const res = await getPaymentDetail(
          finance_payment_id,
          this.data,
          this.detailTableColumns
      );
      if (res) {
        const {editedData, tableColumns} = res;
        this.data = editedData;
        this.detailTableColumns = tableColumns;
      }
    },
    onFiltersChange(key, value) {
      if (this.filters[key] === value) return
      this.filters[key] = value;
      this.getData();
    },
    setCheckInitial() {
      this.selectedItems = {};
      this.allSelected = false;
      this.updateAllPaymentsAmount();
    },
  },
  mounted() {
    this.getData();
    this.$store.dispatch(appActionTypes.getFinancePaymentStatuses);
  },
};
</script>

<style scoped lang="scss">
.btn {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 15px;

    @media (max-width: 768px) {
      align-items: flex-start;
    }
  }
}

.detail__title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
  padding: 10px;
}

.edit {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: all 0.4s;
  text-decoration: underline;

  &__icon {
    position: absolute;
    transition: all 0.4s;
    display: block;
    font-size: 14px;
    right: -15px;
    opacity: 0;
  }

  &:hover {
    color: #292938;
    text-decoration: none;
  }

  &:hover &__icon {
    opacity: 1;
  }
}

.actions {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;

  &__right {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
  @media (max-width: 350px) {
    &__right {
      flex-wrap: wrap;
      justify-content: flex-end;
    }
  }

  &__print {
    background: #21830b !important;
    color: #fff;
  }

  &__edit {
    background: #cc6d33 !important;
  }

  &__denied {
    background: #9d1010 !important;
  }
}

.payments_row {
  cursor: pointer;

  &__selected {
    background-color: #ffcdcd !important;
  }

  &__expand {
    font-size: 25px;
  }

  &__hover {
    text-decoration: underline;
    transition: all 0.4s;

    &:hover {
      color: #1f5ac2;
    }
  }

  &__amount {
    span {
      color: #fff;
      background: #55c558;
      padding: 2px;
      border-radius: 2px;
      white-space: nowrap;
    }
  }

  &__icon {
    font-size: 20px;
    padding: 5px;
    transition: all 0.3s;

    &_wrapper {
      display: flex;
      align-items: center;
      gap: 2px;
    }

    &_close {
      color: #9d1010;
    }

    &_check {
      color: #55c558;
    }

    &_edit {
      border-radius: 50%;
      color: #cc6d33;

      &:hover {
        background: #ebebeb;
      }
    }

    &_file {
      border-radius: 50%;
      color: #0c2f9f;

      &:hover {
        color: #3657c0;
        background: #ebebeb;
      }
    }
  }
}
</style>
