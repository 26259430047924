<template>
  <CustomTable
      title="Вызов на группу работ"
      :rowsData="this.rowsData"
      :columns="callWorkGroupsColumns"
      :custom-body="true"
  >
    <template #customBody="props">
      <q-tr>
        <custom-table-cell align="center">
          {{ props.row.check_group_call_id }}
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.work_set_check_group_name }}
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.call_type_name }}
        </custom-table-cell>
        <custom-table-cell align="center">
          {{ props.row.call_date }}
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.fio }}
        </custom-table-cell>
        <custom-table-cell align="center">
          <CheckOrClose v-if="props.row.is_design_checked" :value="props.row.is_design_checked"/>
          <q-btn v-else padding="xs" size="sm" no-wrap color="primary"
                 :loading="this.loading[props.row.check_group_call_id]"
                 @click="() => this.eliminate(props.row.check_group_call_id)">Принять МП
          </q-btn>
        </custom-table-cell>
        <custom-table-cell align="left" :style="`backgroundColor: ${props.row.checked_status_colour}`">
          {{ props.row.checked_status }}
        </custom-table-cell>
        <custom-table-cell align="center">
          {{ props.row.check_date }}
        </custom-table-cell>
      </q-tr>
    </template>
  </CustomTable>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import {acceptDesignCheck, callWorkGroupsColumns} from "@/views/ClientRequests/remontStages/services";
import CheckOrClose from "@/components/common/CheckOrClose";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";

export default {
  name: "callWorkGroups",
  components: {CheckOrClose, CustomTable, CustomTableCell},
  props: ["data", "client_request_id"],
  data() {
    return {
      callWorkGroupsColumns,
      loading: {},
      rowsData: this.data
    }
  },
  methods: {
    async eliminate(check_group_call_id) {
      this.loading[check_group_call_id] = true
      const res = await acceptDesignCheck(this.client_request_id, check_group_call_id)
      this.loading[check_group_call_id] = false
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
    }
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar();
    return {showSucceedSnackbar};
  },
}
</script>
