<template>
  <FetchingWrapper :loading="this.isFetching" :data="this.data">
    <div class="remontStages__header">
      <q-btn color="primary" size="sm" padding="sm">
        <PopupConfirm
            :loading="this.creatingRequests"
            :approve="this.createRequests"
        />
        Создать заявки
      </q-btn>
    </div>
    <div class="remontStages__wrapper">
      <div v-for="block in this.data">
        <component :is="block.component" :data="block?.data" :client_request_id="this.client_request_id"/>
      </div>
    </div>
  </FetchingWrapper>
</template>

<script>
import RemontMaterialSmetaNotDirty from "./MaterialSmetaNotDirtyBlock";
import RemontMaterialSmetaDirty from "./MaterialSmetaDirtyBlock";
import {
  createSmetaMaterialsRequests,
  getRemontMaterialSmetaData
} from "@/views/ClientRequests/remontMaterialSmeta/services";
import NotFoundText from "@/components/common/NotFoundText";
import FetchingWrapper from "@/components/common/FetchingWrapper";
import PopupConfirm from "@/components/common/PopupConfirm";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";

export default {
  name: "RemontMaterialSmeta",
  components: {PopupConfirm, FetchingWrapper, NotFoundText, RemontMaterialSmetaNotDirty, RemontMaterialSmetaDirty},
  props: ["client_request_id"],
  data() {
    return {
      data: [],
      isFetching: false,
      creatingRequests: false,
    }
  },
  methods: {
    async getData() {
      this.isFetching = true
      const res = await getRemontMaterialSmetaData(this?.client_request_id)
      this.isFetching = false
      if (!res) return
      this.data = res
    },
    async createRequests() {
      this.creatingRequests = true
      const res = await createSmetaMaterialsRequests(this.client_request_id)
      this.creatingRequests = false
      if (!res) return
      this.data = res
      this.showSucceedSnackbar()
    }
  },
  mounted() {
    this.getData()
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar();
    return {showSucceedSnackbar};
  },
}
</script>

<style scoped lang="scss">
.remontStages {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  &__header {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
  }
}

</style>