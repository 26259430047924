<template>
  <div class="techprojectRequests__filters">
    <div class="techprojectRequests__filters_item">
      <CustomSelect
          :options="this.residents"
          label="ЖК"
          optionValueKey="resident_id"
          optionLabelKey="resident_name"
          :value="this.filters.resident_id"
          @change="(value) => onChange('resident_id', value)"
      />
    </div>
    <div class="techprojectRequests__filters_item">
      <CustomSelect
          :options="this.techprojectRequestStatuses"
          label="Статус"
          optionValueKey="id"
          optionLabelKey="label"
          :value="this.filters.status_id"
          @change="(value) => onChange('status_id', value)"
      />
    </div>
  </div>
  <CustomTable
      title="Заявки для проектировщиков"
      :rowsData="this.data"
      :columns="techprojectRequestsColumns"
      :loading="isFetching"
      :custom-body="true"
      :hide-footer-title="true"
  >
    <template #customBody="props">
      <q-tr>
        <custom-table-cell align="center">
          <Clickable router-link :href="`/requests/${props.row.client_request_id}`">
            {{ props.row.client_request_id || '' }}
          </Clickable>
        </custom-table-cell>
        <custom-table-cell align="center">
          {{ props.row.remont_id }}
        </custom-table-cell>
        <custom-table-cell align="left">
          <span class="techprojectRequests__name">{{ props.row.client_name }}</span>
          <br/>
          <span>{{ props.row.preset_name }}</span>
          <span v-if="!!props.row.sh_preset_id">{{ `Умный дом: ${props.row.sh_preset_name_short}` }}</span>
        </custom-table-cell>
        <custom-table-cell align="center">
          {{ props.row.request_date_text }}
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.address }}, кв. №{{ props.row.flat_num }}
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.resident_name }}
        </custom-table-cell>
        <custom-table-cell align="right">
          {{ props.row.area }}
        </custom-table-cell>
        <custom-table-cell align="right">
          {{
            props.row.days_for_project > 0
                ? props.row.days_for_project + 1
                : props.row.days_for_project === 0
                    ? props.row.days_for_project - 1
                    : props.row.days_for_project
          }}
        </custom-table-cell>
        <custom-table-cell align="center">
          <div class="techprojectRequests__warning" v-if="props.row.is_tech_feature === 1">
            <p> <q-icon name="warning"/> Имеются технические особенности</p>
          </div>
        </custom-table-cell>
        <custom-table-cell align="center">
          <CheckOrClose only-true :value="props.row.is_measure_confirm === 1"/>
        </custom-table-cell>
        <custom-table-cell align="center">
          <CheckOrClose only-true :value="props.row.project_accepted === 1"/>
        </custom-table-cell>
        <custom-table-cell align="center">
          <span>{{ props.row.meet_on_place_date }}</span>
          <br/>
          <CheckOrClose only-true :value="props.row.meet_on_place === 1"/>
        </custom-table-cell>
        <custom-table-cell align="center">
          <CheckOrClose only-true :value="props.row.audit_checked === 1"/>
        </custom-table-cell>
        <custom-table-cell align="center">
          <CheckOrClose only-true :value="props.row.project_edited === 1"/>
        </custom-table-cell>
        <custom-table-cell align="center">
          <CheckOrClose only-true :value="props.row.project_taken === 1"/>
        </custom-table-cell>
        <custom-table-cell align="center">
          <CheckOrClose only-true :value="props.row.specification_signed === 1"/>
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.status_name }}
        </custom-table-cell>
      </q-tr>
    </template>
  </CustomTable>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import CheckOrClose from "@/components/common/CheckOrClose";
import {
  getTechprojectRequests,
  techprojectRequestsColumns,
  techprojectRequestStatuses
} from "@/views/techprojectRequests/services";
import Clickable from "@/components/common/Clickable";
import CustomSelect from "@/components/form/CustomSelect";
import {getResidentsByCity} from "@/services/auth";

export default {
  name: "TechprojectRequests",
  components: {
    CustomSelect,
    Clickable,
    CheckOrClose, CustomTable, CustomTableCell
  },
  data() {
    return {
      techprojectRequestsColumns,
      filters: {
        resident_id: this.$route.query?.resident_id ? Number(this.$route.query?.resident_id) : '',
        status_id: this.$route.query?.status_id ? Number(this.$route.query?.status_id) : ''
      },
      residents: [],
      data: [],
      isFetching: false,
      techprojectRequestStatuses
    }
  },
  methods: {
    async getData() {
      this.isFetching = true
      const res = await getTechprojectRequests(this.filters)
      this.isFetching = false
      if (!res) return
      this.data = res
    },
    async getResidents() {
      const res = await getResidentsByCity()
      if (!res) return
      this.residents = res
    },
    onChange(type, value) {
      this.filters[type] = value;
      this.$router.push({query: this.filters})
      this.getData()
    },
  },
  mounted() {
    this.getData()
    this.getResidents()
  }
}
</script>

<style scoped lang="scss">
.techprojectRequests {
  &__filters {
    display: flex;
    align-items: flex-end;
    gap: 15px;
    margin-bottom: 15px;

    &_item {
      width: 250px;
    }
  }

  &__name {
    color: #2e4b8f;
    font-weight: 700;
    line-height: 18px;
  }

  &__warning {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    p {
      color: #eb9c24;
      padding: 5px;
      border-radius: 5px;
      margin: 0 0 10px;
      box-shadow: 0 0 3px #a15f20;
      font-size: 10px;
    }
  }
}
</style>
