<template>
  <CustomTable
      title="Конструктивы"
      :rowsData="this.rowsData"
      :columns="this.handbookWorksetsColumns"
      :loading="isFetching"
      :custom-body="true"
      :small="true"
      :show-search="true"
      :sticky-header="true">
    <template #customBody="props">
      <q-tr>
        <custom-table-cell align="center">
          {{props.row?.work_set_id}}
        </custom-table-cell>
        <custom-table-cell align="left">
          {{props.row?.work_set_name}}
        </custom-table-cell>
        <custom-table-cell align="left">
          {{props.row?.work_set_check_group_name}}
        </custom-table-cell>
        <custom-table-cell align="left">
          {{props.row?.speciality_name}}
        </custom-table-cell>
        <custom-table-cell align="center">
          <CheckOrClose :value="props.row?.is_call"/>
        </custom-table-cell>
        <custom-table-cell align="center">
          <CheckOrClose :value="props.row?.is_service"/>
        </custom-table-cell>
        <custom-table-cell align="center">
          <CheckOrClose :value="props.row?.is_pay_online"/>
        </custom-table-cell>
        <custom-table-cell align="left">
          {{props.row?.service_call_work_set_group_name}}
        </custom-table-cell>
        <custom-table-cell align="center" vertical-align="center">
          <CustomIcon @click="() => this.handleWorksetChange(props.row)" icon="edit" />
        </custom-table-cell>
      </q-tr>
    </template>
  </CustomTable>
</template>

<script>
import {getHandbookWorksets, updateHandbookWorkset} from "@/views/handbook/WorkSets/services";
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import {handbookWorksetsColumns} from './services'
import CheckOrClose from "@/components/common/CheckOrClose";
import CustomIcon from "@/components/common/CustomIcon";
import {appActions} from "@/services/store/modules/app_module/app_actions";
import {MODAL_NAMES} from "@/constants";
import {useCustomSnackbar} from "@/_helpers/hooks/useCustomSnackbar";

export default {
  name: "HandbookWorkSets",
  components: {CustomIcon, CheckOrClose, CustomTableCell, CustomTable},
  data() {
    return {
      handbookWorksetsColumns,
      rowsData: [],
      isFetching: false
    }
  },
  methods: {
    async getData() {
      this.isFetching = true
      const res = await getHandbookWorksets()
      this.isFetching = false
      if (!res) return
      this.rowsData = res
    },
    handleWorksetChange(row) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.EDIT_HANDBOOK_WORKSET, {
        body: row,
        title: 'Изменение конструктива',
        onSubmit: body => this.handleChangeWorkset(row?.work_set_id, body)
      }));
    },
    async handleChangeWorkset(work_set_id, body) {
      const res = await updateHandbookWorkset(work_set_id, body)
      if(!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    }
  },
  mounted() {
    this.getData()
  },
  setup() {
    const {showSucceedSnackbar} = useCustomSnackbar();
    return {showSucceedSnackbar};
  },
}
</script>

<style scoped>
</style>