import {changeWarrantyStatusAPI} from "@/components/modal/modals/change-warranty-status/api";

export const getWarrantyStatusHistory = async (client_request_id, grt_request_id) => {
  try {
    const res = await changeWarrantyStatusAPI.getList(client_request_id, grt_request_id)
    return res.data?.map((item, i)=> ({...item, number: i + 1}))
  } catch (e) {}
}
export const getWarrantyStatuses = async () => {
  try {
    const res = await changeWarrantyStatusAPI.getStatuses()
    return res.data
  } catch (e) {}
}
export const changeWarrantyStatusColumns = [
  {
    name: "number",
    label: "№",
    field: "number",
    align: "center",
  },
  {
    name: "status",
    label: "Статус",
    field: "status",
    align: "left",
  },
  {
    name: "date",
    label: "Дата",
    field: "date",
    align: "center",
  },
  {
    name: "comment",
    label: "Комментарий",
    field: "comment",
    align: "left",
  },
  {
    name: "manager",
    label: "Менеджер ГО",
    field: "manager",
    align: "left",
  },
]

export const warrantyDeniedReasons = [
  {label: 'Не гарантийный случай', value: '1'},
  {label: 'Не указано в АПП', value: '2'},
  {label: 'Конструктив Застройщика', value: '3'},
  {label: 'Нет доступа', value: '4'},
]