<template>
  <div class="filters">
    <div class="filters__item">
      <CustomInput
        type="number"
        @enter="this.onSubmit"
        label="Грейд"
        :value="values.grade_id"
        @change_input="(value) => this.onChange('grade_id', value)"
      />
    </div>
    <q-btn
      :loading="this.loading"
      :disable="this.loading"
      @click="this.onSubmit"
      color="primary"
    >
      <q-icon name="search" />
    </q-btn>
    <q-toggle label="Редактирование" class="filters__checkbox" v-model="this.options.dragMode" />
  </div>
</template>

<script>
import CustomInput from "@/components/form/CustomInput";

export default {
  name: "grade-timeline-filters",
  props: ["values", "onChange", "onSubmit", "options", "loading"],
  components: { CustomInput },
};
</script>

<style scoped lang="scss">
.filters {
  display: flex;
  align-items: center;
  gap: 15px;
  row-gap: 0;
  padding: 15px 15px 5px;
  flex-wrap: wrap;
  &__item {
    width: 100px;
  }

  @media (max-width: 400px) {
    gap: 10px;
  }
}
</style>
