import {timelineStepConditionsAPI} from "@/views/handbook/timlineStepConditions/api";

export const getTimelineStepConditions = async () => {
  try {
    const res = await timelineStepConditionsAPI.getList()
    if(!res?.data?.length) return
    return res?.data?.map(item => ({...item, step_label: `${item.step_id}. ${item.step_name}`}))
  } catch (e) {
  }
}
export const addTimelineStepCondition = async (body = {}) => {
  try {
    const res = await timelineStepConditionsAPI.addCondition(body)
    return res?.data
  } catch (e) {
  }
}
export const updateTimelineStepCondition = async (step_condition_id, body = {}) => {
  try {
    const res = await timelineStepConditionsAPI.updateCondition(step_condition_id, body)
    return res?.data
  } catch (e) {
  }
}
export const deleteTimelineStepCondition = async (step_condition_id) => {
  try {
    const res = await timelineStepConditionsAPI.deleteCondition(step_condition_id)
    return res?.data
  } catch (e) {
  }
}
export const getTimelineSteps = async () => {
  try {
    const res = await timelineStepConditionsAPI.getTimelineSteps()
    return res?.data
  } catch (e) {
  }
}
export const getStepConditionTypes = async () => {
  try {
    const res = await timelineStepConditionsAPI.getConditionTypes()
    return res?.data
  } catch (e) {
  }
}

export const stepConditionsColumns = [
  {
    name: "step_condition_id",
    field: "step_condition_id",
    sortable: true,
    label: "ID",
    align: "center",
  },
  {
    name: "comment",
    field: "comment",
    label: "Комментарий",
    sortable: true,
    align: "left",
  },
  {
    name: "step_label",
    field: "step_label",
    sortable: true,
    label: "Шаг",
    align: "left",
  },
  {
    name: "is_active",
    field: "is_active",
    sortable: true,
    label: "Активность",
    align: "center",
  },
  {
    name: "condition_type_name",
    field: "condition_type_name",
    sortable: true,
    label: "Тип",
    align: "left",
  },
  {
    name: "param_json",
    field: "param_json",
    label: "Параметры (json)",
    align: "left",
  },
  {
    name: "param_value",
    field: "param_value",
    label: "Значение",
    sortable: true,
    align: "left",
  },
  {
    name: "actions",
    label: "",
    align: "center",
  },
]