<template>
  <div class="remontDS__wrapper">
    <FetchingWrapper :loading="this.isFetching" :data="this.data">
      <div v-for="item in this.data">
        <component :is="item.component" :getData="this.getData" :setData="this.setData" :dataFetching="this.isFetching" :client_request_id="this.client_request_id"
                   :data="item?.data"/>
      </div>
    </FetchingWrapper>
  </div>
</template>

<script>
import {getRemontDsData} from "@/views/ClientRequests/remontDS/services";
import RemontDsBlock from "./remontDS";
import FetchingWrapper from "@/components/common/FetchingWrapper";

export default {
  name: "RemontDS",
  components: {FetchingWrapper, RemontDsBlock},
  props: ["client_request_id"],
  data() {
    return {
      data: [],
      isFetching: false,
    }
  },
  methods: {
    async getData(hideFetching = false) {
      this.isFetching = hideFetching !== true
      const res = await getRemontDsData(this?.client_request_id)
      this.isFetching = false
      if (!res) return
      this.data = res
    },
    setData(newData) {
      this.data = newData
    }
  },
  mounted() {
    this.getData()
  },
}
</script>

<style scoped lang="scss">

.remontDS {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
}
</style>