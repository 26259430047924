import instance from "@/services/api/index";
import { PAGES } from "@/constants";
import cookiesService from "@/services/cookiesService";
import axios from "axios";

const refreshTokenEndpoint = "/auth/api/token/refresh/";
const cookiesServiceInstance = cookiesService.getService();

const removeAuthAndRedirectToLogin = () => {
  cookiesServiceInstance.clearToken();
  window.location.href = PAGES.login;
};

export const refreshToken = (redirect = false, originalRequest) => {
  const refresh = cookiesServiceInstance.getRefreshToken();
  if (!refresh) {
    return redirect ? removeAuthAndRedirectToLogin() : false;
  }
  return instance(false)
    .post(refreshTokenEndpoint, {
      refresh,
    })
    .then((res) => {
      if (res.status === 400) {
        return redirect ? removeAuthAndRedirectToLogin() : false;
      }
      if (res.status >= 200) {
        cookiesServiceInstance.setToken(res.data);
        window.location.reload()
        if (originalRequest) return instance(true)(originalRequest);
        return true;
      }
    })
    .catch(() => {
      return redirect ? removeAuthAndRedirectToLogin() : false;
    });
};

const tokenRefresher = (error) => {
  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    refreshToken(true, originalRequest);
  }
};

export const handleError = (error) => {
  if (axios.isCancel(error)) {
    return;
  }
  if(parseInt(error.response?.status) === 401)
    return tokenRefresher(error);
};
